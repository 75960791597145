import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TxreportapiService } from '../../shared/services/txreportapi.service';
import { MessengerService } from '../../shared/services/messenger.service';
import { FetchReports, SetReports } from '../actions/reports.actions';
import { map, switchMap } from 'rxjs/operators';
import { ReportEntity } from '../entities/ReportEntity';

@Injectable()
export class ReportsEffects {
	fetchList$ = createEffect(() =>
		this.actions.pipe(
			ofType(FetchReports),
			switchMap(() => this.api.fetchAll()),
			map((res) => SetReports(res?.map((r) => ReportEntity.tryCast(r))))
		)
	);

	constructor(
		private readonly actions: Actions,
		private readonly api: TxreportapiService,
		private readonly msg: MessengerService
	) {}
}
