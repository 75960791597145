import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectOfficeService } from '../services/project-office.service';
import { ProjectDetailEntity } from '../../store/entities/ProjectDetailEntity';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeDetailResolver implements Resolve<ProjectDetailEntity> {
	constructor(private service: ProjectOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ProjectDetailEntity> | Promise<ProjectDetailEntity> | ProjectDetailEntity {
		return this.service.getProject(route.params.id);
	}
}
