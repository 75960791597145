export class ListOfProjectResponse {
	calculated_time: string;
	creation_date: Date | string;
	project_id: number;
	project_name: string;
	project_number: string;
	project_state: number;
	target_date: Date | string;
	target_time: string;
}
