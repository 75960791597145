<div class="grid" *ngIf="{ users: Users$ | async, currentUser: CurrentUser$ | async, head: MessageHead$ | async } as data">
    <div class="col-12 xl:col-3">
        <mat-card class="mat-elevation-z0" *ngIf="ActiveUsers$ | async as Users">
            <mat-card-title>
                <div>Message Center</div>
            </mat-card-title>
            <tx-list [items]="Users"
                     [labelField]="getLabel"
                     [idField]="'persnr'"
                     [searchField]="'firstname,lastname'"
                     [showNew]="false"
                     [badges]="getBadges(data.head)"
                     routerLinkBase="personaloffice/messages"
                     (selectedItem)="Employee = $event ? $event.firstname + ' ' + $event.lastname : ''"
            ></tx-list>
        </mat-card>
    </div>
    <div class="col-12 xl:col-9" *ngIf="UserMessages$ | async as UserMessages">
        <section class="widget">
            <div>
                <div style="height: 82vh;">
                    <tx-chat
                            #chat
                            [height]="getAvailableHeight() - (AnswerMessage ? 58 : 0)"
                            [is_office]="true"
                            [Messages]="UserMessages"
                            [(WithArchived)]="WithArchived"
                            [Title]="Employee"
                            [(AnswerMessage)]="AnswerMessage"
                            (refresh)="refresh()"
                            (send)="sendMessage($event.message, $event.answer)"
                            (archive)="archiveMessage($event.message, $event.remove)"
                    ></tx-chat>
                </div>
            </div>
        </section>
    </div>
</div>
