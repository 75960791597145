import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PersonalOfficeService } from '../services/personal-office.service';
import { take } from 'rxjs/operators';
import { TeamLeaderEntity } from '../../store/entities/TeamLeaderEntity';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PoTeamleaderResolver implements Resolve<TeamLeaderEntity[]> {
	constructor(private po: PersonalOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<TeamLeaderEntity[]> | Promise<TeamLeaderEntity[]> | TeamLeaderEntity[] {
		return this.po.getTeamleaderList(true).pipe(take(1));
	}
}
