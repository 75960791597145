import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { InfocenterProtokoll } from '../../shared/interfaces/protokollInfocenter';
import { TxApiService } from '../../shared/services/txapi.service';
import { InfocenterService } from '../../shared/services/infocenter.service';
import { Subscription } from 'rxjs';

declare var jQuery: any;

@Component({
	selector: '[infocenter]',
	templateUrl: 'infocenter.template.html',
})
export class InfoCenterComponent implements OnInit {
	@Output() toggleChatEvent: EventEmitter<any> = new EventEmitter();
	currentChoice = 'Name';
	currentChoiceASCDESC = '+';
	infoCenterOpened = false;
	$el: any;
	config: any;
	myInfocenter: InfocenterProtokoll;
	infoCenterSubscription: Subscription;
	public myInfocenterSort = 'name';

	constructor(
		private el: ElementRef,
		private myTxApiserice: TxApiService,
		private myInfocenterService: InfocenterService
	) {
		this.$el = jQuery(el.nativeElement);
		this.infoCenterSubscription = this.myInfocenterService.getInfoCenter().subscribe((myInfocenterData) => {
			this.myInfocenter = myInfocenterData;
		});
	}

	initChatSidebarScroll(): void {
		const $sidebarContent = jQuery('.infocenter-contacts', this.$el);
		if (this.$el.find('.slimScrollDiv').length !== 0) {
			$sidebarContent.slimscroll({
				destroy: true,
			});
		}
		$sidebarContent.slimscroll({
			height: window.innerHeight,
			width: '',
			size: '4px',
		});
	}

	setActive(aChoice: string): void {
		if (this.getActive(aChoice) === 'active') {
			this.toogleCurrentChoiceASCDESC();
		} else {
			// status muss genau umgekehrt sortiert werden.
			if (aChoice === 'status') {
				this.currentChoiceASCDESC = '-';
			}
		}

		this.currentChoice = aChoice;
	}

	toogleCurrentChoiceASCDESC() {
		/* toggle the order by flag */
		if (this.currentChoiceASCDESC === '+') {
			this.currentChoiceASCDESC = '-';
		} else {
			this.currentChoiceASCDESC = '+';
		}
	}

	getActive(aChoice: string): string {
		if (this.currentChoice === aChoice) {
			return 'active';
		} else {
			return '';
		}
	}

	toggleInfoCenter(): void {
		this.toggleChatEvent.emit(null);
	}

	ngOnInit(): void {
		jQuery('tx-layout').addClass('chat-sidebar-container');

		jQuery(window).on('sn:resize', this.initChatSidebarScroll.bind(this));
		this.initChatSidebarScroll();
	}
}
