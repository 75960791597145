import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
	providedIn: 'root',
})
export class TerminalService {
	terminalModeSubject = new BehaviorSubject(false);
	pinLoginSubject = new BehaviorSubject(null);
	pinLoggedInSubject = new BehaviorSubject(null);
	pinLogin = this.pinLoginSubject.asObservable();
	pinLoggedIn = this.pinLoggedInSubject.asObservable();
	terminalMode = this.terminalModeSubject.asObservable();

	fastLogoutSubject = new BehaviorSubject(false);
	fastLogout = this.fastLogoutSubject.asObservable();

	qrModeSubject = new BehaviorSubject(false);
	qrMode = this.qrModeSubject.asObservable();

	qrQrPermanentCameraSubject = new BehaviorSubject(false);
	qrQrPermanentCamera = this.qrQrPermanentCameraSubject.asObservable();

	qrQrPermanentCameraHiddenSubject = new BehaviorSubject(false);
	qrQrPermanentCameraHidden = this.qrQrPermanentCameraHiddenSubject.asObservable();

	qrQrCameraSubject = new BehaviorSubject(null);
	qrQrCamera = this.qrQrCameraSubject.asObservable();

	qrQrCameraPositionSubject = new BehaviorSubject(null);
	qrQrCameraPosition = this.qrQrCameraPositionSubject.asObservable();

	qrQrCameraPositionConfigSubject = new BehaviorSubject(null);
	qrQrCameraPositionConfig = this.qrQrCameraPositionConfigSubject.asObservable();

	constructor(private myAuthSerivce: AuthService, private myLocalStorage: LocalStorage) {
		this.setTerminalModeFromStorage();
		this.setPinLoginFromStorage();
		this.setQrModeFromStorage();
		this.setQrPermanentCameraFromStorage();
		this.setQrPermanentCameraHiddenFromStorage();
		this.setQrCameraFromStorage();
		this.setQrCameraPositionFromStorage();
		this.setQrCameraPositionConfigFromStorage();
		this.setFastLogoutFromStorage();

		this.pinLoginSubject.subscribe((data) => {
			if (data !== null) {
				localStorage.setItem('txPinLogin', data ? 'true' : 'false');
			}
		});

		const txPinLoggedIn = localStorage.getItem('txPinLoggedIn');

		this.pinLoggedInSubject.next(localStorage.getItem('txPinLoggedIn') === 'true');
		this.pinLoggedInSubject.subscribe((value) => {
			if (value !== null) {
				localStorage.setItem('txPinLoggedIn', value ? 'true' : 'false');
			}
		});
	}

	setFastLogout(enabled: boolean) {
		localStorage.setItem('txFastLogout', String(enabled));
		this.fastLogoutSubject.next(enabled);
	}

	setFastLogoutFromStorage() {
		this.fastLogoutSubject.next(localStorage.getItem('txFastLogout') === 'true');
	}

	setQrMode(mode: number) {
		localStorage.setItem('txQrMode', mode === 1 ? 'true' : 'false');
		this.qrModeSubject.next(Boolean(mode));
	}

	setQrModeFromStorage() {
		const qrMode = localStorage.getItem('txQrMode');
		this.qrModeSubject.next(qrMode === 'true');
	}

	setQrPermanentCamera(mode: number) {
		localStorage.setItem('txQrPermanentCamera', mode === 1 ? 'true' : 'false');
		this.qrQrPermanentCameraSubject.next(Boolean(mode));
	}

	setQrPermanentCameraFromStorage() {
		const qrMode = localStorage.getItem('txQrPermanentCamera');
		this.qrQrPermanentCameraSubject.next(qrMode === 'true');
	}

	setQrPermanentCameraHidden(mode: number) {
		localStorage.setItem('txQrPermanentCameraHidden', mode === 1 ? 'true' : 'false');
		this.qrQrPermanentCameraHiddenSubject.next(Boolean(mode));
	}

	setQrPermanentCameraHiddenFromStorage() {
		const qrMode = localStorage.getItem('txQrPermanentCameraHidden');
		this.qrQrPermanentCameraHiddenSubject.next(qrMode === 'true');
	}

	setQrCamera(camera: { deviceId: string }) {
		localStorage.setItem('txQrCamera', camera.deviceId);
		this.qrQrCameraSubject.next(camera.deviceId);
	}

	setQrCameraFromStorage() {
		const camera = localStorage.getItem('txQrCamera');
		this.qrQrCameraSubject.next(camera);
	}

	setQrCameraPosition(point: { x: number; y: number }) {
		localStorage.setItem('txQrCameraPosition', JSON.stringify(point));
		this.qrQrCameraPositionSubject.next(point);
	}

	setQrCameraPositionFromStorage() {
		const point = localStorage.getItem('txQrCameraPosition');
		this.qrQrCameraPositionSubject.next(JSON.parse(point) ?? { x: 0, y: 0 });
	}

	setQrCameraPositionConfig(mode: number) {
		localStorage.setItem('txQrCameraPositionConfig', mode === 1 ? 'true' : 'false');
		this.qrQrCameraPositionConfigSubject.next(Boolean(mode));
	}

	setQrCameraPositionConfigFromStorage() {
		const mode = localStorage.getItem('txQrCameraPositionConfig');
		this.qrQrCameraPositionConfigSubject.next(mode === 'true');
	}

	setTerminalMode(mode: number) {
		localStorage.setItem('txTerminalMode', mode === 1 ? 'true' : 'false');
		this.terminalModeSubject.next(Boolean(mode));
	}

	setTerminalModeFromStorage() {
		const terminalMode = localStorage.getItem('txTerminalMode');
		this.terminalModeSubject.next(terminalMode === 'true');
	}

	setPinLogin(state: boolean) {
		this.pinLoginSubject.next(state);
		localStorage.setItem('txPinLogin', state ? 'true' : 'false');
	}

	setPinLoginFromStorage() {
		const pinLogin = localStorage.getItem('txPinLogin');
		this.pinLoginSubject.next(pinLogin === 'true');
	}
}
