<ng-container *ngIf="what$ | async as what">
    <div mat-dialog-title class="display-flex flex-justify-content-space-between">
        <h3 class="m-0 mr-1">
            {{ (data.what === 'departments' ? 'infocenter.department' : data.what === 'groups' ? 'infocenter.group' : 'infocenter.team') | translate }}
        </h3>
        <div class="display-flex flex-align-items-center">
            <div *ngIf="IsLoading$ | async as isLoading" class="mr-2">
                <mat-spinner *ngIf="isLoading" diameter="24" [strokeWidth]="3"></mat-spinner>
            </div>
            <button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
                <fa-icon icon="times"></fa-icon>
            </button>
        </div>
    </div>
    <div mat-dialog-content style="min-width: 700px">
        <div class="display-flex flex-direction-column">
            <div class="data" *ngFor="let d of what">
                <div *ngIf="EditMode[d.id]" class="mat-dense-input display-flex flex-grow-1 mr-1">
                    <mat-form-field appearance="standard" class="flex-grow-1">
                        <mat-label>{{ 'txPersonalFiles.name' | translate }}</mat-label>
                        <input
                                matInput
                                [disabled]="!EditMode[d.id]"
                                [(ngModel)]="getEdited(d).name" />
                    </mat-form-field>
                </div>
                <span *ngIf="!EditMode[d.id]" class="mat-h3 m-0" style="padding: 9px 0">
					{{ getEdited(d).name }}
				</span>
                <div class="flex-grow-1"></div>
                <div *ngIf="!EditMode[d.id]">
                    <div *ngIf="d.sync_state === 1"
                         class="state-info state-icon bg-green-500 text-center mr-4"
                         matTooltip="{{ 'txPersonalFiles.tooltips.synced' | translate }}"
                    >
                        <fa-icon icon="check" class="text-white"></fa-icon>
                    </div>
                    <div *ngIf="d.sync_state === 0"
                         class="state-info state-icon bg-red text-white text-center mr-4"
                         matTooltip="{{ 'txPersonalFiles.tooltips.unsynced' | translate }}"
                    >
                        <fa-icon icon="times" class="text-white"></fa-icon>
                    </div>
                </div>
                <div class="display-flex flex-align-items-center">
                    <ng-container *ngIf="!EditMode[d.id]">
                        <button mat-mini-fab class="mr-1" (click)="EditMode[d.id] = true">
                            <fa-icon icon="pencil-alt"></fa-icon>
                        </button>
                        <button mat-mini-fab color="primary" (click)="onDelete(d)">
                            <fa-icon icon="trash"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="EditMode[d.id]">
                        <button *ngIf="EditMode[d.id]" mat-mini-fab class="mr-1" (click)="onSave(d)">
                            <fa-icon icon="save"></fa-icon>
                        </button>
                        <button mat-mini-fab color="primary" (click)="onDiscard(d)">
                            <fa-icon icon="times-circle"></fa-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="display-flex mt-1 flex-align-items-center new-data">
            <mat-form-field appearance="outline" class="full-width mr-1">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="NewName" />
            </mat-form-field>
            <div class="full-height display-flex flex-align-items-center">
                <button mat-mini-fab (click)="onAddData()" [disabled]="!canAdd(what)">
                    <fa-icon icon="plus"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</ng-container>
