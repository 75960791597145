import { EmployeeDetailResponse } from './Api/EmployeeDetail.response';
import { UserPermissionEntity } from './UserPermissionEntity';
import { ListOfUserPermissionResponse } from './Api/ListOfUserPermissionResponse';
import { EmployeeTeamleaderAssingmentEntity } from './EmployeeTeamleaderAssingmentEntity';
import { ListOfTeamleaderAssignmentResponse } from './Api/ListOfTeamleaderAssignmentResponse';
import { ListOfEmployeeHolidayAccountEntity } from './ListOfEmployeeHolidayAccountEntity';
import { ListOfEmployeeHolidayAccountResponse } from './Api/ListOfEmployeeHolidayAccount.response';

export class EmployeeEntity extends EmployeeDetailResponse {
	private static SKIP_PERMISSION: string[] = ['PWReset', 'Cloud PIN', 'Loginname', 'QR Code'];

	constructor() {
		super();

		this.isLoaded = false;
	}

	public permissions: UserPermissionEntity[] = [];
	public assingments: EmployeeTeamleaderAssingmentEntity[] = [];
	public holidayAccounts: ListOfEmployeeHolidayAccountEntity[] = [];

	public isLoaded: boolean;

	public static tryCast(o: any): EmployeeEntity {
		const ret = new EmployeeEntity();
		return Object.assign(ret, o);
	}

	public getStaffPermissions() {
		return this.permissions.filter(
			(p) => (p.staff_office || p.value_type === 'label') && !EmployeeEntity.SKIP_PERMISSION.includes(p.name)
		);
	}
	public getLoginPermissions() {
		return this.permissions.filter((p) => p.staff_office && EmployeeEntity.SKIP_PERMISSION.includes(p.name));
	}

	public IsLoaded() {
		return this.isLoaded;
	}

	public Load(
		data: EmployeeDetailResponse,
		permissions: ListOfUserPermissionResponse[],
		assingments: ListOfTeamleaderAssignmentResponse[],
		holidayAccounts: ListOfEmployeeHolidayAccountResponse[]
	) {
		const ret = new EmployeeEntity();
		Object.assign(ret, this, data);
		ret.isLoaded = true;
		if (permissions) {
			ret.permissions = permissions
				.filter((p) => p.parent_key == 0)
				.map((p) => {
					const e = UserPermissionEntity.tryCast(p);
					e.children = permissions
						.filter((c) => c.parent_key == p.key)
						.map((x) => {
							const c = UserPermissionEntity.tryCast(x);

							c.children = permissions
								.filter((cc) => cc.parent_key == x.key)
								.map((xx) => UserPermissionEntity.tryCast(xx))
								.sort((a, b) => a.order_key - b.order_key);

							return c;
						})
						.sort((a, b) => a.order_key - b.order_key);
					return e;
				})
				.sort((a, b) => a.order_key - b.order_key); // permissions.map((x) => UserPermissionEntity.tryCast(x));
		}
		if (assingments) {
			ret.assingments = assingments.map((x) => EmployeeTeamleaderAssingmentEntity.tryCast(x));
		}
		if (holidayAccounts) {
			ret.holidayAccounts = holidayAccounts.map((x) => ListOfEmployeeHolidayAccountEntity.tryCast(x));
		}
		return ret;
	}
}
