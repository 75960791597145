export class EmployeeDetailResponse {
	employee_id: number;
	persnr: string;
	pin_code: string;
	lohn_persnr: string;
	teamleader: number;
	txp_active: number;

	firstname: string;
	lastname: string;
	employee_type: number;
	birthday: Date;
	city: string;
	comp_idcard: string;
	comp_idcard_date: string;
	costcenter_id: number;
	department_id: number;
	documentation: string;
	driving_lic: string;
	driving_lic_date: string;
	driving_lic_iv: number;
	email1: string;
	email2: string;
	empl_contract: string;
	empl_contract_date: string;
	employment_type: number;
	entry_date1: Date;
	entry_date2: Date;
	exit_date: string;
	group_id: number;
	idcard: string;
	idcard_date: string;
	model1: number;
	model2: number;
	model3: number;
	model4: number;
	model5: number;
	passport: string;
	passport_date: string;
	payrate1: number;
	payrate2: number;
	payrate3: number;
	phone1: string;
	phone2: string;

	salary_type: number;
	start_date: Date;
	start_holiday: number;
	start_value: number;
	special_holiday: number;
	annual_holiday: number;
	street: string;
	team_id: number;
	zipcode: string;

	card_number: number;
	fp_number: number;
	qr_code: string;

	/*
    email3: string;
    email4: string;
    email5: string;

    phone3: string;
    phone4: string;
    phone5: string;
    */
}
/*
birthday: "1980-09-09T00:00:00"
city: "Bad Steben"
comp_idcard: null
comp_idcard_date: ""
costcenter_id: 0
department_id: 0
documentation: null
driving_lic: null
driving_lic_date: ""
email1: "sn@senesoft.de"
email2: null
email3: null
email4: null
email5: null
empl_contract: null
empl_contract_date: ""
employee_id: 1
employee_type: 1
employment_type: 5
entry_date1: "1998-09-01T00:00:00"
entry_date2: ""
exit_date: ""
firstname: "Sebastian"
group_id: 0
idcard: null
idcard_date: ""
isLoaded: true
lastname: "Neubner"
model1: 1
model2: 0
model3: 0
model4: 0
model5: 9
passport: null
passport_date: ""
payrate1: 0
payrate2: 0
payrate3: 0
persnr: "A1000"
phone1: "0170341554"
phone2: null
phone3: null
phone4: null
phone5: null
salary_type: 2
start_date: "2020-01-10T00:00:00"
start_holiday: 26
start_value: 0
street: "Teststreet"
team_id: 0
zipcode: "89655"
 */
