import { Component, Input } from '@angular/core';
import moment from 'moment';
import { TxWfCalendarWithDay } from '../po-types';

@Component({
	selector: 'tx-time-beam',
	templateUrl: './tx-time-beam.component.html',
	styleUrls: ['./tx-time-beam.component.scss'],
})
export class TxTimeBeamComponent {
	public times: string[];

	@Input() public Values: TxWfCalendarWithDay[];

	constructor() {
		this.times = [];

		const dayTimes = moment
			.twix(moment().startOf('day').add(5, 'hours'), moment().endOf('day').subtract(4, 'hours'))
			.iterate('hours');
		do {
			this.times.push(dayTimes.next().format('HH:mm'));
		} while (dayTimes.hasNext());
	}
}
