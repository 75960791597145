import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { storeLogger } from 'ngrx-store-logger';

import * as fromEvents from './events.reducer';
import * as fromUser from './user.reducer';
import * as fromUserStates from './user-state.reducer';
import * as fromPersonalOffice from './personal-office.reducer';
import * as fromTeamleader from './teamleader.reducer';
import * as fromProjectOffice from './project-office.reducer';
import * as fromReports from './reports.reducer';
import * as fromPlans from './plans.reducer';
import * as fromData from './data.reducer';
import * as fromMessages from './message-center.reducer';
import { KillStore } from '../actions';
import { storeFreeze } from 'ngrx-store-freeze';

export interface State {
	events: fromEvents.State;
	user: fromUser.State;
	userStates: fromUserStates.State;
	personalOffice: fromPersonalOffice.State;
	teamleader: fromTeamleader.State;
	projectOffice: fromProjectOffice.State;
	reports: fromReports.State;
	plans: fromPlans.State;
	data: fromData.State;
    messages: fromMessages.State;
}

export const reducers: ActionReducerMap<State> = {
	events: fromEvents.reducer,
	user: fromUser.reducer,
	personalOffice: fromPersonalOffice.reducer,
	userStates: fromUserStates.reducer,
	teamleader: fromTeamleader.reducer,
	projectOffice: fromProjectOffice.reducer,
	reports: fromReports.reducer,
	plans: fromPlans.reducer,
	data: fromData.reducer,
    messages: fromMessages.reducer
};

export function logger(reducer: ActionReducer<State>): any {
	// default, no options
	return storeLogger()(reducer);
}

function resetStoreMiddleware(reducer: ActionReducer<any>): ActionReducer<any> {
	return (state, action) => {
		if (action.type === KillStore.type) {
			return reducer(undefined, action);
		}
		return reducer(state, action);
	};
}

export const metaReducers: MetaReducer<State>[] = !environment.production
	? [storeFreeze, resetStoreMiddleware]
	: [resetStoreMiddleware];
