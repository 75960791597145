import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FetchMessages, SetMessages, SetUserMessages } from './../../store/actions/message-center.actions';
import { TxSession } from './../../shared/interfaces/session';
import { getSession } from './../../store/selectors/user.selectors';
import { MessageCenterService } from './../../shared/services/message-center.service';
import { getEmployees } from './../../store/selectors/personal-office.selectors';
import { EmployeeEntity } from './../../store/entities/EmployeeEntity';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { MessageEntity } from '../../store/entities/MessageEntity';
import { getMessageHead, getMessageList } from '../../store/selectors/message-center.selectors';
import { State } from '../../store/reducers';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TxChatComponent } from '../tx-chat/tx-chat.component';
import { isNumber } from 'util';
import { MessageHead } from '../../store/entities/Api/MessageHead.response';
import { MessageHeadEntity } from '../../store/entities/Api/MessageHeadEntity';

@Component({
	selector: 'tx-messagecenter',
	templateUrl: './tx-messagecenter.component.html',
	styleUrls: ['./tx-messagecenter.component.scss'],
})
export class TxMessageCenterComponent implements AfterViewInit {
	public testData: {
		id: number;
		name: string;
		message: string;
	}[] = [];

	public baseDisplayedColumns = ['direction', 'creation_date', 'message', 'target_persnr', 'actions'];
	public archivedDisplayedColumns = ['direction', 'creation_date', 'message', 'target_persnr', 'archived', 'actions'];

	public displayedColumns = ['direction', 'creation_date', 'message', 'target_persnr', 'actions'];

	public UserMessages$: Observable<MessageEntity[]>;

	public MessageDataSource: MatTableDataSource<MessageEntity> = new MatTableDataSource();

	public Users$: Observable<EmployeeEntity[]>;
	public ActiveUsers$: Observable<EmployeeEntity[]>;
	public MessageHead$: Observable<MessageHead[]>;

	public CurrentUser$: Observable<TxSession>;

	public Employee: string;
	public Message: string;

	public AnswerMessage: MessageEntity;

	public WithArchived = false;

	private employee: string;

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('chat') chat: TxChatComponent;

	constructor(
		private readonly store: Store<State>,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly service: MessageCenterService
	) {
		this.MessageHead$ = this.store.select(getMessageHead);

		this.UserMessages$ = combineLatest([
			router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map((event: NavigationEnd) => {
						return event.url.split('/').pop();
					})
				)
				.pipe(
					filter((id) => !!id && !isNaN(+id)),
					map((id: string) => {
						this.employee = id;
						return id;
					}),
					switchMap((id) => this.service.fetchMessages(+id, this.WithArchived).pipe(map((res) => res.messages))),
					tap((res) => {
						setTimeout(() => {
							this.chat.scrollToBottom();
						}, 100);
					})
				)
				.pipe(startWith(null)),
			of(this.router.url.split('/').pop()).pipe(
				switchMap((id) =>
					this.service.fetchMessages(+id, this.WithArchived).pipe(
						filter((res) => res && res.type !== SetUserMessages.type),
						map((res) => res.messages),
						startWith(null)
					)
				)
			),
		]).pipe(map((arr) => arr.filter(Boolean).pop()));

		this.Users$ = this.MessageHead$.pipe(
			filter((heads) => !!heads),
			withLatestFrom(this.store.select(getEmployees)),
			map(([heads, users]) =>
				users.slice().sort((a, b) => {
					const headA = heads?.find((h) => h.persnr === +a.persnr);
					const headB = heads?.find((h) => h.persnr === +b.persnr);
					if (headA && headB) {
						return new Date(headB.last_message_date) > new Date(headA.last_message_date) ? 1 : -1;
					}
					if (headA && !headB) {
						return -1;
					}
					if (!headA && headB) {
						return 1;
					}
					return +a.persnr - +b.persnr;
				})
			)
		);

		this.ActiveUsers$ = this.store.select(getEmployees).pipe(map((users) => users.filter((u) => u.txp_active === 1)));

		this.CurrentUser$ = this.store.select(getSession);
	}

	ngAfterViewInit(): void {
		if (!isNaN(+this.router.url.split('/').pop())) {
			this.service.fetchMessages(+this.router.url.split('/').pop(), this.WithArchived);
		}

		this.service.fetchMessageHead().pipe(take(1)).subscribe();

		this.MessageDataSource.sort = this.sort;
	}

	sendMessage(message: string, answer: MessageEntity) {
		this.service.sendMessage(+this.employee, message, +this.router.url.split('/').pop(), answer);
	}

	/*getEmployeeName(persnr: number, users: EmployeeEntity[]) {
		const user = users.find((u) => u.persnr === `${persnr}`);
		return user ? `${user.firstname} ${user.lastname}` : persnr;
	}*/

	archiveMessage(element: MessageEntity, remove: boolean = false) {
		this.service.archiveMessage(element.id, +this.router.url.split('/').pop(), this.WithArchived, remove);
	}

	/*refetchMessages() {
		this.store.dispatch(FetchMessages(0, this.WithArchived));
		if (this.WithArchived) {
			this.displayedColumns = this.archivedDisplayedColumns;
		} else {
			this.displayedColumns = this.baseDisplayedColumns;
		}
	}*/

	refresh() {
		this.store.dispatch(FetchMessages(+this.employee, this.WithArchived));
	}

	getLabel(user: EmployeeEntity) {
		return user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.persnr;
	}

	getBadges(head: MessageHeadEntity[]) {
		return (
			head?.reduce((acc, cur) => {
				acc[cur.persnr] = cur.unread_messages;
				return acc;
			}, {}) ?? {}
		);
	}

	getAvailableHeight() {
		return window.innerHeight - 263;
	}
}
