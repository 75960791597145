import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProjectBookingEntity } from '../../store/entities/ProjectBookingEntity';
import { Observable } from 'rxjs';
import { ProjectOfficeService } from '../services/project-office.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeDetailBookingsResolver implements Resolve<ProjectBookingEntity[]> {
	constructor(private service: ProjectOfficeService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ProjectBookingEntity[]> | Promise<ProjectBookingEntity[]> | ProjectBookingEntity[] {
		return this.service.getProjectBookings(route.params.id);
	}
}
