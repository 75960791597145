import { AfterViewInit, Component, DoCheck, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import './scr/clockpicker.js';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field/form-field';

declare var jQuery: any;

export const WLCOLORPICKER_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => WLClockpickerComponent),
	multi: true,
};

export const noop = () => {};

@Component({
	selector: 'wl-clockpicker',
	templateUrl: './wl-clockpicker.template.html',
	providers: [WLCOLORPICKER_VALUE_ACCESSOR],
})
export class WLClockpickerComponent implements DoCheck, ControlValueAccessor, OnInit, AfterViewInit {
	@Input() placeholder = '';
	@Input() placement = 'bottom';
	@Input() align = 'left';
	@Input() donetext = 'done';
	@Input() autoclose = false;
	@Input() twelvehour = false;
	@Input() vibrate = false;
	@Input() id = '';
	@Input() name = '';
	@Input() disabled = false;
	@Input() material = false;
	@Input() required = false;
	@Input() appearance: MatFormFieldAppearance = 'outline';
	@Input() textAlign = 'left';

	private innerValue = 'now'; // The internal data model

	// Placeholders for the callbacks which are later provided
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	@ViewChild('input') input: ElementRef<HTMLInputElement>;

	constructor(el: ElementRef) {}

	// get accessor
	get value(): any {
		return this.innerValue;
	}

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.onChangeCallback(v);
		}
	}

	ngDoCheck(): void {}

	ngOnInit() {}

	onClick(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		event.cancelBubble = true;

		this.input.nativeElement.click();
	}

	ngAfterViewInit() {
		this.registerClockpicker();
	}

	registerClockpicker() {
		const cp = jQuery('#' + this.id).clockpicker({
			placement: this.placement,
			default: this.innerValue,
			align: this.align,
			donetext: this.donetext,
			autoclose: this.autoclose,
			twelvehour: this.twelvehour,
			vibrate: this.vibrate,
			afterDone: (element) => {
				this.innerValue = this.getClockPickerValue(element);
				this.onChangeCallback(this.innerValue);
			},
		});
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	// Set touched on blur
	onBlur() {
		this.onTouchedCallback();
	}

	getClockPickerValue(clockPickerValue: any): string {
		let tmpMinutes = clockPickerValue.minutes;
		let tmpHours = clockPickerValue.hours;

		if (tmpHours < 10) {
			tmpHours = '0' + tmpHours;
		}

		if (tmpMinutes < 10) {
			tmpMinutes = '0' + tmpMinutes;
		}
		return tmpHours + ':' + tmpMinutes;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
	}
}
