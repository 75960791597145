import { createAction } from '@ngrx/store';
import { MessageEntity } from '../entities/MessageEntity';
import { MessageHeadEntity } from '../entities/Api/MessageHeadEntity';

export const FetchMessages = createAction('[MESSAGES] fetch', (employee_id: number, include_archived: boolean) => ({
	employee_id,
	include_archived,
}));

export const SetMessages = createAction('[MESSAGES] set messages', (messages: MessageEntity[]) => ({ messages }));

export const SetUserMessages = createAction('[MESSAGES] set user messages', (messages: MessageEntity[]) => ({
	messages,
}));

export const SendMessage = createAction(
	'[MESSAGES] send message',
	(message: string, receiver_id: number, personal_office_for: number, parent: MessageEntity) => ({
		message,
		receiver_id,
		personal_office_for,
		parent,
	})
);

export const ArchiveMessage = createAction(
	'[MESSAGES] archive message',
	(id: string, personal_office_for: number, archive: boolean, remove: boolean) => ({
		id,
		personal_office_for,
		archive,
		remove,
	})
);

export const UpdateMessage = createAction(
	'[MESSAGES] update message',
	(
		id: string,
		personal_office_for: number,
		archive: boolean,
		withArchived: boolean,
		remove: boolean,
		read: boolean
	) => ({
		id,
		personal_office_for,
		archive,
		withArchived,
		remove,
		read,
	})
);

export const setMessageHeader = createAction('[MESSAGES] set message head', (messageHead: MessageHeadEntity[]) => ({
	messageHead,
}));
