export class ListOfUserPermissionResponse {
	key: number;
    parent_key: number;
	name: string;
	value: 0 | 1;
	value_text: string | null;
	value_type: string | null;
	staff_office: boolean;
    selection_value: string | null;
    selection_source: string | null;
    children?: ListOfUserPermissionResponse[];
    options?: any[];
    order_key: number;
    columns: number;

    getChildren(): ListOfUserPermissionResponse[] {
        return this.children?.filter(c => c.staff_office) || [];
    }
}
