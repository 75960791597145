import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPo from '../reducers/personal-office.reducer';
import { EmployeeEntity } from '../entities/EmployeeEntity';
import { ListTypes } from '../actions/personal-office.actions';
import { TypeEntity } from '../entities/TypeEntity';

export const getPersonalOffice = createFeatureSelector<fromPo.State>('personalOffice');

export const getEmployees = createSelector(getPersonalOffice, (s) => s.employees);

export const getEmployeeById = (id: number) =>
	createSelector(getPersonalOffice, (s) => s.employees?.find((e) => e.employee_id === id) as EmployeeEntity | null);

export const getTimemodels = createSelector(getPersonalOffice, (s) => s.timemodels);

export const getTimemodelDetailById = (id) =>
	createSelector(getPersonalOffice, (s) => s.timemodelDetails?.find((tmd) => tmd.model_id === id));

export const getTimemodelDetails = createSelector(getPersonalOffice, (s) => s.timemodelDetails);

export const getTypeList = (listType: ListTypes) =>
	createSelector(getPersonalOffice, (s) => s[listType] as TypeEntity[]);

export const getTeamleaderList = createSelector(getPersonalOffice, (s) => s.teamleader);

export const getAllTeamleaderEmployeeAssignments = createSelector(
	getPersonalOffice,
	(s) => s.teamleaderEmployeeAssignments
);
export const getTeamleaderEmployeeAssignments = (id: number) =>
	createSelector(getPersonalOffice, (s) => s.teamleaderEmployeeAssignments[id]);

export const getCalendarGroupList = createSelector(getPersonalOffice, (s) => s.calendarGroup);

export const getTeamList = createSelector(getPersonalOffice, (s) => s.teams);
