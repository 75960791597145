import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { TxApiService } from '../../../shared/services/txapi.service';
import { lastValueFrom } from 'rxjs';
import { TxListOfWorkflowTyp } from '../../../shared/interfaces/workflow';
import { ApiResponse } from '../../../store/entities/Api/ApiResponse';

interface TimeTrackingDialogData {
	title: string;
}

@Component({
	templateUrl: './tx-time-tracking.dialog.html',
	styleUrls: ['./tx-time-tracking.dialog.scss'],
})
export class TxTimeTrackingDialogComponent implements OnInit {
	@Input()
	public title: string;

	public WorkFlowTypes: TxListOfWorkflowTyp[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: TimeTrackingDialogData,
		private readonly api: TxApiService
	) {
		this.title = data.title;
	}

	ngOnInit() {
		lastValueFrom(
			this.api
				.callAPI('getWorkflowTyp', {
					staff_office: 1,
				})
				.pipe(take(1))
		).then((res: ApiResponse<TxListOfWorkflowTyp[]>) => {
			this.WorkFlowTypes = res.ListOfWorkflowTyp;
		});
	}
}
