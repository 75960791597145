export const CLOUD_MENU = 'cloud_menu';
export const WORKFLOW = 'worflow';
export const CALENDAR = 'calendar';
export const PROJECT_OFFICE = 'projectoffice';
export const PLANNING = 'planning';
export const STAFF_OFFICE = 'staff_office';
export const STAFF_OFFICE_ACCOUNTS = 'staff_office_accounts';
export const STAFF_OFFICE_FILE = 'staff_office_file';
export const STAFF_OFFICE_TIMEMODEL = 'staff_office_timemodel';
export const STAFF_OFFICE_TEAMCONFIG = 'staff_office_timemodel';

export const STAFF_OFFICE_TEAMLEITERGRUPPEN = 'staff_office_tlg';
export const IS_TEAMLEADER = 'is_teamleader';

export const HAS_MESSAGECENTER = 'messages_available';
export const STAFF_OFFICE_FILE_PERMISSIONS = 'staff_office_file_permissions';
