import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'groupFilter',
	pure: true,
})
export class GroupFilterPipe implements PipeTransform {
	transform(value: any, args: (any & { id: number | string, active: boolean })[], trigger: boolean): any {
		if (args) {
			return value.filter((data) => {
				const activeGroups = args.filter(Boolean).filter((group) => group.active === true);
				const groupIds = activeGroups.map((group) => group.id);
				return groupIds.includes(data.group);
			});
		}
		return value;
	}
}
