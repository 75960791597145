import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectOfficeService } from '../../../shared/services/project-office.service';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { ProjectEntity } from '../../../store/entities/ProjectEntity';
import { getFilterStatus, getProjectList, getSearchQuery } from '../../../store/selectors/project-office.selectors';
import { Sort } from '@angular/material/sort';
import { debounceTime, delay, takeUntil, timeout, withLatestFrom } from 'rxjs/operators';
import { ProjectStateEntity } from '../../../store/entities/ProjectStateEntity';
import { ActivatedRoute } from '@angular/router';
import { FetchList, SetFilterStatus, SetSearchQuery } from '../../../store/actions/project-office.actions';
import { MatDialog } from '@angular/material/dialog';
import TxProjectOfficeWorkstepsDialogComponent from '../tx-project-office-worksteps-dialog/tx-project-office-worksteps-dialog.component';
import moment from 'moment';

@Component({
	selector: 'tx-tx-project-office',
	templateUrl: './tx-projects-office.component.html',
	styleUrls: ['./tx-projects-office.component.scss'],
})
export class TxProjectsOfficeComponent implements OnInit, OnDestroy {
	public Projects$: Observable<ProjectEntity[]>;
	public FilterStatus$: Observable<number>;

	public ProjectsDataSource: ProjectEntity[] = [];

	public ProjectStates: ProjectStateEntity[] = null;

	public SearchQuery: string = null;

	public StatusFilter: number | null = null;

	public displayedColumns = [
		'project_number',
		'project_name',
		'project_state',
		'creation_date',
		'target_date',
		'target_time',
		'calculated_time',
		'actions',
	];

	private Search$: Observable<string>;
	private destroyed$ = new Subject<boolean>();

	private timeCalcFields = ['calculated_time', 'target_time'];
	private dateFields = ['creation_date', 'target_date'];

	constructor(
		private service: ProjectOfficeService,
		private route: ActivatedRoute,
		private store: Store<State>,
		private dialog: MatDialog
	) {
		this.Projects$ = this.store.select(getProjectList);
		this.Search$ = this.store.select(getSearchQuery);
		this.FilterStatus$ = this.store.select(getFilterStatus);

		this.Search$.pipe(takeUntil(this.destroyed$)).subscribe((query) => {
			this.SearchQuery = query;
		});

		this.FilterStatus$.pipe(takeUntil(this.destroyed$), withLatestFrom(this.Projects$)).subscribe(
			([status, projects]) => {
				if (!projects || (this.StatusFilter !== null && this.StatusFilter !== status)) {
					this.store.dispatch(FetchList(status));
				}
				this.StatusFilter = status;
			}
		);

		this.Projects$.pipe(takeUntil(this.destroyed$)).subscribe((projects) => {
			this.ProjectsDataSource = projects?.slice() ?? [];
		});

		this.Search$.pipe(debounceTime(300), withLatestFrom(this.Projects$), takeUntil(this.destroyed$)).subscribe(
			async ([value, projects]) => {
				this.ProjectsDataSource =
					projects?.slice().filter((p) => {
						return !value || value === '' || p.toString().toLowerCase().includes(value.toLowerCase());
					}) ?? [];
			}
		);

		route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
			const { projectStates } = data;
			this.ProjectStates = projectStates;
		});
	}

	ngOnInit(): void {}

	deleteProject(id: number) {
		this.service.deleteProject(id);
	}

	async onSort(sort: Sort) {
		const [, projects] = await of(null).pipe(withLatestFrom(this.Projects$)).toPromise();
		if (sort.direction === '') {
			this.ProjectsDataSource = projects.slice();
		} else {
			this.ProjectsDataSource = projects.slice().sort((a, b) => {
				if (this.timeCalcFields.includes(sort.active)) {
					return (
						(moment.duration(a[sort.active] ?? '00:00') < moment.duration(b[sort.active] ?? '00:00') ? -1 : 1) *
						(sort.direction === 'asc' ? 1 : -1)
					);
				}
				if (this.dateFields.includes(sort.active)) {
					let aDate = moment(a[sort.active]);
					if (!aDate.isValid()) {
						aDate = moment('01.01.1970', 'DD.MM.YYYY');
					}
					let bDate = moment(b[sort.active]);
					if (!bDate.isValid()) {
						bDate = moment('01.01.1970', 'DD.MM.YYYY');
					}
					return (aDate < bDate ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
				}
				return (
					(a[sort.active]?.toString() ?? '').localeCompare(b[sort.active]?.toString() ?? '') *
					(sort.direction === 'asc' ? 1 : -1)
				);
			});
		}
	}

	getProjectState(value: number) {
		return this.ProjectStates.find((p) => p.value === value)?.description ?? `${value}`;
	}

	onSearch(query: string) {
		this.store.dispatch(SetSearchQuery(query));
	}

	onFilterList(status: number) {
		this.store.dispatch(SetFilterStatus(status));
	}

	onOpenWorkstepsDialog() {
		this.dialog.open(TxProjectOfficeWorkstepsDialogComponent);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
