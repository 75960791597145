import { ListOfPlanCalendarResponse } from './Api/ListOfPlanCalendarResponse';
import { ListOfPlanItemResponse } from './Api/ListOfPlanItemResponse';

export class PlanCalendarEntity extends ListOfPlanCalendarResponse {
	public static tryCast(o: any) {
		const ret = new PlanCalendarEntity();
		ret.ListOfPlanItem = o.ListOfPlanItem?.map((l) => Object.assign(new ListOfPlanItemResponse(), l));
		return Object.assign(ret, o);
	}
}
