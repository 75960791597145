import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectOfficeService } from '../services/project-office.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorkstepEntity } from '../../store/entities/WorkstepEntity';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeWorkstepsResolver implements Resolve<WorkstepEntity[]> {
	constructor(private projectOfficeService: ProjectOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<WorkstepEntity[]> | Promise<WorkstepEntity[]> | WorkstepEntity[] {
		return this.projectOfficeService.fetchWorksteps().pipe(take(1));
	}
}
