<ng-container>
	<div mat-dialog-title class="display-flex flex-justify-content-space-between flex-align-items-center">
		<h3 class="m-0 mr-1">{{ 'txPlaning.plan' | translate }}: {{ Plan.name }}</h3>
		<div>
			<button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
				<fa-icon icon="times"></fa-icon>
			</button>
		</div>
	</div>
	<div class="plan-detail-content overflow-hidden pb-1" mat-dialog-content>
		<form [formGroup]="PlanForm">
			<div class="flex">
				<mat-form-field class="flex-grow-1" label="Name" appearance="outline">
					<mat-label>Name</mat-label>
					<input matInput formControlName="name" />
				</mat-form-field>
			</div>
			<div class="flex">
				<wl-clockpicker
					id="plan-begin-time"
					class="flex-grow-1 mr-2"
					formControlName="begin_time"
					[placeholder]="'txPlaning.startTime' | translate"
					[donetext]="'txPlaning.done' | translate"
					name="timeFormMaterial-begin"
					[autoclose]="true"
					[required]="true"
					[material]="true">
				</wl-clockpicker>
				<wl-clockpicker
					id="plan-end-time"
					class="flex-grow-1 mr-2"
					formControlName="end_time"
					[placeholder]="'txPlaning.endTime' | translate"
					[donetext]="'txPlaning.done' | translate"
					name="timeFormMaterial-end"
					[autoclose]="true"
					[required]="true"
					[material]="true">
				</wl-clockpicker>
				<mat-form-field class="w-3 flex-grow-0" appearance="outline" label="Farbe">
					<mat-label>{{ 'txPlaning.color' | translate }}</mat-label>
					<input matInput [ngxMatColorPicker]="picker" formControlName="color" />
					<ngx-mat-color-toggle
						matSuffix
						[for]="picker"
						[style]="{ 'color': PlanForm.value.color }"></ngx-mat-color-toggle>
					<ngx-mat-color-picker #picker color="primary" [touchUi]="false"></ngx-mat-color-picker>
				</mat-form-field>
			</div>
			<div class="flex flex-wrap justify-content-between">
				<mat-checkbox formControlName="monday" color="primary">{{ 'txPlaning.monday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="tuesday" color="primary">{{ 'txPlaning.tuesday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="wednesday" color="primary">{{ 'txPlaning.wednesday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="thursday" color="primary">{{ 'txPlaning.thursday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="friday" color="primary">{{ 'txPlaning.friday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="saturday" color="primary">{{ 'txPlaning.saturday' | translate }}</mat-checkbox>
				<mat-checkbox formControlName="sunday" color="primary">{{ 'txPlaning.sunday' | translate }}</mat-checkbox>
			</div>
			<div class="mt-2 flex justify-content-end">
				<button mat-raised-button class="mr-2" (click)="initForm(Plan)" [disabled]="!PlanForm.dirty">
                    {{ 'general.reset' | translate }}
				</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="PlanForm.invalid || !PlanForm.dirty"
					[mat-dialog-close]="result">
                    {{ 'general.save' | translate }}
				</button>
			</div>
		</form>
	</div>
</ng-container>
