import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'tx-modal-pdf',
	templateUrl: './tx-modal-pdf.component.html',
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'flex',
		style: 'min-height: inherit',
	},
})
export class TxModalPdfComponent implements OnInit, AfterViewInit {
	@ViewChild('content')
	content: ElementRef;

	url$: BehaviorSubject<Uint8Array> = new BehaviorSubject<Uint8Array>(null);
	downloadUrl = '';

	fullHeight = '';
	fullWidth = '';

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public readonly data: {
			blob: Blob;
			title: string;
		},
		private readonly san: DomSanitizer
	) {}

	ngOnInit() {
		this.getUrl();
	}

	ngAfterViewInit() {
		this.fullHeight = this.content.nativeElement.offsetHeight + 'px';
		this.fullWidth = this.content.nativeElement.offsetWidth + 'px';
	}

	getUrl() {
		this.data.blob.arrayBuffer().then((data) => {
			this.url$.next(new Uint8Array(data));
		});

		this.downloadUrl = URL.createObjectURL(this.data.blob);
		//this.url$.next(this.san.bypassSecurityTrustResourceUrl(`${url}#toolbar=1&statusbar=0&view=fitH`));
	}

	clickDownload() {
		let downloadLink = document.createElement('a');
		downloadLink.href = this.downloadUrl;
		downloadLink.setAttribute('download', `${this.data.title}.pdf`);
		document.body.appendChild(downloadLink);
		downloadLink.click();
		setTimeout(() => {
			downloadLink.remove();
		}, 500);
	}
}
