import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { Actions, ofType } from '@ngrx/effects';
import { lastValueFrom, Observable, of } from 'rxjs';
import { PlanEntity } from '../../store/entities/PlanEntity';
import { map, switchMap } from 'rxjs/operators';
import { FetchCalendar, FetchPlans, SetCalendar, SetPlans } from '../../store/actions/plans.actions';
import { getPlanCalendar, getPlansList } from '../../store/selectors/plans.selectors';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { PlanCalendarEntity } from '../../store/entities/PlanCalendarEntity';

@Injectable({
	providedIn: 'root',
})
export class PlansService {
	constructor(private api: TxApiService, private store: Store<State>, private actions$: Actions) {}

	fetchList(): Observable<PlanEntity[]> {
		return this.store.select(getPlansList).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchPlans());
					return this.actions$.pipe(
						ofType(SetPlans),
						map((res) => res.plans)
					);
				}
				return of(list);
			})
		);
	}

	fetchCalendarList(start: string, end: string, teamleader: boolean) {
		return this.store.select(getPlanCalendar).pipe(
			switchMap((list) => {
				this.store.dispatch(FetchCalendar(start, end, teamleader));
				return this.actions$.pipe(
					ofType(SetCalendar),
					map((res) => res.calendar)
				);
			})
		);
	}

	fetchCalendarListRaw(start: string, end: string, teamleader: boolean) {
		return this.api
			.callAPI('getPlanCalendar', {
				begin_date: start,
				end_date: end,
				team_leader: teamleader ? 1 : 0,
			})
			.pipe(map((res: ApiResponse<PlanCalendarEntity[]>) => res.ListOfPlanCalendar));
	}

	updateOrSave(plan: PlanEntity): Observable<PlanEntity> {
		return this.api.callAPI('updatePlan', {
			plan: plan.toApi(),
		});
	}

	updateCalendarMulti(
		plan: PlanEntity,
		employeeIds: number[],
		start: string,
		end: string,
		override: { start: string; end: string } = null
	) {
		const ret: Observable<PlanEntity>[] = [];
		for (const employeId of employeeIds) {
			ret.push(this.updateCalendar(plan, employeId, start, end, override));
		}
		return ret;
	}

	updateCalendar(
		plan: PlanEntity,
		employeeId: number,
		start: string,
		end: string,
		override: { start: string; end: string } = null
	): Observable<PlanEntity> {
		return this.api.callAPI('updatePlanCalendar', {
			begin_date: start,
			end_date: end,
			plan_id: plan.id,
			teamleader_for: employeeId,
			begin_time: !!override ? override.start : plan.begin_time,
			end_time: !!override ? override.end : plan.end_time,
			overwrite: 0,
		});
	}

	deletePlan(id: number) {
		return this.api.callAPI('deletePlan', {
			plan_id: id,
		});
	}

	delete(id: number) {
		return this.api.callAPI('deletePlanCalendar', {
			item_id: id,
		});
	}
}
