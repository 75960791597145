/* tslint:disable:variable-name */

import { SelectItem } from 'primeng/api';

export class TxBDEProtokoll {
	public ListOfAuftrag: Array<TxBdeAuftrag> = [];

	constructor(public statuscode: number = 0, public statustext: string = '', aListOfAuftrag: Array<TxBdeAuftrag>) {
		if (Array.isArray(aListOfAuftrag)) {
			for (let i = 0, l = aListOfAuftrag.length; i < l; i++) {
				this.ListOfAuftrag.push(
					new TxBdeAuftrag(
						aListOfAuftrag[i].auftragsnummer,
						aListOfAuftrag[i].bezeichnung,
						aListOfAuftrag[i].ListOfAuftragsschritt
					)
				);
			}
		}
	}
}

export class TxBdeAuftrag implements SelectItem {
	public value = '0';
	public label = '';
	public ListOfAuftragsschritt: Array<TxBdeAuftragsschritt> = [];

	constructor(
		public auftragsnummer: string,
		public bezeichnung: string,
		aListOfAuftragsschritt: Array<TxBdeAuftragsschritt>
	) {
		this.value = auftragsnummer;
		this.label = bezeichnung;

		if (aListOfAuftragsschritt !== null) {
			for (let i = 0, l = aListOfAuftragsschritt.length; i < l; i++) {
				this.ListOfAuftragsschritt.push(
					new TxBdeAuftragsschritt(
						aListOfAuftragsschritt[i].schrittnummer,
						aListOfAuftragsschritt[i].schrittbezeichnung,
						aListOfAuftragsschritt[i].multiple
					)
				);
			}
		}
	}
}

export class TxBdeAuftragsschritt implements SelectItem {
	/*
      Damit wir json objekte auf diese klasse mappen können haben wir variablen leider 3x definiert/kopiert..
    */
	public value: string; // braucht die selectbox
	public label: string; // braucht die selectbox
	public schrittbezeichnung: string; // braucht der createBuchungsRequest
	public schrittnummer: string; // braucht der createBuchungsRequest

	constructor(
		public AuftragsschrittNummer: string, // kommt via infocenter
		public AuftragsschrittBezeichnung: string, // kommt via infocenter
		public multiple: number // kommt via infocenter
	) {
		this.value = AuftragsschrittNummer;
		this.label = AuftragsschrittBezeichnung;
		this.schrittnummer = AuftragsschrittNummer;
		this.schrittbezeichnung = AuftragsschrittBezeichnung;
	}
}

export class TxBuchungsRequest {
	constructor(
		public auftragsnummer?: string,
		public auftragsschritt?: string,
		public buchungsart?: string,
		public kostenstelle?: string,
		public datetime?: string,
		public longitude?: string,
		public latitude?: string,
		public accuracy?: string,
		public platform?: string,
		public barcode?: number,
		public timezone?: string,
		public pin = 0,
		public notice?: string,
		public quantity?: number
	) {}
}

export class TxActiveBDE {
	public ListOfActiveBDE: Array<TxActiveBDEList> = [];

	constructor(public statuscode: number = 0, public statustext: string = '', aListOfActiveBDE: Array<TxActiveBDEList>) {
		for (let i = 0, l = aListOfActiveBDE.length; i < l; i++) {
			this.ListOfActiveBDE.push(
				new TxActiveBDEList(
					aListOfActiveBDE[i].auftrag_id,
					aListOfActiveBDE[i].auftrag_name,
					aListOfActiveBDE[i].auftrag_nummer,
					aListOfActiveBDE[i].auftrag_start,
					aListOfActiveBDE[i].auftrag_zeit_ist,
					aListOfActiveBDE[i].auftrag_zeit_soll,
					aListOfActiveBDE[i].schritt_name,
					aListOfActiveBDE[i].schritt_nummer,
					false
				)
			);
		}
	}
}

export class TxActiveBDEList {
	constructor(
		public auftrag_id: string = '',
		public auftrag_name: string = '',
		public auftrag_nummer: string = '',
		public auftrag_start: string = '',
		public auftrag_zeit_ist: string = '',
		public auftrag_zeit_soll: string = '',
		public schritt_name: string = '',
		public schritt_nummer: string = '',
		public queued = false
	) {}

	get txCustomId() {
		return this.auftrag_nummer + this.schritt_nummer;
	}
}
