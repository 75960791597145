import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
	private history: string[] = [];

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.history.push(event.urlAfterRedirects);
			}
		});
	}

	async back(): Promise<void> {
		this.history.pop();
		if (this.history.length > 0) {
			await this.router.navigateByUrl(this.history[this.history.length - 1]);
		} else {
			await this.router.navigateByUrl('/');
		}
	}

    getBackUrl(offset = -1): string {
        return this.history[this.history.length - 1 + offset] ?? '/';
    }
}
