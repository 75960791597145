<div class="grid">
	<div class="xl:col-12">
		<mat-card class="mat-elevation-z1">
			<mat-card-title>
                <div>{{ 'txPoTimeAccount.day_view' | translate }}</div>
            </mat-card-title>
            <mat-card-content>
                <div *ngIf="Workflows$ | async as workflows">
                    <tx-dayview [Workflows]="workflows"></tx-dayview>
                </div>
            </mat-card-content>
		</mat-card>
	</div>
</div>
