import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppConfigService } from './appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, distinctUntilChanged, map, share } from 'rxjs/operators';
import { ConnectionStateService } from './connection-state.service';
import { Md5 } from 'ts-md5';
import * as LocalForage from 'localforage';
import { HeartbeatService } from './heartbeat.service';
import { ErrorloggerService } from './errorlogger.service';
import { TxResponse } from '../interfaces/tx-response';
import { get, Store } from 'idb-keyval';
import { GlobalConstants } from '../../globalConstants';

const myHeaders = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	}),
};

@Injectable()
export class TxApiService {
	private myRequestQueue: LocalForage;
	private goodConnection = true;
	private connectionSpeed;

	private keyValDB = new Store('offline', 'requests');

	constructor(
		private http: HttpClient,
		private myAuthService: AuthService,
		private myAppConfig: AppConfigService,
		private router: Router,
		private connectionState: ConnectionStateService,
		private heartbeatService: HeartbeatService,
		private errorLoggerService: ErrorloggerService
	) {
		this.myRequestQueue = LocalForage.createInstance({
			name: 'TxWebRequestQueue',
		});
		//this.heartbeatService.addToHeartbeat(() => this.getCurrentQueueSize());
		this.heartbeatService.addToHeartbeat('TxApiServiceRequestQueue', () => this.dispatchRequestQueue());
		this.connectionState.connectionSpeed.subscribe((data) => {
			this.goodConnection = data > 500;
			this.connectionSpeed = data > 10000 ? Math.floor(data / 1000) + ' Mbit/s' : Math.floor(data) + ' Kbit/s';
		});
	}

	isLoggedIn() {
		return this.myAuthService.getSession().sessionid !== '';
	}

	callAPI(endpunkt: string, bodyJson: any = {}, batchmode: boolean = false): Observable<any> {
		// Get the current network state
		const txIsOnline = this.connectionState.isOnlineSubject.getValue();

		// If the conState is offline add it to the request body
		if (!txIsOnline || !this.goodConnection) {
			if (endpunkt === 'createBuchung') {
				bodyJson.offline = 1;
			}
		}

		// hash the complete request as an identifier
		if (!bodyJson?.hash) {
			bodyJson.hash = Md5.hashStr(endpunkt + JSON.stringify(bodyJson));
		}

		if (this.myAuthService.getSession() === null) {
			return of(false);
		}

		const apiUrl = this.myAppConfig.config.TimeIXServerUrl + endpunkt;
		bodyJson.sessionid = this.myAuthService.getSession().sessionid; /* assign sessionID */
		bodyJson.iun = this.myAuthService.getSession().iun; /* assign userName */
		bodyJson.connectionspeed = this.connectionSpeed;
		bodyJson.app_version = GlobalConstants.appVersion ?? 'dev';

		return this.sendApiRequst(apiUrl, bodyJson, myHeaders);
	}

	dispatchRequestQueue(force = false) {
		// Check the request queue length
		this.myRequestQueue.length().then((numberOfKeys) => {
			// If we have Keys then do things
			if (numberOfKeys > 0) {
				// if we are really offline, don't try to send the queue, except we/the user forces it
				if (!this.connectionState.isOnlineSubject.getValue() && !force) {
					return;
				}

				// If there isn't a good connection, then continue
				if (!this.goodConnection) {
					return;
				}

				// Check the current session, if its ok, then start to iterate through the queue
				this.callAPI('checkSession', {}).subscribe(
					(data) => {
						if (data.statuscode === 0) {
							// nur der Statuscode 0 ist KEIN fehler, alle anderen sind fehler...
							this.myRequestQueue
								.iterate((aValue: any, aKey: any, iterationNumber) => {
									// If the item has no key then kill it with fire and continue
									if (aKey === 'undefined') {
										console.log('something went wrong');
										this.myRequestQueue.removeItem(aKey);
										return;
									}

									const aRequest = aValue;
									const currentSession = this.myAuthService.getSession();

									// Continue if the current queue user isn't the request user
									if (currentSession.iun !== aRequest.bodyJson.iun) {
										return;
									}

									const apiUrl = this.myAppConfig.config.TimeIXServerUrl + aRequest.endpunkt;

									// Replace the old sessionID with the new one
									aRequest.bodyJson.sessionid = currentSession.sessionid;

									if (aRequest.endpunkt === 'createBuchung') {
										// entfernt wegen WLT-9144 und nach rücksprache mit Sebastian
										// Check age of the "buchungs"-request, for older ones mark them as offline.
										// const myOrigDateTimeMoment = moment(aRequest.bodyJson.datetime);
										// const twoMinutesAgo = moment().subtract(2, 'minutes');
										// if (myOrigDateTimeMoment.isBefore(twoMinutesAgo)) {
										//   // request is older than 2 minutes, mark it as offline
										//   aRequest.bodyJson.offline = 1;
										// }
										aRequest.bodyJson.offline = 1;
									}

									const body = JSON.stringify(aRequest.bodyJson);
									const myRequestHandler = this.sendApiRequst(apiUrl, body, myHeaders);

									myRequestHandler.subscribe(
										(response) => {
											this.myRequestQueue.removeItem((response as any).hash);
											const txResponse = response as unknown;
											const myTxResponse = txResponse as TxResponse;
											if (myTxResponse.statuscode !== 0) {
												this.errorLoggerService.handleResponse({
													name: 'Offlinebuchung',
													message: myTxResponse.statuscode,
													stack: myTxResponse.statustext,
												});
											}
											return response;
										},
										(error) => {
											this.errorLoggerService.handleError(error);
											console.log('dispatchRequestQueue api anfrage error:');
											console.log('ERROR' + error);
										}
									);
								})
								.then(() => {
									// console.log('dispatchRequestQueue abgearbeitet');
								})
								.catch((error) => {
									// This code runs if there were any errors
									this.errorLoggerService.handleError(error);
									console.log(error);
								});
						}
					},
					(error) => {
						console.log(error);
					}
				);
			}
		});
	}

	getCurrentLocation(): Promise<any> {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject, {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			});
		});
	}

	async getCurrentQueueSize() {
		const currentSession = this.myAuthService.getSession();

		const stalled: any[] = await get('stalled', this.keyValDB);

		/*await this.myRequestQueue.iterate((aValue: any, aKey: any, iterationNumber) => {
			if (aValue.bodyJson.iun === currentSession.iun) {
				currentUserQueueItems++;
			}
		});*/

		return stalled?.filter((s) => s.body?.iun === currentSession.iun) ?? null;
	}

	private sendApiRequst(apiUrl, body, header) {
		return this.http.post<any>(apiUrl, body, header).pipe(
			map((res) => {
				this.checkSession(res);
				return res;
			}),
			//distinctUntilChanged(),
			catchError(this.handleError),
			share()
		);
	}

	private handleError(error: any) {
		// In a real world app, we might use a remote logging infrastructure
		// We'd also dig deeper into the error to get a better message
		const errMsg = error.message
			? error.message
			: error.status
			? `${error.status} - ${error.statusText}`
			: 'Server error';
		return observableThrowError(errMsg);
	}

	private checkSession(data) {
		if (data.statuscode === 1 || data.statuscode === 4) {
			// nur der Statuscode 0 ist KEIN fehler, alle anderen sind fehler...
			localStorage.removeItem('currentSession'); // delete outdatet session data
			this.router.navigate(['/login']);
		}
	}
}
