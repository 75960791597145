import { TxApiService } from './txapi.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ProjectEntity } from '../../store/entities/ProjectEntity';
import { map, switchMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import {
	getProjectList,
	getProjectStates,
	getWorksteps,
	getWorkstepTypes,
} from '../../store/selectors/project-office.selectors';
import {
	AddProject,
	AddWorkstep,
	DeleteProject,
	DeleteWorkstep,
	FetchList,
	FetchProjectStates,
	FetchWorksteps,
	FetchWorkstepTypes,
	SaveProject,
	SetList,
	SetProjectStates,
	SetWorksteps,
	SetWorkstepTypes,
	UpdateWorkstep,
} from '../../store/actions/project-office.actions';
import { WorkstepEntity } from '../../store/entities/WorkstepEntity';
import { WorkstepTypeEntity } from '../../store/entities/WorkstepTypeEntity';
import { ProjectStateEntity } from '../../store/entities/ProjectStateEntity';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { ProjectDetailEntity } from '../../store/entities/ProjectDetailEntity';
import { ProjectBookingEntity } from '../../store/entities/ProjectBookingEntity';
import moment from 'moment';
import { ErrorAction, SuccessAction } from '../../store/actions';
import { ProjectFilter } from '../interfaces/ProjectFilter';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeService {
	destroyed$ = new Subject<boolean>();

	constructor(private api: TxApiService, private store: Store<State>, private actions$: Actions) {}

	fetchList(): Observable<ProjectEntity[]> {
		return this.store.select(getProjectList).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchList());
					return this.actions$.pipe(
						ofType(SetList),
						map((res) => res.list)
					);
				}
				return of(list);
			})
		);
	}

	fetchWorksteps(): Observable<WorkstepEntity[]> {
		return this.store.select(getWorksteps).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchWorksteps());
					return this.actions$.pipe(
						ofType(SetWorksteps),
						map((res) => res.worksteps)
					);
				}
				return of(list);
			})
		);
	}

	addWorkstep(name: string, type: WorkstepTypeEntity) {
		this.store.dispatch(AddWorkstep(name, type));
	}

	deleteWorkstep(id: number) {
		this.store.dispatch(DeleteWorkstep(id));
	}

	changeWorkstep(workstep: WorkstepEntity) {
		this.store.dispatch(UpdateWorkstep(workstep));
	}

	fetchWorkstepTypes(): Observable<WorkstepTypeEntity[]> {
		return this.store.select(getWorkstepTypes).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchWorkstepTypes());
					return this.actions$.pipe(
						ofType(SetWorkstepTypes),
						map((res) => res.workstepTypes)
					);
				}
				return of(list);
			})
		);
	}

	fetchProjectStates(): Observable<ProjectStateEntity[]> {
		return this.store.select(getProjectStates).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchProjectStates());
					return this.actions$.pipe(
						ofType(SetProjectStates),
						map((res) => res.projectStates)
					);
				}
				return of(list);
			})
		);
	}

	addProject(project: ProjectEntity) {
		this.store.dispatch(AddProject(project));
	}

	deleteProject(id: number) {
		this.store.dispatch(DeleteProject(id));
	}

	getProject(id: number, filter: ProjectFilter = null) {
		return this.api
			.callAPI('getProjectDetail', {
				project_id: id,
				begin_date: filter?.from,
				end_date: filter?.to,
			})
			.pipe(
				map((res: ApiResponse<ProjectDetailEntity[]>) => {
					let project = new ProjectDetailEntity();
					project.project_id = 0;
					project.unproductive = 0;
					project.project_state = 0;
					project.target_quantity = 0;
					project.project_name = null;
					project.project_number = null;
					project.description = null;
					project.creation_date = moment().toDate().toString();
					project.target_date = null;
					project.target_time = null;
					project.calculated_time = null;
					project.ListOfProjectWorkstep = [];
					if (res.ProjectDetail?.[0]) {
						project = ProjectDetailEntity.tryCast(res.ProjectDetail[0]);
					}
					return project;
				})
			);
	}

	getProjectBookings(id: number, all = false, filter: ProjectFilter = null) {
		return this.api
			.callAPI('GetProjectBookings', {
				project_id: id,
				all_bookings: all ? 1 : 0,
				begin_date: filter?.from,
				end_date: filter?.to,
			})
			.pipe(
				map((res: ApiResponse<ProjectBookingEntity[]>) => {
					return res.ListOfProjectBooking?.map((p) => ProjectBookingEntity.tryCast(p));
				})
			);
	}

	saveOrCreate(item: ProjectDetailEntity) {
		this.store.dispatch(SaveProject(item));
		return this.actions$.pipe(
			ofType(SuccessAction(SaveProject), ErrorAction(SaveProject)),
			map((data) => {
				return data;
			})
		);
	}
}
