<div>
	<section>
		<div class="display-flex">
			<div class="names">
				<header>
					<div>{{ 'general.employee' | translate }}</div>
				</header>
				<div class="table-row" *ngFor="let workflow of Workflows">
					{{ workflow.calendar.name }}
				</div>
			</div>
			<div class="time-beam flex-grow-1">
				<tx-time-beam [Values]="Workflows"></tx-time-beam>
			</div>
		</div>
	</section>
</div>
