import { ListOfMessageResponse } from './Api/ListOfMessage.response';

export class MessageEntity extends ListOfMessageResponse {
	children: MessageEntity[];

	public static tryCast(o: any, children: MessageEntity[]): MessageEntity {
		const ret = new MessageEntity();
		ret.children = children;
		return Object.assign(ret, o);
	}
}
