import { StateEntity } from '../entities/StateEntity';
import { Action, createReducer, on } from '@ngrx/store';
import { PutStates } from '../actions/user-state.actions';

export interface State {
	list: StateEntity[];
}

export const initialState: State = {
	list: null,
};

const userStateReducer = createReducer(
	initialState,
	on(PutStates, (state, { states }) => ({
		list: states?.slice() ?? null,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return userStateReducer(state, action);
}
