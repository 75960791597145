import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectDetailEntity, ProjectWorkstep } from '../../../store/entities/ProjectDetailEntity';
import { WorkstepEntity } from '../../../store/entities/WorkstepEntity';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

export interface ProjectWorkstepsData {
	project: ProjectDetailEntity;
	available: WorkstepEntity[];
	list: ProjectWorkstep[];
}

@Component({
	selector: 'tx-project-office-workstep-dialog',
	templateUrl: './tx-project-office-workstep-dialog.component.html',
	styleUrls: ['./tx-project-office-workstep-dialog.component.scss'],
})
export class TxProjectOfficeWorkstepDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<TxProjectOfficeWorkstepDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ProjectWorkstepsData
	) {}

	projectHasWorkstep(workstep: WorkstepEntity) {
		return !!this.data.project.ListOfProjectWorkstep.find((w) => w.workstep_number === workstep.workstep_number);
	}

	onCancel(): void {
		this.dialogRef.close();
	}

	onChange(event: MatButtonToggleChange) {
		this.data.list = this.data.available
			.filter((w) => event.value.includes(w.workstep_number))
			.map((w) =>
				Object.assign(new ProjectWorkstep(), {
					...w,
					deleted: false,
					workstep_project_id: 0,
				})
			);
	}

	AddAll() {
		const worksteps = this.data.project.ListOfProjectWorkstep.map((wp) => wp.workstep_number);
		this.data.list = this.data.available
			.filter((w) => !worksteps.includes(w.workstep_number))
			.map((w) =>
				Object.assign(new ProjectWorkstep(), {
					...w,
					deleted: false,
					workstep_project_id: 0,
				})
			);
		this.dialogRef.close(this.data.list);
	}
}
