import { Color } from '@angular-material-components/color-picker';

export class ListOfPlanResponse {
	id: number;
	name: string;
	color: string | Color;
	begin_time: string;
	end_time: string;
	mon: number;
	tue: number;
	wed: number;
	thu: number;
	fri: number;
	sat: number;
	sun: number;
}
