<div class="chat-sidebar-content">
	<header class="chat-sidebar-header" (click)="toggleInfoCenter()">
		<h4 class="chat-sidebar-title">InfoCenter</h4>
		<span class="chat-toggle"><i class="fa fa-times"></i></span>
	</header>
	<hr />
	<div class="chat-sidebar-contacts chat-sidebar-panel" [class.open]="!infoCenterOpened">
		<div class="list-group chat-sidebar-user-group sortbar">
			<div class="list-group-item">
				<span class="mr-2">
					<fa-icon [icon]="currentChoiceASCDESC === '-' ? 'sort-alpha-down' : 'sort-alpha-up'"></fa-icon>
					<!--<i (click)="toogleCurrentChoiceASCDESC()" class="fa mr-xs"
                         [class.fa-sort-alpha-desc]="currentChoiceASCDESC == '-'"
                         [class.fa-sort-alpha-asc]="currentChoiceASCDESC == '+'"></i>-->
				</span>
				<span class="{{getActive('Name')}}" (click)="myInfocenterSort='name'; setActive('Name' )"
					>{{'infocenter.all' | translate }}</span
				>
				|
				<span class="{{getActive('Abteilung')}}" (click)="myInfocenterSort='abteilung'; setActive('Abteilung')"
					>{{'infocenter.department' | translate }}</span
				>
				|
				<span class="{{getActive('Gruppe')}}" (click)="myInfocenterSort='gruppe'; setActive('Gruppe')"
					>{{'infocenter.group' | translate }}</span
				>
				|
				<span class="{{getActive('Status')}}" (click)="myInfocenterSort='status'; setActive('Status')"
					>{{'infocenter.state' | translate }}</span
				>
			</div>
		</div>

		<hr />

		<!--  start of ngfor abteilugen -->

		<div class="char-sidebar-wrapper">
			<ng-template [ngIf]="myInfocenterSort == 'name'">
				<div class="list-group chat-sidebar-user-group">
					<div
						*ngFor="let user of myInfocenter?.Infocenters | orderBy : [currentChoiceASCDESC+'Name'] "
						class="list-group-item">
						<h6 class="message-sender">
							<i
								class="fa fa-circle text-{{user.Status}}-{{user.AuftragAktiv}} pull-right mr-lg"
								style="line-height: 100%"></i
							>{{user.Name}}
						</h6>
						<p *ngIf="user.state_text" class="message-preview">{{user.state_text}}</p>
						<!--<p class="message-preview">{{'infocenter.' + user.Status | translate}}</p>-->
						<p class="message-preview">{{user.AuftragsBezeichnung}}</p>
						<p class="message-preview">{{user.AuftragsschrittBezeichnung}}</p>
					</div>
				</div>
			</ng-template>

			<ng-template [ngIf]="myInfocenterSort == 'abteilung'">
				<div
					*ngFor="let myGroup of myInfocenter?.Infocenters | groupBy:'Abteilung' | orderBy : [currentChoiceASCDESC+'key']"
					class="mb-lg">
					<h5 class="sidebar-nav-title">
						<hr />
						{{myGroup.key}}
						<hr />
					</h5>

					<div class="list-group chat-sidebar-user-group">
						<div *ngFor="let user of myGroup?.value  | orderBy : ['Name']" class="list-group-item">
							<h6 class="message-sender">
								<i
									class="fa fa-circle text-{{user.Status}}-{{user.AuftragAktiv}} pull-right mr-lg"
									style="line-height: 100%"></i
								>{{user.Name}}
							</h6>
							<p *ngIf="user.state_text" class="message-preview">{{user.state_text}}</p>
							<!--<p class="message-preview">{{'infocenter.' + user.Status | translate}}</p>-->
							<p class="message-preview">{{user.AuftragsBezeichnung}}</p>
							<p class="message-preview">{{user.AuftragsschrittBezeichnung}}</p>
						</div>
					</div>
				</div>
			</ng-template>
			<!-- ngfor Infocenters in abteilungen -->

			<!--  start of ngfor Gruppe -->
			<ng-template [ngIf]="myInfocenterSort == 'gruppe'">
				<div
					*ngFor="let myGroup of myInfocenter?.Infocenters | groupBy:'Gruppe' | orderBy : [currentChoiceASCDESC+'key']"
					class="mb-lg">
					<h5 class="sidebar-nav-title">
						<hr />
						{{myGroup.key}}
						<hr />
					</h5>

					<div class="list-group chat-sidebar-user-group">
						<div *ngFor="let user of myGroup?.value  | orderBy : ['Name']" class="list-group-item">
							<h6 class="message-sender">
								<i
									class="fa fa-circle text-{{user.Status}}-{{user.AuftragAktiv}} pull-right mr-lg"
									style="line-height: 100%"></i
								>{{user.Name}}
							</h6>
							<p *ngIf="user.state_text" class="message-preview">{{user.state_text}}</p>
							<!--<p class="message-preview">{{'infocenter.' + user.Status | translate}}</p>-->
							<p class="message-preview">{{user.AuftragsBezeichnung}}</p>
							<p class="message-preview">{{user.AuftragsschrittBezeichnung}}</p>
						</div>
					</div>
				</div>
			</ng-template>
			<!-- ngfor Infocenters in Gruppe -->

			<!--  start of ngfor Status -->
			<ng-template [ngIf]="myInfocenterSort == 'status'">
				<div
					*ngFor="let myGroup of myInfocenter?.Infocenters | groupBy:'Status' | orderBy : [currentChoiceASCDESC+'key'] "
					class="mb-lg">
					<h5 class="sidebar-nav-title">
						<hr />
						{{'infocenter.' + myGroup.key | translate}}
						<hr />
					</h5>

					<div class="list-group chat-sidebar-user-group">
						<div *ngFor="let user of myGroup?.value | orderBy : ['Name']" class="list-group-item">
							<h6 class="message-sender">
								<i
									class="fa fa-circle text-{{user.Status}}-{{user.AuftragAktiv}} pull-right mr-lg"
									style="line-height: 100%"></i
								>{{user.Name}}
							</h6>
							<p *ngIf="user.state_text" class="message-preview">{{user.state_text}}</p>
							<!--<p class="message-preview">{{'infocenter.' + user.Status | translate}}</p>-->
							<p class="message-preview">{{user.AuftragsBezeichnung}}</p>
							<p class="message-preview">{{user.AuftragsschrittBezeichnung}}</p>
						</div>
					</div>
				</div>
			</ng-template>
			<!-- ngfor Infocenters in Status -->
		</div>
	</div>
	<!-- end of sidebar panel -->
</div>
<!-- end of sitebar content -->
