import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'sort' })
export class SortPipe implements PipeTransform {
	transform<T>(value: T[], field: keyof T, dir: 'asc' | 'desc' = 'asc', isDate: boolean = false): T[] {
		return (
			value?.slice().sort((a, b) => {
				let aField: T[keyof T] | moment.Moment = a[field];
				let bField: T[keyof T] | moment.Moment = b[field];

				let ret = 0;

				if (isDate) {
					aField = moment(aField);
					bField = moment(bField);
					ret = aField.diff(bField);
				} else if (typeof aField === 'number' && typeof bField === 'number') {
					ret = aField - bField;
				} else if (typeof aField === 'string' && typeof bField === 'string') {
					ret = aField.localeCompare(bField);
				}

				return ret * (dir === 'asc' ? 1 : -1);
			}) ?? []
		);
	}
}
