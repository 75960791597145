import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectOfficeService } from '../services/project-office.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProjectEntity } from '../../store/entities/ProjectEntity';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeListResolver implements Resolve<ProjectEntity[]> {
	constructor(private projectOfficeService: ProjectOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ProjectEntity[]> | Promise<ProjectEntity[]> | ProjectEntity[] {
		return this.projectOfficeService.fetchList().pipe(take(1));
	}
}
