<ng-container>
    <div mat-dialog-title class="display-flex flex-justify-content-space-between">
        <h3 class="m-0 mr-1">
            {{ title }}
        </h3>
        <button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div *ngIf="WorkFlowTypes.length > 0; else loading">
            <mat-form-field appearance="outline" class="min-w-[400px]">
                <mat-label> {{ 'txApplyBooking.missingTime' | translate }}</mat-label>
                <mat-select #select>
                    <mat-option *ngFor="let option of WorkFlowTypes" [value]="option.nummer">{{ option.bezeichnung }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex flex-justify-content-flex-end">
                <button
                        mat-raised-button
                        color="accent"
                        [mat-dialog-close]="{ selected: select.value, data: WorkFlowTypes }"
                        [disabled]="!select.value"
                >{{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="min-w-[400px] flex justify-center items-center p-12">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
