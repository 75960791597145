export class ListOfMessageResponse {
	id: string;
	parent_id: string;
	creation_date: string;
	type: string;
	type_value: string;
	message: string;
	target_persnr: number;
	source_persnr: number;
	archived: boolean;
}
