import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { PlanEntity } from '../../../store/entities/PlanEntity';
import { getPlansList } from '../../../store/selectors/plans.selectors';
import { FormBuilder, Validators } from '@angular/forms';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';

@Component({
	templateUrl: './tx-planing-select-plan-dialog.component.html',
	styleUrls: ['./tx-planing-select-plan-dialog.component.scss'],
})
export class TxPlaningSelectPlanDialogComponent implements OnDestroy {
	public data: { start: Date; end: Date; view: string } = null;

	public Plans$: Observable<PlanEntity[]>;

	public PlanSelectForm = this.fb.group({
		selectedPlan: [null, [Validators.required]],
		startDate: [null, [Validators.required]],
		endDate: [null, [Validators.required]],
		startTime: [null],
		endTime: [null],
		selectedStart: [null],
		selectedEnd: [null],
	});

	public PlanTime: {
		start: string;
		end: string;
	} = { start: null, end: null };

	public SelectedTime: {
		start: string;
		end: string;
	} = { start: null, end: null };

	private subs: Subscription[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			start: Date;
			end: Date;
			view: string;
		},
		private readonly fb: FormBuilder,
		private readonly store: Store,
		private readonly ref: ChangeDetectorRef
	) {
		this.data = data;

		this.Plans$ = this.store.select(getPlansList);
		this.initForm(moment(data.start).format('HH:mm'), moment(data.end).format('HH:mm'));

		// this.subs.push(
		//     this.PlanSelectForm.valueChanges.pipe(
		//         withLatestFrom(this.Plans$),
		//         map(([values, plans]) =>  {
		//           if(values.selectedPlan) {
		//             const plan = plans.find(p => p.id === values.selectedPlan);
		//             this.PlanTime = {
		//               start: plan.begin_time.split('T').pop().substr(0, 5),
		//               end: plan.end_time.split('T').pop().substr(0, 5)
		//             };
		//             this.PlanSelectForm.patchValue({
		//                 selectedStart: moment(plan.begin_time).format('HH:mm'),
		//                 selectedEnd: moment(plan.end_time).format('HH:mm')
		//             });
		//           }
		//         })
		//     ).subscribe()
		// );
	}

	planSelected(value: number, plans: PlanEntity[]) {
		const plan = plans.find((p) => p.id === value);
		this.PlanTime = {
			start: plan.begin_time.split('T').pop().substr(0, 5),
			end: plan.end_time.split('T').pop().substr(0, 5),
		};
		if (this.data.view === 'resourceTimelineWeek') {
			this.PlanSelectForm.patchValue({
				selectedStart: moment(plan.begin_time).format('HH:mm'),
				selectedEnd: moment(plan.end_time).format('HH:mm'),
			});
		}
	}

	initForm(start: string, end: string) {
		this.PlanSelectForm.setValue({
			selectedPlan: null,
			startDate: this.data.start,
			endDate: this.data.end,
			startTime: null,
			endTime: null,
			selectedStart: this.data.view === 'resourceTimelineWeek' ? null : start,
			selectedEnd: this.data.view === 'resourceTimelineWeek' ? null : end,
		});
	}

	ngOnDestroy() {
		this.subs.map((s) => s.unsubscribe());
	}
}
