import {
    Component,
    Inject,
    OnInit,
    ViewChild
} from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import {
    TxListOfWorkflowTyp,
    TxWorkflowTypen
} from '../../../../shared/interfaces/workflow';
import { TxApiService } from '../../../../shared/services/txapi.service';
import { tap } from 'rxjs/operators';
import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from '@angular/material/dialog';
import {
    FormBuilder,
    Validators
} from '@angular/forms';
import { TxSession } from '../../../../shared/interfaces/session';
import { AuthService } from '../../../../shared/services/auth.service';
import {
    TxBuchung,
    TxDeleteBooking
} from '../../../../shared/interfaces/bdebuchung';
import { TxSelectTypeDialogComponent } from '../tx-select-type-dialog/tx-select-type.dialog.component';
import { CalendarService } from '../../../../shared/services/calendar.service';

declare var Messenger: any;

export interface TxSchedulerFzDialogData {
    id?: string;
    type?: string;
    start?: string;
    end?: string;
    note?: string;
    teamLeaderFor?: boolean;
}

export class TxSchedulerFzDialogResult {
    bookingType: TxListOfWorkflowTyp;
    startDate: string;
    endDate: string;
    note?: string;
    id?: string;
}

@Component({
    templateUrl: './tx-scheduler-fz.dialog.component.html'
})
export class TxSchedulerFzDialogComponent implements OnInit {
    public form = this.fb.group({
        type: [null, Validators.required],
        start: [null, Validators.required],
        end: [null, Validators.required],
        note: [null]
    });
    session: TxSession;

    public WorkflowTypes$: Observable<TxWorkflowTypen>;

    constructor(
        private calendarService: CalendarService,
        public dialogRef: MatDialogRef<TxSelectTypeDialogComponent>,
        public myAuthService: AuthService,
        private myTxApiService: TxApiService,
        public readonly fb: FormBuilder,
        private readonly api: TxApiService,
        @Inject(MAT_DIALOG_DATA) public data: TxSchedulerFzDialogData
    ) {
        this.session = myAuthService.getSession();
        console.log(data);
        this.WorkflowTypes$ = api.callAPI(
            'getWorkflowTyp',
            data.teamLeaderFor ? { team_leader: 1 } : {}
        );
    }

    public ngOnInit() {
        this.form.patchValue({
            type: this.data.type,
            start: this.data.start ?? this.getNow(),
            end: this.data.end ?? this.getNow(),
            note: this.data.note
        });
    }

    public getNow() {
        return moment().format('YYYY-MM-DD');
    }

    public getWorkflowType(type: string, types: TxListOfWorkflowTyp[]) {
        return types.find((t) => t.nummer === type);
    }

    getSaveButtonText() {
        if (!this.data.teamLeaderFor && this.session.calendar_pze === 2) {
            return 'Beantragen';
        }
        return this.data.id ? 'Ändern' : 'Eintragen'
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    deleteEvent() {
        const deleteCheck = confirm('Möchten Sie diesen Eintrag wirklich löschen?');
        if (!deleteCheck) {
            return;
        }
        this.onCancel()
        const myDeleteBuchungsData = this.prepareDelete();
        const apiAnfrage: Observable<TxBuchung> = this.myTxApiService.callAPI('deleteBooking', myDeleteBuchungsData);
        apiAnfrage.subscribe(
            (mySessionData: any) => {
                Messenger().post({
                    message: mySessionData.statustext,
                    type: 'success',
                    showCloseButton: true,
                    hideAfter: 5,
                });
            },
            (error) => {
                Messenger().post({
                    message: 'ERROR' + error,
                    type: 'error',
                    showCloseButton: true,
                });
            },
        );
        this.calendarService.triggerRefreshCalendar();
    }

    prepareDelete() {
        const myDeleteBuchungsData = new TxDeleteBooking();

        myDeleteBuchungsData.book_type = 'FZ';
        myDeleteBuchungsData.book_beginn_id = this.data.id.toString().replace('FZ', '');
        myDeleteBuchungsData.book_end_id = this.data.id ? this.data.id.toString().replace('FZ', '') : '0';

        return myDeleteBuchungsData;
    }
}
