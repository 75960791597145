/**
 * ListOfAuftrag
 * :
 * [{auftragsnummer: "1", bezeichnung: "1 - 1",…}, {auftragsnummer: "2", bezeichnung: "2 - 2",…},…]
 * 0
 * :
 * {auftragsnummer: "1", bezeichnung: "1 - 1",…}
 * ListOfAuftragsschritt
 * :
 * [{schrittnummer: "1", schrittbezeichnung: "Programmierung XXX", multiple: 0}]
 * 0
 * :
 * {schrittnummer: "1", schrittbezeichnung: "Programmierung XXX", multiple: 0}
 * multiple
 * :
 * 0
 * schrittbezeichnung
 * :
 * "Programmierung XXX"
 * schrittnummer
 * :
 * "1"
 * auftragsnummer
 * :
 * "1"
 * bezeichnung
 * :
 * "1 - 1"
 */

export class AuftragsSchrittResponse {
    multiple: number;
    schrittbezeichnung: string;
    schrittnummer: string;
}

export class ListOfAuftragResponse {
    auftragsnummer: string;
    bezeichnung: string;
    ListOfAuftragsschritt: AuftragsSchrittResponse[];
}
