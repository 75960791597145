import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ListOfDepartmentEntity } from '../../../../store/entities/ListOfDepartmentEntity';
import { getDepartments, getEmployeeGroups, isDataLoading } from '../../../../store/selectors/data.selectors';
import { ListOfEmployeeGroupEntity } from '../../../../store/entities/ListOfEmployeeGroupEntity';
import { DataService } from '../../../../shared/services/data.service';
import { TeamEntity } from '../../../../store/entities/TeamEntity';
import { getTeamList } from '../../../../store/selectors/personal-office.selectors';

export class TxPersonalFilesDialogData {
	constructor(public what: 'groups' | 'departments' | 'teams') {}
}

@Component({
	templateUrl: './tx-personal-files.dialog.component.html',
	styleUrls: ['./tx-personal-files.dialog.component.scss'],
})
export class TxPersonalFilesDialogComponent {
	public what$: Observable<ListOfEmployeeGroupEntity[] | ListOfDepartmentEntity[] | TeamEntity[]>;

	public EditMode: { [id: number]: boolean } = {};

	public Edited: { [key: number]: ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity } = {};

	public NewName: string;

	public IsLoading$: Observable<boolean>;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: TxPersonalFilesDialogData,
		private store: Store,
		private service: DataService
	) {
		this.IsLoading$ = this.store.select(isDataLoading);

		switch (data.what) {
			case 'departments':
				this.what$ = this.store.select(getDepartments);
				break;
			case 'groups':
				this.what$ = this.store.select(getEmployeeGroups);
				break;
			case 'teams':
				this.what$ = this.store.select(getTeamList);
				break;
			default:
				break;
		}
	}

	onSave(data: ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity) {
		switch (this.data.what) {
			case 'departments':
				this.service.updateDepartment(this.getEdited(data));
				break;
			case 'groups':
				this.service.updateEmployeeGroup(this.getEdited(data));
				break;
			case 'teams':
				this.service.updateTeam(this.getEdited(data));
				break;
			default:
				break;
		}
		this.EditMode[data.id] = false;
	}

	onDelete(data: ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity) {
		// change if statements to switch case
		switch (this.data.what) {
			case 'departments':
				this.service.deleteDepartment(data.id);
				break;
			case 'groups':
				this.service.deleteEmployeeGroup(data.id);
				break;
			case 'teams':
				this.service.deleteTeam(data);
				break;
			default:
				break;
		}
	}

	onDiscard(data: ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity) {
		delete this.Edited[data.id];
		this.EditMode[data.id] = false;
	}

	getEdited(data: ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity) {
		if (!this.Edited[data.id]) {
			if (data instanceof ListOfDepartmentEntity) {
				this.Edited[data.id] = Object.assign(new ListOfDepartmentEntity(), data);
			} else if (data instanceof ListOfEmployeeGroupEntity) {
				this.Edited[data.id] = Object.assign(new ListOfEmployeeGroupEntity(), data);
			} else if (data instanceof TeamEntity) {
				this.Edited[data.id] = Object.assign(new TeamEntity(), data);
			}
		}
		return this.Edited[data.id];
	}

	canAdd(data: (ListOfEmployeeGroupEntity | ListOfDepartmentEntity | TeamEntity)[]) {
		return !data.find((d) => d.name === this.NewName) && this.NewName;
	}

	onAddData() {
		switch (this.data.what) {
			case 'departments':
				this.service.addDepartment(this.NewName);
				break;
			case 'groups':
				this.service.addEmployeeGroup(this.NewName);
				break;
			case 'teams':
				const team = new TeamEntity();
				team.name = this.NewName;
				team.id = 0;
				team.sync_state = 0;
				this.service.updateTeam(team);
				break;
			default:
				break;
		}

		this.NewName = null;
	}
}
