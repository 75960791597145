<div *ngIf="Teamleader$ | async as Teamleader">
    <div class="grid" [class.disabled-overlay]="isDisabled" *ngIf="Teams$ | async as Teams">
        <div class="col-12 xl:col-4">
            <div #spacer></div>
            <mat-card class="mat-elevation-z1 z-50"
                      stickyThing
                      [spacer]="spacer"
                      [marginTop]="80"
            >
                <mat-card-header>
                    <mat-card-title>Verfügbarer Mitarbeiter</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div>
                        <mat-form-field class="w-full">
                            <mat-label>Suche</mat-label>
                            <input #employeeSearchInput matInput (input)="EmployeeSearch$.next(employeeSearchInput.value)">
                            <div matSuffix *ngIf="EmployeeFilterObservable$ | async as Filter" class="display-flex gap-2">
                                <button mat-icon-button *ngIf="employeeSearchInput.value" (click)="employeeSearchInput.value = null; EmployeeSearch$.next(null)">
                                    <fa-icon icon="times"></fa-icon>
                                </button>
                                <button mat-icon-button [matMenuTriggerFor]="employeeSearchMenu" class="{{ isFilterActive(Filter) ? 'bg-black' : '' }}">
                                    <fa-icon icon="filter" [inverse]="isFilterActive(Filter)"></fa-icon>
                                </button>
                                <mat-menu #employeeSearchMenu="matMenu">
                                    <button mat-menu-item click-stop-propagation (click)="OnEmployeeFilter('teamleader', Filter)">
                                        <ng-container *ngIf="!Filter.teamleader">Nur Teamleiter anzeigen</ng-container>
                                        <ng-container *ngIf="Filter.teamleader">Alle Mitarbeiter anzeigen</ng-container>
                                    </button>
                                    <button mat-menu-item click-stop-propagation (click)="OnEmployeeFilter('teamless', Filter)">
                                        <ng-container *ngIf="!Filter.teamless">Nur ohne Zuordnung anzeigen</ng-container>
                                        <ng-container *ngIf="Filter.teamless">Alle Mitarbeiter anzeigen</ng-container>
                                    </button>
                                </mat-menu>
                            </div>
                        </mat-form-field>
                    </div>
                    <div cdkDropList
                         #employeeList
                         class="h-[80vh] overflow-hidden overflow-y-auto"
                    >
                        <ng-container *ngIf="{
                            employees: FilteredEmployees$ | async,
                            teamleaderAssingmnets: AllTeamleaderAssingments$ | async
                        } as Data; else loading">
                            <div class="grid mb-1 px-2" *ngIf="EmployeeFilterObservable$ | async as Filter">
                                <div class="col-1 border-b flex items-center">
                                    <button class="font-bold">
                                        <mat-checkbox [(ngModel)]="isSelectAll" (change)="OnSelectAll(Data.employees)"></mat-checkbox>
                                    </button>
                                </div>
                                <div class="col-5 font-bold border-b">
                                    <button class="font-bold" mat-button (click)="OnEmployeeFilter('sort:name:' + ((Filter.sort?.dir || 'desc') === 'asc' ? 'desc' : 'asc'), Filter)">
                                        Name
                                        <fa-icon *ngIf="Filter.sort?.column === 'name'" [rotate]="Filter.sort.dir === 'asc' ? null : 180" class="ml-2 transition-all delay-200" icon="chevron-down"></fa-icon>
                                    </button>
                                </div>
                                <div class="col-5 font-bold border-b">
                                    <button class="font-bold" mat-button (click)="OnEmployeeFilter('sort:team:' + ((Filter.sort?.dir || 'desc') === 'asc' ? 'desc' : 'asc'), Filter)">
                                        Team
                                        <fa-icon *ngIf="Filter.sort?.column === 'team'" [rotate]="Filter.sort.dir === 'asc' ? null : 180" class="ml-2 transition-all delay-200" icon="chevron-down"></fa-icon>
                                    </button>
                                </div>
                                <div class="col-1 border-b"></div>
                            </div>
                            <div *ngFor="let employee of Data.employees"
                                 class="grid my-1 cursor-pointer hover:bg-gray-50 items-center px-2"
                                 [dndDraggable]="employee"
                                 dndEffectAllowed="copy"
                                 (dndStart)="onLeave(employee)"
                                 (dndEnd)="onEnd()"
                            >
                                <div class="col-1 whitespace-nowrap">
                                    <mat-checkbox [(ngModel)]="isSelectedEmployee[employee.persnr]" [disabled]="isSelectAll"></mat-checkbox>
                                </div>
                                <div dndDragImageRef
                                     class="col-5 whitespace-nowrap overflow-hidden"
                                     [ngClass]="{
                                        'text-red-500': getDeputyState(employee, Teamleader)?.deputy_active === 1
                                     }"
                                >
                                    {{ employee.firstname }} {{ employee.lastname }} ({{ getTeamleaderAssingmentsCount(employee, Data.teamleaderAssingmnets) }})
                                </div>
                                <div class="col-5 whitespace-nowrap overflow-hidden">{{ getTeamName(employee.persnr, Data.employees, Teams) }}</div>
                                <div class="col-1 text-right" [ngClass]="!getDeputyState(employee, Teamleader) ? 'hidden' : ''">
                                    <button [matMenuTriggerFor]="employeeMoreMenu">
                                        <fa-icon icon="ellipsis-v"></fa-icon>
                                    </button>

                                    <mat-menu #employeeMoreMenu="matMenu">
                                        <button mat-menu-item (click)="toggleDeputyState(getDeputyState(employee, Teamleader))">
                                            <ng-container *ngIf="getDeputyState(employee, Teamleader)?.deputy_active === 0">Vertretung aktivieren</ng-container>
                                            <ng-container *ngIf="getDeputyState(employee, Teamleader)?.deputy_active === 1">Vertretung deaktivieren</ng-container>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!--<tx-list

                            maxHeight="65vh"
                            [items]="Employees"
                            [labelField]="getLabel"
                            [sortField]="'employee_id'"
                            [idField]="'employee_id'"
                            [searchField]="'firstname,lastname'"
                            [showNew]="false"
                            routerLinkBase="personaloffice/teamkonfiguration"
                    ></tx-list>-->
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12 xl:col-8 flex flex-col gap-4">
            <div class="rounded px-3 py-2 bg-white flex">
                <mat-form-field appearance="outline" class="w-full" style="margin-bottom: -1.475em;">
                    <mat-label>Suche</mat-label>
                    <input #filterInput matInput (keydown)="Search$.next(filterInput.value)">
                    <div matSuffix class="flex items-center">
                        <button mat-icon-button *ngIf="filterInput.value" (click)="filterInput.value = null; Search$.next(null)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-form-field>
                <div *ngIf="Employees$ | async as Employees" class="ml-4 flex items-center">
                    <button mat-mini-fab (click)="OnCreateNewTeam(Employees)">
                        <fa-icon icon="plus"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="flex space-x-2 justify-end">
                <button mat-flat-button (click)="accordion.openAll()">Alle aufklappen</button>
                <button mat-flat-button (click)="accordion.closeAll()">Alle zuklappen</button>
            </div>
            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let teamleader of Teamleader" class="mat-elevation-z0"
                                     hideToggle
                                     (opened)="isExpanded[teamleader.id] = true"
                                     (closed)="isExpanded[teamleader.id] = false"
                >
                    <mat-expansion-panel-header *ngIf="getTeamleaderEmployeeAssignments(teamleader.id) | async as data; else loading">
                        <mat-panel-title>
                            <div class="flex-grow flex justify-between">
                                <div class="flex items-center">
                                    <span class="ml-1 mr-4">
                                        <div class="transition-all rotate-0 {{ isExpanded[teamleader.id] ? 'rotate-90' : '' }}">
                                            <fa-icon icon="chevron-right"></fa-icon>
                                        </div>
                                    </span>
                                    <span *ngIf="!IsEditMode[teamleader.id]" class="text-xl font-bold">{{ teamleader.name }}</span>
                                    <span *ngIf="IsEditMode[teamleader.id]">
                                        <mat-form-field class="text-lg" size="xs">
                                            <mat-label>Name</mat-label>
                                            <input id="teamleader-{{ teamleader.id }}-name" matInput [value]="teamleader.name">
                                            <button class="ml-4" mat-icon-button matSuffix size="xs" click-stop-propagation (click)="OnSaveTeamName(teamleader)">
                                                <fa-icon icon="save"></fa-icon>
                                            </button>
                                        </mat-form-field>
                                    </span>
                                    <button *ngIf="!IsEditMode[teamleader.id]" class="ml-2 text-[#797979] transition-all delay-200 hover:text-white hover:bg-[#797979]" mat-icon-button size="xs" click-stop-propagation (click)="IsEditMode[teamleader.id] = true">
                                        <fa-icon icon="pencil-alt"></fa-icon>
                                    </button>
                                </div>
                                <div>
                                    <button *ngIf="HasEmployeesSelected()" mat-stroked-button class="mr-4" click-stop-propagation (click)="onAssignMultiple(teamleader, data)">
                                        {{ CountEmployeesSelected() }} Mitarbeiter zuordnen
                                    </button>
                                    <button mat-mini-fab
                                            class="mat-elevation-z0"
                                            color="primary"
                                            txConfirmClick
                                            title="Gruppe wirklich löschen?"
                                            click-stop-propagation
                                            [confirmText]="'Wollen Sie die Gruppe \'' + teamleader.name + '\' wirklich löschen?'"
                                            [buttonTexts]="{
                                                cancel: 'Abbrechen',
                                                approve: 'Löschen'
                                            }"
                                            (confirmClick)="OnDeleteTeam(teamleader)"
                                    >
                                        <fa-icon icon="trash"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="getTeamleaderEmployeeAssignments(teamleader.id) | async as data; else loading">
                        <ng-container *ngIf="Employees$ | async as Employees">
                            <div #teamleaderList
                                 [id]="'teamleaderlist-' + teamleader.id"
                                 [attr.data-employees]="ReducePersonNr(data)"
                                 [attr.data-teamleader-id]="teamleader.id"
                                 [attr.data-team]="data | json"
                                 class="relative"
                                 dndDropzone
                                 [dndDragoverClass]="'after:bg-green-400/60'"
                                 (dndDrop)="onDrop($event, 'list')"
                            >
                                <div class="grid mb-1">
                                    <div class="col-5 font-bold border-b">Name</div>
                                    <div class="col-4 font-bold border-b">Team</div>
                                    <div class="col-1 font-bold border-b">FZ</div>
                                    <div class="col-1 font-bold border-b">BU</div>
                                    <div class="col-1 font-bold border-b"></div>
                                </div>
                                <div class="grid hover:bg-gray-50 my-1"
                                     *ngFor="let pers of data">
                                    <div class="col-5 whitespace-nowrap overflow-hidden leading-loose">{{ getEmployeeName(pers.persnr, Employees)}}</div>
                                    <div class="col-4 whitespace-nowrap overflow-hidden leading-loose">{{ getTeamName(pers.persnr, Employees, Teams) }}</div>
                                    <div class="col-1 whitespace-nowrap leading-loose">
                                        <mat-checkbox [checked]="pers.EditAbsent === 1" (change)="onChangeEditAbsent(teamleader, data, pers)"></mat-checkbox>
                                    </div>
                                    <div class="col-1 whitespace-nowrap leading-loose">
                                        <mat-checkbox [checked]="pers.EditBooking === 1" (change)="onChangeEditBooking(teamleader, data, pers)"></mat-checkbox>
                                    </div>
                                    <div class="col-1">
                                        <button mat-button class="m-0 p-0 min-w-0 min-h-0" style="line-height: 0!important;" color="warn" (click)="onDeleteFromList(teamleader, data, pers)">
                                            <fa-icon icon="times-circle"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="border-t pt-4">
                                <div class="grid mb-4">
                                    <div class="col-4 font-bold">Teamleiter</div>
                                    <div class="col-8 relative"
                                         #teamleaderList
                                         [id]="'teamleaderlist-' + teamleader.id + '-leader'"
                                         [attr.data-teamleader]="teamleader | json"
                                         dndDropzone
                                         [dndDragoverClass]="'after:bg-green-400/60'"
                                         (dndDrop)="onDrop($event, 'leader')"
                                    >
                                        {{ getEmployeeName(teamleader.persnr, Employees) }}
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-4 font-bold flex items-center space-x-6">
                                        <div>Stellvertreter</div>
                                    </div>
                                    <div class="col-8 relative flex items-center space-x-4"
                                         #teamleaderList
                                         [id]="'teamleaderlist-' + teamleader.id + '-co-leader'"
                                         [attr.data-teamleader]="teamleader | json"
                                         dndDropzone
                                         [dndDragoverClass]="'after:bg-green-400/60'"
                                         (dndDrop)="onDrop($event, 'co-leader')"
                                    >
                                        <div>{{ getEmployeeName(teamleader.deputy_persnr, Employees) }}</div>
                                        <div *ngIf="teamleader.deputy_persnr > 0">
                                            <button mat-button class="m-0 p-0 min-w-0 min-h-0" style="line-height: 0!important;" color="warn" (click)="OnDeleteCoLeader(teamleader)">
                                                <fa-icon icon="times"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="display-flex flex-justify-content-center py-4">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
</ng-template>
