<ng-container>
    <div [formGroup]="bdeForm">
        <ng-container *ngIf="Contracts$ | async as Contracts">
            <mat-form-field appearance="fill" class="fullwidthDiv">
                <mat-label>{{ 'tx-bde.selectAssignment' | translate }}</mat-label>
                <mat-select #auftragSelect formControlName="auftrag_nummer" (selectionChange)="clearSearch()">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="searchControl" (keyup)="onSearchAuftrag($event)"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let auftrag of Contracts" [value]="auftrag.auftragsnummer">
                        {{ auftrag.bezeichnung }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="fullwidthDiv">
                <mat-label>{{ 'tx-bde.selectTask' | translate }}</mat-label>
                <mat-select #contractSelect formControlName="schritt_nummer" (selectionChange)="clearSearch()">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="searchControlContract"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let schritt of filteredOptions | async"
                                [value]="schritt.schrittnummer">
                        {{ schritt.schrittbezeichnung }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <div class="flex flex-col sm:flex-col lg:flex-row gap-2">
            <mat-form-field appearance="fill" class="w-full lg:w-auto">
                <mat-label>{{ 'general.date' | translate }}</mat-label>
                <input matInput formControlName="date" type="date">
            </mat-form-field>
            <wl-clockpicker
                    [autoclose]="true"
                    donetext="Fertig"
                    formControlName="start"
                    id="bde-cp-start"
                    appearance="fill"
                    [material]="true"
                    [placeholder]="'tx-bde.startTime' | translate"
                    class="w-full lg:w-auto">
            </wl-clockpicker>
            <wl-clockpicker
                    [autoclose]="true"
                    donetext="Fertig"
                    formControlName="end"
                    id="bde-cp-end"
                    appearance="fill"
                    [material]="true"
                    [placeholder]="'tx-bde.endTime' | translate"
                    class="w-full lg:w-auto">
            </wl-clockpicker>
        </div>

        <mat-form-field appearance="fill" class="fullwidthDiv">
            <mat-label>{{ 'general.quantity' | translate }}</mat-label>
            <input matInput formControlName="quantity"/>
        </mat-form-field>
        <mat-form-field appearance="fill" class="fullwidthDiv">
            <mat-label>{{ 'general.note' | translate }}</mat-label>
            <textarea matInput rows="8" formControlName="note"></textarea>
        </mat-form-field>
    </div>
</ng-container>
