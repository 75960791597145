import { Action, createReducer } from '@ngrx/store';

export interface State {
	list: Array<any>;
}

export const initialState: State = {
	list: null,
};

const eventsReducer = createReducer(initialState);

export function reducer(state: State | undefined, action: Action) {
	return eventsReducer(state, action);
}
