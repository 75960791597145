import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserStatusService } from '../../shared/services/user-status.service';
import { GetStates, PutStates, SetUserState } from '../actions/user-state.actions';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class UserStateEffects {
	getStates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GetStates),
			switchMap(() => this.svc$.getUserStatus()),
			map((states) => PutStates(states))
		)
	);

	setState$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SetUserState),
				switchMap(({ id }) => this.svc$.setUserState(id))
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private svc$: UserStatusService) {}
}
