import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import { Observable, Subject } from 'rxjs';
import { WorkstepEntity } from '../../../store/entities/WorkstepEntity';
import { getWorksteps, getWorkstepTypes } from '../../../store/selectors/project-office.selectors';
import { WorkstepTypeEntity } from '../../../store/entities/WorkstepTypeEntity';
import { ProjectOfficeService } from '../../../shared/services/project-office.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
	templateUrl: './tx-project-office-worksteps-dialog.component.html',
	styleUrls: ['./tx-project-worksteps-dialog.component.scss'],
})
export default class TxProjectOfficeWorkstepsDialogComponent implements OnDestroy {
	private $destroyed = new Subject<boolean>();

	public $worksteps: Observable<WorkstepEntity[]>;
	public $workstepTypes: Observable<WorkstepTypeEntity[]>;

	public NewName: string | null = null;
	public NewType: number | null = null;

	public EditMode: { [key: number]: boolean } = {};
	public Edited: { [key: number]: WorkstepEntity } = {};

	constructor(private store: Store<State>, private service: ProjectOfficeService) {
		this.$worksteps = this.store.select(getWorksteps);
		this.$workstepTypes = this.store.select(getWorkstepTypes);
	}

	deleteWorkstep(workstep: WorkstepEntity) {
		this.service.deleteWorkstep(workstep.workstep_id);
	}

	canAdd(worksteps: WorkstepEntity[]) {
		return !worksteps.find((w) => w.workstep_name === this.NewName) && this.NewName && this.NewType !== null;
	}

	onAddWorkstep(name: string, types: WorkstepTypeEntity[]) {
		this.service.addWorkstep(
			name,
			types.find((wt) => wt.value === this.NewType)
		);
		this.NewType = null;
		this.NewName = null;
	}

	getWorkstepType(id: number, workstepTypes: WorkstepTypeEntity[]) {
		return workstepTypes.find((wt) => wt.value === id);
	}

	onSave(workstep) {
		this.service.changeWorkstep(this.getEdited(workstep));
		this.EditMode[workstep.workstep_id] = false;
	}

	onDiscard(workstep: WorkstepEntity) {
		delete this.Edited[workstep.workstep_id];
		this.EditMode[workstep.workstep_id] = false;
	}

	getEdited(workstep: WorkstepEntity) {
		if (!this.Edited[workstep.workstep_id]) {
			this.Edited[workstep.workstep_id] = Object.assign(new WorkstepEntity(), workstep);
		}
		return this.Edited[workstep.workstep_id];
	}

	ngOnDestroy() {
		this.$destroyed.next(true);
		this.$destroyed.complete();
	}
}
