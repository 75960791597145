import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectOfficeService } from '../services/project-office.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProjectStateEntity } from '../../store/entities/ProjectStateEntity';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeProjectStatesResolver implements Resolve<ProjectStateEntity[]> {
	constructor(private projectOfficeService: ProjectOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ProjectStateEntity[]> | Promise<ProjectStateEntity[]> | ProjectStateEntity[] {
		return this.projectOfficeService.fetchProjectStates().pipe(take(1));
	}
}
