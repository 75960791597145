import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { TxApiService } from './txapi.service';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getSession } from '../../store/selectors/user.selectors';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(
		private myAuthService: AuthService,
		private router: Router,
		private myTxApiService: TxApiService,
		private store: Store<State>
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		const url: string = state.url;
		/*
    get session from redux store
    check status and sessionid
    if valid, return true (access granted)
    if not, save redirectUrl and return UrlTree for redirect
     */
		return this.store.select(getSession).pipe(
			map((session) => {
				if (session && session.statuscode === 0 && session.sessionid !== '') {
					return true;
				}
				this.myAuthService.redirectUrl = url;
				return this.router.createUrlTree(['/login']);
			})
		);
		/*if (localStorage.getItem('currentSession')) {
      return of(true);
    }
    this.myAuthService.redirectUrl = url;
    this.router.navigate(['/login']);
    return of(false);*/
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.canActivate(route, state);
	}

	checkLogin(url: string): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.myTxApiService.callAPI('getUserInfo', {}).subscribe(
				(myUserInfo) => {
					observer.next(true);
					observer.complete();
				},
				(error) => {
					localStorage.removeItem('currentSession');
					this.myAuthService.redirectUrl = url;
					observer.next(false);
					observer.complete();
				}
			);
		});
	}
}
