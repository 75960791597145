import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EmployeeEntity } from '../../store/entities/EmployeeEntity';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PersonalOfficeService } from '../services/personal-office.service';

@Injectable({
	providedIn: 'root',
})
export class PoUserDetailResolver implements Resolve<EmployeeEntity> {
	constructor(private po: PersonalOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<EmployeeEntity> | Promise<EmployeeEntity> | EmployeeEntity {
		if (route.params.id === 'all' || route.params.id === 'new') {
			return of(null);
		}
		return this.po.getEmployeeDetail(+route.params.id).pipe(take(1));
	}
}
