import { Action, createReducer, on } from '@ngrx/store';
import { TxSession } from '../../shared/interfaces/session';

import * as UserActions from '../actions/user.actions';
import { NotificationEntity } from '../entities/NotificationEntity';

export interface State {
	session: TxSession | null;
	permissions: string[];
	notifications: NotificationEntity[];
	unread_messages: number;
	has_content: boolean;
}

export const initialState: State = {
	session: null,
	permissions: [],
	notifications: [],
	unread_messages: 0,
	has_content: false,
};

const userReducer = createReducer(
	initialState,
	on(UserActions.setSession, (state, { session }) => ({
		...state,
		session,
	})),
	on(UserActions.setPermissions, (state, { permissions }) => ({
		...state,
		permissions,
	})),
	on(UserActions.addPermission, (state, { permission }) => ({
		...state,
		permissions: Array.from(new Set([...state.permissions, permission])),
	})),
	on(UserActions.removePermission, (state, { permission }) => ({
		...state,
		permissions: [...state.permissions.filter((p) => p != permission)],
	})),
	on(UserActions.addNotification, (state, { notification }) => ({
		...state,
		notifications: [...state.notifications, notification],
	})),
	on(UserActions.removeNotification, (state, { notification }) => ({
		...state,
		notifications: [...state.notifications.filter((n) => n != notification)],
	})),
	on(UserActions.setUnreadMessageCount, (state, { count }) =>
		count !== state.unread_messages
			? {
					...state,
					unread_messages: count,
			  }
			: state
	),
	on(UserActions.setHasContent, (state, { has_content }) => ({
		...state,
		has_content,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return userReducer(state, action);
}
