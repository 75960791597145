import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class FormatDate {
	static createTxDate(dateString: any, timeString?: any) {
		const rawDate = new Date(dateString);
		const newDateString = moment(rawDate).format('YYYY-MM-DD');
		const newTimeString = moment(rawDate).format('HH:mm:ss');
		if (timeString !== undefined) {
			return newDateString + 'T' + timeString;
		}
		return newDateString + 'T' + newTimeString;
	}
}
