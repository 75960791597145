/* tslint:disable:variable-name */
export class TxBdeKST {
	public value: number;
	public label: string;
	public command: () => {};

	constructor(public nummer: number, public bezeichnung: string) {
		this.value = nummer;
		this.label = bezeichnung;
	}
}
