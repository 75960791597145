import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EmployeeEntity } from '../../store/entities/EmployeeEntity';
import { PersonalOfficeService } from '../services/personal-office.service';
import { CalendarGroupEntity } from '../../store/entities/CalendarGroupEntity';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeCalendarGroupResolver implements Resolve<CalendarGroupEntity[]> {
	constructor(private po: PersonalOfficeService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<CalendarGroupEntity[]> | Promise<CalendarGroupEntity[]> | CalendarGroupEntity[] {
		return this.po.getCalendarGroupList().pipe(take(1));
	}
}
