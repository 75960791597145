import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMessages from '../reducers/message-center.reducer';

export const getMessageCenter = createFeatureSelector<fromMessages.State>('messages');

export const getMessageList = createSelector(getMessageCenter, (s) => s.list);

export const getUserMessageList = createSelector(getMessageCenter, (s) => s.userList);

export const getMessageHead = createSelector(getMessageCenter, (s) => s.head);
