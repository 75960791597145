import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { ConnectionStateService } from '../services/connection-state.service';
import * as LocalForage from 'localforage';
import { MessengerService } from '../services/messenger.service';
import { TerminalService } from '../services/terminal.service';
import { AuthService } from '../services/auth.service';
import { ErrorloggerService } from '../services/errorlogger.service';

@Injectable({
	providedIn: 'root',
})
export class RequestQueueInterceptor implements HttpInterceptor {
	pinLoggedIn = false;
	private myRequestQueue: LocalForage;
	private myGetRequestQueue: LocalForage;

	constructor(
		private connectionStateService: ConnectionStateService,
		private messengerService: MessengerService,
		private myTerminalService: TerminalService,
		private myAuthService: AuthService,
		private errorloggerService: ErrorloggerService
	) {
		// Initiate the localForageAPI
		this.myRequestQueue = LocalForage.createInstance({
			name: 'TxWebRequestQueue',
		});

		this.myGetRequestQueue = LocalForage.createInstance({
			name: 'TxWebGetRequestQueue',
		});

		this.myTerminalService.pinLoggedIn.subscribe((data) => (this.pinLoggedIn = data));
	}

	/**
	 * parse the request url to its endpoint
	 */
	static endpoint(url: string) {
		const lastSlahOfUrl = url.lastIndexOf('/');
		return url.substring(lastSlahOfUrl + 1);
	}

	/**
	 * Intercept all http requests
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		/*// Parse the endpoint
    const endpoint = RequestQueueInterceptor.endpoint(req.url);

    // check whether the endpoint is cacheable
    const isCacheableEndpoint = this.connectionStateService.offlineCachableEndpoints.indexOf(endpoint) !== -1;

    if (isCacheableEndpoint) {
      return next.handle(req).pipe(
        timeout(1500),
        catchError((error: HttpErrorResponse) => {
          // save the request body to localStorage
          if (req.body.hash) {

            try {
              this.myRequestQueue.setItem(req.body.hash, {
                endpunkt: endpoint,
                bodyJson: req.body
              });
            } catch (e) {
              this.errorloggerService.handleError(e);
            }
          }

          // send fake message on error
          this.messengerService.message(
            'Ihre Verbindung ist schlecht. Ihre Buchungen werden übermittelt sobald Sie wieder online.', 'info', endpoint
          );

          if (this.pinLoggedIn) {
            setTimeout(() => {
              this.myAuthService.logout();
            }, 1500);
          }

          // return empty response
          return of(null);
        })
      );
    }


    if (endpoint.includes('get')) {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            try {
              this.myGetRequestQueue.setItem(req.urlWithParams, event.body);
            } catch (e) {
              this.errorloggerService.handleError(e);
            }
          }
        })
      );
    }*/

		// Parse the endpoint
		const endpoint = RequestQueueInterceptor.endpoint(req.url);

		// check whether the endpoint is cacheable
		const isCacheableEndpoint = this.connectionStateService.offlineCachableEndpoints.includes(endpoint);

		if (isCacheableEndpoint) {
			return next.handle(req).pipe(
				tap((event) => {
					if (event instanceof HttpResponse) {
						if (event.body?.type === 'offline') {
							// send fake message on error
							this.messengerService.message(
								'Ihre Verbindung ist schlecht. Ihre Buchungen werden übermittelt sobald Sie wieder online sind.',
								'info'
							);
							//this.connectionStateService.isOnlineSubject.next(false);
						} else {
							//this.connectionStateService.isOnlineSubject.next(true);
						}
					}
					return event;
				})
			);
		}

		return next.handle(req);
	}
}
