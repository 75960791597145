import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TxWfCalendarWithDay } from '../po-types';

@Component({
	templateUrl: './tx-dayview.component.html',
	styleUrls: ['./tx-dayview.component.scss'],
	selector: 'tx-dayview',
})
export class TxDayviewComponent implements OnDestroy, OnInit {
	@Input() public Workflows: TxWfCalendarWithDay[];

	constructor() {}

	ngOnInit() {}

	ngOnDestroy() {}
}
