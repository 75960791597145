<section [className]="mobileOrTabletDevice ? 'widget' : 'widget md:hidden'" widget>
	<header>
		<h4>{{ title }}</h4>
	</header>
	<div class="widget-body">
		<p>{{ description }}</p>
        <div *ngIf="blockui" class="absolute top-0 right-0 bottom-0 left-0 z-index-1000 flex justify-center items-center">
            <div class="absolute top-0 right-0 bottom-0 left-0 bg-black/80"></div>
            <div class="z-10 text-white">
                <fa-icon icon="spinner" [spin]="true" size="2x"></fa-icon>
            </div>
        </div>
		<mat-card class="mat-elevation-z0">
			<div class="rounded bg-gray-200 p-4 border border-gray-500 flex flex-column align-items-center justify-content-center">
				<input #fileInput (change)="onFileChanged($event)" accept="image/*" capture style="display: none" type="file" />

				<div class="grid">
					<div *ngIf="!readyToUpload" class="col-12 md:col-4">
						<button
							(click)="fileInput.click()"
							mat-raised-button
                            color="primary"
							type="button">
                            <i class="fa fa-fw fa-camera mr-2"></i>
                            {{ chooseLabel }}
                        </button>
					</div>
					<div *ngIf="readyToUpload" class="col-6 md:col-4">
						<button
							(click)="resetUpload()"
							[disabled]="!readyToUpload"
                            mat-raised-button
                            color="primary"
							type="button">
                            <i class="fa fa-fw fa-trash mr-2"></i>
                            {{ resetLabel }}
                        </button>
					</div>
					<div *ngIf="readyToUpload" class="col-6 md:col-4">
						<button
							(click)="onUpload()"
							[disabled]="!readyToUpload"
                            mat-raised-button
                            color="primary"
							type="button">
                            <i class="fa fa-fw fa-cloud-upload-alt mr-2"></i>
                            {{ uploadLabel }}
                        </button>
					</div>
				</div>
                <img *ngIf="imagePreview" alt="upload" style="max-width: 100%; height: auto;" [src]="sanitizer.bypassSecurityTrustUrl(imagePreview)" />
			</div>
			<p-progressSpinner *ngIf="imagePreviewLoading"></p-progressSpinner>
		</mat-card>
	</div>
</section>
