import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'coTime',
	pure: false,
})
export class CoTimePipe implements PipeTransform {
	transform(myCosoftDate: string) {
		if (myCosoftDate === '') {
			return '';
		}
		return myCosoftDate.substr(myCosoftDate.indexOf('T') + 1, 5);
	}
}
