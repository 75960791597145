import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

declare var Messenger: any;

@Injectable({
	providedIn: 'root',
})
export class MessengerService {
	constructor(private msg: MessageService) {}

	message(message, type: MessengerType, id?: string) {
		/*this.msg.add({
			summary: message,
			severity: type,
            sticky: true
		});*/
		return Messenger().post({
			message,
			type,
			showCloseButton: true,
			hideAfter: 5,
			id: id ? id : null,
			singleton: !!id,
		});
	}
}
