import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as UserActions from '../actions/user.actions';
import { concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TxSession } from '../../shared/interfaces/session';
import sha1 from 'crypto-js/sha1';
import { KillStore, NOOP } from '../actions';
import { Md5 } from 'ts-md5';
import { Router } from '@angular/router';
import { from, of, filter } from 'rxjs';
import {
	CALENDAR,
	CLOUD_MENU,
	HAS_MESSAGECENTER,
	IS_TEAMLEADER,
	PLANNING,
	PROJECT_OFFICE,
	STAFF_OFFICE,
	STAFF_OFFICE_ACCOUNTS,
	STAFF_OFFICE_FILE,
	STAFF_OFFICE_TIMEMODEL,
	WORKFLOW,
	STAFF_OFFICE_FILE_PERMISSIONS,
	STAFF_OFFICE_TEAMLEITERGRUPPEN,
} from '../entities/Permissions';
import { AuthService } from '../../shared/services/auth.service';
import { TerminalService } from '../../shared/services/terminal.service';
import { TxApiService } from '../../shared/services/txapi.service';
import { NotificationEntity } from '../entities/NotificationEntity';
import { Store } from '@ngrx/store';
import { getNotifications, getSession, getUser } from '../selectors/user.selectors';
import { GlobalConstants } from '../../globalConstants';
import { addPermission } from '../actions/user.actions';
import { MessageCenterService } from '../../shared/services/message-center.service';
import { FetchMessages, setMessageHeader } from '../actions/message-center.actions';
import { MessageHeadEntity } from '../entities/Api/MessageHeadEntity';
import { ApiResponse } from '../entities/Api/ApiResponse';
import { ActiveBDEService } from '../../shared/services/activebde.service';

const Headers = new HttpHeaders({
	'Content-Type': 'application/json',
});

@Injectable()
export class UserEffects {
	performLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.performLogin),
			switchMap(({ username, password, stay }) => {
				return this.http
					.post<TxSession>(
						`${this.appConfig.getConfig().TimeIXServerUrl}createSession`,
						{
							username,
							password: stay ? password : (password && sha1(password).toString()) || null,
						},
						{
							headers: Headers,
						}
					)
					.pipe(
						map((result) => {
							result.iun = username;
							//localStorage.setItem('currentSession', JSON.stringify(result));
							this.auth.setSession(result);
							this.auth.setSessionDomain(username);
							this.terminal.setPinLogin(result.terminal_mode === 1);
							return result;
						})
					);
			}),
			map((res) => UserActions.setSession(res))
		)
	);

	setPermissions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.setSession),
			concatMap(({ session }) => {
				const perms = [];
				if (session.team_leader === 1) {
					perms.push(UserActions.addPermission(IS_TEAMLEADER));
				}
				if (session.appMode === 'cloudadmin') {
					perms.push(UserActions.addPermission(CLOUD_MENU));
				}
				if (session.calendar === 1) {
					perms.push(UserActions.addPermission(CALENDAR));
				}
				if (session.Workflow === 1) {
					perms.push(UserActions.addPermission(WORKFLOW));
				}
				if (session.project_office === 1) {
					perms.push(UserActions.addPermission(PROJECT_OFFICE));
				}
				if (session.planning === 1) {
					perms.push(UserActions.addPermission(PLANNING));
				}
				if (session.staff_office === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE));
				}
				if (session.staff_office_accounts === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE_ACCOUNTS));
				}
				if (session.staff_office_file === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE_FILE));
				}
				if (session.staff_office_timemodel === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE_TIMEMODEL));
				}
				if (session.staff_office_tlg === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE_TEAMLEITERGRUPPEN));
				}
				if (session.messages_available) {
					perms.push(UserActions.addPermission(HAS_MESSAGECENTER));
				}
				if (session.staff_office_file_permissions === 1) {
					perms.push(UserActions.addPermission(STAFF_OFFICE_FILE_PERMISSIONS));
				}
				return perms;
			})
		)
	);

	verifySession$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.verifySession),
			switchMap(({ session }) =>
				this.http
					.post(`${this.appConfig.config.TimeIXServerUrl}getUserInfo`, {
						hash: Md5.hashStr(`getUserInfo{}`),
						sessionid: session.sessionid,
						iun: session.iun,
						app_version: GlobalConstants.appVersion ?? 'dev',
						connectionspeed: `1000Kbit/s`,
					})
					.pipe(
						switchMap((res: any) => {
							if (res.statuscode === 1 || res.statuscode === 4) {
								const storedLogin = localStorage.getItem('txlgn');
								if (storedLogin) {
									const loginData: {
										username: string;
										password: string;
									} = JSON.parse(storedLogin);
									return from(this.router.navigate(['/', 'login'])).pipe(
										map(() => KillStore()),
										map(() => UserActions.performLogin(loginData.username, loginData.password, true))
									);
								} else {
									localStorage.removeItem('currentSession');
									return from(this.router.navigate(['/', 'login'])).pipe(map(() => KillStore()));
								}
							}
							return of(UserActions.setSession(session));
						})
					)
			)
		)
	);

	requestNotifiction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.requestNotification),
			withLatestFrom(this.store.select(getNotifications)),
			filter(([action, notifications]) => !notifications.find((n) => n.id === action.id)),
			mergeMap(([{ id }]) =>
				this.api.callAPI('getNotification', { notification_id: id }).pipe(
					switchMap((res) => {
						return of(UserActions.addNotification(new NotificationEntity(id, res.message)));
					})
				)
			)
		)
	);

	removeNotification$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.removeNotification),
			mergeMap(({ notification }) => {
				return this.api.callAPI('updateNotification', { notification_id: notification.id }).pipe(map(() => NOOP()));
			})
		)
	);

	hasContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.setHasContent),
			filter(({ has_content }) => has_content),
			tap(() => this.activeBde.requestData()),
			concatMap(() => [FetchMessages(0, false)])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly api: TxApiService,
		private readonly http: HttpClient,
		private readonly appConfig: AppConfigService,
		private readonly router: Router,
		private readonly auth: AuthService,
		private readonly terminal: TerminalService,
		private readonly store: Store,
		private readonly activeBde: ActiveBDEService
	) {}
}
