import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ImageUploadService {
	constructor(private myTxApi: TxApiService) {}

	uploadImage(uploadData): Observable<any> {
		return this.myTxApi.callAPI('setPicture', uploadData);
	}
}
