import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'tx-list-content',
	styleUrls: ['./list-content.component.scss'],
	templateUrl: 'list-content.component.html',
})
export default class ListContentComponent<T> implements OnInit {
	@Input() listHeader: string;
	@Input() items$: Observable<T[]>;

	@Input() getLabel: (T) => string;
	ngOnInit(): void {}

	onItemSelected(event: number) {}
}
