import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
	transform(value: string, format: string): string {
		return moment(value).format(format);
	}
}
