import {
    Pipe,
    PipeTransform
} from '@angular/core';
import { zeroFill } from '../helpers';

@Pipe({
    name: 'ZeroFill',
})
export class ZeroFillPipe implements PipeTransform {
    transform(value: string | number, length = 2) {
        return value && zeroFill(value, length);
    }
}
