import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../shared/interfaces/scheduler';
import moment from 'moment';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'tx-event',
	templateUrl: './tx-event.component.html',
	styleUrls: ['./tx-event.component.scss'],
})
export default class TxEventComponent implements OnInit {
	@Input() event: Event;
	@Input() queued = false;

	everySecond$: Observable<string> | null = null;

	constructor() {}

	ngOnInit() {
		if (!this.event.end) {
			this.everySecond$ = interval(1000).pipe(
				map(() => {
					const current = this.getTimeSpan();
					if (current < 1) {
						return `${(current * 60).toFixed(0)} Minuten`;
					}
					return `${current.toFixed(2)} Stunden`;
				})
			);
		}
	}

	formatTime(time: Date | string) {
		return moment(time).format('DD.MM.YYYY HH:mm');
	}

	getTimeSpan() {
		return moment(this.event.end ?? moment()).diff(moment(this.event.start), 'hours', true);
	}
}
