import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PlansService } from '../services/plans.service';
import { take } from 'rxjs/operators';
import { PlanCalendarEntity } from '../../store/entities/PlanCalendarEntity';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class PlanCalendarResolver implements Resolve<PlanCalendarEntity[]> {
	constructor(private readonly service: PlansService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<PlanCalendarEntity[]> | Promise<PlanCalendarEntity[]> | PlanCalendarEntity[] {
		return this.service
			.fetchCalendarList(
				moment().format('YYYY-MM-DDTHH:mm:ss'),
				moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
				true
			)
			.pipe(take(1));
	}
}
