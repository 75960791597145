<div
	class="tx-event"
	[ngClass]="event.className"
	[class.queued]="event.queued"
	[style]="{ backgroundColor: event.color }">
	<div>
		<h5>{{ event.title }}</h5>
		<div class="display-flex flex-justify-content-space-between">
			<span> Start: {{ formatTime(event.start) }} </span>
			<span *ngIf="event.end"> Ende: {{ formatTime(event.end) }} </span>
		</div>
		<div *ngIf="event.end">
			<span class="fw-bold">Erfasste Zeit: {{ getTimeSpan().toFixed(2) }} Stunden</span>
		</div>
		<div *ngIf="!event.end">
			<span *ngIf="everySecond$ | async as currentSpan; else calcCurrentSpan" class="fw-bold"
				>Laufende Zeit: {{ currentSpan }}</span
			>
			<ng-template #calcCurrentSpan>
				<span class="fw-bold">Laufende Zeit: --</span>
			</ng-template>
		</div>
	</div>
	<div style="flex-basis: 15%; text-align: center">
		<div class="spinner">
			<fa-icon *ngIf="event.queued; else isNotQueued" icon="cloud-upload-alt" size="4x"></fa-icon>
			<ng-template #isNotQueued>
				<div *ngIf="!event.end" class="clock"></div>
				<fa-icon *ngIf="event.start && event.end" style="color: limegreen" icon="check" size="4x"></fa-icon>
			</ng-template>
		</div>
	</div>
</div>
