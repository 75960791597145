<div class="wrapper" [class.queued]="assignment.queued">
	<div class="grid title">
		<div class="col-6">
			<h4 class="title">{{ 'tx-bde.activeAssignment' | translate }}</h4>
		</div>
		<div class="col-6 timedata">
            <div *ngIf="assignment?.auftrag_start">
                {{ 'tx-bde.startTime' | translate }}: <b>{{ assignment?.auftrag_start | date: 'HH:mm' }}</b> {{ 'general.clock' | translate }}
            </div>
            <div>
    <span *ngIf="assignment?.auftrag_zeit_soll">
        Soll: <b>{{ assignment?.auftrag_zeit_soll }}</b>
    </span>
                <span *ngIf="assignment?.auftrag_zeit_soll && assignment?.auftrag_zeit_ist"> / </span>
                <span *ngIf="assignment?.auftrag_zeit_ist">
        Ist: <b>{{ assignment?.auftrag_zeit_ist }}</b>
    </span>
            </div>
		</div>
	</div>
	<div class="widget-body">
		<div class="grid">
			<div class="col-12">
				<dl class="dl-horizontal">
					<dt>{{ 'tx-bde.assignment' | translate }}:</dt>
					<dd>{{ assignment?.auftrag_name }}</dd>
					<dd class="line-break"></dd>
					<dt>{{ 'tx-bde.task' | translate }}:</dt>
					<dd>{{ assignment?.schritt_name }}</dd>
				</dl>
				<div class="spinner mt-4">
					<fa-icon *ngIf="assignment.queued" icon="cloud-upload-alt" size="3x"></fa-icon>
					<fa-icon *ngIf="!assignment.queued" icon="check" style="color: limegreen" size="3x"></fa-icon>
				</div>
				<dl class="dl-horizontal">
					<dt>{{ 'tx-bde.note' | translate }}:</dt>
					<dd>
						<input pInputText [(ngModel)]="Note" style="width: 100%" autocomplete="new-password" />
					</dd>
				</dl>
				<dl class="dl-horizontal">
					<dt>Menge:</dt>
					<dd>
						<input type="number" class="form-control" step="0.1" pInputText [(ngModel)]="Quantity" />
					</dd>
				</dl>
				<div>
					<button
						pButton
						[label]="'tx-bde.endAssignment' | translate"
						class="btn btn-lg btn-primary btn-block"
						(click)="
							this.end.emit({
								aBuchungsart: 'AE',
								aActiveAuftrag: assignment?.auftrag_nummer,
								aActiveSchritt: assignment?.schritt_nummer,
								aIndex: i,
								aKill: true,
								note: Note,
								quantity: Quantity
							});
							this.EndPressed = true
						"
						[disabled]="EndPressed"></button>
				</div>
			</div>
		</div>
	</div>
</div>
