import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { Actions, ofType } from '@ngrx/effects';
import {
    getDepartments,
    getEmployeeGroups,
    getCostcenters,
    getContracts
} from '../../store/selectors/data.selectors';
import * as actions from '../../store/actions/data.actions';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ListOfDepartmentEntity } from '../../store/entities/ListOfDepartmentEntity';
import { TeamEntity } from '../../store/entities/TeamEntity';
import { DeleteTeam, UpdateTeam } from '../../store/actions/personal-office.actions';

@Injectable({
	providedIn: 'root',
})
export class DataService {
	constructor(private store: Store<State>, private actions$: Actions) {}

    getContracts() {
        return this.store.select(getContracts).pipe(
            switchMap((list) => {
                if (!list) {
                    this.store.dispatch(actions.GetContracts());
                    return this.actions$.pipe(
                        ofType(actions.SetContracts),
                        map((a) => a.list)
                    );
                }
                return of(list);
            })
        );
    }

	getKSTs() {
		return this.store.select(getCostcenters).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(actions.GetCostcenters());
					return this.actions$.pipe(
						ofType(actions.SetCostcenters),
						map((a) => a.list)
					);
				}
				return of(list);
			})
		);
	}

	getDepartments(force = false) {
		return this.store.select(getDepartments).pipe(
			switchMap((list) => {
				if (force || !list) {
					this.store.dispatch(actions.GetDepartments());
					return this.actions$.pipe(
						ofType(actions.SetDepartments),
						map((a) => a.list)
					);
				}
				return of(list);
			})
		);
	}

	getEmployeeGroups(force = false) {
		return this.store.select(getEmployeeGroups).pipe(
			switchMap((list) => {
				if (force || !list) {
					this.store.dispatch(actions.GetEmployeeGroups());
					return this.actions$.pipe(
						ofType(actions.SetEmployeeGroups),
						map((a) => a.list)
					);
				}
				return of(list);
			})
		);
	}

	updateDepartment(e: ListOfDepartmentEntity) {
		this.store.dispatch(actions.UpdateDepartment(e.id, e.name));
	}

	addDepartment(name: string) {
		this.store.dispatch(actions.UpdateDepartment(0, name));
	}

	deleteDepartment(id: number) {
		this.store.dispatch(actions.DeleteDepartment(id));
	}

	updateEmployeeGroup(e: ListOfDepartmentEntity) {
		this.store.dispatch(actions.UpdateEmployeeGroup(e.id, e.name));
	}

	addEmployeeGroup(name: string) {
		this.store.dispatch(actions.UpdateEmployeeGroup(0, name));
	}

	deleteEmployeeGroup(id: number) {
		this.store.dispatch(actions.DeleteEmployeeGroup(id));
	}

	updateTeam(e: TeamEntity) {
		this.store.dispatch(UpdateTeam(e));
	}

	deleteTeam(t: TeamEntity) {
		this.store.dispatch(DeleteTeam(t));
	}
}
