import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    private refreshCalendarSource = new Subject<void>();
    refreshCalendar$ = this.refreshCalendarSource.asObservable();

    triggerRefreshCalendar() {
        this.refreshCalendarSource.next();
    }
}
