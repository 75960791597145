import {
    Component,
    OnInit
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { result } from 'lodash';

@Component({
    selector: 'tx-bde-barcode-scanner',
    templateUrl: './tx-barcode-scanner.component.html'
})
export class TxBarcodeScannerComponent implements OnInit {

    public Enabled = false;

    constructor(
        public readonly dialogRef: MatDialogRef<TxBarcodeScannerComponent>
    ) {}

    public receiveScanResult(result: any) {
        this.dialogRef.close(result);
    }

    ngOnInit(): void {
        this.Enabled = true;
    }
}
