<ng-container *ngIf="myTabStateService.TabStates$ | async as TabStates">
    <div mat-dialog-title>
        Zeiterfassung
    </div>
    <div mat-dialog-content>
        <mat-tab-group
                *ngIf="data.type === 'select'"
                [selectedIndex]="selectedIndex"
                (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Kommen/Gehen"
                     *ngIf="this.session.calendar_pze >= 1 || this.data.teamLeaderFor && this.session.team_leader === 1">
                <ng-container *ngTemplateOutlet="pze"></ng-container>
            </mat-tab>
            <mat-tab label="Auftrag"
                     *ngIf="this.session.calendar_bde === 1 || this.data.teamLeaderFor && this.session.team_leader === 1">
                <ng-container *ngTemplateOutlet="bde"></ng-container>
            </mat-tab>
        </mat-tab-group>
        <div *ngIf="data.type === 'bde'">
            <ng-container *ngTemplateOutlet="bde"></ng-container>
        </div>
        <div *ngIf="data.type === 'pze'">
            <ng-container *ngTemplateOutlet="pze"></ng-container>
        </div>
        <div *ngIf="this.data.bookProtocol.length > 0">
            <mat-accordion>
                <mat-expansion-panel
                        class="mat-elevation-z2"
                        hideToggle
                        (opened)="onTabOpen('pr1')"
                        (closed)="onTabClose('pr1')"
                        [expanded]="TabStates.pr1 == null || TabStates.pr1 == true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="flex space-x-2">
                                <div class="ml-1">
                                    <div class="transition-all rotate-0 {{ TabStates.pr1 ? 'rotate-90' : '' }}">
                                        <fa-icon icon="chevron-right"></fa-icon>
                                    </div>
                                </div>
                                <div class="text-xl font-bold">
                                    {{ 'general.protocol' | translate }}
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div style="max-height: 300px; overflow-y: auto;">
                        <table mat-table [dataSource]="this.data.bookProtocol" style="width:100%">

                            <ng-container matColumnDef="BookDate">
                                <th mat-header-cell *matHeaderCellDef>{{ 'general.date' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.BookDate }}
                                    {{ 'general.at' | translate }}
                                    {{ element.BookTime }}
                                    {{ 'general.clock' | translate }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="BookType">
                                <th mat-header-cell *matHeaderCellDef>{{ 'general.type' | translate }}</th>
                                <td mat-cell
                                    *matCellDef="let element"> {{ 'general.' + element.BookType | translate }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="BookError">
                                <th mat-header-cell *matHeaderCellDef>{{ 'general.info' | translate }}</th>
                                <td mat-cell *matCellDef="let element"> {{ element.BookError }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-between">
        <button mat-raised-button color="accent" [mat-dialog-close]="null">Abbrechen</button>
        <div class="button-group">
            <button mat-raised-button color="primary"
                    *ngIf="!(!this.data.teamLeaderFor && (
            this.SelectedType === 'pze' && mustCreatePze ||
            this.SelectedType === 'bde' && mustCreateBde
        )) && this.data.id"
                    (click)="deleteEvent()">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-raised-button color="primary" [mat-dialog-close]="{
             type: SelectedType,
             data: SelectedType == 'pze' ? PzeFormData.value : BdeFormData.value

            }"
                    [disabled]="!(SelectedType == 'pze' ? PzeFormData.isValid : SelectedType == 'bde' ? BdeFormData.isValid : false)">
                {{ getSaveButtonText() }}
            </button>
        </div>
    </div>
</ng-container>
<ng-template #pze>
    <app-tx-scheduler-pze
            [date]="data.start.format('YYYY-MM-DD')"
            [startTime]="data.start.format('HH:mm')"
            [endTime]="data.end.format('HH:mm')"
            [(ngModel)]="PzeFormData">
    </app-tx-scheduler-pze>
</ng-template>
<ng-template #bde>
    <app-tx-scheduler-bde
            [date]="data.start.format('YYYY-MM-DD')"
            [startTime]="data.start.format('HH:mm')"
            [endTime]="data.end.format('HH:mm')"
            [(ngModel)]="BdeFormData"
    ></app-tx-scheduler-bde>
</ng-template>
