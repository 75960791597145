<div class="grid">
	<div class="col-12 xl:col-3">
		<mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
			<mat-expansion-panel-header>{{ listHeader }}</mat-expansion-panel-header>
			<tx-list
				*ngIf="items$ | async as Items"
				maxHeight="65vh"
				[items]="Items"
				[labelField]="getLabel"
				[idField]="'employee_id'"
                [searchField]="'firstname,lastname'"
				routerLinkBase="personaloffice/personalakten"
				(selectedItem)="onItemSelected($event)"></tx-list>
		</mat-expansion-panel>
	</div>
	<div class="col-xs-12 col-sm-12 col-xl-9">
		<section class="widget"></section>
	</div>
</div>
