import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class VibrationService {
	defaultVibrate = 1000;

	constructor() {}

	isSupported(): boolean {
		return 'vibrate' in navigator;
	}

	vibrate(input): void {
		if (typeof input === 'undefined') {
			input = this.defaultVibrate;
		}
		if (this.isSupported()) {
			navigator.vibrate(input);
		}
	}
}
