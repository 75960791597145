import { Action, createReducer, on } from '@ngrx/store';
import { PlanEntity } from '../entities/PlanEntity';
import { SetCalendar, SetPlans } from '../actions/plans.actions';
import { PlanCalendarEntity } from '../entities/PlanCalendarEntity';

export interface State {
	list: Array<any>;
	plans: PlanEntity[];
	calendar: PlanCalendarEntity[];
}

export const initialState: State = {
	list: null,
	plans: null,
	calendar: null,
};

const eventsReducer = createReducer(
	initialState,
	on(SetPlans, (state, { plans }) => ({
		...state,
		plans: plans?.slice() ?? [],
	})),
	on(SetCalendar, (state, { calendar }) => ({
		...state,
		calendar: calendar?.slice() ?? [],
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return eventsReducer(state, action);
}
