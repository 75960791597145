import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	AddWorkstep,
	DeleteProject,
	DeleteWorkstep,
	FetchCalendarGroup,
	FetchList,
	FetchProjectStates,
	FetchWorksteps,
	FetchWorkstepTypes,
	RemoveProject,
	SaveProject,
	SetCalendarGroup,
	SetList,
	SetProjectStates,
	SetWorksteps,
	SetWorkstepTypes,
	UpdateWorkstep,
} from '../actions/project-office.actions';
import { TxApiService } from '../../shared/services/txapi.service';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { ApiResponse, ApiResponseType } from '../entities/Api/ApiResponse';
import { ProjectEntity } from '../entities/ProjectEntity';
import { WorkstepEntity } from '../entities/WorkstepEntity';
import { WorkstepTypeEntity } from '../entities/WorkstepTypeEntity';
import { ProjectStateEntity } from '../entities/ProjectStateEntity';
import { ErrorAction, ErrorEvent, SuccessAction } from '../actions';
import { MessengerService } from '../../shared/services/messenger.service';
import { ListOfCalendarGroupResponse } from '../entities/Api/ListOfCalendarGroupResponse';
import { CalendarGroupEntity } from '../entities/CalendarGroupEntity';

@Injectable()
export class ProjectOfficeEffects {
	fetchList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchList),
			switchMap(({ state }) => this.api.callAPI('GetProjectList', { project_state: state ?? 0 })),
			map((res: ApiResponse<ProjectEntity[]>) => {
				return SetList(res.ListOfProject?.map((l) => ProjectEntity.tryCast(l)));
			})
		)
	);

	fetchWorksteps$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchWorksteps),
			switchMap(() => this.api.callAPI('getWorkStepList')),
			map((res: ApiResponse<WorkstepEntity[]>) => {
				return SetWorksteps(res.ListOfWorkstep?.map((w) => WorkstepEntity.tryCast(w)));
			})
		)
	);

	fetchWorkstepTypes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchWorkstepTypes),
			switchMap(() =>
				this.api.callAPI('getDataList', {
					listName: 'WorkstepType',
				})
			),
			map((res: ApiResponse<WorkstepTypeEntity[]>) => {
				return SetWorkstepTypes(res.ListOfDataListItem?.map((l) => WorkstepTypeEntity.tryCast(l)));
			})
		)
	);

	fetchProjectStataes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchProjectStates),
			switchMap(() =>
				this.api.callAPI('getDataList', {
					listName: 'ProjectState',
				})
			),
			map((res: ApiResponse<ProjectStateEntity[]>) => {
				return SetProjectStates(res.ListOfDataListItem?.map((l) => ProjectStateEntity.tryCast(l)));
			})
		)
	);

	deleteProject$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteProject),
			switchMap(({ project_id }) =>
				this.api
					.callAPI('deleteProject', {
						project_id,
					})
					.pipe(
						map((res: ApiResponseType) => {
							if (res.statuscode === 0) {
								return RemoveProject(project_id);
							}
							this.msg.message(res.statustext, 'error');
							return ErrorEvent(res);
						})
					)
			)
		)
	);

	saveOrCreate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SaveProject),
			switchMap(({ project }) =>
				this.api
					.callAPI('UpdateProject', {
						ProjectDetail: [project],
					})
					.pipe(
						concatMap((res: ApiResponseType) => {
							if (res.statuscode === 0) {
								this.msg.message('Erfolgreich gespeichert!', 'success');
								return [FetchList(), SuccessAction(SaveProject)(project)];
							}
							this.msg.message(res.statustext, 'error');
							return [ErrorEvent(res), ErrorAction(SaveProject)(res.statustext)];
						})
					)
			)
		)
	);

	addWorkstep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AddWorkstep),
			switchMap(({ name, workstepType }) =>
				this.api
					.callAPI('UpdateWorkstep', {
						workstep: {
							workstep_name: name,
							workstep_type: workstepType.value,
						},
					})
					.pipe(
						map((res: ApiResponseType) => {
							return FetchWorksteps();
						})
					)
			)
		)
	);

	deleteWorkstep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteWorkstep),
			switchMap(({ id }) =>
				this.api
					.callAPI('deleteWorkstep', {
						workstep_id: id,
					})
					.pipe(
						map((res: ApiResponseType) => {
							if (res.statuscode === -1) {
								this.msg.message(res.statustext, 'error');
								return ErrorEvent(res.statustext);
							}
							this.msg.message(res.statustext, 'success');
							return FetchWorksteps();
						})
					)
			)
		)
	);

	updateWorkstep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UpdateWorkstep),
			switchMap(({ workstep }) =>
				this.api
					.callAPI('UpdateWorkstep', {
						workstep,
					})
					.pipe(map(() => FetchWorksteps()))
			)
		)
	);

	fetchCalendarGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchCalendarGroup),
			switchMap(() => this.api.callAPI('getCalendarGroupList')),
			map((res: ApiResponse<ListOfCalendarGroupResponse[]>) =>
				SetCalendarGroup(res.ListOfCalendarGroup.map((l) => CalendarGroupEntity.tryCast(l)))
			)
		)
	);

	constructor(private actions$: Actions, private api: TxApiService, private msg: MessengerService) {}
}
