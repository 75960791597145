import { Component, Inject, Input, OnInit } from '@angular/core';
import { EmployeeEntity } from '../../../../../store/entities/EmployeeEntity';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TxApiService } from '../../../../../shared/services/txapi.service';

interface CreateTeamLeaderDialogData {
	title: string;
	teamleaderUsers: EmployeeEntity[];
}

@Component({
	templateUrl: './tx-create-team-leader.dialog.component.html',
})
export class TxCreateTeamLeaderDialogComponent {
	public title: string;
	public teamleaderUsers: EmployeeEntity[];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: CreateTeamLeaderDialogData
	) {
		this.title = data.title;
		this.teamleaderUsers = data.teamleaderUsers;
	}
}
