import { Pipe, PipeTransform } from '@angular/core';
import moment, { Duration } from 'moment';
import { zeroFill } from '../helpers';

type MomentFunctions = 'weekdays' | 'formatTime' | 'duration' | 'durationMinutes';

@Pipe({
	name: 'Moment',
})
export class MomentPipe implements PipeTransform {
    static DurationRegExp = new RegExp('(\d+)[HM]', 'g');

	constructor() {
		moment.locale('de');
	}

	transform(value: number | string, func: MomentFunctions, format?: string): string | number {
		if (!value) {
			return null;
		}
		let duration: Duration = null;
		switch (func) {
			case 'weekdays':
				return value && moment.weekdays(value as number);
			case 'formatTime': {
                if (typeof value === 'string') {
                    duration = moment.duration(value.split('T')[1]);
                } else {
                    duration = moment.duration(value, 'minutes');
                }
                const humanized = duration.toISOString().substring(2);
                let hours = 0;
                let minutes = 0;
                for (let match of humanized.matchAll(/(\d+)([HM])/g)) {
                    const [s, value, unit] = match;
                    if (unit === 'H') {
                        hours = parseInt(value);
                    } else {
                        minutes = parseInt(value);
                    }
                }
                return value && `${ zeroFill(hours) }:${ zeroFill(minutes) }`;
            }
			case 'duration': {
                duration = moment.duration(value, 'h');
                const humanized = duration.toISOString().substring(2);
                let hours = 0;
                let minutes = 0;
                for (let match of humanized.matchAll(/(\d+)([HM])/g)) {
                    const [s, value, unit] = match;
                    if (unit === 'H') {
                        hours = parseInt(value);
                    } else {
                        minutes = parseInt(value);
                    }
                }
                return `${ hours }${ minutes }`;
            }
            case 'durationMinutes':
                duration = moment.duration(value, 'minutes');
                return `${duration.get('h')}${duration.get('m')}`;
            default:
                return value;
		}
	}
}
