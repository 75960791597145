<p-toast position="top-center">
	<ng-template let-message pTemplate="message">
		<div class="body">
			<div class="severity">{{ message.severity }}</div>
			<div class="summary">{{ message.summary }}</div>
		</div>
	</ng-template>
</p-toast>
<div *ngIf="blockLayout" class="blocklayout"></div>
<tx-sidebar
	(toggleSidebarEvent)="toggleSidebarListener()"
	*ngIf="!pinLoggedIn"
	class="sidebar"
	id="sidebar"
	role="navigation"></tx-sidebar>
<nav class="page-controls navbar navbar-dashboard navbar-fixed-top px-4">
	<tx-navbar
		(toggleChatEvent)="toggleChatListener()"
		(toggleSidebarEvent)="toggleSidebarListener()"
		[pinMode]="pinLoggedIn"></tx-navbar>
</nav>
<aside (toggleChatEvent)="toggleChatListener()" *ngIf="!pinLoggedIn" class="chat-sidebar" infocenter></aside>
<div (click)="closeAllListener()" *ngIf="!pinLoggedIn" class="sidebar-overlay"></div>

<div class="content-wrap" id="content-wrap">
	<main class="content" id="content" role="main">
		<router-outlet></router-outlet>
	</main>
</div>
