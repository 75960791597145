import { Component, OnInit } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { TxBuchung, TxBuchungen, TxInfo } from '../../shared/interfaces/buchung';
import { TxApiService } from '../../shared/services/txapi.service';
import { FormatDate } from '../../shared/services/formatdate.service';
import { AuthService } from '../../shared/services/auth.service';
import { TabStates, TabStateService } from '../../shared/services/tabstate.service';
import { TxSession } from '../../shared/interfaces/session';
import { Store } from '@ngrx/store';
import { ReportEntity } from '../../store/entities/ReportEntity';
import { getReportList } from '../../store/selectors/reports.selectors';
import { TxreportapiService } from '../../shared/services/txreportapi.service';
import { tap, map } from 'rxjs/operators';
import { TxModalPdfComponent } from '../tx-modal-pdf/tx-modal-pdf.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { UserPermissionEntity } from '../../store/entities/UserPermissionEntity';
import { getPermissions } from '../../store/selectors/user.selectors';
import { HAS_MESSAGECENTER } from '../../store/entities/Permissions';

@Component({
	selector: 'tx-zeitkonto',
	templateUrl: './txzeitkonto.component.html',
	providers: [TabStateService],
	styleUrls: ['./tx-zeitkonto.component.scss'],
})
export class TxZeitkontoComponent implements OnInit {
	tabState: TabStates;

	myBuchung: Array<TxBuchung>;
	myInfo;
	apiBuchungAnfrage: Observable<TxBuchungen>;
	apiInfoAnfrage: Observable<TxInfo>;

	buchungForm = 'Buchung';
	antragForm = 'Antrag';

	buchungsDate = '';

	session: TxSession;

	reports$: Observable<ReportEntity[]>;
	loadingReport = false;

	showMessageCenter = false;

	userPermissions$: Observable<string[]>;

	public has_message_center = HAS_MESSAGECENTER;

	constructor(
		private readonly myTxApiserice: TxApiService,
		private readonly formatDate: FormatDate,
		public readonly myAuthService: AuthService,
		private readonly myTabStateService: TabStateService,
		private readonly store: Store,
		private readonly reportService: TxreportapiService,
		private readonly dialog: MatDialog
	) {
		this.reports$ = this.store.select(getReportList).pipe(
			map((reports) => reports.filter((report) => report.report_section === 'timeaccount')),
			filter((reports) => reports.length > 0)
		);

		this.userPermissions$ = this.store.select(getPermissions);
	}

	ngOnInit() {
		this.tabState = JSON.parse(localStorage.getItem('tabActives'));

		this.session = this.myAuthService.getSession();
		let myBuchungResponse: any;
		let myInfoResponse: any;
		const futureDate: any = new Date();
		futureDate.setDate(futureDate.getDate() + 7);
		const myBuchungRequestObject = { von: '2010-02-01T04:58:21', bis: FormatDate.createTxDate(futureDate) };
		const myInfoRequestObject = {};

		this.apiBuchungAnfrage = this.myTxApiserice.callAPI('getBuchung', myBuchungRequestObject);
		this.apiInfoAnfrage = this.myTxApiserice.callAPI('getSalden', myInfoRequestObject);

		this.apiBuchungAnfrage.subscribe(
			(myBuchungData) => {
				myBuchungResponse = myBuchungData;
				this.myBuchung = myBuchungData.Buchungen.sort((a, b) => {
					return a.Datum > b.Datum ? -1 : a.Datum < b.Datum ? 1 : 0;
				});
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);

		this.apiInfoAnfrage.subscribe(
			(myInfoData) => {
				myInfoResponse = myInfoData;
				this.myInfo = myInfoData.ListOfSalden;
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);
	}

	hasPermission(permissions: string[], permission: string) {
		return permissions.includes(permission);
	}

	stopPropagation(event: Event) {
		event.stopImmediatePropagation();
		event.stopPropagation();
		return false;
	}

	print(): void {
		window.print();
	}

	isWeekend(date: any) {
		const currentDate = new Date(date);
		if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
			return true;
		}
	}

	isToday(date: any) {
		const currentDate = new Date(date);
		const todayDate = new Date();
		if (
			currentDate.getDate() === todayDate.getDate() &&
			currentDate.getMonth() === todayDate.getMonth() &&
			currentDate.getFullYear() === todayDate.getFullYear()
		) {
			return 'LightBlue';
		}
	}

	isNegative(hours: any) {
		if (hours) {
			if (hours.includes('-')) {
				return 'red';
			} else {
				return 'LimeGreen';
			}
		}
	}

	onTabOpen(name) {
		this.myTabStateService.onTabOpen(name);
	}

	onTabClose(name) {
		this.myTabStateService.onTabClose(name);
	}

	async downloadReport(report: ReportEntity) {
		this.loadingReport = true;

		const data = await firstValueFrom(this.reportService.getReport(report));
		this.dialog.open(TxModalPdfComponent, {
			minHeight: 'calc(100vh - 90px)',
			maxWidth: '95vw',
			height: 'auto',
			width: '1000px',
			data: {
				blob: data,
				title: report.report_name,
			},
		});
		this.loadingReport = false;
	}

	openChatDialog() {}
}
