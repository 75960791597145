import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PlanEntity } from '../../store/entities/PlanEntity';
import { Observable } from 'rxjs';
import { PlansService } from '../services/plans.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PlansResolver implements Resolve<PlanEntity[]> {
	constructor(private readonly service: PlansService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<PlanEntity[]> | Promise<PlanEntity[]> | PlanEntity[] {
		return this.service.fetchList().pipe(take(1));
	}
}
