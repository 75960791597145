export class CalendarEntityConfig {
	constructor(
		public bookingType: null | 'BDE' | 'PZE' | 'FZ' = null,
		public costCentre: null | number = null,
		public assignment: null | string = null
	) {}
}

export default class PersonalAccountsConfig {
	constructor(
		public SelectedGroups: number[] = [],
		public SelectedView: string = null,
		public ShowDayBalance = true,
		public CalendarEntityConfig: CalendarEntityConfig | null = null
	) {}

	public static parse(o: object): PersonalAccountsConfig {
		const ret = new PersonalAccountsConfig();
		Object.assign(ret, o);
		return ret;
	}
}
