import { AfterViewInit, Component, HostBinding, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router, UrlTree } from '@angular/router';
import { TxSession } from '../../shared/interfaces/session';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { GlobalConstants } from '../../globalConstants';
import sha1 from 'crypto-js/sha1';
import { TerminalService } from '../../shared/services/terminal.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { performLogin, verifySession } from '../../store/actions/user.actions';
import { getSession } from '../../store/selectors/user.selectors';
import { delay, filter, map, take, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { CdkDragEnd } from '@angular/cdk/drag-drop/drag-events';

@Component({
	selector: 'tx-login',
	templateUrl: './tx-login.template.html',
	encapsulation: ViewEncapsulation.None,
})
export class TxLoginComponent implements AfterViewInit {
	@HostBinding('class') class = 'login-page';
	@ViewChildren('username') vc;

	CurrentYear = new Date().getFullYear();

	errorText;
	loginData: any;
	errorState = false;
	mySession: TxSession;
	mySession$: Observable<TxSession>;
	config: any;
	wlVersion: string = GlobalConstants.appVersion;
	showPinLoginCard = false;
	pinCode = '';
	pinModeState = false;
	pinError = false;
	showQrCamera = false;
	moveQrCamera = false;

	constructor(
		private store: Store<State>,
		private myAuthService: AuthService,
		private myRouter: Router,
		private myConfigService: AppConfigService,
		public myTerminalService: TerminalService
	) {
		this.mySession$ = store.select(getSession).pipe(
			withLatestFrom(this.myTerminalService.pinLoggedIn),
			tap(([session, pinLoggedIn]) =>
				!!session && pinLoggedIn ? this.handlePinLoginResult(session) : this.handleLoginResult(session)
			),
			map(([session]) => session)
		);

		this.config = this.myConfigService.getConfig();
		if (localStorage.getItem('Frontpage') === null) {
			localStorage.setItem('Frontpage', 'buchungen');
		}

		if (localStorage.getItem('txlgn') != null) {
			this.loginData = JSON.parse(localStorage.getItem('txlgn'));
			if (this.loginData.password === '' || this.loginData.password === null) {
				this.callLogin(this.loginData.username, null, true);
			} else {
				this.callLogin(this.loginData.username, this.loginData.password, true);
			}
		}

		this.myTerminalService.pinLogin.subscribe((data) => {
			this.pinModeState = data;
			if (this.pinModeState) {
				this.showPinLoginCard = localStorage.getItem('showPinLoginCard') === '1';
			}
		});

		this.myTerminalService.qrQrPermanentCamera.pipe(take(1)).subscribe((data) => {
			this.showQrCamera = data;
		});
	}

	get pinLength() {
		return this.pinCode.length;
	}

	get pinEnabled() {
		return this.pinLength < 4;
	}

	qrCodeDragged(event: CdkDragEnd) {
		const point = event.source.element.nativeElement.getBoundingClientRect();
		this.myTerminalService.setQrCameraPosition(point);
		this.moveQrCamera = false;
	}

	callLogin(aUsername: string, aPassword: string, aLoggedIn?: boolean) {
		let loginAnfrage: Observable<TxSession>;
		loginAnfrage = this.myAuthService.login(aUsername, aPassword, aLoggedIn);
		loginAnfrage.subscribe(
			(mySessionData) => {
				this.mySession = mySessionData;
				this.handleLoginResult(mySessionData);
			},
			(error) => {
				console.error(error);
			}
		);
	}

	callQrLogin(qr: string | null, permanent = false) {
		this.showQrCamera = false;
		this.myAuthService.getSessionDomain().then((mySessionDomain) => {
			let loginAnfrage: Observable<TxSession>;
			loginAnfrage = this.myAuthService.qrLogin(qr, mySessionDomain);
			loginAnfrage.subscribe(
				(mySessionData) => {
					this.mySession = mySessionData;
					this.handlePinLoginResult(mySessionData);
				},
				(error) => {
					console.error(error);
				},
				() => {
					if (permanent) {
						this.showQrCamera = true;
					}
				}
			);
		});
	}

	callPinLogin() {
		if (this.pinLength === 4) {
			this.myAuthService.getSessionDomain().then((mySessionDomain) => {
				let loginAnfrage: Observable<TxSession>;
				loginAnfrage = this.myAuthService.pinLogin(this.pinCode, mySessionDomain);
				loginAnfrage.subscribe(
					(mySessionData) => {
						this.mySession = mySessionData;
						this.handlePinLoginResult(mySessionData);
					},
					(error) => {
						console.error(error);
					}
				);
			});
		}
	}

	checkLogin(username, password, stayloggedin) {
		let passwordHash: string;
		let txLoginStorage: string;

		if (stayloggedin) {
			if (password === '') {
				passwordHash = null;
			} else {
				passwordHash = sha1(password).toString();
			}
			txLoginStorage = JSON.stringify({ username, password: passwordHash });
			localStorage.setItem('txlgn', txLoginStorage);
		}
		this.store.dispatch(performLogin(username, password, false));
	}

	clickPinButton(pressedNumber: string | number) {
		if (this.pinLength < 4) {
			this.pinCode = this.pinCode + pressedNumber;
			this.callPinLogin();
		}
	}

	resetPinCode() {
		this.pinCode = '';
	}

	handleLoginResult(mySessionData) {
		if (mySessionData && mySessionData.statuscode === 0 && mySessionData.sessionid !== '') {
			//this.myTerminalService.pinLoggedInSubject.next(false);
			/*if (this.myAuthService.redirectUrl) {
				this.myRouter.navigate([this.myAuthService.redirectUrl]);
			}*/
			let successUrl: string =
				'/' + localStorage.getItem('Frontpage') ? localStorage.getItem('Frontpage') : 'buchungen';
			this.myTerminalService.setTerminalMode(mySessionData.terminal_mode);
			if (mySessionData.reset_pw === 1) {
				//} && environment.production) {
				successUrl = '/account';
			}
			if (this.myAuthService.redirectUrl) {
				successUrl = this.myAuthService.redirectUrl;

				this.myAuthService.redirectUrl = '';

				this.myRouter.navigateByUrl(this.myRouter.parseUrl(successUrl));
			} else {
				this.myRouter.navigate([successUrl]);
			}
		}
	}

	handlePinLoginResult(mySessionData) {
		if (mySessionData.statuscode === 0 && mySessionData.sessionid !== '') {
			this.myTerminalService.pinLoggedInSubject.next(true);
			this.store.dispatch(verifySession(mySessionData));
			this.myRouter.navigateByUrl('/buchungen');
		} else {
			this.pinError = true;
			setTimeout(() => {
				this.pinError = false;
				this.resetPinCode();
			}, 800);
		}
	}

	ngAfterViewInit(): void {
		this.myTerminalService.pinLogin.subscribe((data) => {
			this.pinModeState = data;
			if (this.pinModeState) {
				this.showPinLoginCard = localStorage.getItem('showPinLoginCard') === '1';
			} else {
				this.vc.first.nativeElement.focus();
			}
		});
	}

	toggleShowPinLoginCard() {
		this.showPinLoginCard = !this.showPinLoginCard;
		localStorage.setItem('showPinLoginCard', this.showPinLoginCard ? '1' : '0');
	}
}
