import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../services/data.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListOfDepartmentEntity } from '../../store/entities/ListOfDepartmentEntity';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class DepartmentResolver implements Resolve<ListOfDepartmentEntity[]> {
	constructor(private readonly service: DataService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ListOfDepartmentEntity[]> | Promise<ListOfDepartmentEntity[]> | any[] {
		return this.service.getDepartments().pipe(take(1));
	}
}
