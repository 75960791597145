/* tslint:disable:variable-name */

/*
 Workflow aus spWebGetWorkflow
 99 = offen
 1 = genehmigt
 0 = abgelehnt

 Workflow für spWebUpdateWorkflow
 100 = Löschen
 101 = Kenntnisnahme  senden

 */

export class TxWorkflow {
	constructor(public statuscode: number = 0, public statustext: string = '', public Workflows: Array<TxWorkflows>) {}
}

export class TxWorkflows {
	public StatusText: string;

	constructor(
		public Eigener: number,
		public Name: string,
		public NotizAntragsbearbeiter: string,
		public NotizAntragssteller: string,
		public Status: number,
		public art: string,
		public id: number,
		public von: string,
		public bis: string,
		public Typname: string,
		public Bearbeiter: string,
		public typ: string,
		public info: string
	) {
		switch (Status) {
			case 99:
				this.StatusText = 'offen';
				break;
			case 1:
				this.StatusText = 'genehmigt';
				break;
			case 0:
				this.StatusText = 'abgelehnt';
				break;
			default:
				this.StatusText = '-';
		}
	}
}

export class TxWorkflowTypen {
	public ListOfWorkflowTyp: Array<TxListOfWorkflowTyp> = [];

	constructor(
		public statuscode: number = 0,
		public statustext: string = '',
		aListOfWorkflowTyp: Array<TxListOfWorkflowTyp>
	) {
		// this.ListOfWorkflowTyp.push(new TxListOfWorkflowTyp(null, 'Bitte Fehlzeit wählen'));
		if (aListOfWorkflowTyp != null) {
			for (let i = 0, l = aListOfWorkflowTyp.length; i < l; i++) {
				this.ListOfWorkflowTyp.push(
					new TxListOfWorkflowTyp(aListOfWorkflowTyp[i].nummer, aListOfWorkflowTyp[i].bezeichnung)
				);
			}
		}
	}
}

export class TxListOfWorkflowTyp {
	public value: number;
	public label: string;

	constructor(public nummer: string, public bezeichnung: string) {
		this.value = +nummer;
		this.label = bezeichnung;
	}
}

export class TxWorkflowRequest {
	constructor(public from?: string, public to?: string, public team_leader?: number) {}
}

export class TxUpdateWorkflowRequest {
	constructor(public workflowid: number, public status: number, public notiz: string = null) {}
}

export class TxCreateWorkflowRequest {
	constructor(
		public Notiz: string = null,
		public von: string = null,
		public vonAntragField: string = null,
		public bisAntragField: string = null,
		public vonBuchungDatumField: any = null,
		public vonBuchungZeitField: string = null,
		public bis: string = null,
		public Art: string = null,
		public Typ: string = null,
		public TypNeu: string = null,
		public Typname: string = null,
		public BuchungsId: number = 0,
        public kst_nummer: number = 0,
        public timemodel_id: number = 0,
	) {}
}

export class TxWorkflowCalendarRequest {
	public full_year: number;
	public team_leader = 0;

	constructor() {}
}

export class TxWorkflowCalendarProtokoll {
	constructor(public statuscode: number, public statustext: string, public WfCalendar: Array<TxWfCalendar>) {}
}

export class TxWfCalendar {
	constructor(
		public personalnr: number = 0,
		public name: string = null,
		public begin_date: string = null,
		public end_date: string = null,
		public workflow_group = 0,
		public info = null,
		public ListOfDateItem: Array<TxWfCalendarDateItem>
	) {}
}

export class TxWfCalendarDateItem {
	constructor(
		public calendar_id: string = null,
		public datum: string = null,
		public fehlzeit_id: string = null,
		public fehlzeit_name: string = null,
		public fehlzeit_color: string = null,
		public table_text: string = null
	) {}
}

export const TYPNEU = [
	{ label: 'Kommen', Typ: 'K', value: 1 },
	{ label: 'Gehen', Typ: 'G', value: 2 },
	{ label: 'Pause Beginn', Typ: 'PB', value: 3 },
	{ label: 'Pause Ende', Typ: 'PS', value: 4 },
	/*{ label: 'Dienstgang Beginn', Typ: 'DB', value: 5 },
	{ label: 'Dienstgang Ende', Typ: 'DE', value: 6 },*/
];

export interface WfGroups {
	statuscode: number;
	statustext: string;
	hash: string;
	ListOfWorkflowGroup: Array<ListOfWorkflowGroup>;
}

export interface ListOfWorkflowGroup {
	id: number;
	shortcut: string;
	name: string;
	active?: boolean;
}

export interface WorkflowCalendarData {
	days: Array<any>;
	daysInCurrentYear: Array<any>;
	month: Array<any>;
	years: Array<any>;
	persons: Array<WorkflowPerson>;
}

export interface WorkflowPerson {
	group: number;
	info: string;
	name: string;
	personalnr: number;
	data: Array<WorkflowPersonData>;
}

export interface WorkflowPersonData {
	calendar_id: string;
	datum: string;
	fehlzeit_color: string;
	fehlzeit_id: string;
	fehlzeit_name: string;
	table_text: string;
	tmpOffset: string;
	todayClass: string;
	isError: number;
}
