<ng-container>
    <div mat-dialog-title>
        <h3 class="m-0 mr-1">
            Erstes Zeitmodel geändert
        </h3>
    </div>
    <div mat-dialog-content style="min-width: 400px">
        <div class="text-xl mb-4">
            Bitte Datum für Umstellung des Zeitmodells angeben
        </div>
        <div class="display-flex mt-1 flex-align-items-center new-data">
            <mat-form-field class="full-width">
                <mat-label>{{ 'general.date' | translate }}</mat-label>
                <input #start_date_value matInput [matDatepicker]="start_date" (input)="fixDateValues($event, start_date_value)" />
                <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
                <mat-datepicker #start_date [disabled]="false" startView="month"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex justify-between">
            <button
                mat-raised-button
                color="accent"
                [mat-dialog-close]="null"
            >
                {{ 'general.cancel' | translate }}
            </button>
            <button
                mat-raised-button
                color="primary"
                [mat-dialog-close]="start_date_value.value"
                [disabled]="!start_date_value.value"
            >
                {{ 'general.take' | translate }}
            </button>
        </div>
    </div>
</ng-container>
