import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	get<T = any>(key: string): T {
		return JSON.parse(localStorage.getItem(key) ?? 'null') as T;
	}

	set(key: string, value: any): boolean {
		if (value === null) {
			return false;
		}
		localStorage.setItem(key, JSON.stringify(value));
		return true;
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}
}
