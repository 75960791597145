<div *ngIf="myAuthService?.getSession()?.calendar == 1" class="full-height">
    <div class="m-3">
        <div (click)="goBack()" *ngIf="showBackButton()" class="scheduler__backlink">
            <i class="fa fa-arrow-left"></i> {{ 'general.back' | translate }}
        </div>
    </div>

	<div class="full-height flex flex-direction-column bg-white rounded">
		<div class="p-2 flex-grow-0 font-weight-bold text-xl">{{ title }}</div>
		<div class="flex-grow-1 p-3">
			<tx-scheduler [persoId]="persoId" [defaultDate]="defaultDate"></tx-scheduler>
		</div>
	</div>
</div>
