import { Action, createReducer, on } from '@ngrx/store';
import { SetTeamleaderCalendar } from '../actions/teamleader.actions';
import { TxWfCalendar } from '../../shared/interfaces/workflow';

export interface State {
	calendar: TxWfCalendar[];
}

const initialState: State = {
	calendar: null,
};

const teamleaderReducer = createReducer(
	initialState,
	on(SetTeamleaderCalendar, (state, { calendar }) => ({
		...state,
		calendar,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return teamleaderReducer(state, action);
}
