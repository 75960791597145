import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { PlanEntity } from '../../../store/entities/PlanEntity';
import { getPlansList } from '../../../store/selectors/plans.selectors';
import { MatDialog } from '@angular/material/dialog';
import { TxPlaningPlanDialogComponent } from '../tx-planing-plan-dialog/tx-planing-plan-dialog.component';
import { map, take, tap } from 'rxjs/operators';
import { PlansService } from '../../../shared/services/plans.service';
import { FetchPlans } from '../../../store/actions/plans.actions';
import chroma from 'chroma-js';

@Component({
	templateUrl: './tx-planing-plans-dialog.component.html',
	styleUrls: ['./tx-planing-plans-dialog.component.scss'],
})
export class TxPlaningPlansDialogComponent {
	public plans$: Observable<PlanEntity[]>;
	constructor(
		private readonly store: Store,
		private readonly dialog: MatDialog,
		private readonly service: PlansService
	) {
		this.plans$ = store.select(getPlansList);
	}

	openPlanDetailDialog(plan: PlanEntity = null) {
		const planEdit = this.dialog.open(TxPlaningPlanDialogComponent, {
			data: {
				plan: plan ?? new PlanEntity(),
			},
		});
		planEdit
			.afterClosed()
			.pipe(
				switchMap((res) => {
					if (!res.name) {
						return of(null);
					}
					return this.service.updateOrSave(PlanEntity.tryCast(res));
				}),
				tap(() => {
					this.store.dispatch(FetchPlans());
				}),
				take(1)
			)
			.subscribe();
	}

	getPlanTextColor(plan: PlanEntity) {
		return chroma(plan.color).luminance() > 0.5 ? 'black' : 'white';
	}

	deletePlan(id: number) {
		this.service
			.deletePlan(id)
			.pipe(
				map(() => {
					this.store.dispatch(FetchPlans());
				}),
				take(1)
			)
			.subscribe();
	}
}
