import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Observable } from 'rxjs';
import {
	ListOfWorkflowGroup,
	TxWorkflow,
	TxWorkflowCalendarProtokoll,
	TxWorkflowCalendarRequest,
	TxWorkflowRequest,
} from '../interfaces/workflow';
import { map, share } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class WorkflowService {
	constructor(private myTxApiService: TxApiService) {}

	getWorkflowCalendar(fullYear = 0, teamleader = 0): Observable<TxWorkflowCalendarProtokoll> {
		const myWorkflowCalendarRequestData = new TxWorkflowCalendarRequest();
		myWorkflowCalendarRequestData.full_year = fullYear;
		myWorkflowCalendarRequestData.team_leader = teamleader;
		return this.myTxApiService.callAPI('getWfCalendar', myWorkflowCalendarRequestData);
	}

	getWorkflow(teamleader = 0): Observable<TxWorkflow> {
		const myWorkflowRequestData = new TxWorkflowRequest();
		myWorkflowRequestData.from = '2010-02-01T04:58:21';
		myWorkflowRequestData.to = '2026-12-08T04:58:21';
		myWorkflowRequestData.team_leader = teamleader;
		return this.myTxApiService.callAPI('getWorkflow', myWorkflowRequestData);
	}

	getWorkflowGroups(): Observable<Array<ListOfWorkflowGroup>> {
		return this.myTxApiService.callAPI('getWfGroups', {}).pipe(
			map((data) => {
				return data.ListOfWorkflowGroup;
			}),
			share()
		);
	}
}
