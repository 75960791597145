import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TxActiveBDEList, TxBdeAuftrag, TxBdeAuftragsschritt } from '../../shared/interfaces/bde';
import { TxApiService } from '../../shared/services/txapi.service';
import { AuthService } from '../../shared/services/auth.service';
import { InfocenterService } from '../../shared/services/infocenter.service';
import { FormatDate } from '../../shared/services/formatdate.service';
import { FormControl } from '@angular/forms';
import { ActiveBDEService } from '../../shared/services/activebde.service';
import { TabStates, TabStateService } from '../../shared/services/tabstate.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingService } from '../../shared/services/booking.service';
import { MessengerService } from '../../shared/services/messenger.service';
import { TxSession } from '../../shared/interfaces/session';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { TerminalService } from '../../shared/services/terminal.service';
import {
    BehaviorSubject,
    lastValueFrom,
    Observable,
    Subscription
} from 'rxjs';
import moment from 'moment';
import { UserInfoService } from '../../shared/services/user-info.service';
import { MatDialog } from '@angular/material/dialog';
import { TxBarcodeScannerComponent } from './dialogs/tx-barcode-scanner.component';
import { MatSelect } from '@angular/material/select';
import { data } from 'autoprefixer';

declare var jQuery: any;

@Component({
	selector: 'tx-bde',
	templateUrl: './tx-bde.component.html',
})
export class TxBdeComponent implements OnInit, AfterViewInit, OnDestroy {
	// @ts-ignore
	@ViewChild('bdeFormCtrl', { static: true }) bdeForm: FormControl;
    searchControlAuftrag = new FormControl('');

	loading = false;
	tabState: TabStates;
    tabState$: Observable<TabStates>;

	_myActiveBDE$: BehaviorSubject<TxActiveBDEList[]> = new BehaviorSubject<TxActiveBDEList[]>(null);
	myActiveBDE$ = this._myActiveBDE$.asObservable();

	myQueuedBDE: TxActiveBDEList[] = [];
	myErrorBDE: Array<any> = [];

	myAuftragsArray: Array<TxBdeAuftrag> = [];
	myAuftragsschrittArray: Array<TxBdeAuftragsschritt> = null;

	mycurrentAuftragSelectID: string = null;
	myCurrentAuftrag: TxBdeAuftrag;

	stopAuftrag: any[] = [];

	myCurrentAuftragsschrittSelectID: string = null;
	myCurrentAuftragsschritt: TxBdeAuftragsschritt = null;
	myCurrentAuftragNotice = null;

	updateBlocker: any = new Date();

	myGuiAuftragsschrittDropdownVisible = false;
	myGuiTaetigkeitDropdownVisible = false;

	filterStatus = true;
	displayCodeScannerPopup = false;

	currentSession: TxSession;

	isMobile: boolean;
	isTablet: boolean;

	barcodeState: number;
	showLoadingBDEData = false;

	pinLoggedIn = false;

	isOnline = true;

	private subs: Subscription[] = [];
    filteredAuftragsArray = [...this.myAuftragsArray];
    searchTerm = '';
    @ViewChild('auftragSelect') auftragSelect: MatSelect;
	constructor(
		private readonly userService: UserInfoService,
		private myTxApiserice: TxApiService,
		private myInfocenterService: InfocenterService,
		private myActiveBDEService: ActiveBDEService,
		private formatDate: FormatDate,
		public myTabStateService: TabStateService,
		public myConnectionsStateService: ConnectionStateService,
		private deviceService: DeviceDetectorService,
		private myBookingService: BookingService,
		private myMessengerService: MessengerService,
		private myAuthService: AuthService,
		private myAppConfigSerive: AppConfigService,
		private myTerminalService: TerminalService,
        public readonly dialog: MatDialog
	) {
		this.isMobile = this.deviceService.isMobile() || true;
		this.isTablet = this.deviceService.isTablet();
		this.subs.push(
			this.myAppConfigSerive.getBarcodeStatus().subscribe((state) => (this.barcodeState = state)),
			this.myTerminalService.pinLoggedIn.subscribe((data) => (this.pinLoggedIn = data)),
			this.myConnectionsStateService.onlineState.subscribe((online) => (this.isOnline = online))
		);
	}

    filterAuftrags() {
        this.filteredAuftragsArray = this.myAuftragsArray.filter(auftrag =>
            auftrag.label.toLowerCase().includes(this.searchTerm)
        );
    }

    onSearchAuftrag(event: KeyboardEvent) {
        if (event.target instanceof HTMLInputElement) {
            this.searchTerm = (event.target as HTMLInputElement).value;
            this.filterAuftrags();
        }
    }

    openSelect() {
        this.auftragSelect.open();
    }

	onAuftragSelected(aEvent: any): void {
        this.searchControlAuftrag.setValue('');
        this.filteredAuftragsArray = [...this.myAuftragsArray];
        if (aEvent.value) {
			this.myCurrentAuftrag = this.getAuftragByID(aEvent.value);
			this.mycurrentAuftragSelectID = aEvent.value;
			this.myAuftragsschrittArray = this.myCurrentAuftrag.ListOfAuftragsschritt;
			this.myCurrentAuftragsschrittSelectID = null;
			this.myGuiAuftragsschrittDropdownVisible = true;
			this.moveAuftragToTop(aEvent.value);
			localStorage.setItem('curAuftragId', aEvent.value);
		} else {
			this.myGuiAuftragsschrittDropdownVisible = false;
			this.myGuiTaetigkeitDropdownVisible = false;
		}
	}

	moveAuftragToTop(auftragsId) {
		if (this.myAuftragsArray) {
			const selectedAuftragIndex = this.myAuftragsArray.findIndex((auftrag) => {
				if (auftrag) {
					return auftrag.auftragsnummer === auftragsId;
				}
				return false;
			});
			if (selectedAuftragIndex) {
				this.myAuftragsArray.splice(0, 0, this.myAuftragsArray.splice(selectedAuftragIndex, 1)[0]);
			}
		}
	}

	moveSchrittToTop(schrittId) {
		if (this.myAuftragsschrittArray) {
			const selectedSchrittIndex = this.myAuftragsschrittArray.findIndex((schritt) => {
				if (schritt) {
					return schritt.schrittnummer === schrittId;
				}
				return false;
			});
			if (selectedSchrittIndex) {
				this.myAuftragsschrittArray.splice(0, 0, this.myAuftragsschrittArray.splice(selectedSchrittIndex, 1)[0]);
			}
		}
	}

	onAuftragSchrittSelected(aEvent: any): void {
		if (aEvent.value) {
			this.myCurrentAuftragsschritt = this.getAuftragSchrittByID(aEvent.value, null);
			this.myCurrentAuftragsschrittSelectID = aEvent.value;
			this.myGuiTaetigkeitDropdownVisible = true;
			this.moveSchrittToTop(aEvent.value);
		} else {
			this.myCurrentAuftragsschritt = null;
			this.myCurrentAuftragsschrittSelectID = null;
			this.myGuiTaetigkeitDropdownVisible = false;
		}
	}

	getAuftragSchrittByID(aID: string, aAuftrag?: string) {
		if (aAuftrag) {
			const auftrag = this.getAuftragByID(aAuftrag);
			if (auftrag === undefined) {
				return null;
			}
			return auftrag.ListOfAuftragsschritt.find((data) => data.schrittnummer === aID);
		}

		if (this.myAuftragsschrittArray == null) {
			return null;
		}

		return this.myAuftragsschrittArray.find((data) => data.schrittnummer === aID);
	}

	getAuftragByID(aID: string) {
		if (this.myAuftragsArray == null) {
			return null;
		}
		return this.myAuftragsArray.find((data) => data.auftragsnummer === aID);
	}

	doStempelBDE(
		aBuchungsart: string,
		aActiveAuftrag?: string,
		aActiveSchritt?: string,
		aIndex?: number,
		aKill?: boolean,
		note?: string,
		quantity?: number
	) {
		let barcodeScan = 0;
		if (aBuchungsart === 'AB') {
			this.myGuiAuftragsschrittDropdownVisible = false;
			this.myGuiTaetigkeitDropdownVisible = false;
			this.mycurrentAuftragSelectID = null;
			this.myCurrentAuftragsschrittSelectID = null;
			aActiveAuftrag = this.myCurrentAuftrag.auftragsnummer;
			aActiveSchritt = this.myCurrentAuftragsschritt.AuftragsschrittNummer;
		} else if (aBuchungsart === 'AE') {
			this.updateBlocker = new Date();
		} else if (aBuchungsart === 'SCAN') {
			aBuchungsart = 'AB';
			barcodeScan = 1;
		}

		const auftrag = this.getAuftragByID(aActiveAuftrag);
		const schritt = this.getAuftragSchrittByID(aActiveSchritt, aActiveAuftrag);
		const myActiveBDE = this._myActiveBDE$.getValue();
		const duplicateListItem = myActiveBDE.find((item) => {
			const currentCustomId = item.auftrag_nummer + item.schritt_nummer;
			if (auftrag === undefined) {
				return false;
			}
			const newCustomId = auftrag.auftragsnummer + (schritt?.AuftragsschrittNummer ?? '');
			return currentCustomId === newCustomId;
		});

		if (duplicateListItem && !aKill) {
			this.myMessengerService.message('Auftrag ist noch aktiv', 'error');
			return;
		}
		if (aBuchungsart === 'AB') {
			this.myQueuedBDE.push(
				new TxActiveBDEList(
					'-1',
					auftrag.bezeichnung,
					auftrag.auftragsnummer,
					moment().format('DD.MM.YYYY HH:mm'),
					'?',
					'?',
					schritt.schrittbezeichnung,
					schritt.schrittnummer,
					true
				)
			);
		} else {
			if (!duplicateListItem) {
			} else {
				duplicateListItem.queued = true;
			}
		}
		this.myBookingService
			.createBooking(aBuchungsart, aActiveAuftrag, aActiveSchritt, aIndex, aKill, null, barcodeScan, note, quantity)
			.toPromise()
			.then(
				async (mySessionData) => {
					if (mySessionData.type !== 'offline') {
						let responseStatus = 'error';
						if (mySessionData.statuscode === 0) {
							responseStatus = 'success';
						}
						const message = this.myMessengerService.message(mySessionData.statustext, responseStatus as MessengerType);
						if (this.pinLoggedIn) {
							setTimeout(() => {
								message.cancel();
								this.myAuthService.logout();
							}, 1500);
						}
					}
				},
				(error) => {
					console.log(error);
					this.myMessengerService.message(error, 'error');
				}
			)
			.finally(() => {
				if (!aKill) {
					this.stopAuftrag[this.stopAuftrag.length - 1] = false;
					/*if (!this.pinLoggedIn) {
                            this.showLoadingBDEData = true;
                        }*/
				}
			});
	}

	onFocusDropdown($event) {
		this.filterStatus = true;
		setTimeout(() => {
			jQuery('.p-dropdown-filter').blur();
		}, 250);
	}

	onBlurDropdown($event) {
		const relatedTarget = $event.relatedTarget;
		if (relatedTarget === null) {
			setTimeout(() => {
				this.filterStatus = true;
			}, 250);
		}
	}

	ngAfterViewInit() {
		this.userService.catchUserInfoFromServer(false, true);
	}

	ngOnInit(): void {
		this.currentSession = this.myAuthService.getSession();
		this.myBookingService.getBdeData();

		this.myTxApiserice.getCurrentQueueSize().then((queue) => {
			if (queue && queue.length > 0) {
				for (let q of queue) {
					const auftrag = this.getAuftragByID(q.body.auftragsnummer);
					if (auftrag) {
						const schritt = this.getAuftragSchrittByID(q.body.auftragsschritt, auftrag.auftragsnummer);
						this.myQueuedBDE.push(
							new TxActiveBDEList(
								'-1',
								auftrag.bezeichnung,
								auftrag.auftragsnummer,
								moment(q.datetime).format('DD.MM.YYYY HH:mm'),
								'?',
								'?',
								schritt.schrittbezeichnung,
								schritt.schrittnummer,
								true
							)
						);
					}
				}
			}
		});
		this.subs.push(
			this.myBookingService.tasks.subscribe((data) => {
				if (data && data[0] !== undefined) {
					this.myAuftragsArray = data;
					this.filteredAuftragsArray = data;
					const savedCurAuftragsId = localStorage.getItem('curAuftragId');
					if (savedCurAuftragsId) {
						this.moveAuftragToTop(savedCurAuftragsId);
					}
				} else {
					const tmpAuftragsArray = new TxBdeAuftrag('', '', []);
					this.myAuftragsArray = [tmpAuftragsArray];
                    this.filteredAuftragsArray = [tmpAuftragsArray];
				}
			}),

			this.myActiveBDEService.ActiveBDE.subscribe(
				(data) => {
					const myActiveBDE = this._myActiveBDE$.getValue();
					if (this.myActiveBDEService.compareBDEResult(myActiveBDE, data) === false) {
						this.myCurrentAuftragNotice = '';
						this.myQueuedBDE = [];
						this._myActiveBDE$.next(data);
					}
				},
				(error) => {
					console.error(error);
					this.myCurrentAuftragNotice = '';
				}
			)
		);

		this.tabState = JSON.parse(localStorage.getItem('tabActives'));
		this.loading = true;
	}

	onTabOpen(name :string) {
		this.myTabStateService.onTabOpen(name);
	}

	onTabClose(name :string) {
		this.myTabStateService.onTabClose(name);
	}

	async showCodeScanner() {
        const result = await lastValueFrom(this.dialog.open(TxBarcodeScannerComponent).afterClosed());
        this.receiveScanResult(result);
	}

	receiveScanResult(result) {
		this.displayCodeScannerPopup = false;

		const checkedResult = this.checkScanResult(result);

		if (this.barcodeState === 1) {
			if (checkedResult !== 'error') {
				this.doStempelBDE('SCAN', checkedResult[0], checkedResult[1], null, false);
			} else {
				this.myMessengerService.message('Barcode konnte nicht eingelesen werden', 'error');
			}
		}

		if (this.barcodeState === 2) {
			if (checkedResult === 'error') {
				this.myMessengerService.message('Barcode konnte nicht eingelesen werden', 'error');
			}
		}
	}

	checkScanResult(result) {
		if (this.barcodeState === 1) {
			const parsedResult = result.split('$', 2);

			const auftrag = this.myAuftragsArray.find((data) => data.auftragsnummer === parsedResult[0]);
			if (auftrag && parsedResult.length > 1) {
				const schritt = auftrag.ListOfAuftragsschritt.find((data) => data.AuftragsschrittNummer === parsedResult[1]);
				if (schritt) {
					return [parsedResult[0], parsedResult[1]];
				}
			}
			return 'error';
		}
		if (this.barcodeState === 2) {
			if (this.mycurrentAuftragSelectID === null) {
				const auftrag = this.myAuftragsArray.find((data) => data.auftragsnummer === result);
				if (auftrag) {
					this.mycurrentAuftragSelectID = result;
					this.onAuftragSelected({ value: result });
				} else {
					return 'error';
				}
			} else {
				const auftrag = this.getAuftragByID(this.mycurrentAuftragSelectID);
				if (auftrag) {
					const schrittResult = String(Number(result));
					const schritt = auftrag.ListOfAuftragsschritt.find((data) => data.AuftragsschrittNummer === schrittResult);
					if (schritt) {
						this.myCurrentAuftragsschrittSelectID = schrittResult;
						this.onAuftragSchrittSelected({ value: schrittResult });
					} else {
						const newAuftrag = this.myAuftragsArray.find((data) => data.auftragsnummer === result);
						if (newAuftrag) {
							this.mycurrentAuftragSelectID = result;
							this.onAuftragSelected({ value: result });
						} else {
							return 'error';
						}
					}
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.subs.map((s) => s.unsubscribe());
	}
}
