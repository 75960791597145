<h1 mat-dialog-title>Auftrag '{{ data.project.project_name }}' Tätigkeiten</h1>
<div mat-dialog-content>
	<div class="display-flex">
		<mat-button-toggle-group class="full-width" [vertical]="true" [multiple]="true" (change)="onChange($event)">
			<mat-button-toggle
				*ngFor="let workstep of data.available"
				[value]="workstep.workstep_number"
				class="option"
				[disabled]="projectHasWorkstep(workstep)">
				<span>{{ workstep.workstep_name }}</span>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>
<div mat-dialog-actions class="display-flex flex-justify-content-space-between">
	<button mat-raised-button (click)="onCancel()">{{ 'general.abort' | translate }}</button>
	<button mat-raised-button (click)="AddAll()" color="primary">{{ 'general.add_all' | translate }}</button>
	<button mat-raised-button [mat-dialog-close]="data.list" color="primary">{{ 'txProjectOffice.add' | translate }}</button>
</div>
