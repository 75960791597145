import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { MessengerService } from './../../shared/services/messenger.service';
import { SendMessage, SetUserMessages, ArchiveMessage, UpdateMessage } from './../actions/message-center.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, tap } from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';
import { FetchMessages, SetMessages } from '../actions/message-center.actions';
import { ApiResponse } from '../entities/Api/ApiResponse';
import { MessageEntity } from '../entities/MessageEntity';
import { State } from '../reducers';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MessageCenterEffects {
	getMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchMessages),
			filter(({ employee_id }) => employee_id !== null),
			mergeMap(({ employee_id, include_archived }) =>
				this.api
					.callAPI('getMessages', {
						personal_office_for: employee_id,
						archived: include_archived,
					})
					.pipe(
						map((res: ApiResponse<MessageEntity[]>) => {
							const children = res.ListOfMessage.filter((m) => m.parent_id);
							const messages = res.ListOfMessage.filter((m) => m.parent_id === null).map((m) => {
								const c = children.filter((c) => c.parent_id === m.id);
								return MessageEntity.tryCast(m, c);
							});
							return employee_id === 0 ? SetUserMessages(messages) : SetMessages(messages);
						}),
						tap(() => {
							// console.log(employee_id);
							this.store.dispatch(UpdateMessage(null, employee_id, false, include_archived, false, true));
						})
					)
			)
		)
	);

	updateMessage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateMessage),
				switchMap(
					({ id, archive, remove, personal_office_for, type, read }) =>
						this.api.callAPI('updateMessage', {
							message_id: id,
							personal_office_for: personal_office_for,
							delete_message: remove,
							set_read: read,
							set_archived: archive,
						})
					//.pipe(map(() => FetchMessages(personal_office_for, false)))
				)
			),
		{ dispatch: false }
	);

	sendMessages$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SendMessage),
			switchMap(({ message, receiver_id, personal_office_for, parent }) =>
				this.api
					.callAPI('createMessage', {
						type: null,
						type_value: null,
						target_persnr: receiver_id,
						parent_id: parent?.id,
						message,
					})
					.pipe(map(() => FetchMessages(personal_office_for, false)))
			),
			tap(() => this.toast.message(this.i18n.instant('toast.message_success'), 'success'))
		)
	);

	archiveMessage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArchiveMessage),
				switchMap(({ id, personal_office_for, archive, remove }) =>
					this.api
						.callAPI('updateMessage', {
							message_id: id,
							personal_office_for: personal_office_for,
							delete_message: remove,
							set_read: false,
							set_archived: true,
						})
						.pipe(
							map((res: ApiResponse<any>) => {
								if (res.statuscode > -1) {
									this.store.dispatch(FetchMessages(personal_office_for, archive));
								} else {
									this.toast.message(res.statustext, 'error');
								}
							})
						)
				)
			),
		{ dispatch: false }
	);

	constructor(
		private readonly actions$: Actions,
		private readonly api: TxApiService,
		private readonly toast: MessengerService,
		private readonly store: Store<State>,
		private readonly i18n: TranslateService
	) {}
}
