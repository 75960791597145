import { ListOfProjectResponse } from './Api/ListOfProjectResponse';
import moment from 'moment';

export class ProjectEntity extends ListOfProjectResponse {
	public static tryCast(o: any): ProjectEntity {
		let ret = new ProjectEntity();
		ret = Object.assign(ret, o);
		if (ret.creation_date) {
			ret.creation_date = moment(ret.creation_date).toDate();
		}
		if (ret.target_date) {
			ret.target_date = moment(ret.target_date).toDate();
		}
		return ret;
	}
	public toString(): string {
		return `${this.project_number}${this.project_name}`;
	}
}
