import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Observable } from 'rxjs';
import { ListOfTLProtocol } from '../interfaces/tlprotocol';
import { delay, map } from 'rxjs/operators';
import moment from 'moment';

export interface FSReference {
	statuscode: number;
	statustext: string;
	filename: string;
	creation_date: string;
	ref_data: string;
	user_data: string;
}

export interface UpdateTlProtocol {
	id: number;
	set_confirmed: number;
	prot_type?: string;
	check_result?: number;
	notice?: string;
}

@Injectable()
export class TlProtocolService {
	constructor(private myTxApiService: TxApiService) {}

	getTlProtocoll(archive: boolean): Observable<ListOfTLProtocol[]> {
		return this.myTxApiService.callAPI('getTLProtocol', { archive: archive ? 1 : 0 }).pipe(
			map((data) => {
				data.ListOfTLProtocol.map((myProt) => {
					myProt.datum = moment(myProt.datum, '').format('DD.MM.YYYY H:mm');
					myProt.deleteState = false;
				});
				return data.ListOfTLProtocol;
			})
		);
	}

	updateTlProtocol(
		id: number,
		status: number,
		waitTime: number = 0,
		protType: string = '',
		checkResult: number = 0,
		notice: string = ''
	): Observable<any> {
		const bodyJson: UpdateTlProtocol = {
			id,
			set_confirmed: status,
		};

		if (protType === 'FSCHECK') {
			bodyJson.prot_type = protType;
			bodyJson.check_result = checkResult;
			bodyJson.notice = notice;
		}

		return this.myTxApiService.callAPI('updateTlProtocol', bodyJson).pipe(
			delay(waitTime),
			map((data) => data)
		);
	}

	getReferenceData(dataId: number, personalNr: number): Observable<FSReference> {
		const bodyJson = {
			data_id: dataId,
			personalnr: personalNr,
		};

		return this.myTxApiService.callAPI('getFSReference', bodyJson);
	}
}
