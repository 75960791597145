import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
	selector: 'tx-help',
	templateUrl: './tx-help.component.html',
})
export class TxHelpComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
