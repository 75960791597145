import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { Actions, ofType } from '@ngrx/effects';
import { getTeamleaderCalendar } from '../../store/selectors/teamleader.selectors';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetTeamleaderCalendar, SetTeamleaderCalendar } from '../../store/actions/teamleader.actions';

@Injectable({
	providedIn: 'root',
})
export class TeamleaderService {
	constructor(private store: Store<State>, private actions$: Actions) {}

	getTeamleaderCalendar(fullyear: number, teamleader: number) {
		return this.store.select(getTeamleaderCalendar).pipe(
			switchMap((calendar) => {
				if (!calendar) {
					this.store.dispatch(GetTeamleaderCalendar(fullyear, teamleader));
					return this.actions$.pipe(
						ofType(SetTeamleaderCalendar),
						map((res) => res.calendar)
					);
				}
				return of(calendar);
			})
		);
	}
}
