import { AfterViewChecked, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { WorkflowService } from '../../shared/services/workflow.service';
import {
	BehaviorSubject,
	combineLatest,
	combineLatestWith,
	concat,
	concatMap,
	firstValueFrom,
	forkJoin,
	mergeMap,
	Observable,
	of,
	switchMap,
} from 'rxjs';
import { ListOfWorkflowGroup } from '../../shared/interfaces/workflow';
import { catchError, filter, map, take, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ResourceInput } from '@fullcalendar/resource-common';
import { createElement, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { DateSelectArg } from '@fullcalendar/common';
import { MatDialog } from '@angular/material/dialog';
import { TxPlaningPlansDialogComponent } from './tx-planing-plans-dialog/tx-planing-plans-dialog.component';
import { TxPlaningSelectPlanDialogComponent } from './tx-planing-select-plan-dialog/tx-planing-select-plan-dialog.component';
import { Store } from '@ngrx/store';
import { getPlanCalendar, getPlansList } from '../../store/selectors/plans.selectors';
import { PlanCalendarEntity } from '../../store/entities/PlanCalendarEntity';
import { PlansService } from '../../shared/services/plans.service';
import moment from 'moment';
import chroma from 'chroma-js';
import { MDCTooltip } from '@material/tooltip';
import { TxConfirmDialogComponent } from '../../shared/directives/confirm-dialog-click.directive';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ListOfCalendarHead } from '../../shared/interfaces/scheduler';
import { TxApiService } from '../../shared/services/txapi.service';
import { VariableBinding } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from '../../globalConstants';
import { ReportEntity } from '../../store/entities/ReportEntity';
import { getReportList } from '../../store/selectors/reports.selectors';
import { TxreportapiService } from '../../shared/services/txreportapi.service';
import { TxModalPdfComponent } from '../tx-modal-pdf/tx-modal-pdf.component';

@Component({
	selector: 'tx-planing',
	templateUrl: './tx-planing.component.html',
	styleUrls: ['./tx-planing.component.scss'],
})
export class TxPlaningComponent implements OnDestroy, AfterViewChecked {
	@ViewChild('calendar') calendarComponent: FullCalendarComponent;

	public SelectedGroups: number[] = [];

	public workflowGroups$: Observable<Array<ListOfWorkflowGroup>>;
	public workflows$: Observable<PlanCalendarEntity[]>;

	public selectedGroups$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);

	public DeleteMode = false;

	public SelectedForDelete: string[] = [];

	public MultiSelectUserIds: string[] = [];

	public CalendarOptions: CalendarOptions = {
		schedulerLicenseKey: GlobalConstants.fullCalendarLicenseKey,
		plugins: [resourceTimelinePlugin],
		locale: this.translate.currentLang.slice(0, 2),
		headerToolbar: {
			left: 'refresh today prev,next',
			center: 'title',
			right: 'viewDay,viewWeek',
		},
		initialView: 'resourceTimelineDay',
		themeSystem: 'bootstrap',
		buttonText: {
			today: this.translate.instant('txScheduler.today'),
		},
		bootstrapFontAwesome: {
			today: 'fa-clock',
		},
		customButtons: {
			refresh: {
				text: 'Reload',
				bootstrapFontAwesome: 'fa-sync',
				click: (ev: MouseEvent, element: HTMLElement) => {
					this.calendarComponent.getApi().refetchEvents();
				},
			},
			viewDay: {
				text: this.translate.instant('txScheduler.day'),
				click: (mouseEvent, htmlElement) => {
					this.calendarComponent.getApi().changeView('resourceTimelineDay');
					this.calendarComponent.getApi().refetchEvents();
					this.appConfig.planingConfig$.pipe(take(1)).subscribe((config) => {
						config.SelectedView = 'resourceTimelineDay';
						this.appConfig.setPlaningConfig(config);
					});
					htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
					htmlElement.classList.add('fc-state-active');
				},
			},
			viewWeek: {
				text: this.translate.instant('txScheduler.week'),
				click: (mouseEvent, htmlElement) => {
					this.calendarComponent.getApi().changeView('resourceTimelineWeek');
					this.calendarComponent.getApi().refetchEvents();
					this.appConfig.planingConfig$.pipe(take(1)).subscribe((config) => {
						config.SelectedView = 'resourceTimelineWeek';
						this.appConfig.setPlaningConfig(config);
					});
					htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
					htmlElement.classList.add('fc-state-active');
				},
			},
		},
		resourceAreaColumns: [
			{
				field: 'select',
				headerContent: '',
				width: 30,
				cellContent: (args) => {
					return createElement('input', {
						type: 'checkbox',
						className: 'multi-user-select-cb',
						onClick: () => {
							this.toggleMultiSelect(args.resource.id);
						},
					});
				},
			},
			{
				field: 'title',
				headerContent: 'Name',
			},
			{
				field: 'info',
				headerContent: 'Info',
				cellContent: (args) => {
					return createElement('span', {
						dangerouslySetInnerHTML: {
							__html: args.resource.extendedProps.info,
						},
					});
				},
			},
		],
		eventClick: ({ el, event, jsEvent, view }) => {
			if (this.DeleteMode) {
				if (this.SelectedForDelete.includes(event.id)) {
					this.SelectedForDelete = this.SelectedForDelete.filter(
						(s) => s !== event.id && !event.extendedProps.subEvents.map((e) => e.id).includes(s)
					);
					el.classList.remove('border-red');
				} else {
					this.SelectedForDelete.push(event.id, ...(event.extendedProps.subEvents?.map((e) => e.id) ?? []));
					el.classList.add('border-red');
				}
			}
		},
		eventDidMount: (info) => {
			if (!info.timeText) {
				info.timeText = `${moment(info.event.start).format('HH:mm')} - ${moment(info.event.end).format('HH:mm')}`;
			}
			const event_id = `event-tooltip-${info.event.id}`;
			if (!document.querySelector('#' + event_id)) {
				const tt = document.createElement('div');
				tt.id = event_id;
				tt.className = 'mdc-tooltip event-tooltip';
				tt.setAttribute('role', 'tooltip');
				tt.setAttribute('aria-hidden', 'true');

				const inner = tt.appendChild(document.createElement('div'));
				inner.className = 'mdc-tooltip__surface mdc-tooltip__surface-animation';
				inner.innerText = `${info.timeText}: ${info.event.title}`;

				document.body.appendChild(tt);

				info.el.setAttribute('aria-describedby', tt.id);

				new MDCTooltip(tt);
			}
		},
		weekNumbers: true,
		weekNumberCalculation: 'ISO',
		weekText: this.translate.instant('txScheduler.cw'),
		weekTextLong: this.translate.instant('txScheduler.week'),
		views: {
			resourceTimelineDay: {
				displayEventTime: true,
				eventTimeFormat: {
					hour: '2-digit',
					minute: '2-digit',
				},
				titleFormat: {
					day: '2-digit',
					month: 'long',
					weekday: 'long',
					year: 'numeric',
				},
				buttonText: 'Tag',
				slotDuration: '01:00',
				slotLabelFormat: [
					{
						hour: '2-digit',
						minute: '2-digit',
					},
				],
				slotLabelContent: (hookProps) => hookProps.text.replace(' Uhr', ''),
				select: this.selectTimeRange.bind(this),
			},
			resourceTimelineWeek: {
				type: 'resourceTimeline',
				eventTimeFormat: {
					hour: '2-digit',
					minute: '2-digit',
				},
				titleFormat: {
					month: 'long',
					day: '2-digit',
					week: 'narrow',
					year: 'numeric',
				},
				buttonText: 'Woche',
				slotDuration: { days: 1 },
				slotLabelFormat: {
					weekday: 'short',
					day: '2-digit',
					omitCommas: true,
				},
				select: this.selectTimeRange.bind(this),
			},
		},
		firstDay: 1,
		contentHeight: 'auto',
		scrollTime: '12:00',
		allDaySlot: true,
		defaultAllDay: true,
		editable: false,
		selectable: true,
		nowIndicator: true,
		events: [],
		resources: [],
	};

    public reports$: Observable<ReportEntity[]>;
    public loadingReport = false;

	constructor(
		private readonly store: Store,
		private readonly workflowService: WorkflowService,
		private readonly planService: PlansService,
		private readonly dialog: MatDialog,
		private readonly appConfig: AppConfigService,
		private readonly _ngZone: NgZone,
		private readonly api: TxApiService,
        private readonly reportService: TxreportapiService,
		private translate: TranslateService
	) {
        this.reports$ = this.store.select(getReportList).pipe(
            map((reports) => reports.filter((report) => report.report_section === 'planning')),
            filter((reports) => reports.length > 0)
        );

		appConfig.planingConfig$.pipe(take(1)).subscribe((config) => {
			this.SelectedGroups = config.SelectedGroups;
			this.selectedGroups$.next(config.SelectedGroups);

			const cb = () => {
				this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
			};

			if (this.calendarComponent) {
				cb();
				this.setCurrentViewState();
			} else {
				setTimeout(() => {
					cb();
					this.setCurrentViewState();
				}, 100);
			}
		});

		this.workflowGroups$ = workflowService.getWorkflowGroups();
		this.workflows$ = combineLatest([this.store.select(getPlanCalendar), this.selectedGroups$.asObservable()]).pipe(
			map(([res, selectedGroups]) => {
				return res.filter((d) => (selectedGroups ?? []).includes(d.workflow_group));
			}),
			tap((res) => {
				this.CalendarOptions.resources = res.map(
					(g) => ({ id: `${g.employee_id}`, title: g.employee_name, info: g.info } as ResourceInput)
				);
				this.CalendarOptions.events = this.fetchEvents.bind(this);
			})
		);

		this.selectedGroups$.pipe(withLatestFrom(appConfig.planingConfig$)).subscribe(([selected, config]) => {
			if (JSON.stringify(selected.sort()) !== JSON.stringify(config.SelectedGroups.sort())) {
				config.SelectedGroups = selected;
				this.SelectedGroups = selected;
				this.appConfig.setPlaningConfig(config);
			}
		});
	}

    stopPropagation(event: Event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        return false;
    }

	toggleMultiSelect(id: string) {
		if (this.MultiSelectUserIds.includes(id)) {
			this.MultiSelectUserIds = this.MultiSelectUserIds.filter((s) => s !== id);
		} else {
			this.MultiSelectUserIds.push(id);
		}
	}

    async downloadReport(report: ReportEntity) {
        this.loadingReport = true;

        const data = await firstValueFrom(this.reportService.getReport(report));
        this.dialog.open(TxModalPdfComponent, {
            minHeight: 'calc(100vh - 90px)',
            maxWidth: '95vw',
            height: 'auto',
            width: '1000px',
            data: {
                blob: data,
                title: report.report_name,
            },
        });
        this.loadingReport = false;
    }

	setCurrentViewState(): void {
		// get current view type
		const viewType = this.calendarComponent.getApi().view.type;
		// change state of view type button, depending on which view is currently active
		if (viewType === 'resourceTimelineDay') {
			document.querySelector('.fc-viewDay-button').classList.add('fc-state-active');
		} else if (viewType === 'resourceTimelineWeek') {
			document.querySelector('.fc-viewWeek-button').classList.add('fc-state-active');
		}
	}
	ngAfterViewChecked() {
		/*this.appConfig.planingConfig$.pipe(
          take(1)
      ).subscribe(config => {
          // this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
      });*/
	}
	toggleGroup(event: MatButtonToggleChange) {
		this.selectedGroups$.next(event.value);
	}

	abortDeleteMode() {
		this.SelectedForDelete = [];
		document.querySelectorAll('.border-red').forEach((el) => el.classList.remove('border-red'));
		this.DeleteMode = !this.DeleteMode;
	}

    printCalendar() {
        console.log(document.getElementById('calendar'));

        const mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head>');
        mywindow.document.write(document.getElementsByTagName('head')[0].innerHTML);
        mywindow.document.write('</head><body>');
        mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.querySelector('#calendar').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        //mywindow.close();

        return true;
    }

	toggleDeleteMode() {
		if (this.DeleteMode) {
			combineLatest(this.SelectedForDelete.map((id) => this.planService.delete(Number(id))))
				.pipe(
					mergeMap((res) => {
						return res;
					}),
					tap(() => {
						this.calendarComponent.getApi().refetchEvents();
					}),
					take(1)
				)
				.subscribe();
			this.SelectedForDelete = [];
			document.querySelectorAll('.border-red').forEach((el) => el.classList.remove('border-red'));
		}
		this.DeleteMode = !this.DeleteMode;
	}

	fetchEvents(info, success, failure) {
		this.planService
			.fetchCalendarListRaw(
				moment(info.start).format('YYYY-MM-DDTHH:mm:ss'),
				moment(info.end).format('YYYY-MM-DDTHH:mm:ss'),
				true
			)
			.pipe(
				withLatestFrom(this.planService.fetchList()),
				map(async ([res, plans]) => {
					let data: any[] = (
						await Promise.all(
							res.map(async (r) => {
								const schedulerDateRange = {
									von: moment(info.start).format('YYYY-MM-DDTHH:mm:ss'),
									bis: moment(info.end).format('YYYY-MM-DDTHH:mm:ss'),
									team_leader_for: r.employee_id,
								};
								const calendarHead = await firstValueFrom(
									this.api.callAPI('getCalendarHead', schedulerDateRange).pipe(
										map((res) => {
											return res.ListOfCalendarHead.map((schedulerData: ListOfCalendarHead) => {
												if (!schedulerData.fehlzeit_name) {
													return null;
												}
												const datum = moment(schedulerData.datum).format('YYYY-MM-DD');
												const title = schedulerData.fehlzeit_name + schedulerData.info;
												const currentEvent: EventInput = {
													// Global event settings
													id: schedulerData.id + 'FZ',
													title: title?.replace('null', '') ?? '',
													start: datum,
													end: datum,
													color: schedulerData.fehlzeit_color ? schedulerData.fehlzeit_color : '#dcdcdc',
													allDay: true,
													// editable: !!schedulerData.fehlzeit_name,
													extendedProps: {
														type: 'FZ',
														fehlzeit_id: schedulerData.fehlzeit_id,
														fehlzeit_dauer: schedulerData.fehlzeit_dauer,
														fehlzeit_name: schedulerData.fehlzeit_name,
														clickable: !!schedulerData.fehlzeit_name,
													} as ListOfCalendarHead,
												};
												return currentEvent;
											}).filter(Boolean);
										})
									)
								);
								return [
									...calendarHead?.map((c) => ({
										id: c.id,
										resourceId: r.employee_id,
										allDay: false,
										title: c.extendedProps.fehlzeit_name,
										start: moment(c.start).format('YYYY-MM-DDT00:00:00'),
										end: moment(c.end).format('YYYY-MM-DDT23:59:00'),
										timeZone: 'local',
										display: 'background',
										backgroundColor: c.color,
										borderColor: chroma(c.color).brighten(0.5).hex(),
										textColor: chroma(c.color).luminance() > 0.5 ? 'black' : 'white',
									})),
									...(r.ListOfPlanItem?.map((l) => {
										const start = moment(`${l.plan_date.split('T').reverse().pop()}T${l.begin_time.split('T').pop()}`);
										const end = moment(`${l.plan_date.split('T').reverse().pop()}T${l.end_time.split('T').pop()}`);
										const plan = plans.find((p) => p.id === l.plan_id);
										const color = chroma(plan.color);
										return {
											id: l.id,
											resourceId: r.employee_id,
											allDay: false,
											title: plan.name,
											start: start.format('YYYY-MM-DDTHH:mm:ss'),
											end: end.format('YYYY-MM-DDTHH:mm:ss'),
											timeZone: 'local',
											classNames: 'event-round-border',
											backgroundColor: plan.color,
											borderColor: color.brighten(0.5).hex(),
											textColor: color.luminance() > 0.5 ? 'black' : 'white',
										};
									}) ?? []),
								];
							})
						)
					)
						.flat()
						.filter(Boolean);
					if (this.calendarComponent.getApi().view.type === 'resourceTimelineWeek') {
						// aggregate following dates
						data = Array.from(
							data
								.reduce((acc, curr) => {
									const timeStartArr = curr.start.split('T');
									const timeEndArr = curr.end.split('T');
									const startTime = curr.start.split('T').pop();
									const endTime = curr.end.split('T').pop();

									const keyPrefix = `${curr.title}${curr.resourceId}${startTime}${endTime}`;
									// @ts-ignore
									const keys = Array.from(acc.keys()).filter((k) => k.startsWith(keyPrefix));
									if (keys.length === 0) {
										acc.set(keyPrefix + timeStartArr[0], {
											...curr,
											subEvents: [],
										});
									} else {
										let isInserted = false;
										for (const k of keys) {
											const el = acc.get(k);
											if (
												moment(curr.start).diff(moment(el.start), 'days') === 1 ||
												moment(curr.start).diff(moment(el.subEvents.slice().pop()?.start ?? el.start), 'days') === 1
											) {
												el.subEvents.push(curr);
												el.end = curr.end;
												isInserted = true;
												break;
											}
										}

										if (!isInserted) {
											acc.set(keyPrefix + timeStartArr[0], {
												...curr,
												subEvents: [],
											});
										}
									}
									return acc;
								}, new Map<string, any>())
								.values()
						);
					}
					success(data);
				}),
				catchError((err) => {
					console.error(err);
					failure(err);
					return of(err);
				}),
				take(1)
			)
			.subscribe();
	}

	removeEventTooltips() {
		document.querySelectorAll('.event-tooltip').forEach((el) => el.remove());
	}

	selectTimeRange(info: DateSelectArg) {
		this.openSelectPlanDialog(info.start, info.end, Number(info.resource.id));
	}

	openSelectPlanDialog(start: Date, end: Date, employeeId: number) {
		let checkedEnd = end;
		if (this.calendarComponent.getApi().view.type === 'resourceTimelineWeek') {
			checkedEnd = moment(end).subtract(1, 'days').toDate();
		}
		const dialog = this.dialog.open(TxPlaningSelectPlanDialogComponent, {
			data: {
				start,
				end: checkedEnd,
				view: this.calendarComponent.getApi().view.type,
			},
		});
		dialog
			.afterClosed()
			.pipe(
				filter(Boolean),
				withLatestFrom(this.store.select(getPlansList)),
				switchMap(([{ data, time }, plans]) => {
					const plan = plans.find((p) => p.id === data.selectedPlan);
					const override =
						time !== 'plan'
							? {
									start: moment(start).format(`YYYY-MM-DDT${data.selectedStart}:00`),
									end: moment(end).format(`YYYY-MM-DDT${data.selectedEnd === '00:00' ? '23:59' : data.selectedEnd}:00`),
							  }
							: {
									start: moment(start).format(`YYYY-MM-DDT${plan.begin_time.split('T').pop()}`),
									end: moment(end).format(
										`YYYY-MM-DDT${
											plan.end_time.split('T').pop() === '00:00:00' ? '23:59:00' : plan.end_time.split('T').pop()
										}`
									),
							  };
					if (this.MultiSelectUserIds.length > 0) {
						const ids = this.MultiSelectUserIds.map((i) => +i);
						if (!ids.includes(employeeId)) {
							ids.push(employeeId);
						}
						return forkJoin(
							this.planService.updateCalendarMulti(
								plan,
								ids,
								moment(data.startDate).format('YYYY-MM-DDTHH:mm:00'),
								moment(data.endDate).format('YYYY-MM-DDTHH:mm:00'),
								override
							)
						).pipe(
							tap(() => {
								document.querySelectorAll('.multi-user-select-cb').forEach((el: HTMLInputElement) => {
									el.removeAttribute('checked');
									el.checked = false;
								});
								this.MultiSelectUserIds = [];
							})
						);
					}
					return this.planService.updateCalendar(
						plan,
						employeeId,
						moment(data.startDate).format('YYYY-MM-DDTHH:mm:00'),
						moment(data.endDate).format('YYYY-MM-DDTHH:mm:00'),
						override
					);
				}),
				tap(() => this.calendarComponent.getApi().refetchEvents()),
				take(1)
			)
			.subscribe();
	}

	openPlansDialog() {
		this.dialog.open(TxPlaningPlansDialogComponent);
	}

	ngOnDestroy(): void {
		this.removeEventTooltips();
	}
}
