import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    OnInit
} from '@angular/core';
import { TabStateService } from '../../shared/services/tabstate.service';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Location } from '@angular/common';
import { TeamMember } from '../../shared/interfaces/teammember';
import { TeamMemberService } from '../../shared/services/team-member.service';
import { empty } from '../../shared/helpers';
import { NavigationService } from '../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getEmployees } from '../../store/selectors/personal-office.selectors';
import {
    filter,
    find,
    first,
    map,
    take
} from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { EmployeeEntity } from '../../store/entities/EmployeeEntity';
import { PersonalOfficeService } from '../../shared/services/personal-office.service';

@Component({
    selector: 'tx-kalender',
    templateUrl: './txkalender.component.html',
    providers: [TabStateService],
})
export class TxKalenderComponent implements OnInit {
    persoId = '';
    teamMember: EmployeeEntity;
    title = this.translate.instant('txScheduler.personalCalendar');
    defaultDate: string;

    constructor(
        private route: ActivatedRoute,
        private readonly poService: PersonalOfficeService,
        public myAuthService: AuthService,
        private navigation: NavigationService,
        private myTeamMemberService: TeamMemberService,
        private router: Router,
        private translate: TranslateService
    ) {
    }

    isTeamLeader(): boolean {
        const myCurrentUser = JSON.parse(localStorage.getItem('currentSession'));
        return myCurrentUser.team_leader === 1;
    }

    goBack() {
        const url = this.navigation.getBackUrl();
        if (['/', '/login', '/kalender'].includes(url)) {
            window.location.href = window.location.origin + '/#/kalender';
            window.location.reload();
        } else {
            this.navigation.back();
        }

        // this.router.navigateByUrl(this.navigation.getBackUrl());
    }

    showBackButton() {
        const url = this.navigation.getBackUrl();

        // teamleiter called calendar for a team member
        if (this.isTeamLeader() && this.persoId != '') {
            return true;
        }
        // personal calendar opened from dashboard
        if (empty(this.route.snapshot.paramMap.get('id')) && !empty(this.route.snapshot.paramMap.get('date'))) {
            return true;
        }
        return false;
    }

    async ngOnInit() {
        if (!empty(this.route.snapshot.paramMap.get('date'))) {
            this.defaultDate = this.route.snapshot.paramMap.get('date');
        }
        console.log('params');
        this.route.paramMap.subscribe((params) => {
            console.log(params);
        });
        if (!empty(this.route.snapshot.paramMap.get('id'))) {
            if (this.isTeamLeader()) {
                this.persoId = this.route.snapshot.paramMap.get('id');
                this.teamMember = await lastValueFrom(
                    this.poService.getEmployees().pipe(
                        map((employees) => employees.find((employee) => employee.persnr === this.persoId)),
                        take(1)
                    )
                );
                if (this.teamMember) {
                    this.title = this.title + ' von ' + this.teamMember.firstname + ' ' + this.teamMember.lastname;
                }
            }
        }
    }
}
