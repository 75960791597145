import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { MessageEntity } from '../../store/entities/MessageEntity';
import { MessageCenterService } from '../services/message-center.service';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MessagesClientResolver implements Resolve<MessageEntity[]> {
	constructor(private readonly service: MessageCenterService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<MessageEntity[]> | Promise<MessageEntity[]> | MessageEntity[] {
		return this.service.fetchMessages(0, false).pipe(
			map((res) => res.messages),
			take(1)
		);
	}
}
