import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectOfficeService } from '../services/project-office.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorkstepTypeEntity } from '../../store/entities/WorkstepTypeEntity';

@Injectable({
	providedIn: 'root',
})
export class ProjectOfficeWorkstepTypesResolver implements Resolve<WorkstepTypeEntity[]> {
	constructor(private projectOfficeService: ProjectOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<WorkstepTypeEntity[]> | Promise<WorkstepTypeEntity[]> | WorkstepTypeEntity[] {
		return this.projectOfficeService.fetchWorkstepTypes().pipe(take(1));
	}
}
