<ng-container *ngIf="plans$ | async as plans">
	<div mat-dialog-title class="display-flex flex-justify-content-space-between flex-align-items-center">
		<h3 class="m-0 mr-1">{{ 'txPlaning.plans' | translate }}</h3>
		<div>
			<button mat-raised-button class="mr-2" (click)="openPlanDetailDialog()">
				<fa-icon icon="plus"></fa-icon>
                {{ 'general.add' | translate }}
			</button>
			<button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
				<fa-icon icon="times"></fa-icon>
			</button>
		</div>
	</div>
	<div mat-dialog-content style="min-width: 700px">
		<div>
			<div *ngFor="let plan of plans" class="plan p-2 grid grid-nogutter">
				<div class="plan-name col-4 mat-h3 py-2 m-0">
					<span class="p-2" [ngStyle]="{ backgroundColor: plan.color, color: getPlanTextColor(plan) }">{{
						plan.name
					}}</span>
				</div>
				<div class="plan-day col-4 flex align-items-center flex-wrap">
					<span *ngIf="plan.mon" class="mr-1">{{ 'txPlaning.mo' | translate }}</span>
					<span *ngIf="plan.tue" class="mr-1">{{ 'txPlaning.tu' | translate }}</span>
					<span *ngIf="plan.wed" class="mr-1">{{ 'txPlaning.we' | translate }}</span>
					<span *ngIf="plan.thu" class="mr-1">{{ 'txPlaning.th' | translate }}</span>
					<span *ngIf="plan.fri" class="mr-1">{{ 'txPlaning.fr' | translate }}</span>
					<span *ngIf="plan.sat" class="mr-1">{{ 'txPlaning.sa' | translate }}</span>
					<span *ngIf="plan.sun" class="mr-1">{{ 'txPlaning.su' | translate }}</span>
				</div>
				<div class="col-2 flex align-items-center justify-content-center">
					<span class="mr-2">{{ plan.begin_time | formatTime: 'HH:mm' }}</span>
					<span class="mr-2">-</span>
					<span>{{ plan.end_time | formatTime: 'HH:mm' }}</span>
				</div>
				<div class="col-2 flex justify-content-end">
					<button mat-mini-fab class="mr-2" (click)="openPlanDetailDialog(plan)">
						<fa-icon icon="pencil-alt"></fa-icon>
					</button>
					<button mat-mini-fab color="primary" (click)="deletePlan(plan.id)">
						<fa-icon icon="trash"></fa-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-container>
