export class ListOfTeamleaderResponse {
	id: number;
	persnr: number;
	home_id: number;
	name: string;
	employee_count: number;
	deputy_active: number;
	deputy_persnr: number;
	deputy_name: string;
}
