import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportEntity } from '../../store/entities/ReportEntity';
import { TxreportapiService } from '../services/txreportapi.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class ReportsResolver implements Resolve<ReportEntity[]> {
	constructor(private readonly api: TxreportapiService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ReportEntity[]> | Promise<ReportEntity[]> | ReportEntity[] {
		return this.api.getReports().pipe(take(1));
	}
}
