import { Component, Input } from '@angular/core';
import { ListOfUserInfo } from '../../shared/interfaces/user-info';

@Component({
	selector: 'tx-welcome',
	templateUrl: './tx-welcome.component.html',
})
export class TxWelcomeComponent {
	@Input() listOfUserInfo: ListOfUserInfo;
	@Input() hideTitle = false;
	@Input() offlineBookings = 0;

	constructor() {}
}
