import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { TxActiveBDEList } from '../interfaces/bde';
import { filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Event, ListOfCalendarPZE } from '../interfaces/scheduler';
import moment from 'moment';
import { TerminalService } from './terminal.service';
import { getSession } from '../../store/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';

@Injectable({
	providedIn: 'root',
})
export default class CalendarPzeService {
	constructor(private api: TxApiService, private terminalService: TerminalService, private store: Store<State>) {}
	private dayEvents: Event[] = null;

	private _currentDayEvents$: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>(null);
	public currentDayEvents$ = this._currentDayEvents$.asObservable();

	private checkInterval$: Subscription | null = null;
	private checkIntervalReturn: [Observable<Event[]>, () => void] | null = null;

	private getCalendarPZE(body: object) {
		return this.api.callAPI('getCalendarPZE', body).pipe(
			map((res) => {
				const events: Event[] =
					res.ListOfCalendarPZE?.map((schedulerData: ListOfCalendarPZE) => {
						const currentEvent: Event = {
							// Global event settings
							id: schedulerData.id,
							title: 'PZE',
							start: schedulerData.beginn !== '' ? moment(schedulerData.beginn).toDate() : null,
							end: schedulerData.ende !== '' ? moment(schedulerData.ende).toDate() : null,
							color: schedulerData.color,
							className: ['pze-event'],
							allDay: false,
							overlap: false,
							extendedProps: {
								datum: schedulerData.datum,
								type: 'PZE',
								info: schedulerData.info,
								notiz: schedulerData.notiz,
								beginn_id: schedulerData.beginn_id,
								beginn: schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende,
								ende_id: schedulerData.ende_id,
								ende: schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn,
								kst_nummer: schedulerData.kst_nummer,
							} as ListOfCalendarPZE,
						};
						return currentEvent;
					}) ?? [];
				return events;
			})
		);
	}

	public onChangedDayCalendarPZE(): [Observable<Event[]>, () => void] {
		if (!this.checkInterval$) {
			const behavior = new BehaviorSubject<Event[]>(null);
			let lastEvents: Event[] = null;
			const calendar = {
				von: moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
				bis: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
			};
			const stop$ = new Subject<boolean>();
			this.checkInterval$ = interval(2000)
				.pipe(
					takeUntil(stop$.asObservable()),
					withLatestFrom(this.store.select(getSession)),
					switchMap(([, session]) => (!session ? this.getCalendarPZE(calendar) : of(null))),
					map((events) => {
						if (this.dayEvents === null) {
							this.dayEvents = events;
							this._currentDayEvents$.next(events);
						} else if (
							events?.length >= this.dayEvents?.length &&
							JSON.stringify(lastEvents) !== JSON.stringify(events)
						) {
							this.checkInterval$.unsubscribe();
							behavior.next(events);
							this.dayEvents = events;
							this._currentDayEvents$.next(events);
						}
					})
				)
				.subscribe();
			this.checkIntervalReturn = [
				behavior.asObservable().pipe(
					filter((e) => !!e),
					take(1)
				),
				() => {
					stop$.next(true);
					this.checkInterval$.unsubscribe();
				},
			];
		}

		return this.checkIntervalReturn;
	}

	public getDayEvents() {
		if (this.dayEvents !== null) {
			return of(this.dayEvents);
		}
		return this.getCalendarPZE({
			von: moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
			bis: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
		}).pipe(
			tap((events) => this._currentDayEvents$.next(events)),
			take(1)
		);
	}
}
