import { createAction } from '@ngrx/store';
import { PlanEntity } from '../entities/PlanEntity';
import { PlanCalendarEntity } from '../entities/PlanCalendarEntity';

export const FetchPlans = createAction('[PLANS] fetch');

export const SetPlans = createAction('[PLANS] set plans', (plans: PlanEntity[]) => ({ plans }));

export const FetchCalendar = createAction(
	'[PLANS] fetch calendar',
	(start: string, end: string, teamleader: boolean) => ({ start, end, teamleader })
);

export const SetCalendar = createAction('[PLANS] set calendar', (calendar: PlanCalendarEntity[]) => ({ calendar }));
