import { Injectable } from '@angular/core';
import { TeamMember } from '../interfaces/teammember';

@Injectable({
	providedIn: 'root',
})
export class TeamMemberService {
	constructor() {}

	getTeamMember(teamMemberId): TeamMember {
		const allTeamMember = JSON.parse(localStorage.getItem('txTeam'));

		const searchedTeamMember = allTeamMember.find((data) => {
			return data.personalnr === parseInt(teamMemberId, 10);
		});

		if (searchedTeamMember) {
			return searchedTeamMember;
		} else {
			return { name: '', personalnr: null };
		}
	}
}
