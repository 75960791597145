import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PersonalOfficeService } from '../services/personal-office.service';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EmployeeRelation } from '../../store/entities/EmployeeRelation';

@Injectable({
	providedIn: 'root',
})
export class PoEmployeeRelationsResolver implements Resolve<EmployeeRelation[]> {
	constructor(private po: PersonalOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<EmployeeRelation[]> | Promise<EmployeeRelation[]> | EmployeeRelation[] {
		return this.po.getEmployeeRelations().pipe(take(1));
	}
}
