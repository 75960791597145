import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple string filter, since ng2 does not yet have a filter pipe built in.
 */
@Pipe({
	name: 'filter',
})
export class FilterPipe implements PipeTransform {
	transform<T>(value: T[], field: keyof T, compare: any, func: 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' = 'eq') {
		return value.filter((v) => {
			switch (func) {
				case 'eq':
					return v[field] === compare;
				case 'neq':
					return v[field] !== compare;
				case 'gt':
					return v[field] > compare;
				case 'gte':
					return v[field] >= compare;
				case 'lt':
					return v[field] < compare;
				case 'lte':
					return v[field] <= compare;
				default:
					return true;
			}
		});
	}
}
