<ng-container *ngIf="{ worksteps: $worksteps | async, workstepTypes: $workstepTypes | async } as data">
	<div mat-dialog-title class="display-flex flex-justify-content-space-between flex-align-items-center">
		<h3 class="m-0 mr-1">{{ 'txProjectOffice.task' | translate }}</h3>
		<button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
			<fa-icon icon="times"></fa-icon>
		</button>
	</div>
	<div mat-dialog-content style="min-width: 700px">
		<div class="display-flex flex-direction-column">
			<div class="workstep" *ngFor="let workstep of data.worksteps">
				<div *ngIf="EditMode[workstep.workstep_id]" class="mat-dense-input display-flex flex-grow-1 mr-1">
					<mat-form-field appearance="standard" class="flex-grow-1">
						<mat-label>{{ 'txProjectOffice.name' | translate }}</mat-label>
						<input
							matInput
							[disabled]="!EditMode[workstep.workstep_id]"
							[(ngModel)]="getEdited(workstep).workstep_name" />
					</mat-form-field>
				</div>
				<span *ngIf="!EditMode[workstep.workstep_id]" class="mat-h3 m-0" style="padding: 9px 0">
					{{ getEdited(workstep).workstep_name }}
				</span>
				<div class="display-flex flex-align-items-center">
					<div *ngIf="EditMode[workstep.workstep_id]" class="mat-dense-input">
						<mat-form-field class="mr-1" appearance="standard">
							<mat-label>{{ 'txProjectOffice.type' | translate }}</mat-label>
							<mat-select [(ngModel)]="getEdited(workstep).workstep_type">
								<mat-option *ngFor="let type of data.workstepTypes" [value]="type.value">
									{{ type.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<ng-container *ngIf="!EditMode[workstep.workstep_id]">
						<span class="mat-h3 m-0 mr-1">
							{{ getWorkstepType(getEdited(workstep).workstep_type, data.workstepTypes).description }}
						</span>
						<button mat-mini-fab class="mr-1" (click)="EditMode[workstep.workstep_id] = true">
							<fa-icon icon="pencil-alt"></fa-icon>
						</button>
						<button mat-mini-fab color="primary" (click)="deleteWorkstep(workstep)">
							<fa-icon icon="trash"></fa-icon>
						</button>
					</ng-container>
					<ng-container *ngIf="EditMode[workstep.workstep_id]">
						<button *ngIf="EditMode[workstep.workstep_id]" mat-mini-fab class="mr-1" (click)="onSave(workstep)">
							<fa-icon icon="save"></fa-icon>
						</button>
						<button mat-mini-fab color="primary" (click)="onDiscard(workstep)">
							<fa-icon icon="times-circle"></fa-icon>
						</button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="display-flex mt-1 flex-align-items-center new-workstep">
		<mat-form-field appearance="outline" class="full-width mr-1">
			<mat-label>{{ 'txProjectOffice.name' | translate }}</mat-label>
			<input matInput [(ngModel)]="NewName" />
		</mat-form-field>
		<mat-form-field class="mr-1" appearance="outline">
			<mat-label>{{ 'txProjectOffice.type' | translate }}</mat-label>
			<mat-select [(ngModel)]="NewType">
				<mat-option *ngFor="let type of data.workstepTypes" [value]="type.value">
					{{ type.description }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<div class="full-height display-flex flex-align-items-center">
			<button mat-mini-fab (click)="onAddWorkstep(NewName, data.workstepTypes)" [disabled]="!canAdd(data.worksteps)">
				<fa-icon icon="plus"></fa-icon>
			</button>
		</div>
	</div>
	<!--<div mat-dialog-actions class="display-flex flex-justify-content-flex-end">
        <button mat-raised-button mat-dialog-close color="primary">Schließen</button>
    </div>-->
</ng-container>
