import { Action, createReducer, on } from '@ngrx/store';
import {
	ChangeEmployeeData,
	FetchTeamleaderEmployeeAssignments,
	LoadEmployeeData,
	LoadTeamleaderList,
	LoadTeams,
	LoadTimemodelDetail,
	LoadTimemodels,
	LoadTypeList,
	setEmployees,
} from '../actions/personal-office.actions';
import { EmployeeEntity } from '../entities/EmployeeEntity';
import { TimemodelEntity } from '../entities/TimemodelEntity';
import { TypeEntity } from '../entities/TypeEntity';
import { TimeModelDetailEntity } from '../entities/TimeModelDetailEntity';
import {
    ChangeModelBreak,
    ChangeModelFrame,
    ChangeTimeModelField,
    DeleteTimemodel,
    SaveTimemodel
} from '../actions/timemodel.actions';
import { TeamLeaderEntity } from '../entities/TeamLeaderEntity';
import { CalendarGroupEntity } from '../entities/CalendarGroupEntity';
import { SetCalendarGroup } from '../actions/project-office.actions';
import { TeamEntity } from '../entities/TeamEntity';
import { EmployeeTeamleaderAssingmentEntity } from '../entities/EmployeeTeamleaderAssingmentEntity';

export interface State {
	employees: EmployeeEntity[];
	timemodels: TimemodelEntity[];
	calendarGroup: CalendarGroupEntity[];
	timemodelDetails: TimeModelDetailEntity[];
	salaryTypes: TypeEntity[];
	employeeTypes: TypeEntity[];
	employmentTypes: TypeEntity[];
	teamleader: TeamLeaderEntity[];
	teams: TeamEntity[];
	teamleaderEmployeeAssignments: { [key: number]: EmployeeTeamleaderAssingmentEntity[] };
}

const initialState: State = {
	employees: null,
	timemodels: null,
	calendarGroup: null,
	timemodelDetails: null,
	employeeTypes: null,
	employmentTypes: null,
	salaryTypes: null,
	teamleader: null,
	teams: null,
	teamleaderEmployeeAssignments: {},
};

const personalOfficeReducer = createReducer(
	initialState,
	on(setEmployees, (state, { employees }) => ({
		...state,
		employees,
	})),
	on(LoadEmployeeData, (state, { employee, permissions, assingments, holidayAccounts }) => ({
		...state,
		employees: [
			...state.employees.filter((e) => e.employee_id !== employee.employee_id),
			state.employees
				.find((e) => e.employee_id === employee.employee_id)
				?.Load(employee, permissions, assingments, holidayAccounts) ??
				new EmployeeEntity().Load(employee, permissions, assingments, holidayAccounts),
		],
	})),
	on(ChangeEmployeeData, (state, { employee }) => ({
		...state,
		employees: [
			...state.employees.filter((e) => e.employee_id !== employee.employee_id),
			new EmployeeEntity().Load(employee, null, null, null),
		],
	})),
	on(LoadTimemodels, (state, { timemodels }) => ({
		...state,
		timemodels,
	})),
	on(LoadTypeList, (state, { listType, entities }) => ({
		...state,
		[`${listType}`]: entities,
	})),
	on(LoadTimemodelDetail, (state, { timemodeldetail }) => ({
		...state,
		timemodelDetails: !state.timemodelDetails
			? timemodeldetail
			: [
					...state.timemodelDetails.filter((tm) => !timemodeldetail.map((tmd) => tmd.model_id).includes(tm.model_id)),
					...timemodeldetail,
			  ],
	})),
	on(ChangeModelFrame, (state, { id, change }) => ({
		...state,
		timemodelDetails: [
			...state.timemodelDetails.filter((tmd) => tmd.model_id !== id),
			Object.assign(
				new TimeModelDetailEntity(),
				state.timemodelDetails.find((tmd) => tmd.model_id === id),
				{
					ListOfModelFrame: [
						...state.timemodelDetails
							.find((tmd) => tmd.model_id === id)
							.ListOfModelFrame.filter((mf) => mf.id !== change.id),
						change,
					],
				}
			),
		],
	})),
	on(ChangeModelBreak, (state, { id, change }) => {
		return {
			...state,
			timemodelDetails: [
				...state.timemodelDetails.filter((tmd) => tmd.model_id !== id),
				Object.assign(
					new TimeModelDetailEntity(),
					state.timemodelDetails.find((tmd) => tmd.model_id === id),
					{
						ListOfModelBreak: [
							...state.timemodelDetails
								.find((tmd) => tmd.model_id === id)
								.ListOfModelBreak.filter((mb) => mb.id !== change.id),
							change,
						],
					}
				),
			],
		};
	}),
    on(ChangeTimeModelField, (state, { id, field, value }) => {
        return {
            ...state,
            timemodelDetails: [
                ...state.timemodelDetails.filter((tmd) => tmd.model_id !== id),
                Object.assign(
                    new TimeModelDetailEntity(),
                    state.timemodelDetails.find((tmd) => tmd.model_id === id),
                    {
                        [field]: value,
                    }
                ),
            ],
        }
    }),
	on(SaveTimemodel, (state, { entity }) => {
		return {
			...state,
			timemodelDetails: state.timemodelDetails
				? [
						...state.timemodelDetails.filter((tmd) => tmd.model_id !== entity.model_id),
						Object.assign(
							new TimeModelDetailEntity(),
							state.timemodelDetails.find((tmd) => tmd.model_id === entity.model_id),
							entity
						),
				  ]
				: [entity],
		};
	}),
	on(DeleteTimemodel, (state, { id }) => ({
		...state,
		timemodelDetails: state.timemodelDetails.filter((tmd) => tmd.model_id !== id),
		timemodels: state.timemodels.filter((tm) => tm.model_id !== id),
	})),
	on(LoadTeamleaderList, (state, { teamleaders }) => ({
		...state,
		teamleader: teamleaders,
	})),
	on(SetCalendarGroup, (state, { calendarGroup }) => ({
		...state,
		calendarGroup,
	})),
	on(LoadTeams, (state, { teams }) => ({
		...state,
		teams,
	})),
	on(FetchTeamleaderEmployeeAssignments, (state, { id, assignments }) => ({
		...state,
		teamleaderEmployeeAssignments: {
			...state.teamleaderEmployeeAssignments,
			[id]: assignments,
		},
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return personalOfficeReducer(state, action);
}
