export class ListOfWorkstepResponse {
	workstep_id: number;
	workstep_number: number;
	workstep_name: string;
	workstep_type: number;
}

export class ListOfWorkstepProjectResponse {
	workstep_project_id: number;
	workstep_number: number;
	workstep_name: string;
	workstep_type: number;
}
