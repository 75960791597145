import { ListOfPlanResponse } from './Api/ListOfPlanResponse';
import { Color } from '@angular-material-components/color-picker';

export class PlanEntity extends ListOfPlanResponse {
	constructor() {
		super();
	}

	public static tryCast(o: any): PlanEntity {
		const ret = new PlanEntity();
		return Object.assign(ret, o);
	}

	public toApi(): object {
		return {
			id: this.id,
			name: this.name,
			color: this.color instanceof Color ? this.color.toHexString() : this.color,
			begin_time: this.begin_time,
			end_time: this.end_time,
			mon: this.mon,
			tue: this.tue,
			wed: this.wed,
			thu: this.thu,
			fri: this.fri,
			sat: this.sat,
			sun: this.sun,
		};
	}
}
