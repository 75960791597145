import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ListOfKSTEntity } from '../../store/entities/ListOfKSTEntity';
import { DataService } from '../services/data.service';

@Injectable({
	providedIn: 'root',
})
export class KstResolver implements Resolve<ListOfKSTEntity[]> {
	constructor(private readonly service: DataService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ListOfKSTEntity[]> | Promise<ListOfKSTEntity[]> | ListOfKSTEntity[] {
		return this.service.getKSTs().pipe(take(1));
	}
}
