import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EmployeeEntity } from '../../store/entities/EmployeeEntity';
import { PersonalOfficeService } from '../services/personal-office.service';

@Injectable({
	providedIn: 'root',
})
export class PoUsersResolver implements Resolve<EmployeeEntity[]> {
	constructor(private po: PersonalOfficeService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<EmployeeEntity[]> | Promise<EmployeeEntity[]> | EmployeeEntity[] {
		return this.po.getEmployees(state.url.includes('teamkonfiguration')).pipe(take(1));
	}
}
