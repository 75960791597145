import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { ConnectionStateService } from './connection-state.service';
import { HeartbeatService } from './heartbeat.service';
import { environment } from '../../../environments/environment';
import { TxActiveBDEList } from '../interfaces/bde';
import { switchMap, take } from 'rxjs/operators';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';

@Injectable()
export class ActiveBDEService {
	myActiveBDE: BehaviorSubject<TxActiveBDEList[]> = new BehaviorSubject([]); // Observer und etwas mehr für intern
	ActiveBDE = this.myActiveBDE.asObservable();

	constructor(
		private myTxApiService: TxApiService,
		private myAuthService: AuthService,
		private myConnectionStateService: ConnectionStateService,
		private heartBeatService: HeartbeatService
	) {
		// this.requestData();
		// this.heartBeatService.addToHeartbeat(() => this.requestData(), 1);
	}

	compareBDEResult(a1: TxActiveBDEList[], a2: TxActiveBDEList[]) {
		if (a1?.length !== a2?.length) {
			return false;
		}

		return (
			JSON.stringify(a1, (key, _) => (key === 'queued' ? undefined : _)) ===
			JSON.stringify(a2, (key, _) => (key === 'queued' ? undefined : _))
		);
	}

	requestDataCall(): Observable<ApiResponse<TxActiveBDEList[]> | null> {
		return this.myAuthService.session$.pipe(
			switchMap((session) => {
				if (session?.reset_pw === 0) {
					return this.myTxApiService.callAPI('getActiveBDE', {});
				}
				return of(null);
			}),
			take(1)
		);
	}

	requestData() {
		// check if we are offline
		if (!this.myConnectionStateService.isOnlineSubject.getValue()) {
			return;
		}
		this.requestDataCall().subscribe(
			(mySessionData) => {
				if (mySessionData) {
					this.myActiveBDE.next(mySessionData.ListOfActiveBDE);
				}
			},
			(error) => {
				console.log('ERROR' + error);
				this.myActiveBDE.error(error);
			}
		);
	}
}
