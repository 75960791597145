import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { debounce, map, tap } from 'rxjs/operators';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';

type anyWithId = any & { id: number };

@Component({
	selector: 'tx-list',
	templateUrl: './tx-list.component.html',
	styleUrls: ['./tx-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TxListComponent<T> implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@Input() maxHeight = '';
	@Input() items: T[] | null = null;
	@Input() searchField: string | null = null;
	@Input() idField: keyof T | null = null;
	@Input() sortField: keyof T | null = null;
	@Input() sortDir: 'asc' | 'desc' = 'asc';
	@Input() labelField: keyof T | ((item: T) => string);
	@Input() multiple = false;
	@Input() routerLinkBase: string | string[];
	@Input() showNew = true;
	@Input() badges: { [key: string]: number } = {};

	@Output() newClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	@Output() selectedItem: BehaviorSubject<T> = new BehaviorSubject<T>(null);

	@ViewChild('search') searchInput: ElementRef<HTMLInputElement>;
	@ViewChild('list') list: {
		_element: ElementRef<HTMLDivElement>;
	};

	filteredItems$: Observable<T[]> | null = null;

	Searching = false;
	private search$: Subject<string> = new Subject<string>();

	private subs: Subscription[] = [];

	constructor() {}

	ngOnInit(): void {
		if (typeof this.routerLinkBase === 'string' && this.routerLinkBase.includes('/')) {
			this.routerLinkBase = this.routerLinkBase.split('/');
		}
		this.filteredItems$ = this.search$.pipe(
			tap((query) => {
				this.Searching = true;
			}),
			debounce(() => interval(300)),
			map((query) => {
				if (!query || !this.items) {
					return this.items ?? [];
				}
				return this.items.filter((i) =>
					// check against comma separated search fields
					this.searchField.split(',').some((field) => {
						const value = i[field];
						if (typeof value === 'string') {
							return value.toLowerCase().includes(query.toLowerCase());
						}
						return false;
					})
				);
			}),
			map((items) => {
				return this.sortField
					? items
							.slice()
							.sort((a, b) =>
								this.sortDir === 'asc'
									? +a[this.sortField] - +b[this.sortField]
									: +b[this.sortField] - +a[this.sortField]
							)
					: items;
			}),
			tap(() => (this.Searching = false))
		);
	}

	createRouterLink(links: any[]) {
		return links.flat();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.items) {
			if (this.searchInput) {
				this.search$.next(this.searchInput.nativeElement.value);
			} else {
				this.search$.next(null);
			}
		}
	}

	ngAfterViewInit(): void {
		this.search$.next(null);
	}

	onSearchChange(query: string) {
		this.search$.next(query);
	}

	isSelected(items: anyWithId[], item: anyWithId) {
		return items.some((i) => i.id === item.id);
	}

	trackByFn(item: any) {
		return item;
	}

	getLabel(item: T) {
		if (typeof this.labelField === 'function') {
			return this.labelField(item);
		}
		return item[this.labelField];
	}

	ngOnDestroy(): void {
		this.subs.map((s) => s.unsubscribe());
	}

	getBadgeValue(item: T) {
		const val = this.badges[`${item[this.idField]}`];
		return val && val > 0 ? val.toString() : '';
	}

	onSelectionChange(event: MatSelectionListChange) {
		if (event.options.length > 0) {
			this.selectedItem.next(event.options[0].value);
			setTimeout(() => {
				this.list._element.nativeElement.childNodes.forEach((el) => {
					el.dispatchEvent(new Event('blur'));
				});
			});
		}
	}
}
