import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPo from '../reducers/project-office.reducer';
import { State } from '../reducers';

export const getProjectOffice = createFeatureSelector<State, fromPo.State>('projectOffice');

export const getProjectList = createSelector(getProjectOffice, (state) => state.list);

export const getProjectDetail = (id) =>
	createSelector(getProjectOffice, (state) => state.list?.find((p) => p.project_id === id));

export const getWorksteps = createSelector(getProjectOffice, (state) => state.worksteps);

export const getWorkstepTypes = createSelector(getProjectOffice, (state) => state.workstepTypes);

export const getProjectStates = createSelector(getProjectOffice, (state) => state.projectStates);

export const getSearchQuery = createSelector(getProjectOffice, (state) => state.searchQuery);

export const getFilterStatus = createSelector(getProjectOffice, (state) => state.filterStatus);

export const getEmployeeRelations = createSelector(getProjectOffice, (state) => state.employeeRelations);
