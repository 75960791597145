import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import PlaningConfig from '../interfaces/PlaningConfig';
import PersonalAccountsConfig from '../interfaces/PersonalAccountsConfig';
import CameraConfig from '../interfaces/CameraConfig';

@Injectable()
export class AppConfigService {
	planingConfigSubject$ = new BehaviorSubject<PlaningConfig>(null);
	planingConfig$ = this.planingConfigSubject$.asObservable();

	personalAccountsConfigSubject$ = new BehaviorSubject<PersonalAccountsConfig>(null);
	personalAccountsConfig$ = this.personalAccountsConfigSubject$.asObservable();

    cameraConfigSubject$ = new BehaviorSubject<CameraConfig>(null);

    cameraConfig$ = this.cameraConfigSubject$.asObservable();

	$barcodeState = new BehaviorSubject<number>(0);

	config = {
		name: 'TXportal',
		logoLight: 'assets/img/logo-white.png',
		logoDark: 'assets/img/logo.png',
		TimeIXServerUrl: window.document.getElementsByName('txapi')[0].getAttribute('url'),
		TimeIXReportServerUrl: window.document.getElementsByName('txreportapi')[0].getAttribute('url'),
		host: window.document.location.protocol + '//' + window.location.host,
		browser: '',
		browserVersion: '',
		os: '',
		osVersion: '',
		device: '',
		userAgent: '',
	};

	constructor(private deviceDetector: DeviceDetectorService) {
		this.$barcodeState.next(
			localStorage.getItem('txBarcodeState') ? Number(localStorage.getItem('txBarcodeState')) : 0
		);
		const deviceInfo = deviceDetector.getDeviceInfo();
		this.config.browser = deviceInfo.browser;
		this.config.browserVersion = deviceInfo.browser_version;
		this.config.device = deviceInfo.device;
		this.config.os = deviceInfo.os;
		this.config.osVersion = deviceInfo.os_version;
		this.config.userAgent = deviceInfo.userAgent;

		this.getPlaningConfigFromStorage();
		this.getPersonalAccountsFromStorage();
        this.getCameraConfigFromStorage();
	}

	private getPlaningConfigFromStorage() {
		this.planingConfigSubject$.next(PlaningConfig.parse(JSON.parse(localStorage.getItem('txPlaningConfig')) ?? {}));
	}

	public setPlaningConfig(config: PlaningConfig) {
		this.planingConfigSubject$.next(config);
		localStorage.setItem('txPlaningConfig', JSON.stringify(config));
	}

	private getPersonalAccountsFromStorage() {
		this.personalAccountsConfigSubject$.next(
			PersonalAccountsConfig.parse(JSON.parse(localStorage.getItem('txPersonalAccountsConfig')) ?? {})
		);
	}

	public setPersonalAccountsConfig(config: PersonalAccountsConfig) {
		this.personalAccountsConfigSubject$.next(config);
		localStorage.setItem('txPersonalAccountsConfig', JSON.stringify(config));
	}

    public setCameraConfig(config: CameraConfig) {
        this.cameraConfigSubject$.next(config);
        localStorage.setItem('txCameraConfig', JSON.stringify(config));
    }

	getConfig() {
		return this.config;
	}

	getBarcodeStatus() {
		return this.$barcodeState.asObservable();
	}

	setBarcodeStatus(status: number) {
		localStorage.setItem('txBarcodeState', String(status));
		this.$barcodeState.next(status);
	}

    private getCameraConfigFromStorage() {
        this.cameraConfigSubject$.next(CameraConfig.parse(JSON.parse(localStorage.getItem('txCameraConfig')) ?? {}));
    }
}
