import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objToArr',
	pure: false,
})
export class ObjToArrPipe implements PipeTransform {
	transform(object: any) {
		const newArray = [];
		for (const key of object) {
			newArray.push(object[key]);
		}
		return newArray;
	}
}
