import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { InfocenterProtokoll } from '../interfaces/protokollInfocenter';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { ConnectionStateService } from './connection-state.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class InfocenterService {
	theLatestInfocenterProtokoll: InfocenterProtokoll;
	myInfocenter = new BehaviorSubject<InfocenterProtokoll>(null); // Observer und etwas mehr für intern

	constructor(
		private myTxApiService: TxApiService,
		private myAuthService: AuthService,
		private myConnectionStateService: ConnectionStateService
	) {}

	catchInfoCenterFromServer() {
		if (!this.myTxApiService.isLoggedIn()) {
			return;
		}
		if (!this.myConnectionStateService.isOnlineSubject.getValue()) {
			return;
		}
		// check if we are offline
		let myRequest: Observable<InfocenterProtokoll | null>;
		myRequest = this.myAuthService.session$.pipe(
			switchMap((session) => {
				if (session?.reset_pw === 0) {
					return this.myTxApiService.callAPI('getInfocenter', {});
				}
				return of(null);
			}),
			take(1)
		);
		myRequest.subscribe(
			(myRequestData) => {
				if (!myRequestData || myRequestData.statuscode === 4) {
					return;
				}
				// return if session is outdatet.
				this.theLatestInfocenterProtokoll = new InfocenterProtokoll(
					myRequestData.statuscode,
					myRequestData.statustext,
					myRequestData.Infocenters
				);
				this.myInfocenter.next(this.theLatestInfocenterProtokoll);
			},
			(error) => {
				console.log('ERROR' + error);
			}
		);
	}

	getInfoCenter(): Observable<InfocenterProtokoll> {
		return this.myInfocenter.asObservable();
	}
}
