import { createAction } from '@ngrx/store';
import { EmployeeEntity } from '../entities/EmployeeEntity';
import { EmployeeDetailResponse } from '../entities/Api/EmployeeDetail.response';
import { TimemodelEntity } from '../entities/TimemodelEntity';
import { TypeEntity } from '../entities/TypeEntity';
import { TimeModelDetailEntity } from '../entities/TimeModelDetailEntity';
import { ListOfUserPermissionResponse } from '../entities/Api/ListOfUserPermissionResponse';
import { TeamLeaderEntity } from '../entities/TeamLeaderEntity';
import { UserPermissionEntity } from '../entities/UserPermissionEntity';
import { ListOfTeamleaderAssignmentResponse } from '../entities/Api/ListOfTeamleaderAssignmentResponse';
import { ListOfEmployeeHolidayAccountResponse } from '../entities/Api/ListOfEmployeeHolidayAccount.response';
import { TeamEntity } from '../entities/TeamEntity';
import { EmployeeTeamleaderAssingmentEntity } from '../entities/EmployeeTeamleaderAssingmentEntity';

export type ListTypes = 'EmployeeType' | 'SalaryType' | 'EmploymentType';

export const getEmployees = createAction('[Personal Office] get users');

export const setEmployees = createAction('[Personal Office] set users', (employees: EmployeeEntity[]) => ({
	employees,
}));

export const LoadEmployeeDataRequest = createAction(
	'[Personal Office] load employee data request',
	(employee: EmployeeEntity) => ({ employee })
);

export const LoadEmployeeData = createAction(
	'[Personal Office] load employee data',
	(
		employee: EmployeeDetailResponse,
		permissions: ListOfUserPermissionResponse[],
		assingments: ListOfTeamleaderAssignmentResponse[],
		holidayAccounts: ListOfEmployeeHolidayAccountResponse[]
	) => ({ employee, permissions, assingments, holidayAccounts })
);

export const ArchiveEmployee = createAction('[Personal Office] archive employee', (employee: EmployeeEntity) => ({
	employee,
}));

export const ChangeEmployeeDataRequest = createAction(
	'[Personal Office] change employee data request',
	(employee: EmployeeEntity, timeModelChangeDate?: Date) => ({ employee, timeModelChangeDate })
);

export const ChangeEmployeeData = createAction(
	'[Personal Office] change employee data',
	(employee: EmployeeDetailResponse) => ({ employee })
);

export const ChangeEmployeeDataFailure = createAction(
	'[Personal Office] change employee data failure',
	(text: string) => ({ text })
);

export const CreateEmployeeDataRequest = createAction(
	'[Personal Office] create employee request',
	(employee: EmployeeEntity) => ({ employee })
);

export const CreateEmployeeData = createAction(
	'[Personal Office] create employee',
	(employee: EmployeeDetailResponse) => ({ employee })
);

export const CreateEmployeeDataFailure = createAction('[Personal Office] create employee failure', (text: string) => ({
	text,
}));

export const LoadTimemodelsRequest = createAction('[Personal Office] load timemodels request', (name?: string) => ({
	name,
}));

export const LoadTimemodels = createAction('[Personal Office] load timemodels', (timemodels: TimemodelEntity[]) => ({
	timemodels,
}));

export const LoadTypeListRequest = createAction('[Personal Office] load type list request', (listType: ListTypes) => ({
	listType,
}));

export const LoadTypeList = createAction(
	'[Personal Office] load type list',
	(listType: ListTypes, entities: TypeEntity[]) => ({ listType, entities })
);

export const LoadTimemodelDetailRequest = createAction(
	'[Personal Office] load timemodel detail request',
	(id: number) => ({ id })
);

export const LoadTimemodelDetail = createAction(
	'[Personal Office] load timemodel detail',
	(timemodeldetail: TimeModelDetailEntity[]) => ({ timemodeldetail })
);

export const LoadTeamleaderListRequest = createAction('[Personal Office] load teamleader list request');

export const LoadTeamleaderList = createAction(
	'[Personal Office] load teamleader list',
	(teamleaders: TeamLeaderEntity[]) => ({ teamleaders })
);

export const FetchTeamleaderEmployeeAssignmentsRequest = createAction(
	'[Personal Office] fetch teamleader employee assignments request',
	(id: number) => ({ id })
);

export const SetTeamleaderEmployeeAssignment = createAction(
	'[Personal Office] set teamleader employee assignments request',
	(id: number, assignments: EmployeeTeamleaderAssingmentEntity[]) => ({ id, assignments })
);

export const FetchTeamleaderEmployeeAssignments = createAction(
	`[Personal Office] fetch teamleader employee assignments`,
	(id: number, assignments: ListOfTeamleaderAssignmentResponse[]) => ({ id, assignments })
);

export const UpdateUserPermission = createAction(
	'[Personal Office] update user permission',
	(permission: UserPermissionEntity, employee_id: number) => ({ permission, employee_id })
);

export const UpdateUserPermissions = createAction(
	'[Personal Office] update user permissions',
	(permissions: UserPermissionEntity[], employee_id: number) => ({
		permissions,
		employee_id,
	})
);

export const FetchTeams = createAction('[Personal Office] fetch teams');
export const LoadTeams = createAction('[Personal Office] load teams', (teams: TeamEntity[]) => ({ teams }));

export const UpdateTeam = createAction('[Personal Office] update team', (team: TeamEntity) => ({ team }));

export const DeleteTeam = createAction('[Personal Office] delete team', (team: TeamEntity) => ({ team }));
