<div class="grid">
	<div class="col-12 xl:col-3">
		<mat-card class="mat-elevation-z0">
			<mat-card-title>
                <div class="display-flex flex-justify-content-space-between">
                    <div>Zeitmodelle</div>
                    <div>
                        <button mat-mini-fab class="mr-2" (click)="onNewClick($event)">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                    </div>
                </div>
            </mat-card-title>
            <mat-card-content>
                <tx-list
                    *ngIf="Timemodels$ | async as Timemodels"
                    maxHeight="90vh"
                    [items]="Timemodels"
                    [labelField]="'model_name'"
                    [sortField]="'model_id'"
                    [idField]="'model_id'"
                    [searchField]="'model_name'"
                    [showNew]="false"
                    routerLinkBase="personaloffice/zeitmodelle"
                ></tx-list>
            </mat-card-content>
		</mat-card>
	</div>
	<div class="relative col-12 xl:col-9" *ngIf="SelectedTimemodel$ | async as SelectedTimemodel" [@fadeIn]="'in'">
		<section class="widget">
			<form
				*ngIf="!isLoading"
				[formGroup]="TimemodelForm"
				(ngSubmit)="onSubmit(SelectedTimemodel)"
				class="flex-content">
				<div class="padding-all-1">
					<div class="display-flex flex-ml-1">
						<mat-form-field class="flex-grow-1" appearance="outline">
							<mat-label>Bezeichnung</mat-label>
							<input formControlName="name" matInput />
						</mat-form-field>
                        <mat-form-field *ngIf="CalendarGroups$ | async as CalendarGroups" appearance="outline">
                            <mat-label>Feiertags-Gruppe</mat-label>
                            <mat-select formControlName="calendar_group">
                                <mat-option *ngFor="let group of CalendarGroups" [value]="group.number">{{ group.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Typ</mat-label>
							<mat-select formControlName="type">
								<mat-option [value]="0">Fest/Gleitzeit</mat-option>
								<mat-option [value]="1">Schicht</mat-option>
								<mat-option [value]="2">Vertrauensarbeit</mat-option>
							</mat-select>
						</mat-form-field>
                        <div>
                            <button
                                    mat-mini-fab
                                    type="button"
                                    [matMenuTriggerFor]="moreSettings"
                                    style="margin-top: 10px"
                            >
                                <fa-icon icon="ellipsis-v"></fa-icon>
                            </button>

                            <mat-menu #moreSettings="matMenu">
                                <div
                                        txConfirmClick
                                        title="Archivieren"
                                        [confirmText]="'Wirklich \'' + SelectedTimemodel.name + '\' archivieren?'"
                                        [buttonTexts]="{ cancel: translate.instant('general.no') + '', approve: translate.instant('general.yes') + '' }"
                                        (confirmClick)="deleteTimemodel(SelectedTimemodel)"
                                        mat-menu-item
                                >
                                    {{ 'general.archive' | translate }}
                                </div>
                            </mat-menu>
                        </div>
					</div>
					<div>
						<mat-table [dataSource]="ListOfModelFrame | sort: 'id'">
							<ng-container matColumnDef="id">
								<mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
								<mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
							</ng-container>

							<ng-container matColumnDef="weekday">
								<mat-header-cell *matHeaderCellDef>Tag</mat-header-cell>
								<mat-cell *matCellDef="let row"> {{ row.weekday | Moment: 'weekdays' }} </mat-cell>
							</ng-container>

							<ng-container matColumnDef="comeAndGo">
								<mat-header-cell *matHeaderCellDef>Kommen - Gehen</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<div class="display-flex flex-align-items-center">
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.frame_begin | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'frame_begin', $event, true)"
												matInput />
										</mat-form-field>
										<span class="mr-1 ml-1">-</span>
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.frame_end | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'frame_end', $event, true)"
												matInput />
										</mat-form-field>
									</div>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="should">
								<mat-header-cell *matHeaderCellDef>Soll</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<mat-form-field style="width: 65px">
										<input
											type="time"
											[value]="row.plan_time | Moment: 'formatTime'"
											(change)="onChangeFrameTime(SelectedTimemodel, row, 'plan_time', $event)"
											matInput />
									</mat-form-field>
									<!--{{ row.plan_time | Moment:'formatTime' }}-->
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="core1">
								<mat-header-cell *matHeaderCellDef>Kern1</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<div class="display-flex flex-align-items-center">
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.coretime1_begin | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'coretime1_begin', $event, true)"
												matInput />
										</mat-form-field>
										<span class="mr-1 ml-1">-</span>
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.coretime1_end | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'coretime1_end', $event, true)"
												matInput />
										</mat-form-field>
									</div>
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="core2">
								<mat-header-cell *matHeaderCellDef>Kern2</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<div class="display-flex flex-align-items-center">
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.coretime2_begin | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'coretime2_begin', $event, true)"
												matInput />
										</mat-form-field>
										<span class="mr-1 ml-1">-</span>
										<mat-form-field style="width: 65px">
											<input
												type="time"
												[value]="row.coretime2_end | Moment: 'formatTime'"
												(change)="onChangeFrameTime(SelectedTimemodel, row, 'coretime2_end', $event, true)"
												matInput />
										</mat-form-field>
									</div>
								</mat-cell>
							</ng-container>

                            <ng-container matColumnDef="clearAction">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <div class="action-buttons">
                                        <button mat-icon-button type="button" (click)="clearRow(row)">
                                            <fa-icon icon="broom"></fa-icon>
                                        </button>
                                    </div>
                                </mat-cell>
                            </ng-container>

							<mat-header-row *matHeaderRowDef="['weekday', 'comeAndGo', 'should', 'core1', 'core2', 'clearAction']"></mat-header-row>
							<mat-row *matRowDef="let row; columns: ['weekday', 'comeAndGo', 'should', 'core1', 'core2', 'clearAction']"></mat-row>
						</mat-table>
						<!--<mat-form-field></mat-form-field>-->
					</div>
					<div class="mt-2">
						<div class="flex-ml-1">
							<mat-form-field appearance="outline">
								<mat-label>Prüfung</mat-label>
								<mat-select
									[(ngModel)]="breakCheckValue"
									[ngModelOptions]="{ standalone: true }"
									(selectionChange)="changeCheckedSelection(SelectedTimemodel, $event)">
									<mat-option [value]="'00'">Keine</mat-option>
									<mat-option [value]="'10'">nur Kommen</mat-option>
									<mat-option [value]="'01'">nur Gehen</mat-option>
									<mat-option [value]="'11'">Kommen und Gehen</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Tagesrundung</mat-label>
								<mat-select formControlName="round_day">
									<mat-option [value]="0">Keine Rundung</mat-option>
									<mat-option [value]="1">5 minuten</mat-option>
									<mat-option [value]="2">10 minuten</mat-option>
									<mat-option [value]="3">15 minuten</mat-option>
									<mat-option [value]="4">30 minuten</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<mat-card class="mat-elevation-z0">
						<mat-card-title>Pausen</mat-card-title>
						<mat-card-content>
							<div class="flex flex-col gap-2">
								<mat-checkbox
									color="primary"
									formControlName="break_min_val"
									(change)="onChangeCheckbox(SelectedTimemodel, 'break_min_val', $event)">
                                    Mindestpausen Wert berücksichtigen
								</mat-checkbox>

                                <mat-checkbox
                                        color="primary"
                                        formControlName="break_treshold_under"
                                        (change)="onChangeCheckbox(SelectedTimemodel, 'break_treshold_under', $event)">
                                    Wert Unterschreitung erlauben
                                </mat-checkbox>
							</div>
							<div>
								<!--
                                break_begin_plan: ""
                                break_end_plan: ""
                                break_treshold_under: 0
                                break_treshold_val: 0
                                break_val: 0.5
                                id: 0
                                -->
								<mat-table
									*ngIf="ListOfModelBreak"
									[dataSource]="ListOfModelBreak.filter(filterModelBreaks) | sort: 'id'"
									class="model-break-table"
                                >
									<ng-container matColumnDef="id">
										<mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
										<mat-cell *matCellDef="let row"> {{ row.id }} </mat-cell>
									</ng-container>

									<ng-container matColumnDef="value">
										<mat-header-cell *matHeaderCellDef>Wert</mat-header-cell>
										<mat-cell *matCellDef="let row">
											<mat-form-field style="width: 65px">
												<input
													type="time"
													[value]="row.break_val | Moment: 'formatTime'"
													(change)="onChangeBreakTime(SelectedTimemodel, row, 'break_val', $event)"
													matInput />
											</mat-form-field>
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="treshold">
										<mat-header-cell *matHeaderCellDef>Schwelle</mat-header-cell>
										<mat-cell *matCellDef="let row">
											<mat-form-field style="width: 65px">
												<input
													type="time"
													[value]="row.break_treshold_val | Moment: 'formatTime'"
													(change)="onChangeBreakTime(SelectedTimemodel, row, 'break_treshold_val', $event)"
													matInput />
											</mat-form-field>
										</mat-cell>
									</ng-container>

<!--									<ng-container matColumnDef="treshold_under">-->
<!--										<mat-header-cell *matHeaderCellDef>Unterschreitung erlauben</mat-header-cell>-->
<!--										<mat-cell *matCellDef="let row">-->
<!--											<mat-checkbox-->
<!--												color="primary"-->
<!--												[checked]="row.break_treshold_under === 1"-->
<!--												(change)="-->
<!--													onChangeModelBreakCheckbox(SelectedTimemodel, row, 'break_treshold_under', $event)-->
<!--												"></mat-checkbox>-->
<!--										</mat-cell>-->
<!--									</ng-container>-->

									<ng-container matColumnDef="range">
										<mat-header-cell *matHeaderCellDef>Pausenkernzeit</mat-header-cell>
										<mat-cell *matCellDef="let row">
											<div class="display-flex flex-align-items-center">
												<mat-form-field style="width: 65px">
													<input
														type="time"
														[value]="row.break_begin_plan | Moment: 'formatTime'"
														(change)="onChangeBreakTime(SelectedTimemodel, row, 'break_begin_plan', $event, true)"
														matInput />
												</mat-form-field>
												<span class="mr-1 ml-1">-</span>
												<mat-form-field style="width: 65px">
													<input
														type="time"
														[value]="row.break_end_plan | Moment: 'formatTime'"
														(change)="onChangeBreakTime(SelectedTimemodel, row, 'break_end_plan', $event, true)"
														matInput />
												</mat-form-field>
											</div>
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="actions">
										<mat-header-cell *matHeaderCellDef>
                                            <button
                                                    mat-mini-fab
                                                    (click)="addModelBreak(SelectedTimemodel)"
                                            >
                                                <fa-icon icon="plus"></fa-icon>
                                            </button>
                                        </mat-header-cell>
										<mat-cell *matCellDef="let row">
											<div class="action-buttons">
												<button mat-icon-button color="primary" (click)="deleteBreakModel(SelectedTimemodel, row)">
													<fa-icon icon="times-circle" size="2x"></fa-icon>
												</button>
											</div>
										</mat-cell>
									</ng-container>

									<mat-header-row
										*matHeaderRowDef="['value', 'treshold', 'range', 'actions']"></mat-header-row>
									<mat-row
										*matRowDef="
											let row;
											columns: ['value', 'treshold', 'range', 'actions']
										"></mat-row>
								</mat-table>
							</div>
						</mat-card-content>
					</mat-card>
					<mat-card class="mat-elevation-z0">
						<mat-card-title>Wertung</mat-card-title>
						<mat-card-content>
							<div class="flex-ml-1">
								<mat-form-field>
									<mat-label>Inklusivstunden</mat-label>
									<input
										mask="000:00"
										[showMaskTyped]="true"
										[dropSpecialCharacters]="false"
										type="text"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngModel]="SelectedTimemodel.inclusiv_hours_val | Moment: 'formatTime' | ZeroFill:6"
										matInput
                                        (change)="onChangeTimeValue(SelectedTimemodel, 'inclusiv_hours_val', $event.target)"
                                    />
                                    <button mat-icon-button matSuffix type="button" (click)="setValueToNull(SelectedTimemodel, 'inclusiv_hours_val');">
                                        <fa-icon icon="broom"></fa-icon>
                                    </button>
								</mat-form-field>
								<mat-form-field>
									<mat-select formControlName="inclusiv_type">
										<mat-option [value]="0">Täglich</mat-option>
										<mat-option [value]="1">Wöchentlich</mat-option>
										<mat-option [value]="2">Monatlich</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field>
									<mat-label>Maximalstunden Zeitkonto</mat-label>
									<input
										mask="000:00"
										[showMaskTyped]="true"
										[dropSpecialCharacters]="false"
										type="text"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngModel]="SelectedTimemodel.max_hours_zk_val | Moment: 'formatTime' | ZeroFill:6"
                                        matInput
                                        (change)="onChangeTimeValue(SelectedTimemodel, 'max_hours_zk_val', $event.target)"
                                    />
                                    <button mat-icon-button matSuffix type="button" (click)="setValueToNull(SelectedTimemodel, 'max_hours_zk_val');">
                                        <fa-icon icon="broom"></fa-icon>
                                    </button>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field>
									<mat-label>Maximalstunden täglich</mat-label>
									<input
										mask="000:00"
										[showMaskTyped]="true"
										[dropSpecialCharacters]="false"
										type="text"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngModel]="SelectedTimemodel.max_hours_day_val | Moment: 'formatTime' | ZeroFill:6"
                                        matInput
                                        (change)="onChangeTimeValue(SelectedTimemodel, 'max_hours_day_val', $event.target)"
                                    />
                                    <button mat-icon-button matSuffix type="button" (click)="setValueToNull(SelectedTimemodel, 'max_hours_day_val');">
                                        <fa-icon icon="broom"></fa-icon>
                                    </button>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field>
									<mat-label>Feststd. Monat</mat-label>
									<input
										mask="000:00"
										[showMaskTyped]="true"
										[dropSpecialCharacters]="false"
										type="text"
                                        [ngModelOptions]="{standalone: true}"
                                        [ngModel]="SelectedTimemodel.fix_hours_month_val | Moment: 'formatTime' | ZeroFill:6"
                                        matInput
                                        (change)="onChangeTimeValue(SelectedTimemodel, 'fix_hours_month_val', $event.target)"
                                    />
                                    <button mat-icon-button matSuffix type="button" (click)="setValueToNull(SelectedTimemodel, 'fix_hours_month_val');">
                                        <fa-icon icon="broom"></fa-icon>
                                    </button>
								</mat-form-field>
							</div>
							<div>
								<mat-form-field>
									<mat-label>Kurzpause <i>(in Minuten)</i></mat-label>
									<input type="number" formControlName="short_break" style="padding-right: 1.25rem" matInput />
									<span matSuffix matTooltip="(0 = keine Schwelle)">
										<fa-icon icon="info-circle"></fa-icon>
									</span>
								</mat-form-field>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
				<div class="tab-buttons">
                    <button *ngIf="SelectedTimemodel.model_id === 0"
                        mat-raised-button
                        type="button"
                        (click)="unselectTimemodel()">
                        {{ 'general.abort' | translate }}
                    </button>
					<button
                            *ngIf="SelectedTimemodel.model_id > 0"
						mat-raised-button
						[disabled]="!TimemodelForm.dirty"
						type="button"
						(click)="patchForm(SelectedTimemodel, true)">
                        {{ 'general.abort' | translate }}
					</button>
					<div>
						<button mat-raised-button color="warn" [disabled]="!TimemodelForm.dirty" type="submit">Speichern</button>
					</div>
				</div>
			</form>
			<div class="display-flex flex-justify-content-center" *ngIf="isLoading">
				<p-progressSpinner></p-progressSpinner>
			</div>
		</section>
	</div>
</div>
