<div *ngIf="myFormType == 'Buchung'">
	<div class="widget-body">
		<form #buchungForm="ngForm" [formGroup]="buchungsForm" (ngSubmit)="createWorkflow(); updateWorkflow()">
			<div>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.status' | translate }}</mat-label>
					<mat-select formControlName="currentTypeNeuItems" (selectionChange)="selectStatus($event)" required>
						<mat-option *ngFor="let item of TypeNeuItems" [value]="item.value">
							{{ item.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-100" (click)="picker.open()">
					<mat-label>{{ 'txApplyBooking.date' | translate }}</mat-label>
					<input matInput formControlName="vonBuchungDatumField" [matDatepicker]="picker" readonly required />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
				<ng-container *ngIf="viewClockPicker">
					<wl-clockpicker
						id="buchung-uhrzeit-input-material"
						formControlName="vonBuchungZeitField"
						[placeholder]="'txApplyBooking.time' | translate"
						default="now"
						donetext="Fertig"
						name="timeFormMaterial"
						[autoclose]="true"
						[required]="true"
						[material]="true">
					</wl-clockpicker>
				</ng-container>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.notice' | translate }}</mat-label>
					<input matInput formControlName="Notiz" autocomplete="off" />
				</mat-form-field>
			</div>
			<button class="w-100" color="primary" mat-raised-button type="submit" [disabled]="buchungsForm.invalid">
				{{ 'txApplyBooking.requestBooking' | translate }}
			</button>
			<div
				*ngIf="buchungsForm.invalid && buchungForm.submitted"
				class="alert bg-orange-300 text-white text-center font-bold alert-sm"
				style="margin-top: 20px">
				Bitte alle Pflichtfelder ausfüllen!
			</div>
		</form>
	</div>
</div>
<div *ngIf="myFormType == 'Antrag'">
	<div class="widget-body">
		<form
			#antragForm="ngForm"
			[formGroup]="antragsForm"
			(ngSubmit)="createWorkflow('FZB'); updateWorkflow()"
			autocomplete="off">
			<div>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.missingTime' | translate }}</mat-label>
					<mat-select formControlName="currentListOfWorkflowTyp" placeholder="Kein Antragstyp ausgewählt" required>
						<mat-option *ngFor="let item of myListOfWorkflowTyp" [value]="item.value">{{ item.label }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.dateFrom' | translate }} - {{ 'txApplyBooking.until' | translate }}</mat-label>
					<mat-date-range-input [rangePicker]="fromToPicker">
						<input
							matStartDate
							formControlName="vonAntragField"
							[placeholder]="'txApplyBooking.from' | translate"
							(click)="fromToPicker.open()"
							required
							readonly />
						<input
							matEndDate
							formControlName="bisAntragField"
							[placeholder]="'txApplyBooking.until' | translate"
							(click)="fromToPicker.open()"
							required
							readonly />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="fromToPicker"> </mat-datepicker-toggle>
					<mat-date-range-picker #fromToPicker [disabled]="false"></mat-date-range-picker>
				</mat-form-field>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.note' | translate }}</mat-label>
					<input matInput formControlName="Notiz" />
				</mat-form-field>
				<button mat-raised-button color="primary" class="w-100" type="submit" [disabled]="antragForm.invalid">
					{{ 'txApplyBooking.makeRequest' | translate }}
				</button>
				<div
					*ngIf="antragForm.invalid && antragForm.submitted"
					class="alert bg-orange-300 text-white text-center font-bold alert-sm"
					style="margin-top: 20px">
					Bitte alle Pflichtfelder ausfüllen!
				</div>
			</div>
		</form>
	</div>
</div>
