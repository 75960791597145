import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListOfStatesResponse } from '../../store/entities/Api/ListOfStatesResponse';
import { StateEntity } from '../../store/entities/StateEntity';
import { ApiResponseType } from '../../store/entities/Api/ApiResponse';

@Injectable()
export class UserStatusService {
	constructor(private api: TxApiService) {}

	public getUserStatus(): Observable<ListOfStatesResponse[]> {
		return this.api.callAPI('getStates', {}).pipe(
			map((ret) => {
				return ret.ListOfStates?.map((l) => StateEntity.tryCast(l));
			})
		);
	}

	public setUserState(state_id: number): Observable<ApiResponseType> {
		return this.api.callAPI('setEmployeeState', {
			state_id,
		});
	}
}
