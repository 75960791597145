<div *ngIf="CurrentUser$ | async as currentUser">
    <div *ngIf="Messages$ | async as messages">
        <tx-chat #chat
                 [height]="height"
                 [Messages]="messages"
                 (refresh)="refresh()"
                 [(WithArchived)]="WithArchived"
                 [(AnswerMessage)]="AnswerMessage"
                 (AnswerMessageChange)="checkForFullHeight()"
                 (archive)="archiveMessage($event.message, $event.remove)"
                 (send)="sendMessage($event.message, $event.answer)"
        ></tx-chat>
    </div>
</div>
