<div class="container">
	<main class="error-container" id="content" role="main">
		<div class="grid">
			<div class="col-10 md:col-6 xl:col-4 col-offset-1 md:col-offset-3 xl:col-offset-4">
				<div class="error-container">
					<h1 class="error-code">404</h1>
					<p class="error-info">Opps, it seems that this page does not exist.</p>
					<p class="error-help mb">If you are sure it should, search for it.</p>
					<form (ngSubmit)="searchResult()" method="get">
						<div class="form-group">
							<input class="form-control input-no-border" placeholder="Search Pages" type="text" />
						</div>
						<button class="btn btn-inverse" type="submit">
							Search <i class="fa fa-search text-warning ml-xs"></i>
						</button>
					</form>
				</div>
			</div>
		</div>
	</main>

	<footer class="page-footer text-center">2016 &copy; Sing. Admin Dashboard Template.</footer>
</div>
