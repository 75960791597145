<div class="grid">
	<div class="col-12">
		<mat-card class="widget flex-grow-1 mat-elevation-z0">
			<mat-card-header>
				<mat-card-title class="full-width display-flex flex-justify-content-space-between flex-align-items-center">
					<span>{{ 'txProjectOffice.assignments' | translate }}</span>
					<div class="buttons">
						<button mat-raised-button routerLink="0" class="mr-1">
							<fa-icon icon="plus-circle"></fa-icon>
							Neuer Auftrag
						</button>
						<button mat-mini-fab color="primary" (click)="onOpenWorkstepsDialog()">
							<fa-icon icon="cog"></fa-icon>
						</button>
					</div>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="display-flex">
					<mat-form-field class="flex-grow-1 mr-1" appearance="outline">
						<mat-label>{{ 'txProjectOffice.search' | translate }}</mat-label>
						<input #searchInput matInput [value]="SearchQuery" (keyup)="onSearch(searchInput.value)" />
						<button *ngIf="SearchQuery" matSuffix mat-icon-button (click)="SearchQuery = ''; onSearch(SearchQuery)">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>{{ 'txProjectOffice.status_filter' | translate }}</mat-label>
						<mat-select [value]="StatusFilter" (valueChange)="onFilterList($event)">
							<mat-option *ngFor="let state of ProjectStates" [value]="state.value">
								{{ state.description }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<mat-table matSort class="projects-table" [dataSource]="ProjectsDataSource" (matSortChange)="onSort($event)">
					<ng-container matColumnDef="project_number">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{ 'txProjectOffice.number' | translate }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<a [routerLink]="row.project_id">
								{{ row.project_number }}
							</a>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="project_name">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{ 'txProjectOffice.description_alt' | translate }}</mat-header-cell>
						<mat-cell *matCellDef="let row">{{ row.project_name }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="creation_date">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{ 'txProjectOffice.start' | translate }}</mat-header-cell>
						<mat-cell *matCellDef="let row">{{ row.creation_date | date: 'dd.MM.yyyy' }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="project_state">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{ 'txProjectOffice.status' | translate }}</mat-header-cell>
						<mat-cell *matCellDef="let row">{{ getProjectState(row.project_state) }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="target_date">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold">{{ 'txProjectOffice.end_planed' | translate }}</mat-header-cell>
						<mat-cell *matCellDef="let row">{{ row.target_date | date: 'dd.MM.yyyy' }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="target_time">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold flex-justify-content-flex-end"
							>{{ 'txProjectOffice.should_time' | translate }}</mat-header-cell
						>
						<mat-cell class="flex-justify-content-flex-end" *matCellDef="let row">{{ row.target_time }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="calculated_time">
						<mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold flex-justify-content-flex-end"
							>{{ 'txProjectOffice.is_time' | translate }}</mat-header-cell
						>
						<mat-cell class="flex-justify-content-flex-end" *matCellDef="let row">{{ row.calculated_time }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="actions">
						<mat-header-cell *matHeaderCellDef></mat-header-cell>
						<mat-cell *matCellDef="let row">
							<div>
								<button mat-icon-button [matMenuTriggerFor]="menu">
									<fa-icon icon="ellipsis-v"></fa-icon>
								</button>
								<mat-menu #menu="matMenu">
									<div
										mat-menu-item
										class="no-margin"
										[matTooltipDisabled]="row.calculated_time === '00:00'"
										matTooltip="{{ 'txProjectOffice.assignment_has_booked_time' | translate }}">
										<button
											mat-icon-button
											type="button"
											(click)="deleteProject(row.project_id)"
											[disabled]="row.calculated_time !== '00:00'">
											<fa-icon icon="minus-circle"></fa-icon>
											<span class="text-danger"> {{ 'general.delete' | translate }}</span>
										</button>
									</div>
								</mat-menu>
							</div>
						</mat-cell>
					</ng-container>

					<div class="flex justify-content-center" *matNoDataRow>
						<span class="p-2">
							<mat-spinner [diameter]="50"></mat-spinner>
						</span>
					</div>

					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
				</mat-table>
			</mat-card-content>
		</mat-card>
	</div>
</div>
