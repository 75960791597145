import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
import moment from 'moment';

export const MASKED_INPUT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MaskedInputDirective),
	multi: true,
};

@Directive({
	selector: '[maskedInput]',
	providers: [MASKED_INPUT_VALUE_ACCESSOR],
})
export class MaskedInputDirective implements ControlValueAccessor, OnInit {
	onChange = (a: any) => {};
	onTouched = () => {};

	constructor(private renderer: Renderer2, private element: ElementRef, private maskService: NgxMaskService) {}

	ngOnInit() {
		/*const element = this.element.nativeElement;
        this.onChange(this.maskService.applyMask(element.value, this.mask));*/
        console.log(this.element);
	}

	@HostListener('input', ['$event.target.value'])
	input(value) {
		this.onChange(this.maskService.applyMask(value, this.maskService.maskExpression));
	}

	writeValue(value: any): void {
		const element = this.element.nativeElement;
		this.renderer.setProperty(element, 'value', moment.duration(element.value).asHours());
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
