import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FetchCalendar, FetchPlans, SetCalendar, SetPlans } from '../actions/plans.actions';
import { switchMap } from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../entities/Api/ApiResponse';
import { PlanEntity } from '../entities/PlanEntity';
import { PlanCalendarEntity } from '../entities/PlanCalendarEntity';

@Injectable({
	providedIn: 'root',
})
export class PlansEffects {
	getPlans$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchPlans),
			switchMap(() => this.api.callAPI('getPlanList')),
			map((res: ApiResponse<PlanEntity[]>) => {
				return SetPlans(res.ListOfPlan?.map((p) => PlanEntity.tryCast(p)));
			})
		)
	);

	getCalendar$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchCalendar),
			switchMap(({ start, end, teamleader }) =>
				this.api.callAPI('getPlanCalendar', {
					begin_date: start,
					end_date: end,
					team_leader: teamleader ? 1 : 0,
				})
			),
			map((res: ApiResponse<PlanCalendarEntity[]>) => {
				return SetCalendar(res.ListOfPlanCalendar.map((o) => PlanCalendarEntity.tryCast(o)));
			})
		)
	);

	constructor(private readonly actions$: Actions, private readonly api: TxApiService) {}
}
