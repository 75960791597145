import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConnectionStateService } from './shared/services/connection-state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { verifySession } from './store/actions/user.actions';
import { TxSession } from './shared/interfaces/session';
import { Store } from '@ngrx/store';
import { State } from './store/reducers';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { FetchReports } from './store/actions/reports.actions';

declare var Messenger: any;

@Component({
	selector: 'tx-root',
	encapsulation: ViewEncapsulation.None,
	template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
	constructor(
		private connection: ConnectionStateService,
		private deviceDetection: DeviceDetectorService,
		private translationService: TranslateService,
		private authService: AuthService,
		private router: Router,
		private store: Store<State>
	) {
		translationService.setDefaultLang('de');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translationService.use('de');
		translationService.get('txApplyBooking.status').subscribe();
	}

	ngOnInit(): void {
		if (localStorage.getItem('currentSession') != null) {
			try {
				// this.session = JSON.parse(localStorage.getItem('currentSession'));
				this.authService.redirectUrl = this.router.routerState.snapshot.url;
				this.store.dispatch(verifySession(JSON.parse(localStorage.getItem('currentSession')) as TxSession));
			} catch (e) {
				console.log(e);
			}
		}

		// disable back button on mobile (experimental)
		if (this.deviceDetection.isMobile()) {
			history.pushState(null, null, location.href);
			window.onpopstate = () => {
				history.go(1);
			};
		}

		Messenger.options = {
			extraClasses: 'messenger-fixed messenger-on-top',
			theme: 'flat',
		};
	}
}
