import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxListComponent } from './tx-list/tx-list.component';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TxSharedModule } from '../shared/shared.module';
import { TxProgressbarComponent } from './tx-progressbar/tx-progressbar.component';
import { MatBadgeModule } from '@angular/material/badge';
import { BadgeModule } from 'primeng/badge';

@NgModule({
	declarations: [TxListComponent, TxProgressbarComponent],
	imports: [
		CommonModule,
		ListboxModule,
		FormsModule,
		MatListModule,
		MatInputModule,
		MatIconModule,
		FontAwesomeModule,
		RouterModule,
		MatButtonModule,
		TxSharedModule,
		MatBadgeModule,
		BadgeModule,
	],
	exports: [TxListComponent, TxProgressbarComponent, MatListModule, FontAwesomeModule],
})
export class WidgetsModule {
	constructor(fa: FaIconLibrary) {
		fa.addIconPacks(fas, far);
	}
}
