import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { concat, forkJoin, merge, Observable } from 'rxjs';
import { mergeAll, mergeMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PersonalOfficeService } from '../services/personal-office.service';
import { TypeEntity } from '../../store/entities/TypeEntity';

export interface PoTypeListsResolverType {
	employeeTypes: TypeEntity[];
	employmentTypes: TypeEntity[];
	salaryTypes: TypeEntity[];
}

@Injectable({
	providedIn: 'root',
})
export class PoTypeListsResolver implements Resolve<PoTypeListsResolverType> {
	constructor(private po: PersonalOfficeService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<PoTypeListsResolverType> | Promise<PoTypeListsResolverType> | PoTypeListsResolverType {
		return forkJoin({
			employeeTypes: this.po.getTypeList('EmployeeType').pipe(take(1)),
			employmentTypes: this.po.getTypeList('EmploymentType').pipe(take(1)),
			salaryTypes: this.po.getTypeList('SalaryType').pipe(take(1)),
		});
	}
}
