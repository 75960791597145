import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HeartbeatService implements OnDestroy {
	// the heartbeat ticks in milliseconds
	heartbeatTicks = 1000;

    // if in background, run every 5 minutes, 1 tick = 1 seconds, 60 ticks = 1 minute
    backgroundTicks: number = 60 * 5;

	// the heartbeat observable
	$heartbeat: Subscription;

	// the container for all heartbeat actions
	heartbeatActions: { [key: number]: [() => any, number, string][] } = {};

	isInBackground = false;

	hiddenProperty = 'hidden';

	constructor() {
		const heartbeatInterval$ = interval(this.heartbeatTicks);
		this.$heartbeat = heartbeatInterval$.subscribe((val) => {
			if (typeof val === 'number') {
				this.dispatchHeartbeatFunctions(Number(val) + 1);
			}
		});

		if (!window['_hiddenListenersAttached']) {
			if ('hidden' in document)
				document.addEventListener('visibilitychange', this.onDocumentVisibilityChange.bind(this));
			else if ('mozHidden' in document) {
				this.hiddenProperty = 'mozHidden';
				document.addEventListener('mozvisibilitychange', this.onDocumentVisibilityChange.bind(this));
			} else if ('webkitHidden' in document) {
				this.hiddenProperty = 'webkitHidden';
				document.addEventListener('webkitvisibilitychange', this.onDocumentVisibilityChange.bind(this));
			} else if ('msHidden' in document) {
				this.hiddenProperty = 'msHidden';
				document.addEventListener('msvisibilitychange', this.onDocumentVisibilityChange.bind(this));
			} else {
				window.onpageshow =
					window.onpagehide =
					window.onfocus =
					window.onblur =
						this.onDocumentVisibilityChange.bind(this);
			}

			window['_hiddenListenersAttached'] = true;
		}
	}

	private onDocumentVisibilityChange(evt) {
		const v = 'visible',
			h = 'hidden',
			evtMap = {
				focus: v,
				focusin: v,
				pageshow: v,
				blur: h,
				focusout: h,
				pagehide: h,
			};

		const e = evt || window.event;
		if (e.type in evtMap) document.body.className = evtMap[evt.type];
		else document.body.className = document[this.hiddenProperty] ? 'hidden' : 'visible';

		this.isInBackground = document[this.hiddenProperty];
		if (!this.isInBackground) {
			this.dispatchHeartbeatFunctions(0, true);
		}
	}

	private getCurrentVisibilityState() {
		return this.isInBackground;
	}

	/**
	 * Loops through the heartbeat actions and dispatches them
	 */
	dispatchHeartbeatFunctions(tick, cameBack = false) {
		Object.keys(this.heartbeatActions)
			.sort()
			.forEach(
				function (prio) {
					this.heartbeatActions[prio].forEach(
						function (func) {
							// if in background, run every 30 minutes, 1 tick = 10 seconds, 60 ticks = 1 minute
							if (cameBack || tick % (this.getCurrentVisibilityState() ? this.backgroundTicks : func[1]) === 0) {
								func[0]();
							}
						}.bind(this)
					);
				}.bind(this)
			);
	}

	/**
	 * Adds a function to the heartbeat
	 */
	// tslint:disable-next-line:ban-types
	addToHeartbeat(name, callback: () => any, everyTicks = 10, prio = 10) {
        if (!this.heartbeatActions[prio]) {
			this.heartbeatActions[prio] = [];
		}
		this.heartbeatActions[prio].push([callback, everyTicks, name]);
		callback();
	}

	ngOnDestroy(): void {
		this.heartbeatActions = [];
		this.$heartbeat.unsubscribe();
	}

    removeFromHeartbeat(name, prio = null) {
        if (prio) {
            if (this.heartbeatActions[prio]) {
                this.heartbeatActions[prio] = this.heartbeatActions[prio].filter((func) => func[2] !== name);
            }
        } else {
            Object.keys(this.heartbeatActions).forEach((p) => {
                this.heartbeatActions[p] = this.heartbeatActions[p].filter((func) => func[2] !== name);
            });
        }
    }
}
