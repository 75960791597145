import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlanEntity } from '../../../store/entities/PlanEntity';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { Color } from '@angular-material-components/color-picker';
import { hexToRgb } from '../../../shared/helpers';

@Component({
	templateUrl: './tx-planing-plan-dialog.component.html',
	styleUrls: ['./tx-planing-plan-dialog.component.scss'],
})
export class TxPlaningPlanDialogComponent {
	public Plan: PlanEntity;

	public PlanForm = this.fb.group({
		name: [null, [Validators.required]],
		color: [null, [Validators.required]],
		begin_time: [null, [Validators.required]],
		end_time: [null, [Validators.required]],
		monday: [null, []],
		tuesday: [null, []],
		wednesday: [null, []],
		thursday: [null, []],
		friday: [null, []],
		saturday: [null, []],
		sunday: [null, []],
	});

	constructor(@Inject(MAT_DIALOG_DATA) data: { plan: PlanEntity }, private readonly fb: FormBuilder) {
		this.Plan = data.plan;

		this.initForm(this.Plan);
	}

	initForm(plan: PlanEntity) {
		if (!!plan.name) {
			const c = hexToRgb(plan.color);
			this.PlanForm.setValue({
				name: plan.name,
				color: new Color(c.r, c.g, c.b),
				begin_time: moment(plan.begin_time).format('HH:mm'),
				end_time: moment(plan.end_time).format('HH:mm'),
				monday: plan.mon,
				tuesday: plan.tue,
				wednesday: plan.wed,
				thursday: plan.thu,
				friday: plan.fri,
				saturday: plan.sat,
				sunday: plan.sun,
			});
		}
	}

	get result() {
		return Object.assign(this.Plan.toApi(), this.PlanForm.value, {
			begin_time: `${moment().format('YYYY-MM-DD')}T${this.PlanForm.value.begin_time}:00`,
			end_time: `${moment().format('YYYY-MM-DD')}T${this.PlanForm.value.end_time}:00`,
			mon: this.PlanForm.value.monday ? 1 : 0,
			tue: this.PlanForm.value.tuesday ? 1 : 0,
			wed: this.PlanForm.value.wednesday ? 1 : 0,
			thu: this.PlanForm.value.thursday ? 1 : 0,
			fri: this.PlanForm.value.friday ? 1 : 0,
			sat: this.PlanForm.value.saturday ? 1 : 0,
			sun: this.PlanForm.value.sunday ? 1 : 0,
		});
	}
}
