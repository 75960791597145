import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	ArchiveEmployee,
	ChangeEmployeeDataFailure,
	ChangeEmployeeDataRequest,
	CreateEmployeeDataFailure,
	CreateEmployeeDataRequest,
	DeleteTeam,
	FetchTeamleaderEmployeeAssignments,
	FetchTeamleaderEmployeeAssignmentsRequest,
	FetchTeams,
	getEmployees,
	LoadEmployeeData,
	LoadEmployeeDataRequest,
	LoadTeamleaderList,
	LoadTeamleaderListRequest,
	LoadTeams,
	LoadTimemodelDetail,
	LoadTimemodelDetailRequest,
	LoadTimemodels,
	LoadTimemodelsRequest,
	LoadTypeList,
	LoadTypeListRequest,
	setEmployees,
	SetTeamleaderEmployeeAssignment,
	UpdateTeam,
	UpdateUserPermission,
	UpdateUserPermissions,
} from '../actions/personal-office.actions';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { TxApiService } from '../../shared/services/txapi.service';
import { EmployeeEntity } from '../entities/EmployeeEntity';
import { ApiResponse } from '../entities/Api/ApiResponse';
import { EmployeeDetailResponse } from '../entities/Api/EmployeeDetail.response';
import { forkJoin, interval, Observable, of, takeWhile } from 'rxjs';
import { MessengerService } from '../../shared/services/messenger.service';
import { TimemodelEntity } from '../entities/TimemodelEntity';
import { TypeEntity } from '../entities/TypeEntity';
import { TimeModelDetailEntity } from '../entities/TimeModelDetailEntity';
import { Router } from '@angular/router';
import { ListOfUserPermissionResponse } from '../entities/Api/ListOfUserPermissionResponse';
import { ListOfTeamleaderResponse } from '../entities/Api/ListOfTeamleaderResponse';
import { TeamLeaderEntity } from '../entities/TeamLeaderEntity';
import { ListOfTeamleaderAssignmentResponse } from '../entities/Api/ListOfTeamleaderAssignmentResponse';
import { Store } from '@ngrx/store';
import { ListOfEmployeeHolidayAccountResponse } from '../entities/Api/ListOfEmployeeHolidayAccount.response';
import { UserPermissionEntity } from '../entities/UserPermissionEntity';
import {
	CreateTeamleader,
	DeleteTeamleader,
	FetchEmploymentRelations,
	SetDeputyState,
	SetEmployeeRelations,
	UpdateTeamleader,
} from '../actions/project-office.actions';
import { ListOfEmploymentRelation } from '../entities/Api/ListOfEmploymentRelation';
import { EmployeeRelation } from '../entities/EmployeeRelation';
import { TeamEntity } from '../entities/TeamEntity';
import { GetDepartments, SetDataLoading, UpdateDepartment } from '../actions/data.actions';
import { getDepartments } from '../selectors/data.selectors';
import { getTeamList } from '../selectors/personal-office.selectors';
import { EmployeeTeamleaderAssingmentEntity } from '../entities/EmployeeTeamleaderAssingmentEntity';
import { TxUpdateStellvertreter } from '../../shared/interfaces/session';

@Injectable({
	providedIn: 'root',
})
export class PersonalOfficeEffects {
	getUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getEmployees),
			switchMap(() => this.http.callAPI('getEmployeeList')),
			map((res) => {
				if (res.statuscode !== 0) {
					throw Error();
				}
				const tmp: EmployeeEntity[] = res.ListOfEmployee?.map((e) => EmployeeEntity.tryCast(e));
				return setEmployees(tmp);
			})
		)
	);

	getEmployeeDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadEmployeeDataRequest),
			switchMap(({ employee }) => {
				return forkJoin([
					this.http.callAPI('getEmployeeDetail', { employee_id: employee.employee_id }),
					this.http.callAPI('getUserPermissions', { teamleader_for: employee.employee_id, pin_login: 0 }),
					this.http.callAPI('getEmployeeTeamleaderAssignments', { teamleader_for: employee.employee_id }),
					this.http.callAPI('getEmployeeHolidayAccounts', { teamleader_for: employee.employee_id }),
				]);
			}),
			map(
				([res, permissions, assingments, holidayAccount]: [
					ApiResponse<EmployeeDetailResponse[]>,
					ApiResponse<ListOfUserPermissionResponse[]>,
					ApiResponse<ListOfTeamleaderAssignmentResponse[]>,
					ApiResponse<ListOfEmployeeHolidayAccountResponse[]>
				]) => {
					if (res.statuscode !== 0) {
						throw Error(res.statustext);
					}
					const [employee] = res.EmployeeDetail;
					return LoadEmployeeData(
						employee,
						permissions.ListOfUserPermission,
						assingments.ListOfTeamleaderAssignment,
						holidayAccount.ListOfEmployeeHolidayAccount
					);
				}
			)
		)
	);

	changeEmployeeDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChangeEmployeeDataRequest),
			switchMap(({ employee, timeModelChangeDate }) => {
				return this.http
					.callAPI('setEmployeeTeamleaderAssignments', {
						teamleader_for: employee.employee_id,
						Assignments: employee.assingments,
					})
					.pipe(map(() => [employee, timeModelChangeDate]));
			}),
			switchMap(([employee, timeModelChange]: [EmployeeEntity, Date]) => {
				return this.http
					.callAPI('updateEmployee', {
						EmployeeDetail: [
							{
								...((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => v != null)))(employee),
								timemodel_change_date: timeModelChange,
							},
						],
					})
					.pipe(
						map((res: ApiResponse<[]>) => {
							//const [ employee ] = res.EmployeeDetail;
							if (res.statuscode > -1) {
								this.msg.message('Erfolgreich gespeichert!', 'success');
								return LoadEmployeeDataRequest(employee);
							} else {
								this.msg.message(res.statustext, 'error');
								return ChangeEmployeeDataFailure(res.statustext);
							}
						}),
						catchError((err) => {
							console.log(err);
							this.msg.message(err.statustext, 'error');
							return of(err);
						})
					);
			})
		)
	);
	createEmployeeDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CreateEmployeeDataRequest),
			switchMap(({ employee }) => {
				return this.http
					.callAPI('updateEmployee', {
						EmployeeDetail: [
							((obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => v != null)))(employee),
						],
					})
					.pipe(
						map((res: ApiResponse<[]>) => {
							//const [ employee ] = res.EmployeeDetail;
							if (res.statuscode > -1) {
								this.msg.message('Erfolgreich gespeichert!', 'success');
								return getEmployees();
							} else {
								this.msg.message(res.statustext, 'error');
								return CreateEmployeeDataFailure(res.statustext);
							}
						}),
						catchError((err) => {
							console.log(err);
							this.msg.message(err.statustext, 'error');
							return of(err);
						})
					);
			})
		)
	);

	getTimemodels$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadTimemodelsRequest),
			switchMap(({ name }) =>
				this.http.callAPI('getTimeModelList').pipe(
					map((res: ApiResponse<TimemodelEntity[]>) => {
						if (name) {
							const model = res.ListOfTimeModel?.find((m) => m.model_name === name);
							if (model) {
								this.router.navigate(['/', 'personaloffice', 'zeitmodelle', model.model_id]);
							}
						}
						return LoadTimemodels(res.ListOfTimeModel?.map((t) => TimemodelEntity.tryCast(t)));
					})
				)
			)
		)
	);

	getTypeList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadTypeListRequest),
			mergeMap((a) =>
				this.http
					.callAPI('getDataList', {
						listName: a.listType.toString(),
					})
					.pipe(
						map((res: ApiResponse<TypeEntity[]>) => {
							return LoadTypeList(
								a.listType,
								res.ListOfDataListItem?.map((lt) => TypeEntity.tryCast(lt))
							);
						})
					)
			)
		)
	);

	getTimemodeDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadTimemodelDetailRequest),
			switchMap(({ id }) => this.http.callAPI('getTimemodelDetail', { model_id: id })),
			map((res: ApiResponse<TimeModelDetailEntity[]>) => {
				return LoadTimemodelDetail(res.TimeModelDetails?.map((tmd) => TimeModelDetailEntity.tryCast(tmd)));
			})
		)
	);

	getTeamleaders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoadTeamleaderListRequest),
			switchMap(() => this.http.callAPI('getTeamleader')),
			map((res: ApiResponse<ListOfTeamleaderResponse[]>) => {
				return LoadTeamleaderList(res.ListOfTeamleader.map((tl) => TeamLeaderEntity.tryCast(tl)));
			})
		)
	);

	updateTeamleader$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UpdateTeamleader),
			switchMap(({ teamleader }) => this.http.callAPI('updateTeamleader', { teamleader })),
			map(() => LoadTeamleaderListRequest())
		)
	);

	deleteTeamleader$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteTeamleader),
			switchMap(({ teamleader }) => this.http.callAPI('deleteTeamleader', { id: teamleader.id })),
			map(() => LoadTeamleaderListRequest())
		)
	);

	createTeamleader$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CreateTeamleader),
			switchMap(({ teamleaderName, teamleaderUserId }) =>
				this.http.callAPI('updateTeamleader', {
					teamleader: {
						name: teamleaderName,
						persnr: teamleaderUserId,
					},
				})
			),
			map(() => LoadTeamleaderListRequest())
		)
	);

	setDeputyState$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetDeputyState),
			switchMap(({ state, teamleader_for }) =>
				this.http.callAPI('updateStellvertreter', {
					active: state,
					teamleader_for,
				})
			)
		)
	);

	getTeamleaderEmployeeAssignments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchTeamleaderEmployeeAssignmentsRequest),
			mergeMap(({ id }) =>
				this.http.callAPI('getTeamleaderEmployeeAssignments', { teamleader_id: id }).pipe(
					map((res: ApiResponse<EmployeeTeamleaderAssingmentEntity[]>) =>
						FetchTeamleaderEmployeeAssignments(
							id,
							res.ListOfTeamleaderAssignment.map((l) => EmployeeTeamleaderAssingmentEntity.tryCast(l))
						)
					)
				)
			)
		)
	);

	setTeamleaderEmployeeAssignments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetTeamleaderEmployeeAssignment),
			mergeMap(({ id, assignments }) =>
				this.http
					.callAPI('setTeamleaderEmployeeAssignments', {
						teamleader_id: id,
						Assignments: assignments,
					})
					.pipe(map(() => FetchTeamleaderEmployeeAssignmentsRequest(id)))
			)
		)
	);

	updateUserPermission$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateUserPermission),
				mergeMap(({ permission, employee_id }) =>
					this.http.callAPI('updateUserPermissions', {
						teamleader_for: employee_id,
						Permissions: [permission],
					})
				)
			),
		{ dispatch: false }
	);

	updateUserPermissions$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateUserPermissions),
				switchMap(({ permissions, employee_id }) =>
					this.http.callAPI('updateUserPermissions', {
						teamleader_for: employee_id,
						Permissions: permissions,
					})
				)
			),
		{ dispatch: false }
	);

	fetchEmployeeRelations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchEmploymentRelations),
			switchMap(() => this.http.callAPI('getEmploymentRelations')),
			map((res: ApiResponse<ListOfEmploymentRelation[]>) =>
				SetEmployeeRelations(res.ListOfEmploymentRelation.map((e) => EmployeeRelation.tryCast(e)))
			)
		)
	);

	archiveEmployee$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArchiveEmployee),
			switchMap(({ employee }) => this.http.callAPI('deleteEmployee', { employee_id: employee.employee_id })),
			map(() => getEmployees())
		)
	);

	getTeams$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FetchTeams),
			switchMap(() => this.http.callAPI('GetTeams')),
			map((res: ApiResponse<TeamEntity[]>) => LoadTeams(res.ListOfTeam.map((t) => TeamEntity.tryCast(t))))
		)
	);

	updateTeam$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateTeam),
				switchMap(({ team }) =>
					this.http.callAPI('updateTeam', { team }).pipe(
						tap(() => {
							this.store.dispatch(SetDataLoading(true));
						}),
						switchMap(() =>
							interval(1000).pipe(
								tap(() => {
									this.store.dispatch(FetchTeams());
								}),
								withLatestFrom(this.store.select(getTeamList)),
								takeWhile(([, teams]) => {
									const l_team = teams.find((t) => (team.id > 0 ? t.id === team.id : t.name === team.name));
									console.log(l_team);
									if (l_team?.sync_state === 1) {
										this.store.dispatch(SetDataLoading(false));
									}
									return l_team?.sync_state !== 1;
								})
							)
						)
					)
				)
			),
		{ dispatch: false }
	);

	deleteTeam$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteTeam),
			switchMap(({ team }) => this.http.callAPI('deleteTeam', { id: team.id })),
			map(() => FetchTeams())
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store,
		private http: TxApiService,
		private msg: MessengerService,
		private router: Router
	) {}
}
