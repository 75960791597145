import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscriber } from 'rxjs';
import { MessengerService } from './messenger.service';
import { Coordinates } from '../interfaces/coordinates';

const GEOLOCATION_ERRORS = {
	'errors.location.unsupportedBrowser': 'Browser does not support location services',
	'errors.location.permissionDenied': 'You have rejected access to your location',
	'errors.location.positionUnavailable': 'Unable to determine your location',
	'errors.location.timeout': 'Service timeout has been reached',
};

@Injectable()
export class GeoDataService {
	geoDataLocationUpdate;
	geoDataLocationInterval = interval(5000);
	private geoDataEnabled = new BehaviorSubject<boolean>(false);
	private geoDataLocation = new BehaviorSubject<Coordinates>(null);

	constructor(private myMessengerService: MessengerService) {
		const isEnabled = Boolean(localStorage.getItem('geoDataEnable') === 'true'); // handled for page hard refresh event
		this.setGeoDataLocalStorage(isEnabled);
		this.geoDataEnabled.next(isEnabled);
		if (isEnabled) {
			this.startGeoUpdate();
		} else {
			this.stopGeoUpdate();
		}
	}

	isEnabled(): Observable<boolean> {
		return this.geoDataEnabled.asObservable();
	}

	getLocation(): Observable<Coordinates> {
		return this.geoDataLocation.asObservable();
	}

	setGeoDataLocalStorage(geoDataState: boolean) {
		localStorage.setItem('geoDataEnable', geoDataState.toString());
		this.geoDataEnabled.next(geoDataState);

		if (geoDataState) {
			this.startGeoUpdate();
		} else {
			this.stopGeoUpdate();
		}
	}

	public getLocationData(geoLocationOptions?: any): Observable<any> {
		geoLocationOptions = geoLocationOptions || { timeout: 5000 };

		return new Observable((observer) => {
			if (window.navigator && window.navigator.geolocation && this.geoDataEnabled.getValue()) {
				window.navigator.geolocation.getCurrentPosition(
					(position) => {
						observer.next(position);
						observer.complete();
					},
					(error) => {
						// Disable geoService on error
						this.setGeoDataLocalStorage(false);
						/*
                the observer.error has been replaces with console.log because of WLT-2028,
                which destroyed the serviceWorker due to exceptions when no geodata was allowed by the user
             */
						switch (error.code) {
							case 1:
								// observer.error(GEOLOCATION_ERRORS[ 'errors.location.permissionDenied' ]);
								console.log(GEOLOCATION_ERRORS['errors.location.permissionDenied']);
								this.myMessengerService.message('Geodaten wurden wegen fehlender Berechtigung deaktiviert', 'error');
								break;
							case 2:
								// observer.error(GEOLOCATION_ERRORS[ 'errors.location.positionUnavailable' ]);
								console.log(GEOLOCATION_ERRORS['errors.location.positionUnavailable']);
								break;
							case 3:
								// observer.error(GEOLOCATION_ERRORS[ 'errors.location.timeout' ]);
								console.log(GEOLOCATION_ERRORS['errors.location.timeout']);
								break;
						}
					},
					geoLocationOptions
				);
			} else {
				observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser']);
			}
		});
	}

	startGeoUpdate() {
		this.geoDataLocationUpdate = this.geoDataLocationInterval.subscribe(() => {
			const accuracy = { enableHighAccuracy: true };
			this.getLocationData(accuracy).subscribe((position) => {
				this.geoDataLocation.next(position.coords);
			});
		});
	}

	stopGeoUpdate() {
		if (this.geoDataLocationUpdate instanceof Subscriber) {
			this.geoDataLocationUpdate.unsubscribe();
		}
	}
}
