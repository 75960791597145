<div class="js-sidebar-content" *ngIf="session$ | async as session">
	<header class="logo hidden-sm-down">
		<a href="index.html">
			<img src="{{config.logoLight}}" alt="{{config.name}}" title="{{config.name}}" />
		</a>
        <span class="sidebar-toggle absolute right-0 top-0 p-4 hidden-xl-up" (click)="toggleSidebar()">
            <i class="fa fa-times"></i>
        </span>
	</header>
	<div class="sidebar-status hidden-md-up">
		<a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
			<strong>{{session?.Name}}</strong>
            <b class="caret"></b>
		</a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li>
				<a class="dropdown-item" [routerLink]=" ['/account'] "><i class="fas fa-user"></i> &nbsp; Einstellungen</a>
			</li>
			<li class="dropdown-divider"></li>
			<li>
				<a class="dropdown-item" [routerLink]=" ['/login'] " (click)="logout()"
					><i class="fas fa-sign-out-alt"></i> &nbsp; Log Out</a
				>
			</li>
		</ul>
		<!-- #notifications-dropdown-menu goes here when screen collapsed to xs or sm -->
		<span class="sidebar-toggle" (click)="toggleSidebar()"><i class="fa fa-times"></i></span>
	</div>
	<!-- main notification links are placed inside of .sidebar-nav -->
	<ng-container *ngIf="PermissionsMenuItems$ | async as items">

		<p-panelMenu
			#sideMenu
			*ngIf="items"
			class="side-nav"
			[styleClass]="'side-nav-sub'"
			[multiple]="false"
			[model]="[
                {
                    label: 'Menu',
                    styleClass: 'menu-root',
                    items: items,
                    expanded: true,
                    disabled: false
                }
            ]">
		</p-panelMenu>
	</ng-container>
	<div class="flex-spacer"></div>
	<ul class="sidebar-nav sidebar-nav--bottom">
		<!--        <li style="display: none;">-->
		<!--            <div class="sidebar-nav-title">Netzwerk-Status:</div>-->
		<!--            <div class="sidebar-status onlinestatetoggle">-->
		<!--                <p-toggleButton [(ngModel)]="networkIsOnlineButton" (onChange)="handleNetworkStateChange()"-->
		<!--                                [disabled]="true" onLabel="Online" offLabel="Offline"-->
		<!--                                [style]="{'width': '100%'}"></p-toggleButton>-->
		<!--            </div>-->
		<!--        </li>-->
		<li *ngIf="openActions$ | async as openActions">
			<div *ngIf="openActions?.length > 0" class="sidebar-queue">
				<div class="sidebar-nav-title display-flex flex-justify-content-space-between flex-align-items-center">
					Warteschlange:<i
						class="fa fa-question-circle"
						aria-hidden="true"
						pTooltip="Ihre Internet-Verbindung ist schwach. Wir speichern ihre Buchungen zwischen und senden sie sobald ihre Internet-Verbindung wieder stabil ist!"
						appendTo="body"
						[escape]="true"></i>
					<button mat-icon-button [matMenuTriggerFor]="menu"><fa-icon icon="chevron-down"></fa-icon></button>
				</div>
				<div class="sidebar-status">
					<small class="text-muted">Es sind aktuell <b>{{openActions.length}}</b> Buchungen in der Warteschleife</small>
				</div>
				<div class="sidebar-sidepop">
					<mat-menu class="queue-info-list" yPosition="below" xPosition="after" #menu="matMenu">
						<div *ngFor="let action of openActions" class="queue-info-item">
							<div class="queue-info-header">{{ action.body.datetime | formatTime:"DD.MM.YYYY HH:mm:ss" }}</div>
							<div class="queue-info-content">{{ mapQueueType(action.body.buchungsart) }}</div>
						</div>
					</mat-menu>
				</div>
			</div>
		</li>
		<!--
        <li *ngIf="networkIsOnlineButton$ | async as networkIsOnline">
            <a [routerLink]=" ['/login'] " (click)="logout()" *ngIf="networkIsOnline && !isMobile && !isTablet">
                <span class="icon"><i class="fa fa-sign-out"></i></span>Log Out
            </a>
        </li>
        <li *ngIf="networkIsOnlineButton$ | async as networkIsOnline">
            <a (click)="reload()" *ngIf="networkIsOnline && !isMobile && !isTablet" [routerLink]="null">
                <span class="icon"><i class="fa fa-refresh"></i></span>{{'sidebar.reload' | translate}}
            </a>
        </li>-->
		<li *ngIf="terminalService.fastLogout | async" class="flex flex-direction-column align-items-center">
			<button mat-flat-button [routerLink]="['/login']" (click)="logout()" class="w-100" color="primary">
				<i class="fas fa-sign-out-alt mr-2"></i>Log Out
			</button>
		</li>
		<li>
			<a href="#" (click)="displayAppInfo()">
				<span class="icon"><i class="fa fas fa-code-branch"></i></span
				><small class="text-muted">Version: {{wlVersion}} </small>
			</a>
		</li>
	</ul>
</div>

<p-dialog header="TXportal Informationen" [(visible)]="displayAppInformation" styleClass="tx-app-info">
	<a href="index.html">
		<img src="{{config.logoDark}}" alt="{{config.name}}" title="{{config.name}}" />
	</a>
	<table>
		<tr>
			<td>Version:</td>
			<td>{{wlVersion}}</td>
		</tr>
		<tr>
			<td>URL:</td>
			<td>{{config.host}}</td>
		</tr>

		<tr>
			<td>API:</td>
			<td>{{config.TimeIXServerUrl}}</td>
		</tr>
		<tr>
			<td>Device:</td>
			<td>{{config.device}}</td>
		</tr>
		<tr>
			<td>Browser:</td>
			<td>{{config.browser}}</td>
		</tr>
		<tr>
			<td>Browser:</td>
			<td>{{config.browserVersion}}</td>
		</tr>
		<tr>
			<td>UserAgent:</td>
			<td>{{config.userAgent}}</td>
		</tr>
		<tr>
			<td>OS:</td>
			<td>{{config.os}}</td>
		</tr>
		<tr>
			<td>OS Version:</td>
			<td>{{config.osVersion}}</td>
		</tr>
	</table>
</p-dialog>
