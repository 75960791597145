import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { KillStore } from '../../store/actions';

@Injectable({
	providedIn: 'root',
})
export class ValidSessionInterceptor implements HttpInterceptor {
	constructor(private router: Router, private store: Store<State>) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			map((req: any) => {
				if (req instanceof HttpResponse) {
					if (req.body.statuscode === 4) {
						this.store.dispatch(KillStore());
						localStorage.setItem('currentSession', null);
						this.router.navigateByUrl('/');
					}
				}
				return req;
			})
		);
	}
}
