import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageEntity } from '../../store/entities/MessageEntity';
import { TxSession } from '../../shared/interfaces/session';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers/user-state.reducer';
import { MessageCenterService } from '../../shared/services/message-center.service';
import { getSession } from '../../store/selectors/user.selectors';

@Component({
	selector: 'tx-chat',
	templateUrl: './tx-chat.component.html',
	styleUrls: ['./tx-chat.component.scss'],
})
export class TxChatComponent implements AfterViewInit {
	@Input()
	public Messages: MessageEntity[];

	@Input()
	public height = 600;

	@Input()
	public Title = 'Message Center';

	@Input()
	public is_office = false;

	@Output()
	public refresh = new EventEmitter();

	@Output()
	public archive = new EventEmitter<{ message: MessageEntity; remove: boolean }>();

	@Output()
	public send = new EventEmitter<{ message: string; answer: MessageEntity }>();

	public Type: string;
	public Message: string;

	@Input()
	public AnswerMessage: MessageEntity;
	@Output()
	public AnswerMessageChange = new EventEmitter<MessageEntity>();

	@Input()
	public WithArchived: boolean;
	@Output()
	public WithArchivedChange = new EventEmitter<boolean>();

	public CurrentUser$: Observable<TxSession>;

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('scrollBot') private messagesContainer: ElementRef;

	constructor(private readonly store: Store<State>, private readonly service: MessageCenterService) {
		this.CurrentUser$ = store.select(getSession);
	}

	ngAfterViewInit(): void {
		this.scrollToBottom();
	}

	scrollToBottom(): void {
		try {
			this.messagesContainer.nativeElement.scrollTo(0, this.messagesContainer.nativeElement.scrollHeight);
		} catch (err) {}
	}

	sendMessage() {
		// this.service.sendMessage(0, this.Message, true, this.AnswerMessage);

		this.send.emit({ message: this.Message, answer: this.AnswerMessage });

		this.Type = null;
		this.Message = null;
		this.AnswerMessageChange.emit(null);
	}

	isZero(id: number) {
		return this.is_office ? id > 0 : id === 0;
	}
}
