import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChild,
	NavigationStart,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getSession } from '../../store/selectors/user.selectors';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LeaveGuardService implements CanActivateChild {
	canLeave = true;

	lastTrigger: 'imperative' | 'popstate' | 'hashchange';

	constructor(private router: Router, private location: Location, private store: Store<State>) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.lastTrigger = event.navigationTrigger;
			}
		});
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// Fix browser history if leaving prevented and called by back/forward navigation
		if (!this.canLeave && this.lastTrigger === 'popstate') {
			this.location.go(state.url);
		}
		return this.store.select(getSession).pipe(
			map((session) => {
				return state.url === '/account' || session.reset_pw === 0;
			})
		);
	}
}
