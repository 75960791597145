import { Injectable } from '@angular/core';
import {
    TxActiveBDEList,
    TxBdeAuftrag,
    TxBDEProtokoll,
    TxBuchungsRequest
} from '../interfaces/bde';
import {
    BehaviorSubject,
    interval,
    Observable,
    of,
    switchMap,
    takeWhile
} from 'rxjs';
import { ConnectionStateService } from './connection-state.service';
import { TxApiService } from './txapi.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormatDate } from './formatdate.service';
import { MessengerService } from './messenger.service';
import { GeoDataService } from './geodata.service';
import { TerminalService } from './terminal.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Coordinates } from '../interfaces/coordinates';
import { ActiveBDEService } from './activebde.service';
import {
    takeUntil,
    tap
} from 'rxjs/operators';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { HeartbeatService } from './heartbeat.service';

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    myTasks: TxBdeAuftrag[] = null;
    myTasks$: BehaviorSubject<TxBdeAuftrag[]> = new BehaviorSubject(this.myTasks); // Observer und etwas mehr für intern
    tasks: Observable<TxBdeAuftrag[]> = this.myTasks$.asObservable(); // observer für alle

    geoDataEnabled: boolean;
    geoDataSubscription: any;
    getDataLocationSubscription: any;
    currentLocation: Coordinates = null;
    pinMode = false;
    onlineState: boolean;

    constructor(
        private connectionStateService: ConnectionStateService,
        private txApiService: TxApiService,
        private deviceDetectorService: DeviceDetectorService,
        private formatDate: FormatDate,
        private messengerService: MessengerService,
        private geoDataService: GeoDataService,
        private myTerminalService: TerminalService,
        private localStorageService: LocalStorage,
        private heartbeatService: HeartbeatService,
        private activeBDEService: ActiveBDEService
    ) {
        this.geoDataSubscription = this.geoDataService.isEnabled().subscribe((state) => {
            this.geoDataEnabled = state;
        });

        this.getDataLocationSubscription = this.geoDataService
            .getLocation()
            .subscribe((location) => (this.currentLocation = location));
        this.myTerminalService.pinLoggedIn.subscribe((data) => (this.pinMode = data));
        this.connectionStateService.isOnlineSubject.subscribe((state) => (this.onlineState = state));
    }

    getBdeData() {
        if (!this.onlineState) {
            const myTmpListOfAuftrag = JSON.parse(localStorage.getItem('ListOfAuftrag'));
            this.localStorageService.getItem('txListOfAuftrag').subscribe((data) => {
                if (data) {
                    const currentBdeProtocol = new TxBDEProtokoll(0, 'OK', myTmpListOfAuftrag);
                    this.myTasks$.next(currentBdeProtocol.ListOfAuftrag);
                }
            });
        } else {
            // we are online :-)
            let myRequest: Observable<TxBDEProtokoll>;

            myRequest = this.txApiService.callAPI('getBDE', {});

            myRequest.subscribe(
                (myRequestData) => {
                    if (myRequestData != null) {
                        const currentBdeProtocol = new TxBDEProtokoll(
                            myRequestData.statuscode,
                            myRequestData.statustext,
                            myRequestData.ListOfAuftrag
                        );

                        this.localStorageService.setItem('txListOfAuftrag', JSON.stringify(currentBdeProtocol.ListOfAuftrag));
                        this.myTasks$.next(currentBdeProtocol.ListOfAuftrag);
                    }
                },
                (error) => {
                    console.log('ERROR' + error);
                    this.getBdeData();
                }
            );
        }
    }

    createBooking(
        aBuchungsart: string,
        aActiveAuftrag?: string,
        aActiveSchritt?: string,
        aIndex?: number,
        aKill?: boolean,
        aKostenStelle = '0',
        aBarcodeScanned = 0,
        aNotice = null,
        quantity = undefined
    ) {
        const myBuchungsData = new TxBuchungsRequest();
        myBuchungsData.buchungsart = aBuchungsart;
        myBuchungsData.datetime = FormatDate.createTxDate(new Date());
        myBuchungsData.kostenstelle = aKostenStelle;
        myBuchungsData.auftragsnummer = aActiveAuftrag;
        myBuchungsData.auftragsschritt = aActiveSchritt;
        myBuchungsData.barcode = aBarcodeScanned;
        myBuchungsData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        myBuchungsData.pin = Number(this.pinMode);
        myBuchungsData.notice = aNotice;
        myBuchungsData.quantity = quantity;

        const isMobile = this.deviceDetectorService.isMobile();
        const isTablet = this.deviceDetectorService.isTablet();

        if (isMobile || isTablet) {
            myBuchungsData.platform = 'Mobil';
        } else {
            myBuchungsData.platform = 'Desktop';
        }

        if (this.geoDataEnabled) {
            // prevent exceptions when geodata is enabled but not availabe
            if (this.currentLocation != null) {
                myBuchungsData.latitude = String(this.currentLocation.latitude);
                myBuchungsData.longitude = String(this.currentLocation.longitude);
                myBuchungsData.accuracy = String(this.currentLocation.accuracy);
            }
        }


        return this.txApiService
            .callAPI('createBuchung', myBuchungsData)
            .pipe(tap(() => this.pollForOrder(aActiveAuftrag, aActiveSchritt, aBuchungsart === 'AE')))
    }

    pollForOrder(auftragsnummer: string, auftragsschritt: string, inverse = false) {
        if(!auftragsnummer || !auftragsschritt) {
            // only poll if we have an order and a step
            return null;
        }
        // use the heartbeat service instead of interval to wait for server finish processing
        this.heartbeatService.addToHeartbeat('pollForOrder'+auftragsnummer, () => {
            this.activeBDEService.requestDataCall().subscribe((res: ApiResponse<TxActiveBDEList[] | null>) => {
                const find = res?.ListOfActiveBDE?.find(
                    (bde) => bde.auftrag_nummer === auftragsnummer && bde.schritt_nummer === auftragsschritt
                );
                // console.log(auftragsnummer, auftragsschritt, find);
                const ret = inverse ? !!find : !find;
                if (!ret) {
                    this.activeBDEService.myActiveBDE.next(res.ListOfActiveBDE);
                    this.heartbeatService.removeFromHeartbeat('pollForOrder'+auftragsnummer);
                }
            });
        }, 2);
    }
}
