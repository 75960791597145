import { ReportEntity } from '../entities/ReportEntity';
import { Action, createReducer, on } from '@ngrx/store';
import { SetReports } from '../actions/reports.actions';

export interface State {
	list: ReportEntity[];
}

export const initialState: State = {
	list: null,
};

const reportReducer = createReducer(
	initialState,
	on(SetReports, (state, { list }) => ({
		list,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return reportReducer(state, action);
}
