import { Component, Input } from '@angular/core';

@Component({
	selector: 'tx-progressbar',
	templateUrl: './tx-progressbar.component.html',
	styleUrls: ['./tx-progressbar.component.scss'],
})
export class TxProgressbarComponent {
	@Input() text = '';
	@Input() height = 10;
	@Input() value = 50;
	@Input() color = '#4D90FE';
}
