import { createAction, Creator } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';

export const NOOP = createAction('NOOP');

export const KillStore = createAction('KILL STORE');

export const ErrorEvent = createAction('Error', (error) => ({ error }));

export function SuccessAction(action: ActionCreator<string, Creator>) {
	return createAction(`${action.type}_SUCCESS`, (payload: any) => ({ payload }));
}
export function ErrorAction(action: ActionCreator<string, Creator>) {
	return createAction(`${action.type}_ERROR`, (error: string | null) => ({ error }));
}
