import moment from 'moment';

export class ProjectBookingEntity {
	id: number;
	employee_id: number;
	employee_name: string;
	datum: Date | string;
	begin_time: string;
	end_time: string;
	calculated_time: string;
	quantity: number;

	public static tryCast(o: any) {
		let ret = new ProjectBookingEntity();
		ret = Object.assign(ret, o);
		if (ret.datum) {
			ret.datum = moment(ret.datum).toDate();
		}
		return ret;
	}
}
