/* tslint:disable:variable-name */
export class UserInfo {
	statuscode: number;
	statustext: string;
	ListOfUserInfo: ListOfUserInfo[];
}

export class ListOfUserInfo {
	personalnummer: number;
	name: string;
	user_info: string;
	status: string;
	fs_control: number;
	state_id: number;
	state_name: string;
	notification_list: string;
	unread_messages: number;
}
