<div *ngIf="filteredItems$ | async as Items" class="tx-list">
	<!--<p-listbox [options]="items" optionLabel="{{labelField}}" [filter]="true"></p-listbox>-->
	<div class="display-flex">
		<mat-form-field
			style="width: 0"
			appearance="legacy"
			*ngIf="searchField !== null"
			class="tx-list-search-bar flex-grow-1">
			<mat-label>Suche</mat-label>
			<mat-icon matPrefix style="margin-right: 0.5rem"><fa-icon icon="search" size="xs"></fa-icon></mat-icon>
			<input matInput #search type="text" placeholder="Suche" (keyup)="onSearchChange(search.value)" />
			<mat-icon matSuffix *ngIf="Searching"><fa-icon icon="spinner" [spin]="true"></fa-icon></mat-icon>
            <mat-icon matSuffix *ngIf="!Searching && search.value" class="cursor-pointer text-red-500" (click)="search.value = ''; onSearchChange('')">
                <fa-icon icon="times"></fa-icon>
            </mat-icon>
		</mat-form-field>
		<span *ngIf="showNew" style="margin: 4px">
            <button style="height: 30px; width: 30px; line-height: 30px" mat-icon-button (click)="newClick?.next(true)">
				<fa-icon icon="plus"></fa-icon>
            </button>
        </span>
	</div>
	<mat-selection-list
		#list
		[class.tx-list-max-height]="maxHeight !== ''"
		[style.max-height]="maxHeight"
		*ngIf="Items.length > 0; else noEntries"
		(selectionChange)="onSelectionChange($event)"
		[multiple]="multiple">
		<mat-list-option
			*ngFor="let item of Items"
			class="tx-list-list-option relative"
            [ngClass]="{'mat-list-single-selected-option': isSelected(items, item)}"
			[routerLink]="['/', routerLinkBase, item[idField]] | array: 'flat'"
			routerLinkActive="mat-list-single-selected-option"
			[value]="item">
			{{ getLabel(item) }}
            <p-badge *ngIf="getBadgeValue(item) !== ''" [value]="getBadgeValue(item)" size="large" severity="danger" class="absolute right-0" styleClass="shadow-2" style="top: 50%; transform: translateY(-50%)"></p-badge>
		</mat-list-option>
	</mat-selection-list>
	<ng-template #noEntries>
		<mat-selection-list [multiple]="false">
			<mat-list-option>Keine Einträge.</mat-list-option>
		</mat-selection-list>
	</ng-template>
</div>
