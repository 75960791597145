import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TimeModelDetailEntity } from '../../store/entities/TimeModelDetailEntity';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersonalOfficeService } from '../services/personal-office.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class PoTimemodeldetailResolver implements Resolve<TimeModelDetailEntity> {
	constructor(private po: PersonalOfficeService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<TimeModelDetailEntity> | Promise<TimeModelDetailEntity> | TimeModelDetailEntity {
		if (['0', 'new', 'all'].includes(route.params.id)) {
			return null;
		}
		return this.po.getTimemodelDetail(+route.params.id).pipe(take(1));
	}
}
