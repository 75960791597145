export class ModelBreakResponse {
	id: number;
	public break_val: number;
	public break_treshold_val: number;
	public break_begin_plan: string;
	public break_end_plan: string;
	public deleted: boolean;
	public isNew: boolean;
}

export class ModelFrameResponse {
	id: number;
	public weekday: number;
	public frame_begin: string;
	public frame_end: string;
	public plan_time: number;
	public coretime1_begin: string;
	public coretime1_end: string;
	public coretime2_begin: string;
	public coretime2_end: string;
}

export class TimeModelDetailResponse {
	model_id: number;
	public name: string;
	public type: number;
	public calendar_group: number;
	public break_treshold_under: number;
	public break_min_val: number;
	public frame_check_k: number;
	public frame_check_g: number;
	public round_day: number;
	public inclusiv_hours_val: number;
	public inclusiv_type: number;
	public max_hours_zk_val: number;
	public max_hours_day_val: number;
	public fix_hours_month_val: number;
	public short_break: number;
	public ListOfModelFrame: ModelFrameResponse[];
	public ListOfModelBreak: ModelBreakResponse[];
}
