import { ModelBreakResponse, ModelFrameResponse, TimeModelDetailResponse } from './Api/TimeModelDetailResponse';

export class ModelBreakEntity extends ModelBreakResponse {
	public static tryCast(o: any): ModelBreakEntity {
		return Object.assign(new ModelBreakEntity(), o);
	}
}

export class ModelFrameEntity extends ModelFrameResponse {
	public static tryCast(o: any): ModelFrameEntity {
		return Object.assign(new ModelFrameEntity(), o);
	}
}

export class TimeModelDetailEntity extends TimeModelDetailResponse {
	public static tryCast(o: any): TimeModelDetailEntity {
		const ret = new TimeModelDetailEntity();
		Object.assign(ret, o);
		ret.ListOfModelBreak = ret.ListOfModelBreak?.map((l) => ModelBreakEntity.tryCast(l));
		ret.ListOfModelFrame = ret.ListOfModelFrame?.map((l) => ModelFrameEntity.tryCast(l));
		return ret;
	}

	public TimeModelDetailEntity() {
		this.model_id = 0;
		this.name = null;
		this.type = 0;
		this.calendar_group = 0;
		this.break_treshold_under = 0;
		this.break_min_val = 0;
		this.frame_check_k = 0;
		this.frame_check_g = 0;
		this.round_day = 0;
		this.inclusiv_hours_val = 0;
		this.inclusiv_type = 0;
		this.max_hours_zk_val = 0;
		this.max_hours_day_val = 0;
		this.fix_hours_month_val = 0;
		this.short_break = 0;
	}
}
