import { createAction } from '@ngrx/store';
import { TxWfCalendar } from '../../shared/interfaces/workflow';
import { TeamLeaderEntity } from '../entities/TeamLeaderEntity';

export const GetTeamleaderCalendar = createAction(
	'[teamleader] get teamleader calendar',
	(fullyear: number, teamleader: number) => ({ fullyear, teamleader })
);

export const SetTeamleaderCalendar = createAction(
	'[teamleader] set teamleader calendar',
	(calendar: TxWfCalendar[]) => ({ calendar })
);

export const SetTeamleader = createAction('[teamleader] set teamleader entity', (teamleader: TeamLeaderEntity) => ({
	teamleader,
}));
