import { Injectable } from '@angular/core';
import { ListOfUserInfo, UserInfo } from '../interfaces/user-info';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './appconfig.service';
import { AuthService } from './auth.service';
import { TxApiService } from './txapi.service';
import { ConnectionStateService } from './connection-state.service';
import { HeartbeatService } from './heartbeat.service';
import { environment } from '../../../environments/environment';
import { take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { requestNotification, setHasContent, setUnreadMessageCount } from '../../store/actions/user.actions';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { MessageHeadEntity } from '../../store/entities/Api/MessageHeadEntity';
import { getHasContent } from '../../store/selectors/user.selectors';

@Injectable()
export class UserInfoService {
	userInfo = new Subject<UserInfo>();

	constructor(
		private myAuthService: AuthService,
		private http: HttpClient,
		private myAppConfig: AppConfigService,
		private myTxApiService: TxApiService,
		private myConnectionStateService: ConnectionStateService,
		private heartbeatService: HeartbeatService,
		private store: Store
	) {
		this.heartbeatService.addToHeartbeat('UserInfoService', () => this.catchUserInfoFromServer(false), 10);
		/*this.heartbeatService.addToHeartbeat(
      () => this.myConnectionStateService.checkOnlineState(),
        environment.production ? 3 : 10000
    );*/
	}

	catchUserInfoFromServer(wait: boolean = false, init = false): void {
		if (wait) {
			setTimeout(() => {
				this.callUserInfo(init);
			}, 3000);
		} else {
			this.callUserInfo(init);
		}
	}

	private callUserInfo(init = false): void {
		this.myTxApiService
			.callAPI('getUserInfo', {
				force_data_loading: init,
			})
			.pipe(withLatestFrom(this.store.select(getHasContent)), take(1))
			.subscribe(([data, hasContent]) => {
				if (data && data.statustext !== 'NO CONTENT') {
					if (data.ListOfUserInfo?.length > 0) {
						if (data.ListOfUserInfo[0].notification_list?.length > 0) {
							data.ListOfUserInfo[0].notification_list.split(',').forEach((id) => {
								this.store.dispatch(requestNotification(id));
							});
						}
						this.store.dispatch(setUnreadMessageCount(data.ListOfUserInfo[0].unread_messages));
					}
					this.userInfo.next(data);
				}

				if (!hasContent && data.statustext !== 'NO CONTENT') {
					this.store.dispatch(setHasContent(true));
				} else if (hasContent && data.statustext === 'NO CONTENT') {
					this.store.dispatch(setHasContent(false));
				}
			});
	}

	getUserInfo(): Observable<UserInfo> {
		return this.userInfo.asObservable();
	}
}
