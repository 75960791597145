import { Injectable } from '@angular/core';
import { TxApiService } from './txapi.service';
import { Observable, of } from 'rxjs';
import { EmployeeEntity } from '../../store/entities/EmployeeEntity';
import {
	getCalendarGroupList,
	getEmployeeById,
	getEmployees,
	getTeamleaderEmployeeAssignments,
	getTeamleaderList,
	getTeamList,
	getTimemodelDetailById,
	getTimemodels,
	getTypeList,
} from '../../store/selectors/personal-office.selectors';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import {
	ListTypes,
	LoadEmployeeData,
	LoadEmployeeDataRequest,
	LoadTimemodelDetail,
	LoadTimemodelDetailRequest,
	LoadTimemodels,
	LoadTimemodelsRequest,
	LoadTypeList,
	setEmployees,
	LoadTeamleaderListRequest,
	LoadTeamleaderList,
	LoadTypeListRequest,
	getEmployees as PoGetEmployees,
	LoadTeams,
	FetchTeams,
	FetchTeamleaderEmployeeAssignmentsRequest,
	FetchTeamleaderEmployeeAssignments,
	SetTeamleaderEmployeeAssignment,
} from '../../store/actions/personal-office.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { TimemodelEntity } from '../../store/entities/TimemodelEntity';
import { TypeEntity } from '../../store/entities/TypeEntity';
import { TimeModelDetailEntity } from '../../store/entities/TimeModelDetailEntity';
import { TeamLeaderEntity } from '../../store/entities/TeamLeaderEntity';
import { CalendarGroupEntity } from '../../store/entities/CalendarGroupEntity';
import {
	CreateTeamleader,
	DeleteTeamleader,
	FetchCalendarGroup,
	FetchEmploymentRelations,
	SetCalendarGroup,
	SetEmployeeRelations,
	UpdateTeamleader,
} from '../../store/actions/project-office.actions';
import { EmployeeRelation } from '../../store/entities/EmployeeRelation';
import { getEmployeeRelations } from '../../store/selectors/project-office.selectors';
import { TeamEntity } from '../../store/entities/TeamEntity';
import { EmployeeTeamleaderAssingmentEntity } from '../../store/entities/EmployeeTeamleaderAssingmentEntity';
import { TxUpdateStellvertreter } from '../interfaces/session';

@Injectable({
	providedIn: 'root',
})
export class PersonalOfficeService {
	constructor(private api: TxApiService, private store: Store<State>, private actions$: Actions) {}

	getTypeList(type: ListTypes): Observable<TypeEntity[]> {
		return this.store.select(getTypeList(type)).pipe(
			mergeMap((entities) => {
				if (!entities) {
					this.store.dispatch(LoadTypeListRequest(type));
					return this.actions$.pipe(
						ofType(LoadTypeList),
						map((a) => a.entities)
					);
				}
				return of(entities);
			})
		);
	}

	getEmployees(force = false): Observable<EmployeeEntity[]> {
		return this.store.select(getEmployees).pipe(
			switchMap((users) => {
				if (force || !users) {
					this.store.dispatch(PoGetEmployees());
					return this.actions$.pipe(
						ofType(setEmployees),
						map((a) => a.employees)
					);
				}
				return of(users);
			})
		);
	}

	getEmployeeDetail(id: number): Observable<EmployeeEntity> {
		return this.store.select(getEmployeeById(id)).pipe(
			switchMap((res: EmployeeEntity) => {
				this.store.dispatch(LoadEmployeeDataRequest(res));
				return this.actions$.pipe(
					ofType(LoadEmployeeData),
					switchMap(({ employee }) => this.store.select(getEmployeeById(employee.employee_id)))
				);
			})
		);
	}

	getTeamleaderList(force = false): Observable<TeamLeaderEntity[]> {
		return this.store.select(getTeamleaderList).pipe(
			switchMap((res) => {
				if (force || !res) {
					this.store.dispatch(LoadTeamleaderListRequest());
					return this.actions$.pipe(
						ofType(LoadTeamleaderList),
						map((a) => a.teamleaders)
					);
				}
				return of(res);
			})
		);
	}

	updateTeamleader(tl: TeamLeaderEntity) {
		this.store.dispatch(UpdateTeamleader(tl));
	}

	deleteTeamleader(tl: TeamLeaderEntity) {
		this.store.dispatch(DeleteTeamleader(tl));
	}

	createTeamleader(name: string, teamleaderUserId: number) {
		this.store.dispatch(CreateTeamleader(name, teamleaderUserId));
	}

	getTeamleaderEmployeeAssignments(id: number) {
		return this.store.select(getTeamleaderEmployeeAssignments(id)).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchTeamleaderEmployeeAssignments(id, []));
					this.store.dispatch(FetchTeamleaderEmployeeAssignmentsRequest(id));
					return this.actions$.pipe(
						ofType(FetchTeamleaderEmployeeAssignments),
						filter((a) => a.id === id),
						map((a) => a.assignments)
					);
				}
				return of(list);
			})
		);
	}

	setTeamleaderEmployeeAssignments(teamleader_id: number, assignments: EmployeeTeamleaderAssingmentEntity[]) {
		this.store.dispatch(SetTeamleaderEmployeeAssignment(teamleader_id, assignments));
	}

	getTeams(force = false): Observable<TeamEntity[]> {
		return this.store.select(getTeamList).pipe(
			switchMap((res) => {
				if (force || !res) {
					this.store.dispatch(FetchTeams());
					return this.actions$.pipe(
						ofType(LoadTeams),
						map((a) => a.teams)
					);
				}
				return of(res);
			})
		);
	}

	getTimemodelList(): Observable<TimemodelEntity[]> {
		return this.store.select(getTimemodels).pipe(
			switchMap((models) => {
				if (!models) {
					this.store.dispatch(LoadTimemodelsRequest());
					return this.actions$.pipe(
						ofType(LoadTimemodels),
						map((a) => a.timemodels)
					);
				}
				return of(models);
			})
		);
	}

	getTimemodelDetail(id: number): Observable<TimeModelDetailEntity> {
		this.store.dispatch(LoadTimemodelDetailRequest(id));
		return this.actions$.pipe(
			ofType(LoadTimemodelDetail),
			map((a) => a.timemodeldetail.find((tmd) => tmd.model_id === id))
		);
	}

	getCalendarGroupList(): Observable<CalendarGroupEntity[]> {
		return this.store.select(getCalendarGroupList).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchCalendarGroup());
					return this.actions$.pipe(
						ofType(SetCalendarGroup),
						map((a) => a.calendarGroup)
					);
				}
				return of(list);
			})
		);
	}

	getEmployeeRelations(): Observable<EmployeeRelation[]> {
		return this.store.select(getEmployeeRelations).pipe(
			switchMap((list) => {
				if (!list) {
					this.store.dispatch(FetchEmploymentRelations());
					return this.actions$.pipe(
						ofType(SetEmployeeRelations),
						map((a) => a.employeeRelations)
					);
				}
				return of(list);
			})
		);
	}

	setTeamleaderState(state: number, teamleader_for: number = 0) {
		return;
	}
}
