import { Component } from '@angular/core';
import { concatMap, exhaustMap, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { from, Observable, of, Subscription } from 'rxjs';
import { TxApiService } from '../../../shared/services/txapi.service';
import { WorkflowService } from '../../../shared/services/workflow.service';
import moment from 'moment';
import { TxWfCalendarWithDay } from '../po-types';

@Component({
	templateUrl: './tx-po-time-account.component.html',
	styleUrls: ['./tx-po-time-account.component.scss'],
})
export default class TxPoTimeAccountComponent {
	private subs: Subscription[] = [];

	public Workflows$: Observable<TxWfCalendarWithDay[]>;

	constructor(private api: TxApiService, private workflow: WorkflowService) {
		this.Workflows$ = workflow.getWorkflowCalendar(0, 1).pipe(
			map((wf) => wf.WfCalendar),
			switchMap((calendars) => {
				return from(calendars).pipe(
					concatMap((calendar) => {
						const schedulerDateRange = {
							von: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
							bis: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
							team_leader_for: calendar.personalnr,
						};
						return this.api
							.callAPI('getCalendarPZE', schedulerDateRange)
							.pipe(map((res) => ({ day: res.ListOfCalendarPZE, calendar })));
					}),
					toArray()
				);
			})
		);
	}

	ngOnDestroy() {
		this.subs.map((s) => s.unsubscribe());
	}

	getEmployeeTimeData(employeeId: number) {
		return;
	}
}
