import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as EventActions from '../actions/events.actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { TxApiService } from '../../shared/services/txapi.service';
import * as moment from 'moment';

@Injectable()
export class EventsEffects {
	getEvents$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(EventActions.init),
				switchMap(() => {
					const schedulerDateRange = {
						von: moment().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss'),
						bis: moment().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss'),
						team_leader_for: 4,
					};
					return this.txApiService.callAPI('getCalendarHead', schedulerDateRange);
				}),
				// tap((res) => console.log(res))
			),
		{ dispatch: false }
	);

	constructor(private actions$: Actions, private txApiService: TxApiService) {}
}
