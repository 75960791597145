import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EmployeeEntity } from '../../../store/entities/EmployeeEntity';
import {
	getEmployees,
	getTeamleaderList,
	getTeamList,
	getTimemodels,
	getTypeList,
} from '../../../store/selectors/personal-office.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import {
	ArchiveEmployee,
	ChangeEmployeeDataRequest,
	CreateEmployeeDataRequest,
	LoadTeamleaderListRequest,
	UpdateUserPermissions,
} from '../../../store/actions/personal-office.actions';
import { TimemodelEntity } from '../../../store/entities/TimemodelEntity';
import { TypeEntity } from '../../../store/entities/TypeEntity';
import moment from 'moment';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UserPermissionEntity } from '../../../store/entities/UserPermissionEntity';
import { MessengerService } from '../../../shared/services/messenger.service';
import { TeamLeaderEntity } from '../../../store/entities/TeamLeaderEntity';
import { EmployeeTeamleaderAssingmentEntity } from '../../../store/entities/EmployeeTeamleaderAssingmentEntity';
import { ListOfKSTEntity } from '../../../store/entities/ListOfKSTEntity';
import { getDepartments, getCostcenters, getEmployeeGroups } from '../../../store/selectors/data.selectors';
import { ListOfDepartmentEntity } from '../../../store/entities/ListOfDepartmentEntity';
import { ListOfEmployeeGroupEntity } from '../../../store/entities/ListOfEmployeeGroupEntity';
import { ListOfWorkflowGroup } from '../../../shared/interfaces/workflow';
import { WorkflowService } from '../../../shared/services/workflow.service';
import { MatDialog } from '@angular/material/dialog';
import { TxPersonalFilesDialogComponent } from './dialogs/tx-personal-files.dialog.component';
import { TX_BOOL } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../shared/services/auth.service';
import { TxSetTeamleader, TxGetDataList } from '../../../shared/interfaces/session';
import { TxApiService } from '../../../shared/services/txapi.service';
import { EmployeeRelation } from '../../../store/entities/EmployeeRelation';
import { getEmployeeRelations } from '../../../store/selectors/project-office.selectors';
import { TxPersonalFilesTmocDialogComponent } from './time-model-one-changed/tx-personal-files.tmoc.dialog';
import { TeamEntity } from '../../../store/entities/TeamEntity';
import { isArray } from 'lodash';
import { ApiResponse } from '../../../store/entities/Api/ApiResponse';
import { DataListEntity } from '../../../store/entities/DataListEntity';
import { DateAdapter } from '@angular/material/core';

@Component({
	selector: 'tx-personal-files',
	templateUrl: './tx-personal-files.component.html',
	styleUrls: ['./tx-personal-files.component.scss'],
})
export class TxPersonalFilesComponent implements OnDestroy {
	private destroyed$ = new Subject<boolean>();
	private isSaving = false;
	private savedEmployee$ = new BehaviorSubject<EmployeeEntity>(null);
	// numeric employee id or null
	private employee_id: number;
	public selectionSources: { [key: string]: DataListEntity[] };
	public isDisabled = false;
	public TimeModel1Changed = false;
	private timeModelsPopoverLeaveTimeout: NodeJS.Timeout;

	Sticky = false;

	SelectedTab: number;

	separatorKeysCodes: number[] = [ENTER, COMMA];
	timeModelCtrl = new FormControl();
	teamleaderModelCtrl = new FormControl();
	@ViewChild('timeModelInput') timeModelInput: ElementRef<HTMLInputElement>;
	@ViewChild('teamleaderInput') teamleaderInput: ElementRef<HTMLInputElement>;
	@ViewChild('buttons') buttons: ElementRef<HTMLInputElement>;
	@ViewChild('buttonsDummy') buttonsDummy: ElementRef<HTMLInputElement>;
	@ViewChild('buttonsParent') buttonsParent: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	@ViewChild('personalForm') personalForm: NgForm;

	Employees$: Observable<EmployeeEntity[]>;

	SelectedEmployee$: Observable<EmployeeEntity | null>;

	Timemodels$: Observable<TimemodelEntity[]>;
	Teamleader$: Observable<TeamLeaderEntity[]>;
	KST$: Observable<ListOfKSTEntity[]>;
	Departments$: Observable<ListOfDepartmentEntity[]>;
	EmployeeGroups$: Observable<ListOfEmployeeGroupEntity[]>;
	WorkflowGroups$: Observable<ListOfWorkflowGroup[]>;
	Teamlist$: Observable<TeamEntity[]>;
	EmployeeRelations$: Observable<EmployeeRelation[]>;

	SelectedTimeModels: any[] = [];
	SelectedTeamLeader: any[] = [];
	EmployeeTeamleaderAssingments: EmployeeTeamleaderAssingmentEntity[] = [];

	SalaryTypes$: Observable<TypeEntity[]>;
	EmploymentTypes$: Observable<TypeEntity[]>;
	EmployeeTypes$: Observable<TypeEntity[]>;

	UserDataForm = this.fb.group({
		setPassword: [null],
		//pin_code: [null],
	});

	PersonalForm = this.fb.group({
		employee_id: [null, [Validators.required]],
		persnr: [null, [Validators.required]],
		lohn_persnr: [null],
		employee_type: [null],
		employment_type: [null],
		salary_type: [null],
		costcenter_id: [null],
		department_id: [null],
		birthday: [null],
		entry_date1: [null],
		entry_date2: [null],
		exit_date: [null],
		firstname: [null],
		lastname: [null],
		empl_contract: [null],
		empl_contract_date: [null],
		email1: [null, [Validators.email]],
		email2: [null, [Validators.email]],
		documentation: [null],
		team_id: [null],
		group_id: [null],
		street: [null],
		zipcode: [null],
		city: [null],
		phone1: [null],
		phone2: [null],
		comp_idcard: [null],
		comp_idcard_date: [null],
		idcard: [null],
		idcard_date: [null],
		driving_lic: [null],
		driving_lic_date: [null],
		driving_lic_iv: [null],
		passport: [null],
		passport_date: [null],
		payrate1: [null],
		payrate2: [null],
		payrate3: [null],
		start_holiday: [null],
		start_date: [null],
		start_value_state: [null],
		start_value: [null],
		special_holiday: [null],
		annual_holiday: [null],
		card_number: [null],
		fp_number: { value: null, disabled: true },
		pin_code: [null],
	});

	public UpdatePermission: UserPermissionEntity[] = [];

	private buttonsOffset = -1;
	public buttonsWidth = -1;

	public HolidayLocked = true;

	private momentConversionFields = ['salary_type'];
	private maskConversionFields = ['start_value'];

	public LoginName: string | null = null;
	public CloudPin: string | null = null;
	public QrCode: string | null = null;

	public LoginPermissionChange$ = new BehaviorSubject<{
		employee: EmployeeEntity;
		perm: 'Cloud PIN' | 'Loginname' | 'QR Code';
		value: string;
	} | null>(null);

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		public authService: AuthService,
		private myMessengerService: MessengerService,
		private store: Store<State>,
		private sanitizer: DomSanitizer,
		private workflowService: WorkflowService,
		private dialog: MatDialog,
		public translate: TranslateService,
		private http: TxApiService,
		private _adapter: DateAdapter<any>
	) {
		this.selectionSources = {};
		this.Employees$ = this.store.select(getEmployees);

		this.Timemodels$ = this.store.select(getTimemodels);
		this.Teamleader$ = this.store.select(getTeamleaderList);
		this.KST$ = this.store.select(getCostcenters);
		this.Departments$ = this.store.select(getDepartments);
		this.EmployeeGroups$ = this.store.select(getEmployeeGroups);
		this.WorkflowGroups$ = this.workflowService.getWorkflowGroups();
		this.Teamlist$ = this.store.select(getTeamList);
		this.EmployeeRelations$ = this.store.select(getEmployeeRelations);

		this.SalaryTypes$ = this.store.select(getTypeList('SalaryType'));
		this.EmployeeTypes$ = this.store.select(getTypeList('EmployeeType'));
		this.EmploymentTypes$ = this.store.select(getTypeList('EmploymentType'));

		this.SelectedEmployee$ = combineLatest([route.paramMap, this.savedEmployee$.asObservable()]).pipe(
			takeUntil(this.destroyed$),
			withLatestFrom(this.Employees$),
			map(([[params, employee], employees]) => {
				this.clearForm();
				if (params.get('id')) {
					const id = params.get('id');
					if (id === 'new') {
						return new EmployeeEntity();
					}
					const employee = employees.find((u) => u.employee_id === +id);
					if (employee) {
						this.loadSelectedEmployee(employee);
					}
					return employee ?? null;
				}

				return null;
			})
		);

		this.SelectedEmployee$.pipe(takeUntil(this.destroyed$));

		// create list of all available employees
		this.Employees$.pipe(takeUntil(this.destroyed$)).subscribe((employees) => {
			if (this.isSaving) {
				const selectedId = this.getIdFromUrl();
				const e = employees.find((e) => e.employee_id === selectedId);
				this.savedEmployee$.next(e);
				this.loadSelectedEmployee(e);
				this.isSaving = false;
			}
		});

		this.LoginPermissionChange$.pipe(
			takeUntil(this.destroyed$),
			debounceTime(300),
			filter((d) => !!d)
		).subscribe(({ employee, perm, value }) => {
			this.processLoginPermChange(employee, perm, value);
		});
	}

	getSessionDomain() {
		return localStorage.getItem('txSessionDomain');
	}

	get FormattedLoginName() {
		return this.LoginName.substring(0, this.LoginName.indexOf('@'));
	}

	set FormattedLoginName(value: string) {
		this.LoginName = `${value}@${this.getSessionDomain()}`;
	}

	//@HostListener('window:scroll', ['$event'])
	checkScroll() {
		if (this.buttonsOffset === -1) {
			const bodyRect = document.body.getBoundingClientRect(),
				elemRect = this.buttonsDummy
					? this.buttonsDummy.nativeElement.getBoundingClientRect()
					: this.buttons.nativeElement.getBoundingClientRect(),
				offset = elemRect.top - bodyRect.top;
			this.buttonsOffset = offset;
			this.buttonsWidth = this.buttonsParent.nativeElement.getBoundingClientRect().width;
		}
		this.Sticky = window.pageYOffset > this.buttonsOffset - 50;
	}

	//@HostListener('window:resize', ['$event'])
	checkResize() {
		const bodyRect = document.body.getBoundingClientRect(),
			elemRect = this.buttonsDummy
				? this.buttonsDummy.nativeElement.getBoundingClientRect()
				: this.buttons.nativeElement.getBoundingClientRect(),
			offset = elemRect.top - bodyRect.top;
		this.buttonsOffset = offset;
		this.buttonsWidth = this.buttonsParent.nativeElement.getBoundingClientRect().width;
	}

	filterTeamleaderList(teamleader: TeamLeaderEntity[]) {
		return teamleader.filter((t) => !this.SelectedTeamLeader.find((stl) => stl.id === t.id));
	}

	numberInputToFixed(event: Event) {
		if (event.target instanceof HTMLInputElement) {
			event.target.value = parseFloat(event.target.value).toFixed(2);
		}
	}

	resetPassword(employee: EmployeeEntity) {
		const loginPerms = employee.getLoginPermissions();
		const pwReset = loginPerms.find((p) => p.name === 'PWReset');
		if (pwReset) {
			this.updateUserPermission(
				pwReset,
				{
					value: 1,
					value_text: null,
				},
				employee.employee_id
			);

			this.save(employee);
		}
	}

	updateUserPermission(
		permission: UserPermissionEntity,
		newValue: { value: TX_BOOL; value_text: string | EventTarget },
		employee_id: number
	) {
		let value_text = '';
		if (newValue.value_text instanceof EventTarget) {
			value_text = (newValue.value_text as HTMLInputElement).value;
		} else {
			value_text = newValue.value_text;
		}

		const update = this.UpdatePermission.find((o) => o.key === permission?.key);

		if (update) {
			update.value = newValue.value;
			update.value_text = value_text;
		} else {
			this.UpdatePermission.push(
				UserPermissionEntity.tryCast({
					...permission,
					value: newValue.value,
					value_text: value_text,
				})
			);
		}

		this.PersonalForm.markAsDirty();
		// this.myMessengerService.message('Berechtigung erfolgreich gespeichert!', 'success' as MessengerType);
	}

	updateUserSelection(permission: UserPermissionEntity, newValue: { value: any }, employee_id: number) {
		// console.log('updateUserSelection',newValue);
		// let selection_source = '';
		// if (newValue.selection_source instanceof EventTarget) {
		//     selection_source = (newValue.selection_source as HTMLInputElement).value;
		// } else {
		//     selection_source = newValue.selection_source;
		// }
		//
		const update = this.UpdatePermission.find((o) => o.key === permission?.key);
		//
		if (update) {
			update.selection_value = newValue.value ?? 0;
		} else {
			this.UpdatePermission.push(
				UserPermissionEntity.tryCast({
					...permission,
					selection_value: newValue.value,
				})
			);
		}

		this.PersonalForm.markAsDirty();
		// this.myMessengerService.message('Berechtigung erfolgreich gespeichert!', 'success' as MessengerType);
	}

	getLabel(item: EmployeeEntity) {
		return `${item.lastname}, ${item.firstname}`;
	}

	onNewClick(clicked) {
		if (clicked) {
			this.router.navigate(['personaloffice', 'personalakten', 'new']);
		}
	}

	clearForm() {
		this.PersonalForm.reset();
		this.PersonalForm.markAsPristine();
		this.PersonalForm.markAsUntouched();

		this.UserDataForm.reset();
		this.UserDataForm.markAsPristine();
		this.UserDataForm.markAsUntouched();

		this.UpdatePermission = [];
		this.SelectedTimeModels = [];
		this.SelectedTeamLeader = [];
		this.EmployeeTeamleaderAssingments = [];
		this.HolidayLocked = true;
		this.LoginName = null;
		this.CloudPin = null;
		this.QrCode = null;
	}

	archiveEmployee(employee: EmployeeEntity) {
		this.store.dispatch(ArchiveEmployee(employee));
		setTimeout(() => {
			this.router.navigateByUrl('/personaloffice/personalakten');
		}, 200);
	}

    switchSign(){
        event.preventDefault();
        const currentValue = this.PersonalForm.value.start_value;
        const currentSign = this.PersonalForm.value.start_value_state;
        const newSign = currentSign === '' ? '' : currentSign === '+' ? '-' : '+';
        const newValue = newSign === '+' ? currentValue.replace(/-/g, '') : newSign === '-' ? '-' + currentValue : '';
        if (newValue !== '') {
            this.PersonalForm.markAsDirty();

            this.PersonalForm.patchValue({
                start_value_state: newSign,
                start_value: newValue
            });
        }
    }

	patchForm(item: EmployeeEntity) {
		if (!item) {
			return;
		}
		const val = {
			employee_id: item.employee_id,
			persnr: item.persnr,
			lohn_persnr: item.lohn_persnr,
			employee_type: item.employee_type,
			employment_type: item.employment_type,
			salary_type: item.salary_type,
			costcenter_id: item.costcenter_id,
			department_id: item.department_id,
			birthday: item.birthday,
			entry_date1: item.entry_date1,
			entry_date2: item.entry_date2,
			exit_date: item.exit_date,
			firstname: item.firstname,
			lastname: item.lastname,
			start_holiday: item.start_holiday,
			email1: item.email1,
			email2: item.email2,
			documentation: item.documentation,
			phone1: item.phone1,
			phone2: item.phone2,
			payrate1: item.payrate1?.toFixed(2),
			payrate2: item.payrate2?.toFixed(2),
			payrate3: item.payrate3?.toFixed(2),
			team_id: item.team_id,
			group_id: item.group_id,
			street: item.street,
			zipcode: item.zipcode,
			city: item.city,
			comp_idcard: item.comp_idcard,
			comp_idcard_date: item.comp_idcard_date,
			idcard: item.idcard,
			idcard_date: item.idcard_date,
			passport: item.passport,
			passport_date: item.passport_date,
			empl_contract: item.empl_contract,
			empl_contract_date: item.empl_contract_date,
			driving_lic: item.driving_lic,
			driving_lic_date: item.driving_lic_date,
			start_date: item.start_date,
			start_value_state: item.start_value < 0 ? '-' : item.start_value > 0 ? '+' : '',
			start_value: item.start_value ? moment.duration(`${item.start_value}`, 'hours').format('HHH:mm') : null,
			driving_lic_iv: item.driving_lic_iv,
			special_holiday: item.special_holiday,
			annual_holiday: item.annual_holiday,
			card_number: item.card_number,
			fp_number: item.fp_number ?? '',
			pin_code: item.pin_code,
		};

		this.clearForm();
		this.PersonalForm.setValue(val);

		// map time models form employee to available time models
		this.Timemodels$.pipe(take(1)).subscribe((models) => {
			for (let i = 1; i < 6; ++i) {
				if (item[`model${i}`] > 0) {
					const model = models.find((m) => m.model_id === item[`model${i}`]);
					if (model) {
						this.SelectedTimeModels.push({
							id: model.model_id,
							label: model.model_name,
						});
					}
				}
			}
		});
		// this.SelectedTeamLeader = [];
		this.Teamleader$.pipe(take(1)).subscribe((teamleader) => {
			this.SelectedTeamLeader = item.assingments.map((a) => ({
				id: a.teamleader_id,
				label: teamleader.find((t) => t.id === a.teamleader_id)?.name ?? 'FEHLER',
			}));
		});
		// this.EmployeeTeamleaderAssingments = [];

		const loginPerms = item.getLoginPermissions();

		const name = loginPerms.find((p) => p.name === 'Loginname');
		if (name) {
			this.LoginName = name.value_text;
		}
		const pin = loginPerms.find((p) => p.name === 'Cloud PIN');
		if (pin) {
			this.CloudPin = pin.value_text;
		}
		const qrCode = loginPerms.find((p) => p.name === 'QR Code');
		if (qrCode) {
			this.QrCode = qrCode.value_text;
		}

		this.EmployeeTeamleaderAssingments = [
			...item.assingments.map((st) => ({
				teamleader_id: st.teamleader_id,
				EditAbsent: 0,
				EditBooking: 0,
				...st,
			})),
		];

		this.TimeModel1Changed = false;

		// this.UpdatePermission = [];
	}

	processLoginPermChange(employee: EmployeeEntity, perm: 'Cloud PIN' | 'Loginname' | 'QR Code', event: string) {
		const loginPerms = employee.getLoginPermissions();
		// console.log(loginPerms)
		switch (perm) {
			case 'Loginname':
				const name = loginPerms.find((p) => p.name === 'Loginname');
				if (name.value_text !== event) {
					// this.updateUserPermission(name, { value: null, value_text: event }, employee.employee_id);
					this.PersonalForm.markAsDirty();
				}
				break;
			case 'Cloud PIN':
				const pin = loginPerms.find((p) => p.name === 'Cloud PIN');
				if (pin.value_text !== event) {
					// this.updateUserPermission(pin, { value: null, value_text: event }, employee.employee_id);
					this.PersonalForm.markAsDirty();
				}
				break;
			case 'QR Code':
				const qrCode = loginPerms.find((p) => p.name === 'QR Code');
				if (qrCode.value_text !== event) {
					// this.updateUserPermission(pin, { value: null, value_text: event }, employee.employee_id);
					this.PersonalForm.markAsDirty();
				}
				break;
			default:
				break;
		}
	}

	getQRCodeHref(canvas: HTMLCanvasElement) {
		return this.sanitizer.bypassSecurityTrustUrl(
			canvas.toDataURL().replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
		);
	}

	generateRandomPassword(
		formGroup: FormGroup,
		field: string,
		length = 10,
		wishlist = '0123456789abcdefghijklmnopqrstuvwxyz'
	) {
		const pass = Array(length)
			.fill('')
			.map(
				() => wishlist[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * wishlist.length)]
			)
			.join('');

		formGroup.get(field).setValue(pass);
		formGroup.get(field).markAsDirty();
	}

	filterTimemodels(timemodels: TimemodelEntity[]) {
		const addedIds = this.SelectedTimeModels.map((stm) => stm.id);
		return timemodels.filter((tm) => !addedIds.includes(tm.model_id));
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		if (this.SelectedTimeModels.length === 0) {
			this.TimeModel1Changed = true;
		}

		if ((value || '').trim()) {
			this.SelectedTimeModels.push(value.trim());
			this.PersonalForm.markAsDirty();
		}

		if (input) {
			input.value = '';
		}

		this.timeModelCtrl.setValue(null);
	}

	addTeamleader(event: MatChipInputEvent) {
		const input = event.input;
		const value = event.value;

		if ((value || '').trim()) {
			this.SelectedTeamLeader.push(value.trim());
			this.PersonalForm.markAsDirty();
		}

		if (input) {
			input.value = '';
		}

		this.teamleaderModelCtrl.setValue(null);
	}

	remove(timeModel: any): void {
		const index = this.SelectedTimeModels.indexOf(timeModel);

		if (index >= 0) {
			this.SelectedTimeModels.splice(index, 1);
			this.PersonalForm.markAsDirty();

			if (index === 0) {
				this.TimeModel1Changed = true;
			}
		}
	}

	removeTeamleader(teamleader: TeamLeaderEntity) {
		const index = this.SelectedTeamLeader.indexOf(teamleader);

		if (index >= 0) {
			this.SelectedTeamLeader.splice(index, 1);
			this.PersonalForm.markAsDirty();
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.PersonalForm.markAsDirty();
		this.SelectedTimeModels.push({ id: event.option.value, label: event.option.viewValue });
		this.timeModelInput.nativeElement.value = '';
		this.timeModelCtrl.setValue(null);
		this.timeModelInput.nativeElement.blur();
		setTimeout(() => {
			this.timeModelInput.nativeElement.focus();
		});
	}

	selectedTeamleader(event: MatAutocompleteSelectedEvent) {
		this.PersonalForm.markAsDirty();
		this.SelectedTeamLeader.push({ id: event.option.value, label: event.option.viewValue });
		this.teamleaderInput.nativeElement.value = '';
		this.teamleaderModelCtrl.setValue(null);
		this.teamleaderInput.nativeElement.blur();
		setTimeout(() => {
			this.teamleaderInput.nativeElement.focus();
		});
	}

	onItemSelected(item: any) {}

	getEmployeeFromForm() {
		let modelCounter = 0;
		return {
			...Object.entries(this.PersonalForm.value)
				.map(([key, value]: [string, string | number | boolean | Date]) => {
					/*if (typeof value === 'object' && value instanceof Date) {
						return [key, value]; // [key, value.toISOString().slice(0, -1)];
					}*/
					if (this.momentConversionFields.includes(key) && typeof value === 'string') {
						return [key, moment.duration(value, 'hours').asHours().toFixed(2)];
					}
					if (this.maskConversionFields.includes(key) && typeof value === 'string') {
						return [key, moment.duration(value, 'hours').asHours()];
					}
					if (key.startsWith('payrate') && typeof value === 'string') {
						return [key, parseFloat(value)];
					}
					return [key, value];
				})
				.reduce((acc, [key, value]) => {
					return Object.assign(acc, { [`${key}`]: value });
				}, {}),
			...{
				model1: 0,
				model2: 0,
				model3: 0,
				model4: 0,
				model5: 0,
			},
			...this.SelectedTimeModels.map((stm) => ({
				[`model${++modelCounter}`]: stm.id,
			})).reduce((acc, curr) => Object.assign(acc, curr), {}),
			assingments: this.SelectedTeamLeader.map((st) => ({
				teamleader_id: st.id,
				EditAbsent: 0,
				EditBooking: 0,
				...this.EmployeeTeamleaderAssingments.find((e) => e.teamleader_id === st.id),
			})),
			permissions: [],
			// persnr: employee.persnr,
		} as EmployeeEntity;
	}

	async save(employee: EmployeeEntity) {
		let timeModelChangeDate = null;
		if (this.TimeModel1Changed) {
			timeModelChangeDate = await lastValueFrom(this.dialog.open(TxPersonalFilesTmocDialogComponent).afterClosed());
			if (!timeModelChangeDate) {
				return;
			}
			timeModelChangeDate = moment(timeModelChangeDate, 'DD.MM.YYYY').startOf('day').toDate();
			this.TimeModel1Changed = false;
		}
		let isNew = false;
		if (!this.PersonalForm.valid) {
			const invalidControls = this.getInvalidControls(this.PersonalForm);
			// check if route ends with /new
			if (!this.router.url.endsWith('/new')) {
				console.log('invalidControls', invalidControls);
				return;
			} else {
				isNew = true;
				// get reason why form is not valid
				if (!(invalidControls.length === 1 && invalidControls[0] === 'employee_id')) {
					console.log('invalidControls', invalidControls);
					return;
				}
			}
		}
		this.isSaving = true;

		const crafted_employee = this.getEmployeeFromForm();
		crafted_employee.permissions = employee.permissions?.slice() ?? [];
		crafted_employee.persnr = employee.persnr;

		const updatePerms: UserPermissionEntity[] = [];

		const loginPerms = employee.getLoginPermissions();
		const name = loginPerms.find((p) => p.name === 'Loginname');
		if (name?.value_text !== this.LoginName) {
			updatePerms.push({
				...name,
				value: 0,
				value_text: this.LoginName,
			} as UserPermissionEntity);
		}
		const pin = loginPerms.find((p) => p.name === 'Cloud PIN');
		if (pin?.value_text !== this.CloudPin) {
			updatePerms.push({
				...pin,
				value: 0,
				value_text: this.CloudPin,
			} as UserPermissionEntity);
		}

		const qrCode = loginPerms.find((p) => p.name === 'QR Code');
		if (qrCode?.value_text !== this.QrCode) {
			updatePerms.push({
				...qrCode,
				value: 0,
				value_text: this.QrCode,
			} as UserPermissionEntity);
		}

		if (this.UpdatePermission.length > 0) {
			this.UpdatePermission.forEach((p) => {
				updatePerms.push({
					...p,
					value: p.value,
					value_text: p.value_text,
				} as UserPermissionEntity);
			});

			this.UpdatePermission = [];
		}

		if (isNew) {
			crafted_employee.persnr = this.PersonalForm.get('persnr').value;
			crafted_employee.employee_type = 0;

			this.store.dispatch(CreateEmployeeDataRequest(crafted_employee));
			// this.store.dispatch(UpdateUserPermissions(updatePerms, employee.employee_id));

			return;
		}

		this.store.dispatch(UpdateUserPermissions(updatePerms, employee.employee_id));

		this.store.dispatch(ChangeEmployeeDataRequest(crafted_employee, timeModelChangeDate));

		this.router.navigate(['personaloffice', 'personalakten', 'all']);
		this.isDisabled = true;
		setTimeout(() => {
			this.router.navigate(['personaloffice', 'personalakten', employee.employee_id]);
			this.isDisabled = false;
		}, 500);
	}

	changePermission(permission: 'absent' | 'booking', teamleader: TeamLeaderEntity, employee: EmployeeEntity) {
		let perm = this.EmployeeTeamleaderAssingments.find((e) => e.teamleader_id === teamleader.id);

        console.log(perm, teamleader, employee);

        if (!perm) {
			perm = new EmployeeTeamleaderAssingmentEntity();
			perm.teamleader_id = teamleader.id;
			perm.EditBooking = 0;
			perm.EditAbsent = 0;

            this.EmployeeTeamleaderAssingments.push(perm);
		}

        console.log(perm, teamleader, employee);

		switch (permission) {
			case 'absent':
				perm.EditAbsent = (perm.EditAbsent + 1) % 2;
				break;
			case 'booking':
				perm.EditBooking = (perm.EditBooking + 1) % 2;
				break;
			default:
				break;
		}
		this.PersonalForm.markAsDirty();
	}

	getPermission(employee: EmployeeEntity, teamleader: TeamLeaderEntity) {
		const perm =
			this.EmployeeTeamleaderAssingments.find((e) => e.teamleader_id === teamleader.id) ??
			employee.assingments.find((e) => e.teamleader_id === teamleader.id);
		if (!perm) {
			return {
				absent: false,
				booking: false,
			};
		}
		return {
			absent: perm.EditAbsent === 1,
			booking: perm.EditBooking === 1,
		};
	}

	openDialog(what: 'departments' | 'groups' | 'teams') {
		this.dialog.open(TxPersonalFilesDialogComponent, { data: { what } });
	}

	getEmployeeHolidayAccountData(
		employee: EmployeeEntity,
		type: 'annual' | 'prev_year' | 'prev_year_remaining' | 'remaining' | 'predictable' | 'special_year' | 'special_rest'
	) {
		return employee.holidayAccounts.find((h) => h.account_type === type) ?? { account_value: null };
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private loadSelectedEmployee(employee: EmployeeEntity) {
		if (!employee) {
			return;
		}

		const newlySelectedId = this.getIdFromUrl();

		if (newlySelectedId !== this.employee_id) {
			this.SelectedTab = 0;
		}

		this.patchForm(employee);
		this.employee_id = newlySelectedId;

		employee.permissions.forEach((p) => {
			this.resolveDataList(p);
		});
		// this.http.callAPI()
	}

	private async resolveDataList(p: UserPermissionEntity) {
		const exceptionList: { [key: number]: Observable<any> } = {
			60: this.store.select(getEmployees).pipe(
				take(1),
				map((employees) =>
					employees
						.filter((e) => e.txp_active === 1)
						.map((e) => ({
							description: `${e.firstname} ${e.lastname}`,
							name: e.persnr,
							value: +e.persnr,
						}))
				)
			),
		};

		if (p.value_type === 'selection') {
			if (Object.keys(exceptionList).includes(`${p.key}`)) {
				this.selectionSources[p.selection_source] = await lastValueFrom(exceptionList[p.key]);
			} else {
				await this.callGetDataList(p.selection_source);
			}
		}
		if (p.children && isArray(p.children)) {
			for (const c of p.children) {
				await this.resolveDataList(c);
			}
		}
	}

	public onTimeModelsInfoMouseenter(event: MouseEvent, element: HTMLDivElement) {
		clearTimeout(this.timeModelsPopoverLeaveTimeout);
		if (element.classList.contains('hidden')) {
			element.classList.remove('hidden');
		}
	}

	public onTimeModelsInfoMouseleave(event: MouseEvent, element: HTMLDivElement) {
		if (!element.classList.contains('hidden')) {
			clearTimeout(this.timeModelsPopoverLeaveTimeout);
			this.timeModelsPopoverLeaveTimeout = setTimeout(() => {
				element.classList.add('hidden');
			}, 1000);
		}
	}

	private async callGetDataList(selectionSource: string) {
		const { ListOfDataListItem } = await lastValueFrom(
			this.http.callAPI('getDataList', { listName: selectionSource }) as Observable<ApiResponse<DataListEntity[]>>
		);
		this.selectionSources[selectionSource] = ListOfDataListItem ?? [];
	}

	private getIdFromUrl() {
		return parseInt(this.route.snapshot.paramMap.get('id'), 10);
	}

	unselectEmployee() {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	private getInvalidControls(PersonalForm: FormGroup) {
		const invalid = [];
		const controls = PersonalForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
		return invalid;
	}

	public fixDateValues(event: Event, controlName: string) {
		if (event.target instanceof HTMLInputElement) {
			const control = this.PersonalForm.get(controlName);
			const date = moment(event.target.value, 'DD.MM.YYYY', true);
			if (date.isValid()) {
				control.setValue(date.toDate());
			}
		}
	}

	getDeputyState(employee: EmployeeEntity, teamleader: TeamLeaderEntity[]): any {
		return teamleader.find((t) => t.persnr == +employee.persnr);
	}

	setDeputyState(teamLeader: TeamLeaderEntity, state: number) {
		// clone the object, but write the new value
		const teamLeaderClone = { ...teamLeader, deputy_active: state };
		const aUtRequest: TxSetTeamleader = new TxSetTeamleader(teamLeaderClone);

		let myResponse: any;
		const apiAnfrage: Observable<TxSetTeamleader> = this.http.callAPI('setTeamleader', aUtRequest).pipe(take(1));

		apiAnfrage.subscribe(
			(myRequestData) => {
				myResponse = myRequestData;
				if (myResponse.statuscode !== 0) {
				} else {
					// get new teamleader data and update the store
					this.store.dispatch(LoadTeamleaderListRequest());
				}
			},
			(error) => {
				console.log('ERROR' + error);
				//         Messenger().post({
				//             message: 'ERROR' + error,
				//             type: 'error',
				//             showCloseButton: true,
				//         });
			}
		);
	}

	isAnyTeamleader(Employee: EmployeeEntity) {
		// true if employee has any teamleader assingments
		return Employee.assingments.length > 0;
	}

	getOptions(perm: UserPermissionEntity) {
		// fetch getDataList api endpoint and return the options
		const options = this.http.callAPI('getDataList', new TxGetDataList(perm.selection_source)).pipe(take(1));
		return options;
	}
}
