<div class="min-h-full min-w-full flex flex-column">
	<h2 mat-dialog-title class="flex justify-content-between">
		<span>{{ data.title }}</span>
        <div class="display-flex">
            <button mat-icon-button class="mr-4" color="primary" (click)="clickDownload()">
                <fa-icon icon="download"></fa-icon>
            </button>
            <button mat-icon-button mat-dialog-close>
                <fa-icon icon="times"></fa-icon>
            </button>
        </div>
	</h2>
	<div #content class="h-full">
		<div *ngIf="url$ | async as url">
            <pdf-viewer
                    [src]="url"
                    [original-size]="false"
                    [fit-to-page]="false"
                    [autoresize]="true"
                    [show-borders]="false"
                    [zoom-scale]="'page-fit'"
                    [zoom]="1"
                    [show-all]="true"
                    style="width: {{ fullWidth }}; height: {{ fullHeight }}"
            ></pdf-viewer>
		</div>
	</div>
</div>
