import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { EventsEffects } from './effects/events.effects';
import { UserEffects } from './effects/user.effects';
import { PersonalOfficeEffects } from './effects/personal-office.effects';
import { TimemodelEffects } from './effects/timemodel.effects';
import { UserStateEffects } from './effects/user-state.effects';
import { TeamleaderEffects } from './effects/teamleader.effects';
import { ProjectOfficeEffects } from './effects/project-office.effects';
import { ReportsEffects } from './effects/reports.effects';
import { PlansEffects } from './effects/plans.effects';
import { DataEffects } from './effects/data.effects';
import { MessageCenterEffects } from './effects/message-center.effects';

@NgModule({
	imports: [
		StoreModule.forRoot(reducers, {
			metaReducers,
		}),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
		}),
		EffectsModule.forRoot([
			EventsEffects,
			UserEffects,
			UserStateEffects,
			PersonalOfficeEffects,
			TimemodelEffects,
			TeamleaderEffects,
			ProjectOfficeEffects,
			ReportsEffects,
			PlansEffects,
			DataEffects,
			MessageCenterEffects,
		]),
	],
})
export class TxStoreModule {}
