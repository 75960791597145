import { Component, Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

interface TxConfirmDialogData {
	text?: string;
	title?: string;
	buttonTexts?: {
		cancel?: string;
		approve?: string;
	};
}

@Directive({
	selector: '[txConfirmClick]',
})
export class ConfirmDialogClickDirective {
	@Input() confirmText = 'Wollen Sie diese Aktion wirklich ausführen?';
	@Input() title = 'Sicherheitsabfrage!';
	@Input() bypass = false;
	@Input() buttonTexts: {
		cancel: string;
		approve: string;
	} = {
		cancel: 'Abbrechen',
		approve: 'Ja',
	};

	@Output() confirmClick = new EventEmitter();

	constructor(public dialog: MatDialog) {}

	@HostListener('click', ['$event'])
	onClick(event: Event) {
		if (this.bypass) {
			this.confirmClick.emit(event);
		} else {
			const dialogRef = this.dialog.open(TxConfirmDialogComponent, {
				data: {
					text: this.confirmText,
					title: this.title,
					buttonTexts: this.buttonTexts,
				},
			});
			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.confirmClick.emit(event);
				}
			});
		}
	}
}

@Component({
	selector: 'tx-confirm-dialog',
	template: `
		<h3>{{ data.title }}</h3>
		<mat-dialog-content class="dialog-content">
			{{ data.text }}
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="buttons">
				<button mat-raised-button mat-dialog-close>{{ data.buttonTexts.cancel }}</button>
				<button mat-raised-button color="primary" (click)="onApprove()">{{ data.buttonTexts.approve }}</button>
			</div>
		</mat-dialog-actions>
	`,
	styles: [
		`
			.buttons {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		`,
		`
			.dialog-content {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		`,
	],
})
export class TxConfirmDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<TxConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TxConfirmDialogData
	) {}

	onApprove() {
		this.dialogRef.close(true);
	}
}
