/* tslint:disable:variable-name */
export class TxInfocenterUser {
	constructor(
		public Personalnummer: number,
		public Name: string,
		public Abteilung: string,
		public Gruppe: string,
		public Status: string,
		public Tagesinfo: string,
		public FehlzeitId: string,
		public FehlzeitName: string,
		public LetzteBuchungsZeit: string,
		public LetzterBuchungsTyp: string,
		public AuftragAktiv: number,
		public AuftragsNummer: string,
		public AuftragsBezeichnung: string,
		public AuftragsschrittNummer: string,
		public AuftragsschrittBezeichnung: string,
		public AuftragsBuchung: string,
		public InfoDatum: string,
		public state_text: string
	) {}
}

export class InfocenterProtokoll {
	constructor(public statuscode: number, public statustext: string, public Infocenters: Array<TxInfocenterUser>) {
		if (Infocenters !== null && Infocenters !== undefined) {
			for (let i = 0, l = this.Infocenters.length; i < l; i++) {
				this.Infocenters[i] = new TxInfocenterUser(
					this.Infocenters[i].Personalnummer,
					this.Infocenters[i].Name,
					this.Infocenters[i].Abteilung,
					this.Infocenters[i].Gruppe,
					this.Infocenters[i].Status,
					this.Infocenters[i].Tagesinfo,
					this.Infocenters[i].FehlzeitId,
					this.Infocenters[i].FehlzeitName,
					this.Infocenters[i].LetzteBuchungsZeit,
					this.Infocenters[i].LetzterBuchungsTyp,
					this.Infocenters[i].AuftragAktiv,
					this.Infocenters[i].AuftragsNummer,
					this.Infocenters[i].AuftragsBezeichnung,
					this.Infocenters[i].AuftragsschrittNummer,
					this.Infocenters[i].AuftragsschrittBezeichnung,
					this.Infocenters[i].AuftragsBuchung,
					this.Infocenters[i].InfoDatum,
					this.Infocenters[i].state_text
				);
			}
		}
	}

	getUserInfo(aUsername: string) {
		if (this.Infocenters !== null && this.Infocenters !== undefined) {
			for (let i = 0, l = this.Infocenters.length; i < l; i++) {
				if (this.Infocenters[i].Name === aUsername) {
					return this.Infocenters[i];
				}
			}
		} else {
			return null;
		}
	}
}
