import { createAction } from '@ngrx/store';
import { ProjectEntity } from '../entities/ProjectEntity';
import { WorkstepEntity } from '../entities/WorkstepEntity';
import { WorkstepTypeEntity } from '../entities/WorkstepTypeEntity';
import { ProjectStateEntity } from '../entities/ProjectStateEntity';
import { ProjectDetailEntity } from '../entities/ProjectDetailEntity';
import { CalendarGroupEntity } from '../entities/CalendarGroupEntity';
import { EmployeeRelation } from '../entities/EmployeeRelation';
import { TeamLeaderEntity } from '../entities/TeamLeaderEntity';

export const FetchList = createAction('[PROJECT_OFFICE] fetch list', (state = 0) => ({ state }));

export const SetList = createAction('[PROJECT_OFFICE] set list', (list: ProjectEntity[]) => ({ list }));

export const AddProject = createAction('[PROJECT_OFFICE] add project', (project: ProjectEntity) => ({ project }));

export const DeleteProject = createAction('[PROJECT_OFFICE] delete project', (id: number) => ({ project_id: id }));

export const FetchWorksteps = createAction('[PROJECT_OFFICE] fetch worksteps');

export const SetWorksteps = createAction('[PROJECT_OFFICE] set worksteps', (worksteps: WorkstepEntity[]) => ({
	worksteps,
}));

export const AddWorkstep = createAction(
	'[PROJECT_OFFICE] add workstep',
	(name: string, workstepType: WorkstepTypeEntity) => ({ name, workstepType })
);

export const UpdateWorkstep = createAction('[PROJECT_OFFICE] update workstep', (workstep: WorkstepEntity) => ({
	workstep,
}));

export const DeleteWorkstep = createAction('[PROJECT_OFFICE] delete workstep', (id: number) => ({ id }));

export const FetchWorkstepTypes = createAction('[PROJECT_OFFICE] fetch worksteptypes');

export const SetWorkstepTypes = createAction(
	'[PROJECT_OFFICE] set worksteptypes',
	(workstepTypes: WorkstepTypeEntity[]) => ({ workstepTypes })
);

export const FetchProjectStates = createAction('[PROJECT_OFFICE] fetch project states');

export const SetProjectStates = createAction(
	'[PROJECT_OFFICE] set project states',
	(projectStates: ProjectStateEntity[]) => ({ projectStates })
);

export const RemoveProject = createAction('[PROJECT_OFFICE] remove project', (id: number) => ({ project_id: id }));

export const SaveProject = createAction('[PROJECT_OFFICE] save project', (project: ProjectDetailEntity) => ({
	project,
}));

export const InsertProject = createAction('[PROJECT_OFFICE] insert project', (project: ProjectDetailEntity) => ({
	project,
}));

export const SetSearchQuery = createAction('[PROJECT_OFFICE] project search', (query: string) => ({ query }));

export const SetFilterStatus = createAction('[PROJECT_OFFICE] filter status', (status: number) => ({ status }));

export const FetchCalendarGroup = createAction('[PROJECT_OFFICE] fetch calendar group');

export const SetCalendarGroup = createAction(
	'[PROJECT_OFFICE] set calendar group',
	(calendarGroup: CalendarGroupEntity[]) => ({
		calendarGroup,
	})
);

export const FetchEmploymentRelations = createAction('[PROJECT_OFFICE] fetch employment relations');

export const SetEmployeeRelations = createAction(
	'[PROJECT_OFFICE] set employment relations',
	(employeeRelations: EmployeeRelation[]) => ({ employeeRelations })
);

export const UpdateTeamleader = createAction('[PROJECT_OFFICE] update teamleader', (teamleader: TeamLeaderEntity) => ({
	teamleader,
}));

export const DeleteTeamleader = createAction('[PROJECT_OFFICE] delete teamleader', (teamleader: TeamLeaderEntity) => ({
	teamleader,
}));

export const CreateTeamleader = createAction('[PROJECT_OFFICE] create teamleader', (name: string, id: number) => ({
	teamleaderName: name,
	teamleaderUserId: id,
}));

export const SetDeputyState = createAction(
	'[PROJECT OFFICE] set deputy state',
	(state: number, teamleader_for: number) => ({ state, teamleader_for })
);
