import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './tx-error.template.html',
	encapsulation: ViewEncapsulation.None,
})
export class TxErrorComponent {
	@HostBinding('attr.style') class = 'error-page app';
	router: Router;

	constructor(router: Router) {
		this.router = router;
	}

	searchResult(): void {
		this.router.navigate(['/app', 'extra', 'search']);
	}
}
