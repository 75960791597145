import { createAction, props } from '@ngrx/store';
import { TxSession } from '../../shared/interfaces/session';
import { NotificationEntity } from '../entities/NotificationEntity';
import { MessageHeadEntity } from '../entities/Api/MessageHeadEntity';

export const performLogin = createAction(
	'[User] perform login',
	(username: string, password: string | null, stay: boolean | null) => ({ username, password, stay })
);

export const setSession = createAction('[User] set session', (session: TxSession | null) => ({ session }));

export const verifySession = createAction('[User] verify session', (session: TxSession | null) => ({ session }));

export const setPermissions = createAction('[User] set permissions', (permissions: string[] | null) => ({
	permissions,
}));

export const setHasContent = createAction('[User] set has content', (has_content: boolean) => ({ has_content }));

export const addPermission = createAction('[User] add permission', (permission: string) => ({ permission }));

export const removePermission = createAction('[User] remove permission', (permission: string) => ({ permission }));

export const requestNotification = createAction('[User] request notification', (id: string) => ({ id }));

export const addNotification = createAction('[User] add notification', (notification: NotificationEntity) => ({
	notification,
}));

export const removeNotification = createAction('[User] remove notification', (notification: NotificationEntity) => ({
	notification,
}));

export const setUnreadMessageCount = createAction('[User] set unread message count', (count: number) => ({ count }));
