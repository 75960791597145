import { Component } from '@angular/core';
import moment from 'moment/moment';

@Component({
	templateUrl: './tx-personal-files.tmoc.dialog.html',
	styleUrls: ['./tx-personal-files.tmoc.dialog.scss'],
})
export class TxPersonalFilesTmocDialogComponent {
	constructor() {}
    public fixDateValues(event: Event, input: HTMLInputElement) {
        if (event.target instanceof HTMLInputElement) {
            const date = moment(event.target.value, 'DD.MM.YYYY', true);
            if (date.isValid()) {
                input.valueAsDate = date.toDate();
            }
        }
    }
}
