import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from '../reducers/user.reducer';

export const getUser = createFeatureSelector<fromUser.State>('user');

export const getNotifications = createSelector(getUser, (s) => s.notifications);

export const getSession = createSelector(getUser, (s) => s.session);

export const getPermissions = createSelector(getUser, (s) => s.permissions);

export const getUnreadMessageCount = createSelector(getUser, (s) => s.unread_messages);

export const getHasContent = createSelector(getUser, (s) => s.has_content);
