<section class="widget" widget *ngIf="listOfUserInfo">
	<header *ngIf="!hideTitle">
		<h4>{{ 'tx-welcome.welcome' | translate }} {{ listOfUserInfo.name }}</h4>
		<h5 *ngIf="listOfUserInfo.state_name" style="margin-top: 1rem">{{ 'txBuchungen.userState' | translate}}: {{ listOfUserInfo.state_name }}</h5>
	</header>
	<div *ngIf="offlineBookings > 0" class="widget-body">
		<p class="font-weight-bold">{{ 'txBuchungen.bookingsInQueue' | translate }}: {{ offlineBookings }}</p>
	</div>
	<div
		class="widget-body"
		*ngIf="listOfUserInfo.user_info !== undefined"
		[innerHTML]="listOfUserInfo.user_info !== undefined ? listOfUserInfo.user_info : ''"></div>
</section>
