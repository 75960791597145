import { getSession } from '../../../store/selectors/user.selectors';
import { TxSession } from '../../../shared/interfaces/session';
import { FetchMessages } from '../../../store/actions/message-center.actions';
import { MessageCenterService } from '../../../shared/services/message-center.service';
import { getUserMessageList } from '../../../store/selectors/message-center.selectors';
import { State } from '../../../store/reducers/user-state.reducer';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { Component, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MessageEntity } from '../../../store/entities/MessageEntity';
import { TxChatComponent } from '../../tx-chat/tx-chat.component';

@Component({
	selector: 'tx-messagecenter-client',
	templateUrl: './tx-messagecenter-client.component.html',
	styleUrls: ['./tx-messagecenter-client.component.scss'],
})
export class TxMessageCenterClientComponent implements AfterViewInit {
	@Input()
	public fullHeight = false;

	public Messages$: Observable<MessageEntity[]>;

	public Type: string;
	public Message: string;
	public AnswerMessage: MessageEntity;

	public WithArchived = false;

	public CurrentUser$: Observable<TxSession>;

	public height = 600;

	@ViewChild('chat') private chat: TxChatComponent;

	constructor(private readonly store: Store<State>, private readonly service: MessageCenterService) {
		this.Messages$ = store.select(getUserMessageList).pipe(
			tap((msgs) => {
				setTimeout(() => {
					this.scrollToBottom();
				}, 100);
			})
		);
		this.CurrentUser$ = store.select(getSession);
	}

	ngAfterViewInit(): void {
		this.checkForFullHeight();
	}

	checkForFullHeight() {
		if (this.fullHeight) {
			setTimeout(() => {
				this.height = window.screen.availHeight - 223 - (this.AnswerMessage ? 58 : 0);
			});
		}
	}

	scrollToBottom(): void {
		try {
			this.chat.scrollToBottom();
			// this.messagesContainer.nativeElement.scrollTo(0, this.messagesContainer.nativeElement.scrollHeight);
		} catch (err) {}
	}

	sendMessage(message: string, answer: MessageEntity) {
		this.service.sendMessage(0, message, 0, answer);

		this.Type = null;
		this.Message = null;
		this.AnswerMessage = null;
	}

	archiveMessage(element: MessageEntity, remove: boolean = false) {
		this.service.archiveMessage(element.id, 0, this.WithArchived, remove);
	}

	refresh() {
		this.store.dispatch(FetchMessages(0, this.WithArchived));
	}
}
