import {
	SendMessage,
	SetUserMessages,
	ArchiveMessage,
	setMessageHeader,
} from '../../store/actions/message-center.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, of, switchMap } from 'rxjs';
import { FetchMessages, SetMessages } from '../../store/actions/message-center.actions';
import { State } from '../../store/reducers';
import { MessageEntity } from '../../store/entities/MessageEntity';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { MessageHeadEntity } from '../../store/entities/Api/MessageHeadEntity';
import { TxApiService } from './txapi.service';
import { getSession } from '../../store/selectors/user.selectors';
import { HeartbeatService } from './heartbeat.service';

@Injectable({
	providedIn: 'root',
})
export class MessageCenterService {
	constructor(
		private store: Store<State>,
		private actions$: Actions,
		private readonly api: TxApiService,
		private readonly heartbeatService: HeartbeatService
	) {
        heartbeatService.addToHeartbeat(
            'MessageCenterService',
			() =>
				of(null)
					.pipe(
						withLatestFrom(this.store.select(getSession)),
						filter(([_, session]) => session?.staff_office > 0),
						switchMap(() => this.callMessageHead()),
						tap((res: ApiResponse<MessageHeadEntity[]>) => {
							this.store.dispatch(setMessageHeader(res.MessageHead.map((m) => MessageHeadEntity.tryCast(m))));
						}),
						take(1)
					)
					.subscribe(),
			20
		);
		/*timer(0, 5000)
			.pipe(
				withLatestFrom(this.store.select(getSession)),
				filter(([_, session]) => session?.staff_office > 0),
				switchMap(() => this.callMessageHead()),
				tap((res: ApiResponse<MessageHeadEntity[]>) => {
					this.store.dispatch(setMessageHeader(res.MessageHead.map((m) => MessageHeadEntity.tryCast(m))));
				})
			)
			.subscribe();*/
	}

	public fetchMessageHead() {
		return this.callMessageHead().pipe(
			tap((res: ApiResponse<MessageHeadEntity[]>) => {
				this.store.dispatch(setMessageHeader(res.MessageHead.map((m) => MessageHeadEntity.tryCast(m))));
			})
		);
	}

	public callMessageHead() {
		return this.api.callAPI('getMessageHead').pipe(take(1));
	}

	public fetchMessages(employee_id: number, include_archived: boolean) {
		if (isNaN(employee_id)) {
			return of(null);
		}
		this.store.dispatch(FetchMessages(employee_id, include_archived));
		return this.actions$.pipe(ofType(SetMessages, SetUserMessages));
	}

	public sendMessage(to: number, message: string, employee_id: number = 0, parent: MessageEntity = null) {
		this.store.dispatch(SendMessage(message, to, employee_id, parent));
	}

	public archiveMessage(id: string, employee_id: number, archive: boolean, remove: boolean = false) {
		this.store.dispatch(ArchiveMessage(id, employee_id, archive, remove));
	}
}
