import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './tx-warning.dialog.component.html',
})
export class TxWarningDialogComponent {

    constructor(public dialogRef: MatDialogRef<TxWarningDialogComponent>) {
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
