import { Component, ElementRef, HostBinding, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { TerminalService } from '../shared/services/terminal.service';
import { Observable } from 'rxjs';

declare var jQuery: any;

@Component({
	selector: 'tx-layout',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
	@HostBinding('class.chat-sidebar-opened') chatOpened = false;
	@HostBinding('class.nav-collapsed') navOpened = false;
	@HostBinding('class.app') app = true;

	config: any;
	blockLayout = false;
	pinLoggedIn = false;

	constructor(
		private el: ElementRef,
		private router: Router,
		private myAuthService: AuthService,
		private myTerminalService: TerminalService,
		private renderer: Renderer2
	) {
		this.myTerminalService.pinLoggedIn.subscribe((data) => {
			this.pinLoggedIn = data;
			if (data) {
				this.renderer.addClass(document.body, 'pin-login-mode');
			} else {
				this.renderer.removeClass(document.body, 'pin-login-mode');
			}
		});
	}

	toggleSidebarListener(): void {
		this.navOpened = !this.navOpened;
	}

	toggleChatListener(): void {
		this.chatOpened = !this.chatOpened;
	}

	closeAllListener(): void {
		this.navOpened = false;
		this.chatOpened = false;
	}

	ngOnInit(): void {
		if (this.myAuthService.getSession().sessionid !== '') {
			this.blockLayout = false;

			const $el = jQuery(this.el.nativeElement);
			// this.$sidebar = $el.find('[sidebar]');

			this.router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					setTimeout(() => {
						this.navOpened = false;
						window.scrollTo(0, 0);

						$el.find('a[href="#"]').on('click', (e) => {
							e.preventDefault();
						});
					});
				}
			});

			this.router.events.subscribe(() => {
				this.navOpened = false;
				window.scrollTo(0, 0);
			});
		}
	}
}
