import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TxActiveBDEList } from '../../shared/interfaces/bde';

@Component({
	selector: 'tx-bde-assignment',
	templateUrl: './tx-bde-assignment.component.html',
	styleUrls: ['./tx-bde-assignment.component.scss'],
})
export default class TxBdeAssignmentComponent {
	@Input() assignment: TxActiveBDEList;
	@Input() i: number;

	@Output() end: EventEmitter<{
		aBuchungsart: string;
		aActiveAuftrag?: string;
		aActiveSchritt?: string;
		aIndex?: number;
		aKill?: boolean;
		note?: string;
		quantity?: number;
	}> = new EventEmitter<{
		aBuchungsart: string;
		aActiveAuftrag?: string;
		aActiveSchritt?: string;
		aIndex?: number;
		aKill?: boolean;
		note?: string;
		quantity?: number;
	}>();

	public EndPressed = false;

	public Note: string;
	public Quantity: number;
}
