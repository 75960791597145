import { setMessageHeader, SetMessages, SetUserMessages } from '../actions/message-center.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { MessageEntity } from '../entities/MessageEntity';
import { MessageHeadEntity } from '../entities/Api/MessageHeadEntity';

export interface State {
	list: MessageEntity[];
	userList: MessageEntity[];
	head: MessageHeadEntity[];
}

const initialState: State = {
	list: null,
	userList: null,
	head: null,
};

const messagesReducer = createReducer(
	initialState,
	on(SetMessages, (state, { messages }) => ({
		...state,
		list: messages?.slice() ?? [],
	})),
	on(SetUserMessages, (state, { messages }) => ({
		...state,
		userList: messages?.slice() ?? [],
	})),
	on(setMessageHeader, (state, { messageHead }) => ({
		...state,
		head: messageHead?.slice() ?? [],
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return messagesReducer(state, action);
}
