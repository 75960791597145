import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'array' })
export class ArrayPipe implements PipeTransform {
	transform<T>(value: T[], func: 'flat' = 'flat') {
		switch (func) {
			case 'flat':
				return value.flat();
			default:
				break;
		}
		return value;
	}
}
