import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TxWfCalendar } from '../interfaces/workflow';
import { Observable } from 'rxjs';
import { TeamleaderService } from '../services/teamleader.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class TeamleaderCalendarResolver implements Resolve<TxWfCalendar[]> {
	constructor(private api: TeamleaderService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<TxWfCalendar[]> | Promise<TxWfCalendar[]> | TxWfCalendar[] {
		return this.api.getTeamleaderCalendar(0, 1).pipe(take(1));
	}
}
