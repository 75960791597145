<ng-container>
    <div [formGroup]="pzeForm">
        <ng-container *ngIf="Session.KST == 1 || teamLeaderFor" class="grid">
            <mat-form-field *ngIf="BdeKst$ | async as BdeKst" appearance="fill" class="fullwidthDiv">
                <mat-label>{{ 'txScheduler.costCentre' | translate }}</mat-label>
                <mat-select formControlName="kst_number">
                    <!--                                    (selectionChange)="saveCalendarEntityValues('costCentre', $event.value)"-->
                    <mat-option *ngFor="let option of BdeKst" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="Timemodels$ | async as timemodels" class="grid">
            <mat-form-field appearance="fill" class="fullwidthDiv">
                <mat-label>{{ 'txScheduler.timemodel' | translate }}</mat-label>
                <mat-select formControlName="timemodel_id">
                    <mat-option *ngFor="let model of timemodels" [value]="model.value">
                        {{ model.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <div class="flex flex-col sm:flex-col lg:flex-row gap-2">
            <mat-form-field appearance="fill" class="w-full lg:w-auto">
                <mat-label>{{ 'general.date' | translate }}</mat-label>
                <input matInput formControlName="date" type="date">
            </mat-form-field>
            <wl-clockpicker
                    [autoclose]="true"
                    donetext="Fertig"
                    formControlName="start"
                    id="pze-cp-start"
                    appearance="fill"
                    [material]="true"
                    [placeholder]="'tx-bde.startTime' | translate"
                    class="w-full lg:w-auto">
            </wl-clockpicker>
            <wl-clockpicker
                    [autoclose]="true"
                    donetext="Fertig"
                    formControlName="end"
                    id="pze-cp-end"
                    appearance="fill"
                    [material]="true"
                    [placeholder]="'tx-bde.endTime' | translate"
                    class="w-full lg:w-auto">
            </wl-clockpicker>
        </div>

        <mat-form-field appearance="fill" class="fullwidthDiv">
            <mat-label>{{ 'general.note' | translate }}</mat-label>
            <textarea matInput rows="8" formControlName="note"></textarea>
        </mat-form-field>
    </div>
</ng-container>
