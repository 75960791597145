import { Action, createReducer, on } from '@ngrx/store';
import {
	SetFilterStatus,
	InsertProject,
	RemoveProject,
	SetSearchQuery,
	SetList,
	SetProjectStates,
	SetWorksteps,
	SetWorkstepTypes,
	SetEmployeeRelations,
} from '../actions/project-office.actions';
import { ProjectEntity } from '../entities/ProjectEntity';
import { WorkstepEntity } from '../entities/WorkstepEntity';
import { WorkstepTypeEntity } from '../entities/WorkstepTypeEntity';
import { ProjectStateEntity } from '../entities/ProjectStateEntity';
import { EmployeeRelation } from '../entities/EmployeeRelation';

export interface State {
	list: ProjectEntity[];
	worksteps: WorkstepEntity[];
	workstepTypes: WorkstepTypeEntity[];
	projectStates: ProjectStateEntity[];
	employeeRelations: EmployeeRelation[];
	searchQuery: string;
	filterStatus: number;
}

export const initialState: State = {
	list: null,
	worksteps: null,
	workstepTypes: null,
	projectStates: null,
	employeeRelations: null,
	searchQuery: '',
	filterStatus: 0,
};

const projectOfficeReducer = createReducer(
	initialState,
	on(SetSearchQuery, (state, { query }) => ({
		...state,
		searchQuery: query,
	})),
	on(SetFilterStatus, (state, { status }) => ({
		...state,
		filterStatus: status,
	})),
	on(SetList, (state, { list }) => ({
		...state,
		list,
	})),
	on(SetWorksteps, (state, { worksteps }) => ({
		...state,
		worksteps,
	})),
	on(SetWorkstepTypes, (state, { workstepTypes }) => ({
		...state,
		workstepTypes,
	})),
	on(SetProjectStates, (state, { projectStates }) => ({
		...state,
		projectStates,
	})),
	on(RemoveProject, (state, { project_id }) => ({
		...state,
		list: [...state.list.filter((p) => p.project_id !== project_id)],
	})),
	on(InsertProject, (state, { project }) => ({
		...state,
		list: [...state.list.filter((p) => p.project_id !== project.project_id), project],
	})),
	on(SetEmployeeRelations, (state, { employeeRelations }) => ({
		...state,
		employeeRelations,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return projectOfficeReducer(state, action);
}
