import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ListOfEmployeeGroupEntity } from '../../store/entities/ListOfEmployeeGroupEntity';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class EmployeeGroupsResolver implements Resolve<ListOfEmployeeGroupEntity[]> {
	constructor(private service: DataService) {}
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ListOfEmployeeGroupEntity[]> | Promise<ListOfEmployeeGroupEntity[]> | ListOfEmployeeGroupEntity[] {
		return this.service.getEmployeeGroups().pipe(take(1));
	}
}
