import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { AppConfigService } from './appconfig.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ErrorloggerService implements ErrorHandler {
	endpoint = 'logError';

	constructor(private http: HttpClient, private myAppConfig: AppConfigService) {}

	handleError(error: Error | HttpErrorResponse) {
		if (navigator.onLine) {
			if (error instanceof Error) {
				const currentSession = JSON.parse(localStorage.getItem('currentSession'));

				if (environment.production === false) {
					this.sendErrorToSlack(error, currentSession);
				}
				this.sendErrorToApi(error, currentSession);
			}
		}

		throw error;
	}

	handleResponse(response) {
		if (navigator.onLine) {
			const currentSession = JSON.parse(localStorage.getItem('currentSession'));

			if (environment.production === false) {
				this.sendErrorToSlack(response, currentSession);
			}
			this.sendErrorToApi(response, currentSession);
		}
	}

	sendErrorToSlack(error, currentSession) {
		if (environment.production) {
			const errorMsg = {
				attachments: [
					{
						fallback: 'Client Error.',
						color: 'danger',
						pretext: 'Ein Fehler ist aufgetreten',
						author_name: currentSession?.Name,
						title: error.name,
						text: error.message,
						fields: [
							{
								title: 'Stack',
								value: error.stack,
								short: false,
							},
						],
						ts: Math.round(new Date().getTime() / 1000),
					},
				],
			};

			this.http
				.post(environment.slackWebHook, errorMsg, {
					headers: new HttpHeaders({
						'Content-Type': 'application/x-www-form-urlencoded',
					}),
				})
				.subscribe();
		}
	}

	sendErrorToApi(error, currentSession) {
		if (environment.production) {
			const bodyJson = {
				hash: null,
				iun: currentSession?.iun,
				e_message: error.message,
				e_spec: navigator.userAgent,
				e_stack: error.stack,
				e_date: null,
			};

			const errorHash = Md5.hashStr(JSON.stringify(bodyJson));
			let sendErrors: string[] = JSON.parse(localStorage.getItem('sentErrors') ?? '[]');

			if (!sendErrors.includes(errorHash)) {
				if (sendErrors.length > 1000) {
					sendErrors = [];
				}

				sendErrors.push(errorHash);
				localStorage.setItem('sentErrors', JSON.stringify(sendErrors));

				bodyJson.e_date = Date();
				bodyJson.hash = Md5.hashStr(this.endpoint + JSON.stringify(bodyJson));
				const apiUrl = this.myAppConfig.config.TimeIXServerUrl + this.endpoint;
				this.http
					.post(apiUrl, bodyJson, {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
						}),
					})
					.subscribe();
			}
		}
	}
}
