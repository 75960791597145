<section class="waiting" [style.display]="calendarReady ? 'none' : 'block'">
	<div class="row title">
		<div class="col-xs-6">
			<h4 class="title">{{ 'txWorkflowCalender.pleaseWait' | translate }}</h4>
		</div>
	</div>
	<div class="widget-body">
		<div class="grid">
			<div class="col-12">
				<p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
				<h6>{{ 'txWorkflowCalender.data' | translate }}</h6>
			</div>
		</div>
	</div>
</section>

<div class="flatcalendar" [style.display]="!calendarReady ? 'none' : 'block'">
    <div class="mb-4" *ngIf="WfGroups">
        <mat-button-toggle-group
            name="workflowGroups"
            aria-label="Workflow Gruppen"
            multiple>
            <mat-button-toggle
                *ngFor="let group of WfGroups"
                class="workflow-toggle-button"
                [value]="group.id"
                [checked]="group.active"
                (click)="toggleGroup($event, group.id)"
                >{{ group.name }}</mat-button-toggle
            >
        </mat-button-toggle-group>
    </div>
	<div class="grid mb-1" *ngIf="myCalendarHasData">
		<div class="col-12 md:col-4 display-flex">
			<div class="mr-1">
				<button pButton type="button" icon="pi pi-refresh" (click)="refresh()" class="btn-primary btn-rounded"></button>
			</div>
			<div *ngIf="myCalendarHasData" class="mr-1">
				<button
					(click)="jumpToToday(true)"
					type="button"
					pButton
					icon="fa fa-clock"
					class="btn-primary"
					[label]="'txWorkflowCalender.today' | translate"></button>
			</div>
			<div *ngIf="showAllDataLoad">
				<button
					(click)="loadFullYear()"
					type="button"
					pButton
					icon="fa fa-calendar"
					class="btn-primary"
					[label]="'txWorkflowCalender.loadAllData' | translate"></button>
			</div>
		</div>
		<ul class="col-12 md:col-4 ngx-pagination ng-star-inserted">
			<li class="ng-star-inserted" [class.current]="itemsPerPage === 10">
				<a (click)="itemsPerPage = 10; persistItemsPerPage()"> 10 </a>
			</li>
			<li class="ng-star-inserted" [class.current]="itemsPerPage === 20">
				<a (click)="itemsPerPage = 20; persistItemsPerPage()"> 20 </a>
			</li>
			<li class="ng-star-inserted" [class.current]="itemsPerPage === 50">
				<a (click)="itemsPerPage = 50; persistItemsPerPage()"> 50 </a>
			</li>
			<li class="ng-star-inserted" [class.current]="itemsPerPage === 9999">
				<a (click)="itemsPerPage = 9999; persistItemsPerPage()"> {{ 'txWorkflowCalender.all' | translate }} </a>
			</li>
		</ul>
		<div class="col-12 md:col-4 ng-pagination">
			<pagination-controls
				class="MyPagination"
				(pageChange)="pagination = $event; persistItemsPerPage()"
				[directionLinks]="false">
			</pagination-controls>
		</div>
	</div>

	<!-- <div class="display-flex mb-1" *ngIf="WfGroups && myCalendarHasData">
		<div class="ui-g-12">
			<ng-template ngFor let-group [ngForOf]="WfGroups">
				<p-toggleButton
					class=""
					styleClass="outline-none shadow-none btn-primary focus:bg-gray-200 no-focus"
					[(ngModel)]="group.active"
					[offLabel]="group.name"
					[onLabel]="group.name"
					[style]="{ 'margin-right': '10px' }"
					(onChange)="toggleGroup($event, group.id)"></p-toggleButton>
			</ng-template>
		</div>
	</div> -->

	<div *ngIf="!myCalendarHasData">Keine Daten vorhanden</div>
	<div *ngIf="myCalendarHasData">
		<div class="scheduletable">
			<div class="pinned">
				<table>
					<thead>
						<tr class="month">
							<th *ngIf="TeamLeader" colspan="2">&nbsp;</th>
							<th *ngIf="!TeamLeader"></th>
						</tr>
						<tr>
							<th>Name</th>
							<th *ngIf="TeamLeader">{{ 'txWorkflowCalender.info' | translate }}</th>
						</tr>
					</thead>
				</table>
				<div class="table-overflow">
					<table id="headerpersons">
						<tbody>
							<ng-template
								ngFor
								let-person
								[ngForOf]="
									myCalendarRange.persons
										| groupFilter:WfGroups:groupFilterTrigger
										| paginate: { itemsPerPage: itemsPerPage, currentPage: pagination }
								"
								[ngForTrackBy]="trackByFn">
								<tr [ngClass]="{ 'with-info-field': TeamLeader }">
									<td *ngIf="TeamLeader" [matTooltip]="person.name" matTooltipPosition="above" [matTooltipShowDelay]="1000">
										<ng-template [ngIf]="checkCurrentUser(person.personalnr)">
                                            <div class="overflow-hidden font-bold">
                                                <a routerLink="/kalender">
                                                    {{ person.name }}
                                                </a>
                                            </div>
                                        </ng-template>
										<ng-template [ngIf]="!checkCurrentUser(person.personalnr) && isTeamLeader()">
                                            <div class="overflow-hidden text-nowrap ">
                                                <a [routerLink]="['/kalender', { id: person.personalnr, date: null }]">
                                                    {{ person.name }}
                                                </a>
                                            </div>
										</ng-template>
									</td>
									<td *ngIf="TeamLeader" [innerHTML]="person.info" style="line-height: 14px"></td>
									<td *ngIf="!TeamLeader" style="line-height: 14px">
										<ng-template [ngIf]="checkCurrentUser(person.personalnr)">
											<b>{{ person.name }}</b> {{ person.info }}</ng-template
										>
										<ng-template [ngIf]="!checkCurrentUser(person.personalnr)"
											>{{ person.name }} {{ person.info }}</ng-template
										>
									</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>
			</div>
			<div class="scrollable">
				<table class="calendar-data" id="headerdaymonth">
					<thead>
						<tr class="month">
							<ng-template ngFor let-month [ngForOf]="myCalendarRange?.month">
								<th *ngIf="month[2] > 5" [colSpan]="month[2]" class="month">{{ month[0] }} {{ month[1] }}</th>
								<th *ngIf="month[2] <= 5" [colSpan]="month[2]" class="month"></th>
							</ng-template>
						</tr>
						<tr class="days">
							<ng-template ngFor let-days [ngForOf]="myCalendarRange?.days" [ngForTrackBy]="trackByFn">
								<!--check if weekend and first date of month -->
								<th
									*ngIf="(days[1] == 'Sa' || days[1] == 'So') && days[0] == '01'"
									[ngClass]="{ today: days[4] == true }"
									id="mon-{{ days[2] }}-{{ days[3] }}">
									<span>{{ days[0] }}</span
									>{{ days[1] }}
								</th>
								<!--check if weekend and not first date of month -->
								<th
									*ngIf="(days[1] == 'Sa' || days[1] == 'So') && days[0] != '01'"
									[ngClass]="{ today: days[4] == true }">
									<span>{{ days[0] }}</span
									>{{ days[1] }}
								</th>
								<!--check if not weekend and first date of month -->
								<th
									*ngIf="days[1] != 'Sa' && days[1] != 'So' && days[0] == '01'"
									[ngClass]="{ today: days[4] == true }"
									id="mon-{{ days[2] }}-{{ days[3] }}">
									<span>{{ days[0] }}</span
									>{{ days[1] }}
								</th>
								<!--check if not weekend and not first date of month -->
								<th
									*ngIf="days[1] != 'Sa' && days[1] != 'So' && days[0] != '01'"
									[ngClass]="{ today: days[4] == true }">
									<span>{{ days[0] }}</span
									>{{ days[1] }}
								</th>
							</ng-template>
						</tr>
					</thead>
				</table>
				<div class="table-overflow" id="calendardatatable" (scroll)="onScrollCalendar($event)">
					<table>
						<tbody>
							<ng-template
								ngFor
								let-person
								[ngForOf]="
									myCalendarRange?.persons
										| groupFilter:WfGroups:groupFilterTrigger
										| paginate: { itemsPerPage: itemsPerPage, currentPage: pagination }
								"
								[ngForTrackBy]="trackByFn">
								<tr>
									<ng-template ngFor let-calentry let-i="index" [ngForOf]="person.data">
										<td
											*ngIf="calentry.isError === 1 && person.personalnr === currentPersoId"
											[style.backgroundColor]="calentry.fehlzeit_color"
											[routerLink]="['/kalender', { id: null, date: (calentry.datum | formatTime: 'YYYY-MM-DD') }]"
											[style.boxShadow]="'inset 1px 1px 0 red, inset -1px -1px 0 red'"
											[style.cursor]="'pointer'"
											[attr.class]="calentry.todayClass"
											[attr.title]="calentry.fehlzeit_name + ' ' + (calentry.datum | formatTime: 'DD.MM.YYYY')">
											<ng-template [ngIf]="calentry.table_text != null">
												{{ calentry.table_text }}
											</ng-template>
										</td>
										<td
											*ngIf="calentry.isError === 1 && person.personalnr !== currentPersoId"
											[style.backgroundColor]="calentry.fehlzeit_color"
											[routerLink]="[
												'/kalender',
												{ id: person.personalnr, date: (calentry.datum | formatTime: 'YYYY-MM-DD') }
											]"
											[style.boxShadow]="'inset 1px 1px 0 red, inset -1px -1px 0 red'"
											[style.cursor]="'pointer'"
											[attr.class]="calentry.todayClass"
											[attr.title]="calentry.fehlzeit_name + ' ' + (calentry.datum | formatTime: 'DD.MM.YYYY')">
											<ng-template [ngIf]="calentry.table_text != null">
												{{ calentry.table_text }}
											</ng-template>
										</td>
										<td
											*ngIf="calentry.isError !== 1"
											[style.backgroundColor]="calentry.fehlzeit_color"
											[attr.class]="calentry.todayClass"
											[attr.title]="calentry.fehlzeit_name + ' ' + (calentry.datum | formatTime: 'DD.MM.YYYY')">
											<ng-template [ngIf]="calentry.table_text != null">
												{{ calentry.table_text }}
											</ng-template>
										</td>
									</ng-template>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
