import { ApplicationRef, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, from, interval, of } from 'rxjs';
import { bufferCount, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { HeartbeatService } from './heartbeat.service';
import { environment } from '../../../environments/environment';

export interface Measure {
	start: number;
	end: number;
	len: number;
	delta: number;
	speed: number;
}

@Injectable()
export class ConnectionStateService {
	isOnlineSubject = new BehaviorSubject(true);
	isIntermediateSubject = new BehaviorSubject(false);
	pingMeasurement = new BehaviorSubject<Measure>({
		start: 0,
		end: 0,
		len: 0,
		delta: 0,
		speed: 1001,
	});
	onlineState = this.isOnlineSubject.asObservable();
	intermediateState = this.isIntermediateSubject.asObservable();
	forceOfflineSubject = new BehaviorSubject(false);

	connectionSpeedSubject = new BehaviorSubject(1000);
	connectionSpeed = this.connectionSpeedSubject.asObservable();

	offlineCachableEndpoints = ['createBuchung'];

	constructor(
		private myDeviceService: DeviceDetectorService,
		private myHttp: HttpClient,
		private myAuthService: AuthService,
		private heartbeatService: HeartbeatService,
		private appRef: ApplicationRef
	) {
		const txForceOfflineBooleanVal = Boolean(localStorage.getItem('txForceOffline') === 'true');
		this.forceOfflineSubject.next(txForceOfflineBooleanVal);
		window.addEventListener('online', () => this.checkOnlineState());
		window.addEventListener('offline', () => this.checkOnlineState());
		//this.pingInterval().subscribe();
		this.pingMeasurement
			.pipe(bufferCount(5))
			.subscribe((data) => this.connectionSpeedSubject.next(this.findAvgSpeed(data)));
	}

	findAvgSpeed(arr) {
		let min = arr[0].speed;
		let max = arr[0].speed;

		for (let i = 1, len = arr.length; i < len; i++) {
			const v = arr[i].speed;
			min = v < min ? v : min;
			max = v > max ? v : max;
		}
		const minIndex = arr.findIndex((data) => data.speed === min);
		const maxIndex = arr.findIndex((data) => data.speed === max);
		delete arr[minIndex];
		delete arr[maxIndex];
		const cleanArray = arr.filter(() => {
			return true;
		});
		const sum = cleanArray.reduce((acc, obj) => {
			return acc + obj.speed;
		}, 0);
		return sum / cleanArray.length;
	}

	pingInterval() {
		return interval(1000).pipe(
			map(() => {
				return environment.production
					? this.makeFileRequest('https://senesoft.cloud/timeix/txportal/ping.jpg').subscribe((measure) =>
							this.pingMeasurement.next(measure)
					  )
					: of({
							start: 0,
							end: 0,
							len: 0,
							delta: 0,
							speed: 1001,
					  }).subscribe((m: Measure) => this.pingMeasurement.next(m));
			})
		);
	}

	checkOnlineState(): void {
		this.isOnlineSubject.next(navigator.onLine);
		/*const session = this.myAuthService.getSession();
    if (session.always_online === 1) {
      this.isOnlineSubject.next(true);
    } else {
      this.forceOfflineSubject.subscribe(state => {
        if (state === true) {
          // offline is forced, dont care further
          this.isOnlineSubject.next(false);
        } else {
          this.isOnlineSubject.next(navigator.onLine);
        }
      });
    }*/
	}

	makeFileRequest(url: string) {
		const measure: Measure = {
			start: 0,
			end: 0,
			len: 0,
			delta: 0,
			speed: 0,
		};
		if (navigator.onLine) {
			return from(
				new Promise<Measure>((resolve, reject) => {
					const xhr = new XMLHttpRequest();
					measure.start = new Date().getTime();
					xhr.onreadystatechange = function () {
						if (xhr.readyState === 4) {
							if (xhr.status === 200) {
								// HOTFIX content-length with iis not recognised
								// measure.len = parseInt(this.getResponseHeader('Content-Length') || '0', 10);
								measure.len = parseInt(this.getResponseHeader('Content-Length') || '759', 10);
								measure.end = new Date().getTime();
								measure.delta = measure.end - measure.start;
								measure.speed = (measure.len * 1000) / measure.delta;
								resolve(measure);
							} else {
								reject(measure);
							}
						}
					};
					xhr.open('GET', url + '?' + Math.random(), true);
					xhr.send(null);
				})
			);
		} else {
			return of(measure);
		}
	}

	toggleOfflineState(): void {
		const forceOfflineStateToggledVal = !this.forceOfflineSubject.getValue();
		localStorage.setItem('txForceOffline', forceOfflineStateToggledVal.toString());
		this.forceOfflineSubject.next(forceOfflineStateToggledVal);
		setTimeout(() => this.checkOnlineState(), 500);
	}
}
