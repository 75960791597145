<div class="dialog-container">
    <h1 mat-dialog-title class="flex justify-between">
        <div>Barcode scannen</div>
        <div>
            <button mat-icon-button (click)="Enabled = false; dialogRef.close(null)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </h1>
    <div mat-dialog-content>
        <tx-code-scanner
                [qrScan]="true"
                [scannerEnabled]="Enabled"
                [configMode]="true"
                (scanResult)="receiveScanResult($event)">
        </tx-code-scanner>
    </div>
</div>
