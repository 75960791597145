<ng-container>
	<div mat-dialog-title class="display-flex flex-justify-content-space-between flex-align-items-center">
		<h3 class="m-0 mr-1">{{ 'txPlaning.assignPlan' | translate }}</h3>
		<div>
			<button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
				<fa-icon icon="times"></fa-icon>
			</button>
		</div>
	</div>
	<div class="plan-detail-content overflow-hidden pb-1" style="min-width: 600px" mat-dialog-content>
		<form [formGroup]="PlanSelectForm">
			<div>
				<mat-form-field *ngIf="Plans$ | async as Plans" class="w-100" appearance="outline">
					<mat-label>{{ 'txPlaning.plan' | translate }}</mat-label>
					<mat-select formControlName="selectedPlan" (valueChange)="planSelected($event, Plans)">
						<mat-option *ngFor="let plan of Plans" [value]="plan.id">{{ plan.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>{{ 'txApplyBooking.dateFrom' | translate }} - {{ 'txApplyBooking.until' | translate }}</mat-label>
					<mat-date-range-input [rangePicker]="fromToPicker">
						<input
							matStartDate
							[disabled]="data.view === 'resourceTimelineDay'"
							formControlName="startDate"
							[placeholder]="'txApplyBooking.from' | translate"
							(click)="fromToPicker.open()"
							required
							readonly />
						<input
							matEndDate
							[disabled]="data.view === 'resourceTimelineDay'"
							formControlName="endDate"
							[placeholder]="'txApplyBooking.until' | translate"
							(click)="fromToPicker.open()"
							required
							readonly />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [disabled]="data.view === 'resourceTimelineDay'" [for]="fromToPicker">
					</mat-datepicker-toggle>
					<mat-date-range-picker #fromToPicker [disabled]="false"></mat-date-range-picker>
				</mat-form-field>
			</div>
			<div class="flex align-items-center">
				<mat-form-field class="flex-grow-1 mr-4">
					<mat-label>{{ 'txPlaning.planTimeFromTo' | translate }}</mat-label>
					<div class="flex text-center">
						<input matInput [value]="PlanTime.start" disabled />
						<span>-</span>
						<input matInput [value]="PlanTime.end" disabled />
					</div>
				</mat-form-field>
				<button
					mat-flat-button
					type="button"
					color="primary"
					class="h-3rem"
					[disabled]="PlanSelectForm.invalid"
					[mat-dialog-close]="{ data: PlanSelectForm.value, time: 'plan' }">
                    {{ 'general.take' | translate }}
				</button>
			</div>
			<div class="flex align-items-center">
				<div class="flex text-center mr-4 flex-grow-1">
					<wl-clockpicker
						id="selected-time-start"
						class="mr-2 flex-grow-1"
						[placeholder]="'txPlaning.selectedTimeFrom' | translate"
						[material]="true"
						[donetext]="'txPlaning.done' | translate"
						name="timeFormMaterial"
						[autoclose]="true"
						[required]="true"
						appearance="standard"
						textAlign="center"
						formControlName="selectedStart"></wl-clockpicker>
					<wl-clockpicker
						id="selected-time-end"
						class="flex-grow-1"
						[placeholder]="'txPlaning.to' | translate"
						[material]="true"
						[donetext]="'txPlaning.done' | translate"
						name="timeFormMaterial"
						[autoclose]="true"
						[required]="true"
						appearance="standard"
						textAlign="center"
						formControlName="selectedEnd"></wl-clockpicker>
				</div>
				<button
					mat-flat-button
					type="button"
					color="primary"
					class="h-3rem"
					[disabled]="PlanSelectForm.invalid"
					[mat-dialog-close]="{ data: PlanSelectForm.value, time: 'custom' }">
                    {{ 'general.take' | translate }}
				</button>
			</div>
		</form>
	</div>
</ng-container>
